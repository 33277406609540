/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import Link from 'next/link';
import MenuLanguage from 'components/organisms/Header/MenuLanguage';
import UserMenu from 'components/organisms/Header/UserMenu';
import SiderMenuHeader from 'components/organisms/Sider/RightSider/SiderMenuHeader';
import { getTransText } from 'common/translate';
import {
  ACCESS_TOKEN,
  GET_SETTING,
  ROUTES,
  IS_ADMIN_ACCOUNT,
  HOME_PATH,
  CLIENT_ID_HEADER_PIXLR_DESIGN,
  LANG,
  WIDTH_TABLET,
  UNPL_CLIENT,
} from 'constants/index';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import * as cookie from 'helpers/cookies';
import { getLangRoute } from 'common/common';
import { Button, Divider, Skeleton } from 'antd';
import MainContexts from 'stores/context/MainContexts';
import { getStorage } from 'helpers/storage';
import FreeResearchImage from 'components/atoms/FreeResearchImage/FreeResearchImage';
import UseWindowWidth from 'helpers/CustomHooks/useWindowWidth';
import TTIIntroduction from 'components/atoms/TTIIntroduction/TourIntroduction';
import { LANGUAGE_CODE_DEFAULT } from 'constants/intl';
import UserInformationMenu from './UserInformationMenu';
import { onLogout } from './MenuSetting/MenuSetting';

const Header = ({ customColor, sticky, previewMode }) => {
  const router = useRouter();
  const [isShowLandingPage, setShowLandingPage] = useState(false);
  const [isCheckAuthentication, setCheckAuthentication] = useState(false);
  const [isUpdatPassword, setIsUpdatPassword] = useState(false);
  const [logoPath, setLogoPath] = useState('');
  const [hideImage, setHideImage] = useState(0);
  const [hideFootage, setHideFootage] = useState(0);
  const [hideAudio, setHideAudio] = useState(0);
  const [hideCredit, setHideCredit] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [pixlrAndDesignOption, setPixlrAndDesignOption] = useState(false);
  const [freeImageResearchActivated, setFreeImageResearchActivated] = useState(false);
  const [isShowTII, setShowTTI] = useState(true);
  const {
    initDataProvider,
    dataUserProfile,
    actionsUser,
    actionsCustomizedLanding,
    initDataCustomizedLandingProvider,
  } = useContext(MainContexts);

  const loading = initDataProvider?.loadingSettings;
  const getUserProfile = dataUserProfile;

  const windowWidth = UseWindowWidth();
  const lang = router?.query?.lang ?? cookie.getCookies(LANG) ?? LANGUAGE_CODE_DEFAULT;

  let needToHideTTI = false;
  let needToHideFree = false;
  let width1 = 1204;
  let width2 = 1088;

  switch (lang) {
    case 'it':
      width1 = 1366;
      width2 = 1366;
      break;
    case 'jp':
      width1 = 1249;
      width2 = 1175;
      break;
    case 'de':
      width1 = 1262;
      width2 = 1112;
      break;
    case 'pl':
    case 'ko':
      width1 = 1275;
      width2 = 1089;
      break;
    case 'nl':
      width1 = 1428;
      width2 = 1181;
      break;
    case 'pt':
      width1 = 1368;
      width2 = 1168;
      break;
    case 'tw':
      width1 = 1108;
      width2 = 1038;
      break;
    case 'es':
      width1 = 1363;
      width2 = 1145;
      break;
    case 'ru':
      width1 = 1465;
      width2 = 1250;
      break;
    case 'fr':
      width1 = 1340;
      width2 = 1145;
      break;
    case 'tr':
      width1 = 1266;
      width2 = 1114;
      break;
    default:
      break;
  }

  if (!isCheckAuthentication) {
    width1 -= 40;
    width2 -= 40;
  }

  if (windowWidth <= width1) needToHideFree = true;
  if (windowWidth <= width2) needToHideTTI = true;

  const needToHide = (needToHideTTI || needToHideFree) && router?.pathname !== ROUTES.LOGIN;

  const getHomePath = (imageHide, footageHide) => {
    if (imageHide) {
      return footageHide ? ROUTES.AUDIO : ROUTES.FOOTAGE;
    }
    return ROUTES.HOME;
  };

  const pathName = router?.pathname;

  const showFreeImageResearchPopUp = true;
  const showLandingPage = (
    <ul className="ul-header-forgot">
      {hideImage !== 1 && (
        <>
          <li
            className={`li-header-left ${pathName === ROUTES.HOME
                || ((router.query?.mediaType === '1' || router.query?.mediaType === undefined)
                  && pathName === ROUTES.SEARCH_IMAGE)
              ? 'active-menu'
              : ''
            }`}
          >
            <Link href={getLangRoute(ROUTES.HOME)}>{getTransText('photos')}</Link>
          </li>
          <li
            className={`li-header-left ${pathName === ROUTES.VECTOR
                || (router.query?.mediaType === '2' && pathName === ROUTES.SEARCH_IMAGE)
              ? 'active-menu'
              : ''
            }`}
          >
            <Link href={getLangRoute(ROUTES.VECTOR)}>{getTransText('vectors')}</Link>
          </li>
        </>
      )}
      {hideFootage !== 1 && (
        <li
          className={`li-header-left ${pathName === ROUTES.FOOTAGE || pathName === ROUTES.SEARCH_FOOTAGE ? 'active-menu' : ''
          }`}
        >
          <Link href={getLangRoute(ROUTES.FOOTAGE)}>{getTransText('footage')}</Link>
        </li>
      )}
      {hideAudio !== 1 && (
        <li
          className={`li-header-left ${pathName === ROUTES.AUDIO || pathName === ROUTES.SEARCH_AUDIO ? 'active-menu' : ''
          }`}
        >
          <Link href={getLangRoute(ROUTES.AUDIO)}>{getTransText('audio')}</Link>
        </li>
      )}
      {showFreeImageResearchPopUp && !needToHideFree && (
        <>
          <div style={{ color: '#A3A3A3', fontSize: 16 }}>|</div>
          <FreeResearchImage
            freeImageResearchActivated={false}
            changeFreeImageResearchStatus={setFreeImageResearchActivated}
          >
            <li
              className={`li-header-left ${pathName === ROUTES.IMAGE_RESEARCH_SERVICE ? 'active-menu' : ''
              }`}
            >
              <Link href={getLangRoute(ROUTES.IMAGE_RESEARCH_SERVICE)}>
                {getTransText('freeImageResearch')}
              </Link>
            </li>
          </FreeResearchImage>
        </>
      )}

      {isShowTII && !needToHideTTI && (
        <>
          <div style={{ color: '#A3A3A3', fontSize: 16 }}>|</div>
          <TTIIntroduction title={getTransText('textToImage')} desc={getTransText('descTourTTI')}>
            <li
              className={`li-header-left ${[ROUTES.TEXT_TO_IMAGE, `${ROUTES.TEXT_TO_IMAGE}/[hash]`].includes(pathName) ? 'active-menu' : ''
              }`}
            >
              <Link href={getLangRoute(ROUTES.TEXT_TO_IMAGE)} passHref>
                <a>
                  {getTransText('imageGenerator')}
                  <span className="new-link">{getTransText('New')}</span>
                </a>
              </Link>
            </li>
          </TTIIntroduction>
        </>
      )}

      {pixlrAndDesignOption && (
        <>
          <li className="li-header-left">
            <a href="https://pixlr.com/" target="_blank" rel="noreferrer">
              Pixlr
            </a>
          </li>
          <li className="li-header-left">
            <a href="https://designs.ai/" target="_blank" rel="noreferrer">
              Designs.ai
            </a>
          </li>
        </>
      )}
    </ul>
  );

  useEffect(() => {
    setCheckAuthentication(cookie.getCookies(ACCESS_TOKEN));
    if (initDataProvider?.getSettings?.logo_path) {
      setLogoPath(initDataProvider?.getSettings?.logo_path);
    } else {
      setLogoPath(getTransText('imageLogo'));
    }
  });
  useEffect(() => {
    if (cookie.getCookies(ACCESS_TOKEN)) {
      actionsUser.getNotiList(10);
      actionsUser.getCreditSummary('user');
    }
  }, [cookie.getCookies(ACCESS_TOKEN)]);

  useEffect(() => {
    const getSetting = getStorage(GET_SETTING);
    const isUNPL = getSetting?.show_hide_header?.hide_credit === UNPL_CLIENT;
    const hideTTI = (!getSetting?.tti_price && isUNPL) || !getSetting?.show_ai_generator;

    const homePath = getHomePath(
      getSetting?.show_hide_header?.hide_image,
      getSetting?.show_hide_header?.hide_footage,
    );
    cookie.setCookie(HOME_PATH, homePath);
    setHideImage(getSetting?.show_hide_header?.hide_image);
    setHideFootage(getSetting?.show_hide_header?.hide_footage);
    setHideAudio(getSetting?.show_hide_header?.hide_audio);
    setHideCredit(getSetting?.show_hide_header?.hide_credit);
    setPixlrAndDesignOption(getSetting?.client_id === CLIENT_ID_HEADER_PIXLR_DESIGN);
    setShowTTI(!hideTTI);
  }, [getStorage(GET_SETTING)]);

  useEffect(() => {
    setShowLandingPage(ROUTES.LOGIN === router.pathname);
    setIsUpdatPassword(ROUTES.UPDATE_PASSWORD === router.pathname);
  }, [router.pathname]);
  useEffect(() => {
    setIsAdmin(IS_ADMIN_ACCOUNT.includes(getUserProfile?.is_admin));
  }, [getUserProfile]);

  useEffect(() => {
    if (!previewMode) {
      actionsCustomizedLanding.setLogoConfigPreview(logoPath);
    }
  }, [logoPath]);

  return (
    <div className="header" style={{ background: customColor }}>
      <Link href={getLangRoute(ROUTES.HOME)} className="a-image">
        {loading ? (
          <div className="image-logo">
            <Skeleton active paragraph={false} />
          </div>
        ) : (
          <a className="logo-wrapper">
            <img
              className={`${logoPath ? 'image-logo' : 'image-logo-default'}`}
              alt=""
              src={logoPath}
            />
          </a>
        )}
      </Link>
      {!isShowLandingPage && <>{showLandingPage}</>}
      <div className="space" />
      {/* menu right */}
      {isUpdatPassword ? (
        <>
          <ul className="ul-right ul-header-forgot">
            <li className="li-header-dropdown">
              <SiderMenuHeader sticky={sticky} />
            </li>
          </ul>
          <ul className="ul-right ul-header-forgot ul-menu-deskop">
            <li className="li-header-dropdown">
              <MenuLanguage />
            </li>
          </ul>
          <ul className="ul-right ul-header-forgot">
            <li className="li-header-left ">
              <Button className="btn-logout" onClick={() => onLogout()}>
                {getTransText('logOut')}
              </Button>
            </li>
          </ul>
        </>
      ) : (
        <ul className="ul-right ul-header-forgot">
          {isCheckAuthentication && (
            <>
              {/* {(isAdmin && !hideCredit) && (
                <li className="plan-and-pricing">
                  <Link href="/">
                    {getTransText('plansAndPricing')}
                  </Link>
                </li>
              )} */}
              <li>
                <UserInformationMenu />
              </li>
              <li className="li-header-dropdown">
                <MenuLanguage />
              </li>
              <li className="li-header-dropdown">
                <UserMenu />
              </li>
            </>
          )}
        </ul>
      )}
      <ul className={`ul-right ul-header-forgot ul-menu ${needToHide && 'need-to-show'}`}>
        <li className="li-header-dropdown">
          <SiderMenuHeader sticky={sticky} />
        </li>
      </ul>
      {!isCheckAuthentication && !needToHide && (
        <>
          <ul className="ul-right ul-header-forgot ul-menu-deskop">
            <li className="li-header-dropdown">
              <MenuLanguage />
            </li>
          </ul>
          <ul className="ul-right ul-header-forgot">
            {!isShowLandingPage && (
              <li className="li-header-left">
                <Link href={getLangRoute(ROUTES.LOGIN)}>{getTransText('signin')}</Link>
              </li>
            )}
          </ul>
        </>
      )}
      {windowWidth <= WIDTH_TABLET && showFreeImageResearchPopUp && (
        <FreeResearchImage
          freeImageResearchActivated={false}
          changeFreeImageResearchStatus={setFreeImageResearchActivated}
        />
      )}
    </div>
  );
};
Header.propTypes = {
  customColor: PropTypes.string,
  sticky: PropTypes.bool,
  previewMode: PropTypes.bool,
};
Header.defaultProps = {
  customColor: undefined,
  sticky: false,
  previewMode: false,
};
export default Header;
