const nl = {
  // Header
  photos: "Stockfoto's",
  Photos: "Stockfoto's",
  vectors: 'Illustraties',
  Vectors: 'Illustraties',
  footageupper: 'Video',
  footage: 'Video',
  video: 'Video',
  audio: 'Audio',
  signin: 'Aanmelden',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Nederland.png',
  language: 'Taal',
  // Footer
  contact: 'Contact',
  contactUs: 'Neem contact met ons op',
  privacy: 'Privacybeleid',
  terms: 'Gebruiksvoorwaarden',
  cookie: 'Cookie Policy',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Wachtwoord vergeten',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Vul een geldig emailadres in!',
  emailCannotEmpty: 'Email kan niet leeg zijn',
  returnToLogin: 'Ga terug naar inloggen',
  email: 'Email',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: 'Gebruikersnaam',
  pw: 'Wachtwoord',
  rememberMe: 'Herinner mij',
  login: 'Inloggen',
  forgotPassword: 'Wachtwoord vergeten',
  loginAsGuest: 'Inloggen gast',
  // ResetPassword
  resetPwdDesc: 'Vul een nieuw wachtwoord in en we loggen u meteen in.',
  newPw: 'Nieuw Wachtwoord',
  confirmPwd: 'Bevestig wachtwoord',
  resetPwdButton: 'Reset wachtwoord',
  resetPwdTitle: 'Wijzig uw wachtwoord',
  enterYourNewPw: 'Vul uw nieuwe wachtwoord in.',
  pwMiniMumChars: 'Minimaal 7 karakters',
  pwMaxChar20: 'Het maximale aantal karakters is 20',
  pwLowerCase: 'Minimaal 1 kleine letter',
  pwUpperCase: 'Minimaal 1 hoofdletter',
  pwNumeral: 'Minimaal 1 cijfer',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Bevestig Wachtwoord is niet gelijk aan Wachtwoord',
  resetPwdInvalidLink: 'De link is ongeldig of verlopen.',
  pwHasBeenReset: 'Wachtwoord is gewijzigd, log in om verder te gaan.',
  // Admin index
  companyName: 'Bedrijfsnaam',
  phone: 'Telefoon',
  fax: 'Fax',
  address: 'Adres',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Totaal',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Oud wachtwoord',
  confirmNewPassword: 'Bevestig nieuw wachtwoord',
  returnToHome: 'Ga terug naar Home',
  // Cookie Policy
  langCookie01:
    'Wanneer u %host% bezoekt, plaatsen wij een klein tekstbestand (een cookie) op de harde schijf van uw computer.',
  langCookie02:
    'Dit bestand is een tracker en helpt ons om u basisfunctionaliteit te leveren zoals winkelwagentjes. Ze worden ook gebruikt om te zorgen voor een verbeterde online gebruikerservaring waaronder bijvoorbeeld de lichtbak (selectie) functionaliteit.',
  langCookie03:
    "De cookies helpen ons ook in de analyse van uw interactie met onze website (waaronder bezochte pagina's, zoektermen en bekeken foto’s). Dit is ongeacht of u ervoor kiest een aankoop te doen of niet.",
  langCookie04:
    'De cookies waarmee u instemt, worden ook gebruikt om een profiel van u te maken zodat we gerichte advertenties kunnen leveren afgestemd op uw interesses.',
  langCookie05:
    "Op de meeste internet browsers kunt u cookies weigeren. Als u dit doet, is het mogelijk dat bepaalde functies niet beschikbaar zijn en bepaalde webpagina's niet juist worden weergegeven.",

  downloadModalTitle: 'Beeld Download',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'TIFF bestanden, video en audio worden niet ondersteund. De volgende media:',
  messageFileNotSupported2ndHalf: 'zijn verwijderd van de bulk download.',
  licenseOnBehalf: 'Licentie namens',
  customizedPortal: 'Customized portal',
  normalDownload: 'Normale Download',
  myCredit: 'Mijn credit',
  lowCreditsReminder: 'Lage Credit',
  downloadModalId: 'Beeldnummer:',
  downloadModalPrice: 'Krediet:',
  clearAll: 'Wis alles',
  myActivities: 'Mijn activiteiten',
  info: 'Info',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'download deze content met behulp van',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Voer hier uw zoekopdracht in',
  toSearchAIKeyword: 'Voer trefwoorden, artikeltitel of blogtitel in',
  allImages: 'Alle beelden',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Recent bekeken',
  latestIMG: 'Latest Images',
  // Setting
  hello: 'Hallo,',
  adminControlPanel: 'Corporate+ Administratie Controle Paneel',
  downloadHistory: 'Download geschiedenis',
  hruc: 'HRUC',
  changePassword: 'Wijzig wachtwoord',
  logOut: 'Uitloggen',
  viewAccount: 'View Account',
  myAccount: 'Account',
  myCollections: 'Mijn collecties',
  personalCredits: 'Persoonlijk tegoed',
  groupTotal: 'Groep totaal',
  toTalUnpaidLicense: 'Totaal onbetaalde licenties',
  highRUnwatermarkComp: 'Hoge Resolutie voorbeeld zonder watermerk',
  monthSelection: 'Month selection',

  // Menu
  categories: 'Categorie',
  myLikebox: 'Mijn lichtbak',
  settings: 'Instellingen',
  notification: 'Kennisgeving',
  clear: 'Leeg',
  likebox: '收藏夹',
  credits: 'Credits',
  // Credit summary at header
  downloadAndUsege: 'Download en gebruik',
  totalDlCredit: 'Beschikbare credits',
  allocatedDlCredit: 'Toegewezen credits',
  personalAvailableCre: 'Persoonlijk beschikbare credits',
  groupAvailableCre: 'Beschikbare groepscredits',
  availableDlSlot: 'Beschikbare downloads',
  uNPLD: 'Gebruik Nu, Betaal Later',
  hiResHRUC: 'Hi-Res Unwatermarked Comps (HRUC)',
  monthlyAllocation: 'Maandelijkse toewijzing',
  remainingQuota: 'Resterend quotum',
  remainingHRUCQ: 'Resterende Hi-Res Unwatermarked Comps quota',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Groepen',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Laat ons weten hoe we je kunnen helpen.',
  liveChat: 'Live chat',
  whatsApp: 'WhatsApp',
  hiThere: 'Hallo daar,',
  // email response
  emailIsNotAvailable: 'Email is niet beschikbaar.',
  anErrorHaSoccured: 'Er is een fout opgetreden, porbeert u het opnieuw.',
  emailHasBeenSent:
    'Email is succesvol verzonden. Contoleer uw inbox om verder te gaan. Een link met wijzigingsinstructies is gestuurd naar __email. Als u de email niet binnen 5 minuten ontvangt checkt u dan de spambox.',
  tooManyAttempts: 'Te veel pogingen. Je bent tijdelijk geblokkeerd',
  // INVALIDPASSWORD
  inValidPassword: 'Ongeldig wachtwoord! Controleer of uw caps lock aanstaat.',
  userIsBlocked: 'Te veel pogingen. Je bent tijdelijk geblokkeerd',
  blockWarrings: 'Je wordt tijdelijk geblokkeerd na 10 pogingen!',
  // Update Password
  resetPwdUpdated: 'Uw wachtwoord is gewijzigd.',
  updatePwDfailed: 'Het is niet gelukt je wachtwoord te wijzigen!',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'minuten',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'Licentie overeenkomst',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Gebruikersnaam kan niet leeg zijn',
  passWordCannotEmpty: 'Wachtwoord kan niet leeg zijn',
  oldPasswordCannotEmpty: 'Oud wachtwoord kan niet leeg zijn',
  confirmPasswordCannotBeEmpty: 'Bevestig nieuw wachtwoord kan niet leeg zijn',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Kan geen e-mails meer versturen, controleer uw mailbox.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: 'Gebruik je een oudere browser?',
  upgradeBrowser: 'Upgrade naar een nieuwere browserversie voor een vlottere ervaring!',
  clickToDownload: 'Klik om een van deze te downloaden:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'Bekijk Alles',
  // Filter bar
  aiGenerate: 'AI-gegenereerde afbeelding',
  standardModels: 'Standaardmodellen',
  fineTunedModels: 'Fijn-afgestelde modellen',
  aiContent: 'Neem AI-gegenereerde inhoud op',
  filter: 'Filter',
  safeSearch: 'Veilig zoeken',
  aiAssist: 'AI Assistentie',
  freshness: 'Versheid',
  gender: 'Geslacht',
  sortBy: 'Sorteer op',
  mediaType: 'Soort media',
  orientation: 'Orientatie',
  modelPreferences: 'Model voorkeuren',
  people: 'Mensen',
  more: 'Meer',
  numOfPeople: 'Aantal mensen',
  age: 'Leeftijd',
  ethnicity: 'Etniciteit',
  collection: 'Collecties',
  uploaded: 'Uploaded',
  style: 'Stijl',
  cutOut: 'Vaste/Transparante achtergrond',
  doNotIncludeWords: 'Gebruik deze woorden niet',
  doNotIncludeWords_ex: 'ex. kaas',
  color: 'Kleur',
  max1Color: 'Max 1 kleur',
  license: 'Licentie type',
  fps: 'FPS',
  resolution: 'Resolutie',
  clipDuration: 'Tijdsduur Clip (minuten)',
  loop: 'Loop',
  category: 'Categorie',
  genre: 'Genre',
  tempo: 'Tempo (bpm)',
  // Filter button text
  Relevance: 'Willekeurig',
  New: 'Nieuw',
  Georank: 'Georank',
  DownloadHistory: 'Download geschiedenis',
  Popularity: 'Populariteit',
  Photography: 'Fotografie',
  VectorIllustration: 'Vektor Illustratie',
  Horizontal: 'Horizontaal',
  Landscape: 'Liggend',
  Portrait: 'Staand',
  Square: 'Vierkant',
  Panorama: 'Panorama',
  numPpl: 'Aantal mensen',
  WithoutPeople: 'Zonder mensen',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Zonder',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: 'Afgelopen week',
  LastMonth: 'Last month',
  PastThreeMonths: 'Afgelopen 3 maanden',
  PastTwodays: 'Afgelopen 2 dagen',
  Male: 'Man',
  Female: 'Vrouw',
  Standard: 'Standaard',
  extended: 'Uitgebreid',
  Editorial: 'Redactioneel',
  Commercial: 'Commercieel',
  Black: 'Black',
  Caucasian: 'Blank',
  AfricanAmerican: 'African American',
  Asian: 'Aziatisch',
  EastAsian: 'East Asian',
  Hispanic: 'Spaans',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: 'Kinderen',
  Teenagers: 'Pubers',
  Adults: 'Volwassenen',
  Seniors: 'Senioren',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Ja',
  Nonloopable: 'Nee',
  Music: 'Muziek',
  SoundEffects: 'Geluidseffecten',
  LogoAndIdents: "Logo's",
  Veryslow: 'Heel Langzaam',
  VeryslowSubText: '(0-79)',
  Slow: 'Langzaam',
  SlowSubText: '(80-119)',
  Medium: 'Medium',
  MediumSubText: '(120-139)',
  Fast: 'Snel',
  FastSubText: '(140-159)',
  Veryfast: 'Heel Snel',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Populaire',
  MostDownload: 'Meest Gedownload',
  Credits: 'Credits',
  Freshness: 'Versheid',
  pickAGenre: 'Kies een genre',
  // Paginations
  Of: 'van',
  Page: 'Pagina',
  // home
  recommended: 'Aanbevolen voor jou',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Trending zoektermen',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Game Sounds',
  cartoonSounds: 'Cartoon Sounds',
  soundPacks: 'Sound Packs',
  interfaceSounds: 'Interface Sounds',
  transitionsAndMovementsSounds: 'Transitions & Movement',
  natureSounds: 'Nature Sounds',
  domesticSounds: 'Domestic Sounds',
  urbanSounds: 'Urban Sounds',
  industrialSounds: 'Industrial Sounds',
  futuristicSounds: 'Futuristic Sounds',
  humanSounds: 'Human Sounds',
  othersSounds: 'Others',
  ambientSounds: 'Ambient',
  childrenSounds: "Children's",
  cinematicSounds: 'Cinematic',
  classicalSounds: 'Classical',
  corporateSounds: 'Corporate',
  electronicaSounds: 'Electronica',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  worldBeatSounds: 'World Beat',

  countrySounds: 'Country & Western',
  drumSounds: 'Drum & Bass',

  folkSounds: 'Folk & Acoustic',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Holiday & Seasonal',

  rockSounds: 'Rock',

  individualSounds: 'Individual',
  introSounds: 'Intro & Outro',
  ascSounds: 'Oplopend & Aflopend',
  businessSounds: 'Zakelijk & media',
  orchestralSounds: 'Orkest',
  festiveSounds: 'Feestelijk',
  percussiveSounds: 'Percussie',
  miscellaneousSounds: 'Divers',
  packsSounds: 'pakketten',
  percussionSounds: 'Percussie Geluiden',
  sampleLoopSounds: 'Sample Loop',

  // Search images
  searchImage: "Foto's & Vectoren",
  StockPhotosAndImages: "Stockfoto's en beelden",
  StockVectorClipartAndIllustration: 'Stock Vectoren en Illustraties',

  matches: 'overeenkomsten',
  editorial: 'Redactioneel',
  downloaded: 'Dit beeld is eerder gedownload',
  to: 'to',
  relatedSearches: 'Verwante zoekopdrachten',
  similarTo: 'Vergelijkbaar met',
  searchByImage: 'Zoek per beeld',
  options: 'Opties',
  searchByImageMsg:
    'Zoek 123RF met een afbeelding in plaats van tekst. Probeer een afbeelding naar het zoekvak te slepen.',
  mustBeJPGorPNG: 'Afbeelding moet JPG / PNG zijn',
  sizeLessThan5MB: 'Afbeeldingsgrootte moet kleiner zijn dan 5 MB',
  sizeLessThan4MB: 'Afbeeldingsgrootte moet kleiner zijn dan 4 MB',
  dragOrDropFile: 'Bestand slepen of',
  browse: 'bladeren',
  uploadedImage: 'Geuploade afbeelding',
  similarStockPhotos: 'vergelijkbare stockfoto&apos;s',
  // Search footage
  searchFootage: 'Video',
  StockFootage: 'Stockvideo',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageProperties: 'Eigenschappen beeldmateriaal',
  audioProperties: 'Audio-eigenschappen',
  modalDownloadTitle: 'Opties voor downloaden van bestanden',
  credit: 'Krediet',
  saveAs: 'Opslaan als',
  ownLicense: 'Normale download (eigen licentie)',
  downloadAs: 'Downloaden als',
  insufficientCredits: 'Onvoldoende credits',
  custom: 'Aangepaste',
  personalAndCom:
    'Persoonlijk en commercieel gebruik Advertenties, presentaties, sociale media, web.',
  licenseType: 'Licentie type',
  requiredForResale:
    'Vereist voor wederverkoop of distributie in theatervoorstellingen, betaalde toegang, streaming video, games en apps.',
  footageDetail: 'Detail beeldmateriaal',
  stockAudioKeywords: 'Trefwoorden voor standaardaudio',
  stockFootageKeywords: 'Trefwoorden',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'Stereo',
  fileSize: 'Bestandsformaten',
  footageId: 'Video ID',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  fileName: 'File Name',
  // Search audio
  searchAudio: 'Audio (Muziek & Geluidseffecten)',
  StockAudio: 'Stock audio',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Link kopiëren',
  preview: 'Voorbeschouwing',

  bestSellingFootage: 'Blader door de best verkochte royalty footage',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Vergelijkbare afbeeldingen',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'Je recent bekeken afbeeldingen worden hier weergegeven',
  rVVectors: 'Je recent bekeken vectoren worden hier weergegeven',
  rVFootage: 'Je recent bekeken video wordt hier weergegeven',
  rVAudio: 'Je recent bekeken audio wordt hier weergegeven',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'Oeps, je zoekopdracht met deze filters leverde geen resutaat op',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: 'Probeer wat filters te verwijderen of kies een andere zoekterm',
  emptyReverseSearchTitle: 'Geen match gevonden!',
  emptyReverseSearchSubTitle1:
    'Het geuploade beeld bevat errors en dient aan de volgende criteria te voldoen:',
  emptyReverseSearchSubTitle2: 'Check your refine search with keyword',
  emptyReverseSearchSubTitle3: 'Het beeld moet JPG/PNG zijn',
  emptyReverseSearchSubTitle4: 'Het beeldformaat is kleiner dan 5 MB',
  emptySearchResultClearFilter: 'Verwijder alle filters',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Vul een zoekterm in',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: 'Zoek Collecties',
  personal: 'Persoonlijk',
  groups: 'Groepen',
  createNew: 'Maak nieuwe',
  createNewCollection: 'Maak een nieuwe collectie',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: 'TITEL',
  selectGroup: 'Selecteer groep',
  description: 'Beschrijving',
  charactersRemaining: 'Nog 180 karakters over',
  cancel: 'Cancel',
  create: 'Maak',
  or: 'OR',
  defaultCollection: 'Standaard collectie',
  addToMyCollections: 'Aan Lichtbak toevoegen',
  addedToCollection: 'Toegevoegd aan collectie',
  removedFromCollection: 'Verwijderd uit collectie',
  collectionModalPlaceholder: 'B.v. Natuur',
  groupNameShowsHere: 'Bekijk de groep naam',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: 'Deel met mij',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Eigenaar',
  images: 'Afbeeldingen',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Persoonlijke',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: 'Deel',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: 'Je hebt geen collecties',
  btnUnDo: 'Ongedaan maken',
  // my collection
  dateAdded: 'Datum toegevoegd',
  collectionName: 'Collectie naam',
  selectAll: 'Alles selecteren',
  delete: 'Delete',
  seeAll: 'Bekijk Alles',
  deleteCollection: 'Verwijder Collectie',
  deleteWarning:
    'Als je deze actie voltooit, worden je collecties en de inhoud ervan permanent verwijderd. Houd er rekening mee dat deze actie niet ongedaan kan worden gemaakt',
  createCollection: ' is gemaakt',
  shareCollection: 'Deel Collectie',
  shareWithTeam: 'Share in team',
  selectUsers: 'Selecteer gebruikers',
  searchUserName: 'Zoek een Gebruikersnaam',
  copy: 'Kopieer',
  titleCopyModal: 'Kopieer geselecteerde items naar',
  collectionUppercase: 'COLLECTIE',
  groupCollection: 'Groep',
  moveImage: 'Verplaats geselecteerde items naar',
  defaultSelect: 'Collectie naam hier',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Als je deze actie voltooit, worden je collecties en de inhoud ervan permanent verwijderd. Houd er rekening mee dat deze actie niet ongedaan kan worden gemaakt.',
  areYouSure: 'Weet je het zeker?',
  editCollection: 'Bewerk Collectie',
  collectionNameUpper: 'Collectie naam',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Bewerk',
  messageRemoveImage:
    'Weet je zeker dat je het/de geselecteerde item(s) uit de collectie wilt verwijderen?',
  titleRemove: 'Verwijder geselecteerde items',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'Opslaan',
  shareLikebox: 'Deel met mij',
  remove: 'Verwijderen',
  moveTo: 'Verplaatsen naar',
  createNewPlaceholder: 'B.v. Natuur',
  shareViaEmail: 'Deel via Email',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'Ontvangers Email',
  message: 'Bericht',
  insertReceipmentEmail: 'Ontvangst-e-mail invoegen',
  messageText: 'Bericht tekst',
  exportCollection: 'Exporteeer Collectie',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: 'Voeg Nieuwe Collectie toe',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'FORMAT',
  lowCredit: 'Lage Credit',
  downloadSuccess: 'Dank voor uw download! Uw download start zo.',
  downloadFailed: 'Download fout: Er is een fout opgetreden, porbeert u het opnieuw.',
  downloadedLabel: 'Gedownload',
  any: 'Any',
  resetFilter: 'Reset filter',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Volg',
  name: 'Naam',
  location: 'Lokatie',
  since: 'Sinds',

  // Account
  updated: 'Update',
  userName: 'GEBUIKERS NAAM',
  firstName: 'Voornaam',
  lastName: 'Achternaam',
  myProfile: 'My Profile',
  changeProfiledetail: 'Wijzig hier uw profielgegevens',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'Mijn pakket',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: 'Gebruik Nu, Betaal Later',
  bodyUNPL:
    'Use Now, Pay Later (UNPL) is een vorm van kortetermijnfinanciering waarmee consumenten aankopen kunnen doen en deze op een later tijdstip kunnen betalen, vaak rentevrij. Ook wel "point of sale leningen op afbetaling" genoemd.',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  // Download-history modal
  reDownload: 'Download opnieuw',
  chooseCustomTagOrCreate: 'Kies aangepaste tag(s) of maak een nieuwe aan',
  downloadDetails: 'Download details',
  image: 'Image',
  downloadType: 'Soort download',
  groupName: 'Groeps naam',
  size: 'Formaat',
  dateDownloaded: 'Datum gedownload',
  reLicense: 'Opnieuw licenseren',
  customFields: 'Aangepaste velden',
  saveAndClose: 'Bewaar en sluit',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'Datum',
  export: 'Exporteer',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Gratis Content',
  searchById: 'Zoek mbv ID',
  pleaseSelect: 'Selecteer',
  totalOutstanding: 'Totaal uitstaand',
  viewInvoices: 'Bekijk factuur',
  // Image detail page - select download size sidebar
  standardSizes: 'Standaardformaten',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'Crop',
  // empty data
  opsNothingToSeeHere: 'Oeps! Niets te zien hier.',
  emailMaxLength75: 'The maximum characters allowed is 75',

  // audio player
  categoryGenre: 'Categorie / Genre',
  duration: 'Lengte',
  seeMore: 'Meer',
  seeLess: 'Minder weergeven',
  audioID: 'Audio ID',
  // category
  landscape: 'Landschap',
  Babies: 'Babys',
  business: 'Business',
  food: 'Eten',
  animals: 'Dieren',
  technology: 'Technologie',
  healthcare: 'Gezondheidszorg',
  nature: 'Natuur',
  romance: 'Romantiek',
  // category vector
  graphic: 'Graphic',
  advertising: 'Advertising',
  flower: 'Flower',
  abstract: 'Abstrakt',
  Illustration: 'Illustratie',
  pop: 'Pop',
  bird: 'Bird',
  pixel: 'Pixel',
  threeD: '3D',

  // footage category
  education: 'Onderwijs',
  communication: 'Communicatie',
  beauty: 'Schoonheid',
  concept: 'Concept',
  fitness: 'Fitness',
  lifestyle: 'Lifestyle',
  celebration: 'Feestelijk',
  family: 'Familie',
  fashion: 'Mode',

  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Zakelijk',
  electronica: 'Electronisch',
  classical: 'Klassiek',
  funkAndGrooveMusic: 'Funk & Groove muziek',
  worldBeat: 'World Beat',
  cinematic: 'Filmisch',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Volgend Pagina',
  reverseImageSearch: 'Reverse Image Search',

  // search image
  authentic: 'Authentiek',
  // search audio
  mostDownloaded: 'Meest Gedownload',
  download: 'Download',

  group: 'Groep',
  needHelp: 'Hulp nodig?',
  copied: 'gekopieerd!',
  copyPortfolioLinkSuccess: 'Bijdragenportfolio -link gekopieerd naar klembord.',

  // my activities
  myActivity: 'Mijn activiteiten',
  recentLike: 'Mijn recente vind-ik-leuks',
  recentView: 'Mijn recente weergaven',
  myActivityViewDesc: 'De recente weergaveg eschiedenis kan maximaal 3 maanden worden opgeslagen',
  myActivityLikeDesc: 'Toont alleen de geschiedenis van de afgelopen 3 maanden',

  // dashboard
  profile: 'Profiel',

  // Pricing
  pricingCreditText: 'Credit',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'Alles',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Reset',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'AI-aangedreven zoeken',
  aiPoweredSearchOn: 'AI search staat aa',
  aiSearchIntroduction:
    'Probeer onze snellere en krachtigere zoekmachine. Onze AI -zoekopdracht zorgt voor de rest!',
  ok: 'OK',
  // Filter bar ai
  bstract: 'Abstrakt',
  macroCloseUp: 'Makro/Close up',
  object: 'Object',
  watercolor: 'Aquarel',
  flat: 'Vlak',
  cartoon: 'Cartoon',
  geometric: 'Geometrisch',
  gradient: 'Gradatie',
  isometric: 'Isometrisch',
  '3d': '3d',
  handDrawn: 'Getekend',
  natural: 'Natuurlijk',
  warm: 'Warm',
  dramatic: 'Dramatisch',
  vividAndBold: 'Levendig',
  blackAndWhite: 'Zwart Wit',
  // introduce Free image research
  imageResearchContentTitle:
    "Maak kennis met 123RF's gratis service voor beeld research: Perfecte beelden, geen gedoe",
  imageResearchContentDesc:
    'Wij revolutioneren beeld research voor zakelijke klanten, waardoor het moeiteloos is om de perfecte afbeelding te vinden en je visie tot leven te brengen.',
  submitRequest: 'Aanvraag indienen',
  imageResearchContentInfoTitle1: 'Eenvoudige beeld research',
  imageResearchContentInfoDesc1:
    'Aan de slag gaan is eenvoudig. Geef ons een gedetailleerde beschrijving van het gewenste beeld, inclusief stijl, sfeer, kleuren en specifieke elementen. Hoe meer details je geeft, hoe beter we aan je wensen kunnen voldoen.',
  imageResearchContentInfoTitle2: 'Hoe werkt het?',
  imageResearchContentInfoTitle3: 'Snelle, betrouwbare service',
  imageResearchContentInfoTitle4: 'Laat ons het werk voor je doen',
  imageResearchContentInfoDesc4:
    'Wij vinden de perfecte beelden voor je, zodat jij je kunt richten op je project. Vertrouw op ons om je visie tot leven te brengen met zorgvuldig gekozen beelden van hoge kwaliteit.',
  requestForm: 'Aanvraagformulier',
  whatDoYouWantThisImageFor: 'Waar wil je dit beeld voor gebruiken?',
  websiteBanner: 'Website banner',
  socialMedia: 'Sociale media',
  blogPost: 'Blogbericht',
  advertisement: 'Advertentie',
  print: 'Afdrukken',
  others: 'Andere',
  othersOutputPlaceholder: 'Voer andere soorten uitvoer in',
  whatIsPrimaryMessage:
    'Wat is de belangrijkste boodschap of het thema dat je met de afbeelding wilt overbrengen?',
  whatIsPrimaryMessagePlaceholder: 'Bijv. hond rent met vrouw in het park',
  areThereSubjectYouWantInThePicture:
    'Zijn er specifieke onderwerpen of elementen die je in de afbeelding wilt opnemen?',
  egPeopleNatureTechnologyAbstract: 'Bijv. mensen, natuur, technologie, abstracte concepten',
  areThereImageOrStyleReference:
    'Zijn er beelden of stijlen die je eerder hebt gezien en die je ons wilt laten gebruiken als inspiratie of referentie?',
  ifPeopleIncludeWhatTheirDemographic:
    'Als er mensen in het beeld moeten worden opgenomen, wat moet dan hun demografie zijn?',
  egAgeGenderEthnicityOccupation: 'Bijv. leeftijd, geslacht, etniciteit, beroep',
  areThereAnyThingToAvoid:
    "Zijn er elementen, onderwerpen of thema's die je wilt vermijden in het beeld?",
  whoisYourTargetAudience: 'Wie is je doelgroep?',
  whoisYourTargetAudiencePlaceholder: 'Bijv. jonge volwassenen die graag lezen',
  typeOfImage: 'Type afbeelding',
  whatMoodShouldImageEvoke: 'Welke stemming of emotie moet de afbeelding oproepen?',
  happy: 'Vrolijk',
  sad: 'Droevig',
  calm: 'Kalm',
  excited: 'Opgewonden',
  angry: 'Boos',
  enterCustomMood: 'Aangepaste stemming invoeren',
  areThereAnyOrientationForImage: 'Zijn er specifieke oriëntaties nodig voor de afbeelding?',
  enterCustomOrientation: 'Aangepaste oriëntatie invoeren',
  isThereLocationYouWantImageToBeIn:
    'Is er een bepaalde locatie of instelling waar je de afbeelding wilt hebben?',
  no: 'Nee',
  yes: 'Ja',
  shareWithUsAReference: 'Deel een referentie met ons (alleen JPG of PDF. Max 4MB)',
  replace: 'vervangen',
  areThereAnySpecificRequirements: 'Zijn er nog andere specifieke vereisten of voorkeuren?',
  enterOtherComments: 'Voer andere opmerkingen in',
  submit: 'Verzenden',
  fieldCannotEmpty: 'Dit veld mag niet leeg zijn',
  selectAtLeastOne: 'Selecteer er ten minste één',
  fileMustBeJpgOrPdf: 'Bestand moet JPG of PDF zijn',
  fileSizeLessThan4MB: 'De bestandsgrootte moet kleiner zijn dan 4MB',
  maximumFileNameAllow255: 'Het maximum aantal toegestane tekens is 255',
  updateSuccessfully: 'We hebben je aanvraag ontvangen.',
  freeImageResearch: 'Gratis onderzoek naar afbeeldingen',
  newService: 'Nieuwe service!',
  freeImageResearchIntroduction:
    'Probeer onze beeldzoekservice en zie waarom onze beelden perfect aansluiten bij jouw wensen.',
  later: 'Later',
  letTryIt: 'Laten we het proberen!',
  imageResearchContentInfoDesc2: (
    <>
      Ons team combineert
      {' '}
      <strong>AI-technologie</strong>
      {' '}
      en ervaring om de ideale afbeeldingen voor
      je te vinden en geavanceerde algoritmen beperken de opties uit onze enorme bibliotheek op
      basis van jouw input. Ons
      {' '}
      <strong>team van toegewijde en ervaren researchers</strong>
      {' '}
      verfijnen de resultaten om perfect aan jouw criteria te voldoen.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      We leveren de resultaten binnen
      {' '}
      <strong>2 werkdagen</strong>
      {' '}
      en geven een uitgebreide uitleg bij de
      beeldselecties. Concentreer je op je bedrijf terwijl wij voor de rest zorgen.
    </>
  ),
  viewPdfOfSampleOutput: 'Bekijk PDF van voorbeeld',
  characterMaxChar255: 'Het maximum aantal toegestane tekens is 255',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'is vereist',
  requiredWithVariable: '{{var}} is vereist',
  mood: 'Stemming',
  person: 'Persoon',
  doNotIncludeWords_ex2: 'Bijv. groene kleur, steil haar',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'AI afbeelding generator',
  imageGenerator: 'Afbeeldings Generator',
  aiImageGeneratorDesc: 'Creativiteit met de snelheid van uw verbeelding.',
  generate: 'Genereren',
  exploreVisualsCreatedWithThePowerOfAI: 'Verken visuals gemaakt met de kracht van AI',
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Zet uw verbeelding om in werkelijkheid in 3 eenvoudige stappen',
  startWithGoodTextPrompt: (
    <>
      Begin met een
      {' '}
      <span>
        goed
        <br />
        tekstvoorstel
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'Een goede prompt garandeert goede resultaten. Een prompt moet perspectief, onderwerp, actie, trefwoorden, belichting en stijl bevatten voor de beste resultaten.',
  reviewYourAIGeneratedImages: (
    <>
      Je AI-gegenereerde
      {' '}
      <br />
      <span>afbeeldingen bekijken</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Onze AI-generator genereert en toont 3 voorbeeldafbeeldingen die het meest overeenkomen met je vraag.',
  downloadAndLicenseVisuals: (
    <>
      Visuals downloaden
      {' '}
      <br />
      <span>en licenseren</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'Je kunt deze afbeeldingen nu downloaden en licenseren voor persoonlijk en commercieel gebruik met een wettelijke dekking van $25.000.',
  needSomeIdeas: 'Ideeën nodig? Begin met deze voorbeelden en genereer je ideeën!',
  tryPrompt: 'Probeer prompt',
  creativityAtTheSpeedOfYourImagination: 'Creativiteit met de snelheid van je verbeelding',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Genereer je eigen AI-afbeeldingen van tekstaanwijzingen met Corporate+ in seconden!',
  tryNow: 'Probeer nu',

  /// Random prompt
  prompt1: 'Portret van een zelfverzekerde, maar benaderbare 28-jarige Koreaanse mannelijke zanger met een fascinerende podiumprésence. Toon zijn dynamische vocale capaciteiten en magnetische charme door middel van een expressief portret dat zijn opvallende kenmerken benadrukt. Weergeef zijn dikke, zwarte haar en intense, emotionele ogen in levendige details, en breng de diepte van zijn artistieke passie over. Kleed hem in stijlvolle, moderne kleding die zijn muzikale persoonlijkheid weerspiegelt, misschien met een vleugje eigenzinnigheid of verfijning. Positioneer hem in een omgeving die suggereert een live optreden of opnamestudio, en vang de energie en opwinding van zijn vakgebied. Gebruik dramatische belichting en kadrering om een cinematografische, larger-than-life kwaliteit te creëren, waarbij zijn status als een rijzende ster wordt benadrukt. Breng een gevoel van beweging en dynamiek in de compositie, alsof hij midden in het geven van een fascinerende vocale performance is. Streef naar een beeld dat de aandacht van de kijker onmiddellijk trekt en de onmiskenbare podiumprésence en vocale talent van de zanger laat zien.',
  prompt2: 'Een fotorealistisch beeld van een majestueuze leeuw die op een rots ligt, met de uitgestrekte Afrikaanse savanne op de achtergrond. Gebruik een camera met een 400mm lens bij een diafragma van F 2.8 om de achtergrond te vervagen en scherp te focussen op de leeuw. De belichting moet natuurlijk en warm zijn, en de gouden tinten van de zonsondergang vastleggen. Benadruk het fotorealisme om een levendig, gedetailleerd beeld te creëren dat de schoonheid en grandeur van de natuur laat zien.',
  prompt3: 'Een mixed media werkstuk dat een futuristische samurai in een actiepose afbeeldt, met een gloeiend katana, tegen een cyberpunk stadslandschap met neonlichten en hologrammen. Integreer een verscheidenheid aan texturen en materialen, waaronder metalen en digitale elementen, om een visueel rijke en dynamische scène te creëren.',
  prompt4: 'Een vrolijke digitale illustratie van een jonge prinses in een zwierige jurk, die speelt met magische wezens in een betoverd bos. Omring haar met kleurrijke bloemen, vlinders en sprankelend feeënstof, tegen een levendige en grillige achtergrond.',
  prompt5: 'Een gedetailleerde grafitekening van een oude krijger met een verweerd gezicht en een vastberaden uitdrukking, die een zwaard vasthoudt. Gebruik ingewikkelde schaduwen en texturen om het harnas en de littekens van de krijger te benadrukken, en creëer een tijdloos en krachtig beeld.',
  prompt6: "Een surrealistische gemengde mediacollage van een tijdreizende ontdekkingsreiziger die door verschillende historische tijdperken navigeert, met elementen zoals vintage kaarten, oude artefacten en futuristische gadgets. Gebruik een combinatie van oude krantenknipsels, foto's en digitale kunst om een humoristisch en tot nadenken stemmend werk te creëren.",
  prompt7: "Een gedetailleerde digitale illustratie van een dystopische stadsgezicht, met torenhoge wolkenkrabbers, vliegende auto's en donkere, onheilspellende wolken. Focus op het creëren van een gevoel van diepte en schaal, met ingewikkelde details in de architectuur en dramatische belichting om de stemming te versterken.",
  prompt8: 'Een portret van een wijze oudere die een magische staf vasthoudt, vastgelegd in dramatische clair-obscur belichting om de gezichtskenmerken van de oudere en de gloeiende runen van de staf te accentueren. Gebruik rijke texturen en schaduwen om een gevoel van mysterie en wijsheid te creëren.',
  prompt9: 'Een levendige fantasyillustratie van een drakenruiter die door de lucht zweeft, waarbij de ruiter in een harnas is gekleed en de vleugels van de draak wijd uitgespreid zijn. Omring hen met wolken en verre bergen, en voeg een gevoel van avontuur en majesteit toe.',
  prompt10: 'Een spontane zwart-wit straatfoto van een bruisende markt in een Aziatische stad, met verkopers die kleurrijk fruit en groenten verkopen. Gebruik een geringe scherptediepte om de gezichtsuitdrukkingen en interacties van de verkopers te benadrukken en de levendige sfeer vast te leggen.',
  prompt11: 'Een grillig aquarelschilderij van een eenhoorn met een regenboogmanen, staande in een magisch bos omringd door gloeiende paddenstoelen en sprankelende vuurvliegjes. Gebruik zachte, pastelkleuren en vloeiende penseelstreken om een dromerige, betoverende scène te creëren.',
  prompt12: 'Een 3D-rendering van een moderne keuken met een strak, minimalistisch ontwerp, roestvrijstalen apparaten en een groot eiland in het midden. Gebruik fotorealistische texturen en verlichting om de strakke lijnen en de moderne stijl te benadrukken, met de focus op de reflecties en materialen.',
  prompt13: 'Een fantasielandschapsschilderij van een zwevend eiland in de lucht, met watervallen die van de randen stromen en weelderige vegetatie die het oppervlak bedekt. Omring het eiland met donzige wolken en een levendige blauwe lucht, en creëer een gevoel van verwondering en magie.',
  prompt14: 'Een gedetailleerd portret van een Viking krijger met gevlochten haar en een woeste uitdrukking, een strijdbijl vasthoudend. De achtergrond moet een stormachtige zee en ruige kliffen afbeelden, met dramatische belichting om de kenmerken en de bepantsering van de krijger te benadrukken.',
  prompt15: "Een surrealistische gemengde media collage van een steampunk uitvinder in een rommelige werkplaats vol tandwielen, uurwerkmechanismen en stoomaangedreven constructies. Gebruik vintage foto's, oude blauwdrukken en metalen texturen om een visueel rijke en fantasierijke scène te creëren.",
  prompt16: "Een digitale illustratie van een futuristische stadsgezicht 's nachts, met torenhoge wolkenkrabbers, neonreclames en vliegende voertuigen. Gebruik levendige kleuren en dynamische belichting om een levendige en meeslepende sfeer te creëren, met de nadruk op de technologische vooruitgang en de bruisende energie van de stad.",
  prompt17: 'Een fotorealistische 3D-rendering van een knusse leeshoek met een comfortabele fauteuil, een klein tafeltje met een lamp en planken vol boeken. De setting moet warme, uitnodigende verlichting en zachte texturen hebben, die de serene en ontspannen ambiance van de ruimte vastleggen.',
  prompt18: 'Afbeelding van een transparante, glasachtige handtas met een volledig functioneel aquarium in het ontwerp. Gedetailleerd renderen van de delicate, zwemmende vissen in de handtas, waardoor een fascinerend en onconventioneel accessoire ontstaat. Zorg ervoor dat het aquarium naadloos in de structuur van de handtas is geïntegreerd, met naadloze overgangen tussen de twee elementen. Plaats de handtas prominent in een high-end warenhuisomgeving, omringd door een achtergrond van slanke, moderne schappen, verlichting en andere luxe winkeldisplays. Gebruik dramatische, gerichte verlichting om een gevoel van drama te creëren en de aandacht van de kijker te boeien, waardoor hun blik direct op de aquatische handtas wordt gericht. Leg de handtas vast vanuit een hoek die de transparante, bijna etherische kwaliteit laat zien, waardoor de vissen duidelijk zichtbaar zijn en een indruk van gewichtloosheid of vloeibaarheid ontstaat.',
  prompt19: 'Leg een knusse, uitnodigende scène vast met zachte fauteuils in een gedempte kleurenpalet van bruine en olijfgroene tinten. Toon de stoelen in een warme, intieme setting, omringd door details die een gevoel van ontspanning en comfort oproepen, zoals zachte textiel, rijke houten accenten en misschien een glimp van een open haard of een raam met uitzicht op een rustig natuurlijk landschap. Benadruk de weelderige, textuurrijke kwaliteit van de bekleding en het algemene gevoel van gezelligheid en rust.',
  prompt20: 'Leg een levendige, levensechte afbeelding vast van een kuifpatrijzen die bovenop een mosrijke tak zit, tegen een weelderige, smaragdgroene achtergrond die het prachtige verenkleed van de vogel aanvult. Render de gedetailleerde veren van de patrijzen met uiterste precisie, gebruikmakend van een subtiele gradiënt van licht en schaduw om hun texturen te accentueren. Benadruk de rijke, donkergroene kuif van de patrijzen, de warme, aardbruine snavel en de heldere, nieuwsgierige zwarte ogen, waardoor een fascinerend portret van de natuurlijke schoonheid van de vogel ontstaat. Breng een warme, rustige sfeer in de algehele scène, waardoor de kijker wordt uitgenodigd om de serene natuurlijke omgeving te betreden.',
  prompt21: 'Ontwerp een visueel aantrekkelijke afbeelding met een kom romige, bevroren yoghurt, overgoten met een levendig assortiment aan vers fruit en knapperige noten. Vang de verfrissende en voedzame eigenschappen van dit gezonde dessert, waarbij het contrast tussen de gladde, lichtgekleurde yoghurt en de levendige kleuren van de fruittopping wordt getoond. Schik de ingrediënten op een kunstzinnige, smakelijke manier, zodat de kijker de visuele aantrekkingskracht kan waarderen en de verfrissende, smaakvolle ervaring kan anticiperen. Zorg ervoor dat de algehele afbeelding een gevoel van puurheid en verwennerij uitstraalt, waardoor de kijker wordt uitgenodigd om te genieten van de heerlijke en voedzame eigenschappen van deze verrukkelijke traktatie.',
  prompt22: 'Dompel jezelf onder in een visueel feest, en verken een surrealistische bloementuin die barst van de levendige kleuren en ingewikkelde details. Vang de boeiende essentie van bloemen met krullende, spiraalvormige bloemblaadjes, gevarieerde bladeren en andere fascinerende tinten - elk een kunstwerk op zich. Gebruik de vloeibaarheid van aquarel en de expressiviteit van inkt, waarbij je een nat-in-nat techniek gebruikt om een gevoel van beweging en diepte te creëren. Breng de surrealistische, dromerige kwaliteit van dit bloemenwonderland over door het samenspel van rijke, verzadigde kleuren en de delicate, gedetailleerde weergave van elk bloemblad en blad.',
  prompt23: 'Creëer een boeiende nachtscène illustratie met een oude vuurtoren die hoog oprijst op een rotsachtig eiland. De vuurtoren, versierd met meerdere ramen, straalt een warme, uitnodigende gloed uit, waardoor de ingewikkelde architectuur en rijke geschiedenis worden benadrukt. Het rotsachtige eiland is bedekt met weelderige, groene bomen, waarvan de reflectie danst op het kalme, maanverlichte zeewater dat de zilveren straling van de volle maan weerspiegelt. De hemellichaam siert de hemel en werpt een etherische gloed op de wolken en de zee, terwijl waaiervormige wolken de helderheid gedeeltelijk verbergen, wat bijdraagt aan de mystiek van de scène. Twinkelende sterren bezaaien de inktzwarte uitgestrektheid en creëren een hemels tapijt dat harmonieert met de straling van de maan. Dit betoverende kunstwerk vangt een serene en vredige nacht, waar de verlichte vuurtoren standvastig staat te midden van de hemelse schoonheid, als een wachter dient en een filmische, dromerige ervaring creëert voor de kijker.',
  /// Tool list
  // ratio
  ratio: 'Verhouding',
  square1_1: 'Vierkant (1:1)',
  square: 'Vierkant',
  portrait2_3: 'Portret (2:3)',
  portrait: 'Portret',
  landscape3_2: 'Liggend (3:2)',
  wide16_9: 'Breed (16:9)',
  wide: 'Breed',
  // style
  none: 'Geen',
  enhance: 'Verbeteren',
  anime: 'Anime',
  photographic: 'Fotografisch',
  digitalArt: 'Digitale kunst',
  comicBook: 'Stripboek',
  fantasyArt: 'Fantasie kunst',
  analogFilm: 'Analoge film',
  neonPunk: 'Neon punk',
  lowPoly: 'Laagpolig',
  origami: 'Origami',
  lineArt: 'Lijnkunst',
  tileTexture: 'Tegel textuur',
  threeDModel: '3D model',
  pixelArt: 'Pixel kunst',
  craftClay: 'Ambachtelijke klei',
  // color
  coolTone: 'Koele toon',
  mutedColors: 'Gedempte kleuren',
  pastelColors: 'Pastelkleuren',
  vibrantColors: 'Levendige kleuren',
  warmTone: 'Warme toon',
  // lighting
  lighting: 'Verlichting',
  backlight: 'Tegenlicht',
  crepuscularRays: 'Crepusculaire stralen',
  dimlyLit: 'Gedimd licht',
  goldenHour: 'Gouden uur',
  lowLight: 'Laag licht',
  rimLighting: 'Randverlichting',
  studio: 'Studio',
  sunlight: 'Zonlicht',
  volumetric: 'Volumetrisch',
  // composition
  composition: 'Samenstelling',
  blurryBackground: 'Wazige achtergrond',
  closeUp: 'Close-up',
  macrophotograph: 'Macrofotografie',
  narrowDepth: 'Smalle diepte',
  shotFromAbove: 'Opname van bovenaf',
  shotFromBelow: 'Opname van onderaf',
  wideAngle: 'Groothoek',

  /// FQAs
  allAIImageQuestionsAnswered: 'Al uw AI-gegenereerde afbeeldingsvragen beantwoord.',
  TTI_Q1: 'Wat zijn AI-gegenereerde afbeeldingen?',
  TTI_A1:
    'AI-gegenereerde beelden zijn beelden die met behulp van kunstmatige intelligentie (AI) algoritmen zijn gemaakt. Deze algoritmen zijn ontworpen om menselijke creativiteit te simuleren en kunnen beelden genereren die lijken op die van menselijke kunstenaars, maar met unieke en vaak surrealistische of abstracte elementen.',
  TTI_Q2: 'Is er een limiet aan het aantal beelden dat ik per dag kan genereren?',
  TTI_A2:
    'Ja, er is een limiet aan het aantal afbeeldingen dat je dagelijks kunt genereren. De limiet zorgt ervoor dat alle gebruikers een eerlijke toegang en een goede gebruikerservaring hebben bij het gebruik van de AI beeldgeneratie service.',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: 'Wat is het verschil tussen het genereren en het in licentie geven van een afbeelding?',
  TTI_A4:
    'Wanneer je een afbeelding genereert, creëer je een set AI-afbeeldingen die je kunt bekijken. Wanneer je ze licenseert op Corporate+, krijg je officieel toegang om een hoge resolutie versie van de AI-afbeelding te downloaden met een eigen gebruikslicentie.',
  TTI_Q5:
    'Een afbeelding die ik heb gemaakt bevat een herkenbaar handelsmerk, herkenningspunt of publieke persoonlijkheid. Mag ik het nog gebruiken?',
  TTI_A5:
    'Wanneer u een afbeelding genereert, creëert u een set AI-afbeeldingen die u kunt bekijken. Wanneer u deze in licentie neemt met uw abonnement of credits, krijgt u officieel toegang tot het downloaden van een high-res versie van de AI-afbeelding met een eigen gebruikslicentie.',
  TTI_Q6: 'Wie zijn de mensen die in gegenereerde beelden voorkomen?',
  TTI_A6:
    'De mensen die verschijnen in door AI gegenereerde beelden zijn geen echte mensen maar digitale creaties.',
  TTI_Q7:
    'Wat gebeurt er met de content die ik heb gegenereerd? Worden ze beschikbaar gesteld aan andere gebruikers?',
  TTI_A7:
    'De content die u genereert kan ook beschikbaar worden gesteld aan andere gebruikers. U kunt onze licentie raadplegen voor meer informatie.',
  TTI_Q8: 'Waarom duurt het een tijdje om te laden als ik een afbeelding probeer te genereren?',
  TTI_A8:
    'Het genereren van de afbeelding duurt enkele seconden. De benodigde tijd hangt af van de complexiteit van de prompt, de beschikbare hardware en computermiddelen, en het aantal verzoeken dat de AI tegelijkertijd moet verwerken.',
  TTI_Q9: 'Hoe krijg ik een licentie voor de gegenereerde AI-afbeeldingen?',
  TTI_A9:
    'Je kunt een licentie nemen op de AI-afbeeldingen die je hebt gemaakt door ze te downloaden vanuit je Corporate+ account. Bij elke download ontvang je de hoge resolutie versie van de gegenereerde afbeelding en een wettelijke garantie van $25.000.',
  TTI_Q10: 'Kan ik vorige generaties bekijken?',
  TTI_A10:
    'Ja, dat kunt u doen door de URL van uw vorige AI generaties te bookmarken en opnieuw te bekijken. U kunt de resultaten van de generaties ook delen met uw vrienden.',
  TTI_Q11: 'Hoe genereer ik afbeeldingen van hoge kwaliteit met AI?',
  TTI_A11: `<ol>
              <li>Begin met een duidelijk doel: "Hoogwaardig beeld van een berglandschap voor een reisblog."</li>
              <li>Specificeer de gewenste stijl en sfeer: "Het beeld moet helder en levendig zijn met een natuurlijk, organisch gevoel."</li>
              <li>Beschrijf het onderwerp: Wees specifiek over het onderwerp van de foto, inclusief details zoals de omgeving, de belichting en andere belangrijke kenmerken of elementen. Bijvoorbeeld: "Laat een besneeuwde bergtop zien met een kronkelende rivier op de voorgrond en een helderblauwe lucht op de achtergrond."</li>
              <li>Geef voorbeelden: Voeg voorbeelden toe van andere afbeeldingen die een soortgelijke stijl of stemming hebben als degene die je zoekt. Dit kan het AI-algoritme helpen sturen en ervoor zorgen dat de uitvoer aan uw verwachtingen voldoet.</li>
              <li>Gebruik relevante trefwoorden: "berglandschap", "met sneeuw bedekte top" of "helder en levendig".</li>
              <li>Vermijd dubbelzinnigheid: Wees zo duidelijk en specifiek mogelijk bij het geven van instructies, en vermijd het gebruik van vage of dubbelzinnige taal die tot onverwachte resultaten kan leiden.</li>
            </ol>`,

  /// Generating page
  bringingYourImagesToLife: 'Uw beelden tot leven brengen...',
  cplusAiGenerator: 'AI Generator',
  generatedImages: 'Gegenereerde beelden',
  promptSettings: 'Instellingen prompt',
  pastGeneration: 'Vorige generaties',
  generateVariation: 'Variatie genereren',
  iLikeThis: 'Ik vind dit leuk',
  iDislikeThis: 'Ik vind dit niet leuk',
  shareOptions: 'Opties delen',
  variations: 'Variaties',
  copiedSharedUrl: 'Gedeelde URL gekopieerd naar klembord',

  galleryPrompt1: 'Etherische bescheiden zeemeermin, onder water, zeeschelpenkroon omringd door vissen, portret',
  galleryPrompt2: 'hartig zondaggebraad op tafel, vol eten en kaarsen',
  galleryPrompt3: 'Schattige pluizige vos lachend in een bos, zeer gedetailleerd, uitgezoomd met andere bosdieren',
  galleryPrompt4: 'hoge kwaliteit, 8K Ultra HD, muzieknoten instrument vormen in een pompoen gemaakt van kristal, zeer gedetailleerde, magische pompoen lamp, herfst gevoel, rustig',
  galleryPrompt5: 'Schotland panoramisch filmisch landschap, heldere mooie dag',
  galleryPrompt6: '3d stijl klein meisje, 4k, 8k, octane render fotorealistisch, hdr, fotografie, high definition, symmetrisch gezicht, volumetrische belichting, stoffige waas, foto, octane render, 24mm, 4k, 24mm, DSLR, hoge kwaliteit, 60 fps, ultra realistisch',
  galleryPrompt7: 'bruinharige man, held die planeet jupiter in de ruimte slaat, gezicht naar voren',
  galleryPrompt8: 'koekje in warme koffie, in een kerstomgeving',
  galleryPrompt9: 'Artistieke waterfles op een witte Minimalistische achtergrond met Montserra bladeren',
  galleryPrompt10: 'evanescent diamant fantasie draak',
  galleryPrompt11: 'planeten in dauwdruppels, planetaire dauwdruppels, bij zonsopgang, close-up, hyper gedetailleerd, scherpe focus, studiofoto, ingewikkelde details, zeer gedetailleerd',
  galleryPrompt12: 'een ekster zittend op een kist van goud, in de stijl van realistisch fantasy kunstwerk, edelsteen, donkere sprookjes, mysterieus',
  galleryPrompt13: 'een berg sneeuw en een spoor van ijs bovenop de berg die de andere kant op gaat, ultra hd, realistisch, levendige kleuren, zeer gedetailleerd, UHD tekening, pen en inkt, perfecte compositie, mooie gedetailleerde ingewikkelde waanzinnig gedetailleerde octane render trending op artstation, 8k artistieke fotografie, fotorealistische concept art, zacht natuurlijk volumetrisch filmisch perfect licht',
  galleryPrompt14: "Interstellaire beschaving tijdperk, staande hoog naar beneden met uitzicht op de mysterieuze digitale ruimte super sciencefiction buitenaardse stad gebouwen, Exquisite mooie buitenkant super wolkenkrabber toren gebouw, straat, sky bridge, hemel drijvende super sciencefiction architectuur, oorlog machine, high-tech, talloze elektronische schermverlichting, Super Sci-Fi visueel feest ruimte, Sci-Fi, mysterie, sci-fi, surrealistisch, superheldere foto's, HD-pixels, ultra HD, 5D, 8K resolutie, pixel perfect, perfecte ultrafijne details, scherpe focus, studiofoto, ingewikkelde details, zeer gedetailleerd",
  galleryPrompt15: 'Mooi strand met oceaan op de achtergrond in de Florida Keys bij nacht, neonkleuren, zwarte achtergrond, kalme golven, natuurlijke tint, zonsondergang, kalm, ontspannen zoom in golven, strand, rechtgetrokken horizon, roze, blauw, oranje, paars',
  galleryPrompt16: 'beste kwaliteit, dubbele belichting, mail art, kunst op een gebarsten papier, een close-up schattig kitten spelen met een bal wol gedetailleerde gezellige kamer interieur, glinsterende, zonlicht, zonnestralen, dynamische pose, verhalenboek illustratie, 2d, plat, schattig, schattig, vintage, sprookje, patchwork, glas in lood, verhalenboek gedetailleerde illustratie, filmische, ultra zeer gedetailleerde, kleine details, prachtige details, mystieke, levendige kleuren, complexe achtergrond',
  galleryPrompt17:
    'piramide op het water met vuurreflecties, bergen, donker surrealisme, sfeervolle portretten, scherpe focus',
  galleryPrompt18: 'rode panda in het bos, filmisch',
  galleryPrompt19: 'zee, maan, vissen, sterrenhemel, boot, kleurrijke illustratie',
  galleryPrompt20:
    'Levendige stedelijke reflecties dansen tussen opvallende gebouwen en glinsterend water in minimalistische abstracte stijl, levendige avond energie, geometrische abstractie, hedendaagse pop-art stijl met neon verlichting en acrylverf techniek',
  galleryPrompt21:
    'Achteraanzicht van een silhouet vrouw met gloeiend zwaard, cirkelvormig portaal in het poolgebied dat naar ijsbergen leidt, filmisch medium shot, monumentale figuur',

  /// error page
  copyrightDetected:
    'Auteursrechtelijk beschermde termen/persoonlijkheden of handelsmerken gedetecteerd',
  copyrightDetectedDesc:
    'We streven ernaar een verantwoordelijke AI-generator te zijn en zullen geen resultaten genereren voor auteursrechtelijk beschermde handelsmerken, termen, merken of persoonlijkheden in prompts om mogelijke inbreuk te voorkomen. In onze Servicevoorwaarden staat dat u de services NIET mag gebruiken op een manier die inbreuk maakt op intellectuele-eigendomsrechten, deze zich wederrechtelijk toe-eigent of schendt. Wijzig uw prompt en probeer het opnieuw.',
  errorMesTTI: 'Oeps! Het spijt ons, maar er is iets fout gegaan.',
  errorMesTTIDesc: 'Het probleem is geregistreerd voor onderzoek. Probeer het later nog eens.',
  tryAgain: 'Probeer het nog eens.',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished: 'De downloadquote zijn verbruikt',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Zoek prachtige afbeeldingen. Zoeken op trefwoorden of uitdrukkingen.',
  searchBarPlaceholderAllImageTablet: 'Zoek prachtige afbeeldingen.',
  searchBarPlaceholderAllImageMobile: 'Zoek afbeeldingen.',
  searchBarPlaceholderPhotoDesktop: "Zoek prachtige stockfoto's. Zoeken op trefwoorden of uitdrukkingen.",
  searchBarPlaceholderPhotoTablet: "Zoek prachtige stockfoto's.",
  searchBarPlaceholderPhotoMobile: "Zoek stockfoto's.",
  searchBarPlaceholderVectorDesktop: 'Zoek prachtige illustraties. Zoeken op trefwoorden of uitdrukkingen.',
  searchBarPlaceholderVectorTablet: 'Zoek prachtige illustraties.',
  searchBarPlaceholderVectorMobile: 'Zoek illustraties.',
  searchBarPlaceholderFootageDesktop: 'Zoek prachtige video. Zoeken op trefwoorden of uitdrukkingen.',
  searchBarPlaceholderFootageTablet: 'Zoek prachtige video.',
  searchBarPlaceholderFootageMobile: 'Zoek video.',
  searchBarPlaceholderAudioDesktop:
    'Zoek prachtige audio- en muziekbestanden. Zoeken op trefwoorden of uitdrukkingen.',
  searchBarPlaceholderAudioTablet: 'Zoek prachtige audio- en muziekbestanden.',
  searchBarPlaceholderAudioMobile: 'Zoek audio- en muziekbestanden.',
  searchBarPlaceholderFreeDesktop: 'Zoek prachtige gratis afbeeldingen. Zoeken op trefwoorden of uitdrukkingen.',
  searchBarPlaceholderFreeTablet: 'Zoek prachtige gratis afbeeldingen.',
  searchBarPlaceholderFreeMobile: 'Zoek gratis afbeeldingen.',
  // editorial warning
  forEditorialUseOnly: 'Alleen voor redactioneel gebruik',
  forEditorialUseOnlyDesc:
    'Gebruik van dit beeld voor adverteren of promotie doeleinden is verboden tenzij toegevoegde vrijgaven zijn gewaarborgd.',

  // download comp
  downloadHiResComp: 'DOWNLOAD HI-RES COMP',
  downloadComp: 'DOWNLOAD COMP',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),

  textToImage: 'Tekst naar beeld',
  descTourTTI: 'Laat je fantasie de vrije loop en maak afbeeldingen met tekst!',
  colorAndTone: 'Kleur en toon',
  titleSuccessDownload: 'We zijn blij dat je gevonden hebt wat je nodig had.',
  hangInThere: 'Hou vol! Het zal niet lang duren.',
  adultContentTitle: 'Potentiële volwassenen termen ontdekt',
  adultContentDesc:
    'We streven naar verantwoorde AI en genereren daarom geen resultaten voor bepaalde zinnen, concepten en/of ideeën. Wijzig je vraag (de beschrijving die je hebt gegeven om de afbeelding te genereren) en probeer het opnieuw.',
  reachedTheLimit:
    'Oh nee! Je hebt de limiet bereikt voor het genereren van AI afbeeldingen voor vandaag.',
  backTomorrow: 'Kom morgen terug voor meer AI generaties!',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: 'Om te beginnen, beschrijf hier je visie en wij brengen het tot leven!',

  downloadAgain: 'Download again',
  btnClose: 'Sluiten',

  fluxPro: 'Flux biedt geavanceerde, hoogwaardige en gevarieerde beeldgeneratie.',
  flux: 'Ons snelste model, speciaal ontworpen voor lokale ontwikkeling.',
  ideogram: 'Binnen enkele seconden een tekstbeschrijving omzetten in prachtige beelden.',
  realStockPhoto: 'Creëer moeiteloos boeiende stockfoto’s met ons geavanceerde AI-model.',
  realCinematic: 'Een model gespecialiseerd in het produceren van filmische outputs, ideaal voor bredere beeldverhoudingen.',
  realFood: 'Verbeter uw culinaire merk met ons voedselvisualisatiemodel.',
  realMacro: 'Een uitstekend model voor het genereren van gedetailleerde macrobeelden.',
  realBokeh: 'Een fotorealistisch stijlenmodel, perfect voor het maken van verbluffende bokehbeelden.',
  aiLicenseHeader: 'Dit bestand is gegenereerd door AI (Kunstmatige Intelligentie).',
  aiLicenseContent: 'Door kunstmatige intelligentie gegenereerde inhoud is niet onderworpen aan het standaard conformiteitscontroleproces van 123RF. Het is de verantwoordelijkheid van de maker om ervoor te zorgen dat dergelijke door AI gegenereerde materialen geen inbreuk maken op intellectuele eigendomsrechten of andere rechten van derden. Het produceren van spam, evenals alle inhoud die vals, misleidend, schadelijk, gewelddadig, pornografisch of illegaal is, is strikt verboden volgens ons beleid.',
  variousTitle: 'Ontdek onze verschillende modellen',
  variousSDXL: 'Breng verbluffende fotorealistische mensen en scènes tot leven.',
  variousEimisAnime:
    'Generatief AI-model ontworpen voor het maken van afbeeldingen in anime-stijl.',
  variousDynaVisionXL: 'Genereer 3D gestileerde stripfiguren.',
  variousJuggernautXL: 'Gecentreerd rond architecturale elementen en landschappen/achtergronden.',
  variousDreamShaperXL:
    'Upscaling in hoge resolutie, verbeterde generaties van personages en draken, minder randonscherpte.',
  variousRealismEngineSDXL:
    'Dit model is gespecialiseerd in zeer gedetailleerde, realistische beelden.',
  variousDALLE:
    'DALL-E 3, een allrounder model blinkt uit in precieze concept-naar-visuele transformaties.',
  aiEngine: 'AI-engine',
  'DALL-E': 'DALL-E 3',

  langText: 'Tekst',
  langElements: 'Elementen',
  langAuto: 'Auto',
  langBgRemoval: 'Achtergrond verwijderen',
  resetAll: 'Reset Alles',
  facebookImage: 'Facebook image',
  facebookLink: 'Facebook link',
  facebookCover: 'Facebook cover',
  facebookStory: 'Facebook story',
  instagramPost: 'Instagram post',
  instagramStory: 'Instagram story',
  twitterPost: 'Twitter post',
  twitterHeader: 'Twitter header',
  pinterestPost: 'Pinterest post',
  emailHeader: 'Email header',
  presentation: 'Presentatie',
  presentationWide: 'Wijde presentatie',
  ebookCover: 'E-book cover',
  linkedinPost: 'LinkedIn post',
  brochureCover: 'Brochure cover (A4)',
  postcard: 'Wenskaart',
  photoPrint: 'Foto print',
  youtubeProfileImage: 'YouTube profiel beeld',
  youtubeChannelCoverPhoto: 'YouTube kanaal cover foto',
  langApplyBgRemoval: 'Pas achtergrond verwijderaar toe',
  autoBnW: 'Auto Zwart Wit',
  autoAdjust: 'Auto aanpassen',
  autoPop: 'Auto Pop',
  original: 'Origineel',
  addHeader: 'Voeg kop toe',
  addTitle: 'Voeg titel toe',
  addBody: 'Voeg tekst toe',
  addAssistive: 'Voeg extra tekst toe',
  langCompare: 'Vergelijk',
  langEnableEditor: 'Bewerk afbeelding',
  langSimilarStock: 'Vergelijkbare afbeeldingen',
  langTextUndo: 'Ongedaan maken',
  langTextRedo: 'Doe opnieuw',
  langTextFont: 'Font',
  langTextFontSize: 'Font formaat',
  langTextColor: 'Tekst kleur',
  langTextHighlight: 'Highlight',
  langTextAlignment: 'Uitlijning',
  langTextBold: 'Vet',
  langTextItalic: 'Cursief',
  langTextUnderline: 'Onderlijnd',
  langTextLineSpacing: 'Regel spatiering',
  langTextLetterSpacing: 'Letter spatiering',
  langBringForward: 'Breng naar voren',
  langBringBackward: 'Breng naar achteren',
  langDuplicate: 'Dupliceer',
  langOpacity: 'Doorzichtigheid',
  langDelete: 'Verwijder',
  imageProperties: 'Beeld informatie',
  showMore: 'Meer',
  showLess: 'Minder weergeven',
  noEditCompareMsg: 'Er zijn geen bewerkingen gemaakt om te vergelijken.',
  stockPhoto: 'Stockfoto',
  basicShape: 'Basic Vorm',
  chitChat: 'Chit Chat',
  shapes: 'Vormen',
  elementChitChat: 'Element Chit Chat',
  organicAbstractShapes: 'Organische Abstracte Vormen',
  elementShapes: 'Element Vormen',
  langApplyingEdit: 'Bewerkingen toepassen...',
  langTextAllFonts: 'Alle fonts',
  langSearchFontType: 'Zoek font type',
  editorCompareImageBeforeTitle: 'Voor',
  editorCompareImageAfterTitle: 'Na',
  langSizePx:
    'Een pixel is de basiseenheid van een digitale afbeelding. De afbeeldingsgrootte, gemeten in pixels, bepaalt het detailniveau en de downloadgrootte.',
  langSizeDpi:
    'Dots per inch meet de afdrukresolutie. Een hogere dpi betekent meer detail. Voor afdrukken is de aanbevolen resolutie voor alle afbeeldingen 300 dpi.',
  cautionTTI:
    'Let op : We hebben het gebruik van zinnen in de prompt gedetecteerd die geassocieerd worden met handelsmerken, auteursrechten, bekende oriëntatiepunten en/of personen.  Houd er rekening mee dat U als enige verantwoordelijk bent voor het gebruik van de Inhoud, inclusief dat deze geen toepasselijke wetgeving schendt of inbreuk maakt op rechten.',
  downloadTTIBtn: 'Downloaden',

  addImageReference: 'Afbeeldingsreferentie toevoegen (optioneel)',
  uploadImageOnly: 'Upload alleen JPG of PNG. Maximaal 4MB.',
  uploadJPGImageOnly: 'Upload alleen JPG. Maximaal 4MB.',
  dragAndDrop: 'Bestand slepen of',
  errorFileSize: 'Dit bestand is te groot. Maximaal 4MB toegestaan.',
  adjustScale: 'Pas deze schaal aan om zeer gelijkende of andere resultaten te krijgen dan je geüploade afbeelding. Het aanbevolen bereik is 7 tot 15.',
  similar: 'Vergelijkbare',
  different: 'Verschillende',
  uploadNew: 'Nieuw uploaden',

  imageGuideGeneration: 'Je afbeelding leidt je prompt tijdens het genereren.',
  notAvailableForEngine: 'Niet beschikbaar voor geselecteerde AI Engine.',

  imagePromptDescribe: 'Om te beginnen geef je een voorbeeld van het beeld dat onze AI moet genereren door te beschrijven wat je in gedachten hebt.',
  fileTypeNotSp: 'Het bestandstype wordt niet ondersteund.',
  theFileSizeTooSmall: 'De bestandsgrootte is te klein. Minimaal 512px toegestaan.',

  relatedSearchPhoto: "Verwante zoekopdrachten naar stockfoto's",
  STOCK_PHOTO_PATH: 'stock-foto',
  STOCK_FOOTAGE_PATH: 'stockvideo',
  STOCK_AUDIO_PATH: 'stock-audio',
  CLIPART_VECTOR_PATH: 'clipart-vectoren',
  ALL_IMAGES: 'Alle Beelden',
  PHOTO: 'Photo',
  PHOTOS: "Stockfoto's",
  VECTORS: 'Illustraties',
  FOOTAGE: 'Video',
  AUDIO: 'Audio',
  LANG_MORETHANMILLION: "RECHTENVRIJE STOCKFOTO'S",
  LANG_SVG_OR_EPS: 'SVG or EPS',
  LANG_OR: 'Or',
  STANDARD_LICENSE: 'Standaard licentie',
  EXTENDED_LICENSE: 'Uitgebreide Licentie',
  CREDIT_PACK: 'Credit Pakket',
  DOWNLOAD_PACK: 'Downloadpakket',
  SUBSCRIPTION_PLAN: 'Abonnement',
  LANG_DOWNLOAD: 'Download',
  STOCKPHOTO: 'Stockfoto',
  STOCK_PHOTO: 'Stock Foto',
  STOCK_VECTOR: 'Stock Vector',
  PREMIUM_STOCKPHOTO: 'PREMIUM Stock Photo',
  PLUS_STOCK_PHOTO: 'PLUS Stock Foto',
  PLUS_STOCK_VECTOR: 'PLUS Stock Illustratie',
  MOBILE_STOCKPHOTO: 'Mobiele stockfoto',
  STOCKFOOTAGE: 'Stockvideo',
  IMAGEID: 'Beeldnummer',
  FONTID: 'ID',
  MEDIATYPE: 'Media type',
  COPYRIGHT_TEXT: 'Auteursrecht',
  KEYWORDS: 'Trefwoorden',
  LANG_CANCEL: 'Cancel',
  LANG_PURCHASE: 'Aankoop',
  LANG_STOCKVECTORSANDILLUS: 'Stock Vectoren en Illustraties',
  LANG_STOCKPHOTOSANDIMAGES: "Foto's en Afbeeldingen",
  LANG_MATCHES: 'overeenkomsten',
  LANG_VECTORRESULTS: '{number} {keyword} rechtenvrije vectorafbeeldingen voor je gevonden.',
  LANG_PHOTORESULTS: "{number} {keyword} rechtenvrije stockfoto's en afbeeldingen voor je gevonden.",
  LANG_SIMILARIMGS: "Vergelijkbare rechtenvrije foto's",
  LANG_COLLECTIONS: 'Collecties',
  LANG_ALL: 'Allen',
  LANG_STD: 'Standaard',
  LANG_EXTD: 'Uitgebreide',
  LANG_COMMERCIAL: 'Commercieel',
  LANG_SUBSCRIPTION: 'Abonnement',
  LANG_EDITORIAL: 'Redactioneel',
  LANG_UPLOADED: 'Uploaded',
  LANG_FRESHNESS: 'Versheid',
  LANG_ANYTIME: 'Elke periode',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: 'Afgelopen 2 dagen',
  LANG_PASTWEEK: 'Afgelopen week',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/maand",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/jaar",
  LANG_PASTMONTH: 'Afgelopen maand',
  LANG_PAST3MONTHS: 'Afgelopen 3 maanden',
  LANG_NUMOFPPL: 'Aantal Personen',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: 'Heb je de verificatie -e -mail niet ontvangen?',
  LANG_WITHOUTPEOPLE: 'Zonder Mensen',
  LANG_PERSON: 'Persoon',
  LANG_PEOPLE: 'Personen',
  LANG_INFANTS: "Baby's",
  LANG_TODDLERS: 'Toddlers',
  LANG_TEENS: 'Tieners',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'Blank',
  LANG_BLACK: 'Zwart',
  LANG_HISPANIC: 'Latijns-Amerikaans',
  LANG_EAST_ASIAN: 'Oost-Aziatisch',
  LANG_SOUTHEAST_ASIAN: 'Zuidoost-Aziatisch',
  LANG_INDIAN: 'Indian',
  LANG_MIDDLE_EASTERN: 'Midden-Oosters',
  LANG_AGE: 'Leeftijd',
  LANG_ALL_SHAPE: 'Toon Alles',
  LANG_ETHNICITY: 'Etniciteit',
  LANG_PEOPLEORMORE: '4 personen of meer',
  LANG_COLOR: 'Kleur',
  LANG_OTHEROPTION: 'Opties',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Vaste/Transparante achtergrond',
  LANG_FILTER_BY: 'Filteren op',
  LANG_FILTER: 'Filteren',
  LANG_PHOTOGRAPHY: 'Fotografie',
  LANG_VECTORILLUST: 'Vector Illustratie',
  LANG_SORTBY: 'Sorteer op',
  LANG_MORE: 'Meer',
  LANG_RELEVANCE: 'Resultaat',
  LANG_RESEND: 'Opnieuw verzenden',
  LANG_RESEND_VERIFICATION: 'Verificatie e-mail opnieuw verzenden',
  LANG_NEWEST: 'Nieuw',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: 'Oriëntatie',
  LANG_HORIZONTAL: 'Horizontaal',
  LANG_PORTRAIT: 'Portret',
  LANG_SQUARE: 'Vierkant',
  LANG_PANORAMA: 'Panorama',
  LANG_SELECTIVE_FOCUS: 'Focus',
  LANG_PATTERN: 'Patroon',
  LANG_VIBRANCE: 'Kleurrijk',
  LANG_VERIFICATION_DESCRIPTION: 'Je moet je account verifiëren voordat je content van 123rf download. Controleer je e -mail of klik op de onderstaande link als je deze opnieuw wilt verzenden.',
  LANG_VERIFICATION_TITLE: 'Controleer je e -mail.',
  VERIFICATIONRESETUNABLE: 'Oeps! Je e-mail kon niet worden verzonden. Neem contact op met ',
  LANG_CHOOSEUPTO3COLORS: 'U kunt 3 kleuren kiezen',
  LANG_ISOLATED: 'Geïsoleerd',
  LANG_STYLE: 'Stijl',
  LANG_SAFESEARCH: 'Veilig zoeken',
  LANG_ONLY_AUTHENTIC: 'Alleen authentiek',
  LANG_EXCLUDE_PLACEHOLDER: 'ex. cheese',
  LANG_EXCLUDEWORDS: 'Geen van deze woorden',
  LANG_EXCLUDE: 'Zonder',
  LANG_INCLUDE_AICONTENT: 'Neem AI-gegenereerde inhoud op',
  VISUAL_SEARCH: 'Visueel zoeken',
  VISUAL_SEARCH_TOOLTIP_TITLE: '3 Makkelijke manieren om zoeken binnen een beeld aan te passen.',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1. Klink op de punten in een beeld om het item te identificeren waar je op wilt zoeken.',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. Selecteer en gebruik de crop functie om in te zoeken of het zoek gebied kleiner te maken.',
  VISUAL_SEARCH_TOOLTIP_INFO_3: "3. Selecteer 'X' om naar de zoekfunctie terug te gaan.",
  VISUAL_SEARCH_NO_RESULTS: 'Je zoekopdracht heeft geen resultaten',
  LANG_RIS_TITLE: 'Omgekeerd afbeelding zoeken',
  LANG_RIS_NOTFOUND: 'Geen resultaat gevonden!',
  LANG_RIS_ERROR_HEADER: 'De geüploade afbeelding bevat fouten, controleer of de afbeelding aan de volgende criteria voldoet:',
  LANG_RIS_REFINE_SRCH_MSG: 'Controleer je zoekopdracht verfijnen met trefwoord',
  LANG_RIS_UPLOADED_IMAGE: 'Geüploade afbeelding',
  LANG_TELL_ME_MORE: 'Vertel me meer',
  LANG_ENTERKEYWORD: 'Vul een zoekterm in',
  LANG_SIZE: 'Formaat',
  LANG_STANDARD_SIZE: 'Standaardmaten',
  LANG_EXTENDED_SIZE: 'UITGEBREIDE MATEN',
  LANG_SIZE_PX: 'Een pixel is de basiseenheid van een digitale afbeelding. De afbeeldingsgrootte, gemeten in pixels, bepaalt het detailniveau en de downloadgrootte.',
  LANG_SIZE_DPI: 'Dots per inch meet de afdrukresolutie. Een hogere dpi betekent meer detail. Voor afdrukken is de aanbevolen resolutie voor alle afbeeldingen 300 dpi.',
  LANG_SOCMED_SIZE: 'Social media formaat',
  LANG_COMPARE: 'Vergelijk',
  LANG_AUTO_ENHANCE: 'Auto verbeter',
  LANG_AUTO: 'Auto',
  LANG_BG_REMOVAL: 'Achtergrond verwijderen',
  LANG_TEXT: 'Tekst',
  LANG_ELEMENTS: 'Elementen',
  LANG_IMAGE_INFO: 'Beeld informatie',
  LANG_FOLLOW: 'Ik wil  volgen',
  LANG_FOLLOWED: 'Gevolgd door',
  LANG_FOLLOWING: 'Following',
  FACEBOOK_IMAGE: 'Facebook image',
  FACEBOOK_LINK: 'Facebook link',
  FACEBOOK_COVER: 'Facebook cover',
  FACEBOOK_STORY: 'Facebook story',
  INSTAGRAM_POST: 'Instagram post',
  INSTAGRAM_STORY: 'Instagram story',
  TWITTER_POST: 'Twitter post',
  TWITTER_HEADER: 'Twitter header',
  PINTEREST_POST: 'Pinterest post',
  EMAIL_HEADER: 'Email header',
  PRESENTATION: 'Presentatie',
  PRESENTATION_WIDE: 'Wijde presentatie',
  EBOOK_COVER: 'E-book cover',
  LINKEDIN_POST: 'LinkedIn post',
  BROCHURE_COVER: 'Brochure cover (A4)',
  POSTCARD: 'Wenskaart',
  PHOTO_PRINT: 'Foto print',
  YOUTUBE_PROFILE_IMAGE: 'YouTube profiel beeld',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'YouTube kanaal cover foto',
  AUTO_COLOR: 'Auto-kleur',
  BALANCE: 'Balans',
  AUTO_LEVEL: 'Auto-level',
  AUTO_TONE: 'Auto-toon',
  ENRICH: 'Verrijken',
  RESET: 'Reset',
  RESET_ALL: 'Reset Alles',
  FILE_DOWNLOAD_OPTIONS: 'Bestand download opties',
  FILE_DOWNLOAD_INITIATED: 'Bestand Downloaden Gestart',
  IMAGE_PROPERTIES: 'Beeld informatie',
  DOWNLOAD_DETAILS: 'Download eigenschappen',
  FILE_SIZE: 'Bestands formaat',
  LICENSE: 'Licenseer',
  FILEFORMAT: 'Filetype',
  CHANGE: 'Verander',
  PRICING: 'Prijzen',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Bewaar als',
  IMAGE_USAGE: 'Beeld gebruik',
  PRINT: 'Print',
  ELECTRONIC: 'Electronisch',
  COMPREHENSIVE: 'Uitgebreid',
  DOWNLOAD_CREDITS: '<b>{quota}</b> credits van uw Credits',
  BASIC_SHAPE: 'Basic Vorm',
  CHIT_CHAT: 'Chit Chat',
  SHAPES: 'Vormen',
  ELEMENT_CHIT_CHAT: 'Element Chit Chat',
  ORGANIC_ABSTRACT_SHAPES: 'Organische Abstracte Vormen',
  ELEMENT_SHAPES: 'Element Vormen',
  BUY_SINGLE_IMAGE: 'Koop een enkel beeld<br/><b>{quota} credits</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Koop credits tegoed <br/><b>{quota} credits</b>',
  BUY_CREDIT_PACK: 'Koop een tegoed (credits) en<br/><b>bespaar meer</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota} Credit pakket voor <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'Geen maandelijkse kosten.',
  BUY_CREDIT_PACK_POINT_3: '<b>1 jaar</b> geldig.',
  BUY_SINGLE_IMAGE_PSUB: 'Koop een enkel beeld<br/><b>{quota} download bestand</b>',
  BUY_DOWNLOAD_PACK: 'Koop een download pack en<br/><b>bespaar</b>',
  BUY_DOWNLOAD_PACK_POINT_1: '{quota} beelden download pack voor <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Geen maandelijkse kosten.',
  BUY_DOWNLOAD_PACK_POINT_3: '<b>1 jaar</b> geldig.',
  LOWEST_PRICE: '<b>Laagste prijs</b> <br/>met een abonnement',
  LOWEST_PRICE_POINT_1: 'robeer 1 maand voor <b>{price}</b>',
  LOWEST_PRICE_POINT_2: "Download 10 foto's of illustraties",
  LOWEST_PRICE_POINT_3: 'Geen dagelijkse download limitet, neem ongebruikte downloads mee naar de volgende maand bij automatische verlenging',
  PER_IMAGE_SIZE: 'Per beeld <b>{size}</b> formaat',
  PER_IMAGE_ANY: 'Per beeld <b>ieder formaat</b>',
  PER_IMAGE: 'Per beeld',
  VIEW_PRICING: 'Meer opties en prijzen',
  DOWNLOAD_BEGIN: 'Uw download begint over enkele ogenblikken. Even geduld a.u.b.',
  DOWNLOAD_NOT_STARTING: 'Download nog niet begonnen?',
  SELECT_MIRROR: '[Selecteer een server]',
  SHOW_MORE: 'Meer',
  SHOW_LESS: 'Minder weergeven',
  LICENSE_TYPE: 'Licentie type',
  LICENSE_SUMMARY: 'Licentie Samenvatting',
  BACK: 'Terug',
  LANG_VECTOR: 'Vector',
  PREMIUM_VECTOR: 'PREMIUM Stock Vector',
  SCALE_ANY_SIZE: 'Naar elk formaat aanpassen',
  LANDINGPAGE_VECTOR_HEADLINE: 'Stock Illustraties, Vectoren en Clipart - 123RF',
  LANDINGPAGE_PHOTO_HEADLINE: 'Stock Fotografie, Rechtenvrije Afbeeldingen en Stockfoto - 123RF',
  CATEGORIES: 'Categorie',
  LANDINGPAGE_PRICING_1: 'Credits',
  LANDINGPAGE_PRICING_2: 'Abonnement Kopen',
  LANDINGPAGE_AS_LOW_AS: 'Vanaf slechts',
  EMPTY_SEARCH_TITLE: 'Oeps, je zoekopdracht gaf geen resultaten',
  LANG_SMALL: 'Klein',
  LANG_MEDIUM: 'Medium',
  LANG_LARGE: 'Groot',
  LANG_EXTRA_LARGE: 'Extra Groot',
  LANG_LOW_CREDITS: 'Het lijkt erop dat je nog maar weining credits over hebt!',
  LANG_TOPUP: 'Top up',
  LANG_TOPUP_NOW: 'Top up',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} Royalty-Vrije Foto, Plaatjes, Beelden en Stock Fotografie. Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} Royalty vrije SVG, Cliparts, Vectoren, en Stock Illustratie. Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: 'Afbeelding van {description} stockfoto, beelden en stockfotografie. Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Illustratie van {description} vector art, clipart en stock vectoren. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Gratis Beeld en Foto {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Gratis foto van {description}. Gratis beeld {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Gratis Illustratie {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'Gratis illustratie van {description}. Gratis design {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: "123RF - Miljoenen Creatieve Stockfoto's, Vectoren, Video en Muziek bestanden ter inspiratie voor al uw projecten.",
  LIVECHAT_LOOKINGHELP: 'Kun je wat hulp gebruiken?',
  LIVECHAT_CHATWITHUS: 'CHAT MET ONS!',
  FREE_TRIAL: '<b>Krijg 10 gratis beelden</b> <br/>abonnement gratis',
  LANG_RELATEDSEARCH: 'Verwante zoekopdrachten',
  LANG_RELATED_STOCKPHOTO_SEARCH: "Verwante zoekopdrachten naar stockfoto's",
  LANG_PROPERATTRIBUTE: 'Hier is onze toeschrijving:',
  LANG_ATTRIBUTEDESC: 'Gelieve dit beeld aan zijn fotograaf toe te schrijven door deze kredietlijn hieronder in om het even welk gebied binnen uw product, website of onderpand te plaatsen',
  LANG_ATTRIBUTE_TITLE: 'Naamsvermelding is optioneel.',
  LANG_ATTRIBUTE_CREDIT: 'Als u de naam van de maker wilt vermelden, gebruik dan de credit regel hieronder:',
  LANG_ATTRIBUTE_COPYLINK: 'Kopieer Link',
  LANG_ATTRIBUTE_COPYTEXT: 'Tekst kopiëren',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>Dit</b></a> is de licentieovereenkomst voor deze download',
  LANG_NEXTPAGE: 'Volgende Pagina',
  LANG_IMAGE_NO_AVAILABLE: 'Beeldnummer {mediaId} is niet langer beschikbaar',
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Vind betaalbare stock fotografie, rechtenvrije afbeelding en stockfoto vanaf minder dan 1 euro met dagelijks 35.000 nieuwe hoge resolutie afbeeldingen beschikbaar met abonnement of pay as you go.',
  LANG_VECTOR_METAIMG_DESC: 'Koop betaalbare illustraties, clipart, vectoren en clip art illustraties vanaf minder dan 1 euro met abonnement of pay as you go.',
  LANG_ANOTHERMETAKWDEFAULT: "stock foto, rechtenvrije foto's, stockbeelden, stock fotografie, stock afbeeldingen, illustraties, stock agentschap, webdesign, hoge resolutie afbeelding, rechtenvrije afbeelding, foto's kopen, vectoren, beeldbank, microstock, royalty vrij, website ontwerpen, clipart, video, footage, Rechtenvrije Muziek, audio, stock audioclips",
  LANG_ANOTHERMETADESCDEFAULT: "Vind betaalbare stockfoto, rechtenvrije foto's, illustratie, videos en muziek vanaf minder dan 1 euro met dagelijks 35.000 nieuwe hoge resolutie afbeeldingen beschikbaar met abonnement of pay as you go.",
  EMPTY_FILTERED_RESULT_TITLE: 'Oeps, je zoekopdracht met deze filters leverde geen resutaat op',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Probeer wat filters te verwijderen of kies een andere zoekterm',
  CLEAR_ALL_FILTER: 'Verwijder alle filters',
  PROMOBAR_FREE10_TEXT: '<b>10 GRATIS beelden</b> bij ons gratis 1 maand proef abonnement.',
  PROMOBAR_FREE10_BTNLABEL: 'BEGIN NU',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Grote korting op alle abonnementen.',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Bekijk pakketten',
  LANG_MODEL_RELEASED: 'Liberatoria Modelli',
  LANG_PROPERTY_RELEASED: 'Liberatoria Proprietà',
  LANG_MODEL_RELEASE: 'met Model Release',
  LANG_PROPERTY_RELEASE: 'met Property Release',
  LANG_YES: 'Si',
  LANG_NO: 'No',
  LANG_MREXPLAIN: "Una liberatoria di immagine è un documento legale firmato dal/dai modello/i di un fotografo, che concede l'autorizzazione a pubblicare / diffondere / utilizzare la sua/loro immagine nella fotografia sotto una o altre forme.",
  LANG_PREXPLAIN: "Una liberatoria di immagine è un documento legale firmato dal proprietario dell'oggetto, soggetto o locale a cui è stata scattata una fotografia, che concede l'autorizzazione a pubblicare / diffondere / utilizzare la fotografia sotto una o altre forme.",
  LANG_DOWNLOADS: 'Download',
  LANG_OLDFILENAME: 'Vecchio nome del file',
  LANG_DOWNLOAD_PREVIEW: 'Download Preview',
  LANG_SHARE: 'Deel',
  LANG_RESTRICTIONS: 'Beperkingen',
  LANG_READ_MORE: 'Lees meer',
  LANG_EDITORIAL_USE: 'Alleen voor redactioneel gebruik',
  LANG_EDITORIALDESCP: 'Redactionele gebruik: Dit beeld kan alleen worden gebruikt voor redactionele doeleinden. Gebruik van dit beeld in de reclame, commerciële of voor promotionele doeleinden is niet toegestaan tenzij specifieke extra toestemming is verleend. 123RF.com biedt geen speling diensten.',
  LANG_LOGO_OWNERSHIP: 'Logo Eigenaars Rechten',
  LANG_PHOTO_SHOT_SMARTPHONE: 'Deze foto is gemaakt met een smartphone.',
  LANG_VECTORNOLOGO: 'If you wish to use this as your company logo, we may arrange for a <b>rights buy out</b> on your behalf. Please contact us at <b>{phonenum}</b> to find out more!',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> download van uw Abonnement',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> download van uw Downloadpakket',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: 'Gebruik je een oudere browser?',
  UPGRADE_NEWER_BROWSER: 'Upgrade naar een nieuwere browserversie voor een vlottere ervaring!',
  CLICK_TO_DOWNLOAD: 'Klik om een van deze te downloaden:',
  LANG_VECTOR_EDITABLE: 'Bestanden die je kunt bewerken in SVG en EPS',
  PROMOBAR_GOOD15_TEXT1: 'Betaal minder, download meer. ',
  PROMOBAR_GOOD15_TEXT2: '15% korting op alle abonnementen met promocode:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Betaal minder, download meer. ',
  PROMOBAR_SUMMER25_TEXT2: '25% korting op alle abonnementen met <b>promocode</b>',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '30% korting op alle abonnementen met <b>promocode</b>',
  NO_EDIT_COMPARE_MSG: 'Er zijn geen bewerkingen gemaakt om te vergelijken.',
  SWITCH_TO_VECTOR_MSG: 'Foto-editor wordt niet ondersteund voor vectoren. Alle edits gaan verloren als je naar vectoren wisselt.',
  SWITCH_TO_XL_MSG: 'Foto-editor wordt niet ondersteund voor XL bestanden. Alle edits gaan verloren als je naar XL formaat wisselt.',
  UNAVAILABLE_FOR_THIS_SIZE: 'Niet beschikbaar voor {size} formaat.',
  SWITCH: 'WISSEL',
  SWITCH_LICENSE_EXT_MSG: 'Wil je dit bestand eerst downloaden als een {type}? Je verliest alle bewerkingen als je wisselt naar Vector format.',
  LANG_SESSION_EXPIRED: 'Je sessie is verlopen. Graag <a href={url}>hier inloggen</a>',
  LANG_TEXT_COLOR: 'Tekst kleur',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Highlight',
  LANG_TEXT_ALIGNMENT: 'Uitlijning',
  LANG_TEXT_BOLD: 'Vet',
  LANG_TEXT_ITALIC: 'Cursief',
  LANG_TEXT_UNDERLINE: 'Onderlijnd',
  LANG_TEXT_BULLET: 'Opsomming',
  LANG_TEXT_LINE_SPACING: 'Regel spatiering',
  LANG_TEXT_LETTER_SPACING: 'Letter spatiering',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'Breng naar voren',
  LANG_BRING_BACKWARD: 'Breng naar achteren',
  LANG_DUPLICATE: 'Dupliceer',
  LANG_OPACITY: 'Doorzichtigheid',
  LANG_DELETE: 'Verwijder',
  LANG_TEXT_ADD: 'Voeg tekst toe',
  LANG_TEXT_EDIT: 'Bewerk tekst',
  LANG_TEXT_FONT_TYPE: 'Font type',
  LANG_TEXT_FONT_SIZE: 'Font formaat',
  LANG_TEXT_ALL_FONTS: 'Alle fonts',
  LANG_TEXT_UNDO: 'Ongedaan maken',
  LANG_TEXT_REDO: 'Doe opnieuw',
  LANG_TEXT_FONT: 'Font',
  LANG_SEARCH_FONT_TYPE: 'Zoek font type',
  LANG_SEARCH_SHAPE_TYPE: 'Zoek Elementen',
  LANG_DONE: 'Gedaan',
  ADD_HEADER: 'Voeg kop toe',
  ADD_TITLE: 'Voeg titel toe',
  ADD_BODY: 'Voeg tekst toe',
  ADD_ASSISTIVE: 'Voeg extra tekst toe',
  STOCK_PHOTO_BANNER_TITLE: "Miljoenen foto's Voor alle creaties!",
  STOCK_PHOTO_BANNER_DESC: 'Vind alle beelden die je nodig hebt om visuals te maken en je  ideeën te illustreren.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Visualiseer je verhalen met de juiste beelden',
  STOCK_PHOTO_SUBSCRIBE_DESC: 'Krijg direct toegang tot meer dan 180 miljoen afbeeldingen om je verhalen te versterken. Onze beelden met jouw verhaal, het is de perfecte combinatie!',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Download Pack',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'Voor zo laag als',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Abonnement',
  STOCK_PHOTO_CARD_IMAGE: 'afbeelding',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Abonneer nu',
  STOCK_PHOTO_CARD_BUY_NOW: 'Koop nu',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Populaire Categorieën',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: "Ontdek betaalbare plannen en prijzen voor foto's",
  STOCK_PHOTO_DISCOVER_NOW: 'Bekijken',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Landschap',
  STOCK_PHOTO_BABIES: 'Babys',
  STOCK_PHOTO_BUSINESS: 'Business',
  STOCK_PHOTO_FOOD: 'Eten',
  STOCK_PHOTO_ANIMALS: 'Dieren',
  STOCK_PHOTO_TECHNOLOGY: 'Technologie',
  STOCK_PHOTO_HEALTHCARE: 'Gezondheidszorg',
  STOCK_PHOTO_NATURE: 'Natuur',
  STOCK_PHOTO_ROMANCE: 'Romantiek',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Maak je creatieve werk, met miljoenen vectoren binnen handbereik.',
  CLIPART_VECTOR_NEED_AN_EYE: 'Vind vectorillustraties, infographics, clipart, pictogrammen en nog veel meer om je te helpen creatieve ideeën te transformeren',
  CLIPART_VECTOR_FIND_VECTOR: 'Zoek de juiste vectoren om je projecten te illustreren.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'Of het nu infographics, illustraties, cartoons, clipart, achtergronden, pictogrammen of iets anders is; we hebben het hier. ',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Populaire royalty-vector Categorieën',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Ontdek betaalbare plannen en prijzen voor vectoren',
  CLIPART_VECTOR_DISCOVER_NOW: 'Discover Now',
  SPEND_AND_WIN_SUBTITLE: 'Iedere {currency} besteed = 1 keer meedoen',
  SPEND_AND_WIN_IMGALT: 'Koop $ Win een iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: 'Graphic',
  CLIPART_VECTOR_ADVERTISING: 'Advertising',
  CLIPART_VECTOR_FLOWER: 'Flower',
  CLIPART_VECTOR_ABSTRACT: 'Abstract',
  CLIPART_VECTOR_POP: 'Pop',
  CLIPART_VECTOR_BIRD: 'Bird',
  CLIPART_VECTOR_ILLUSTRATION: 'Illustration',
  CLIPART_VECTOR_PIXEL: 'Pixel',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'Vector, SVG en EPS',
  FREE_UPGRADE: 'Gratis upgrade',
  FREE_UPGRADE_MESSAGE: 'GRATIS XL upgrade voor het gehele abonnement of downloadpakket. Koop nu.',
  LANG_FREE_IMAGE: '123RF Gratis',
  LANG_IMAGE_FREE: 'Deze stockfoto is gratis voor commercieel en persoonlijk gebruik. Attributie is vereist.',
  LANG_HOW_TO_ATTRIBUTE: 'Hoe vermeld ik?',
  LANG_FREE_DOWNLOAD: 'Gratis download',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Download',
  LANG_GO_PLUS_BUTTON: 'ga voor PLUS',
  LANG_ATTRIBUTION_IS_KEY: 'Attributie is een belangrijk element voor de makers van inhoud die hun werk publiekelijk toegankelijk maken. Onze contentmakers waarderen de blootstelling die is verkregen door hun creaties die worden gebruikt, gedeeld en gecrediteerd door de miljoenen gebruikers zoals u.',
  LANG_GET_ATTRIBUTION_LINK: 'Kopieer deze link en plaats deze op een locatie in de buurt van waar inhoud wordt gebruikt.',
  LANG_CREDIT_OWNER: 'Credit de maker:',
  LANG_USE_ATTRIBUTION: 'Gebruik de onderstaande toeschrijvingslink.',
  LANG_ATTRIBUTION_LOCATION: 'Als dit niet uitvoerbaar is, staan ​​andere goede locaties op de voettekst van de website, blog of nieuwsbrief of zelfs een specifieke credits -sectie van de site, is voldoende om de eigenaar van de {media_type} te crediteren.',
  LANG_GOT_A_QUESTION: "Heb je een vraag? <a href={url} target='_blank'><u>Neem contact met ons op</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'U zou moeten crediteren de eigenaar voor deze gratis afbeelding.',
  LANG_ATTR_FREE_DOWNLOADING: 'Wacht daar! Het duurt niet lang.',
  LANG_PLUS_CONTENT_1: 'Exclusieve PLUS content wordt iedere maand toegevoegd',
  LANG_PLUS_CONTENT_2: 'PLUS licentie - geen vermelding nodig',
  LANG_PLUS_CONTENT_3: '24/7 exclusieve klantenondersteuning',
  LANG_PLUS_CONTENT_4: 'Onbeperkte downloads',
  LANG_PLUS_CONTENT_5: 'Onbeperkte creatieve vrijheid',
  FREE_VECTOR: 'FREE Stock Illustratie',
  FREE_STOCKPHOTO: 'FREE Stock Foto',
  FREE_PUBLIC_DOMAIN_CC0: 'Publiek Domein CC0',
  BUY_5_DOWNLOAD_PACK: "<b>Tijdelijke aanbieding!</b><br/>3 + <b><span style='color:red'>2 GRATIS</span></b> = 5 foto's of vectoren",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'Download op elk moment (tot een jaar)',
  BUY_5_GET_2_FREE: 'Krijg er 2 GRATIS!',
  LANG_ASK_DOWNLOAD_PLUS: 'Wil je PLUS content downloaden?',
  LANG_SIGN_UP_PLUS: 'Meld je aan voor ons 123RF PLUS -plan vanaf',
  LANG_FOR_ONLY: "<span class='text'>voor slechts</span> <span class='price'>{price}</span>/maand",
  LANG_FOR_ONLY_2: "<span class='text'>voor slechts</span> <span class='price'>{price}</span>",
  LANG_BENEFIT_PLUS: 'Profiiteer van ons PLUS pakket vanaf',
  LANG_RELEASES: 'Rechten',
  LANG_UNAVAILABLE: 'Niet beschikbaar',
  LANG_ATTR_FREE_IMG_TITLE2: 'We zijn blij dat je gevonden hebt wat je nodig had. 🎉',
  LANG_PLEASE_NOTE: 'Let op dat dit beeld geen model of eigendomsrechten heeft. Commercieel gebruik is op eigen risico.',
  LANG_PLEASE_ADVISED: 'Houd er rekening mee dat afbeeldingen met herkenbare personen geen releases hebben. Om deze afbeeldingen commercieel te gebruiken, moet toestemming worden verkregen van de relevante partijen. Als de afbeelding geen herkenbare individuen bevat, mogen er geen auteursrechtelijke problemen zijn bij het commercieel gebruik van het.',
  LANG_REACHED_LIMIT: 'Ops! U hebt uw dagelijkse downloadlimiet bereikt.',
  LANG_COME_TOMORROW: 'We zijn blij dat u van onze gratis beelden houdt! Kom morgen terug voor een opfrissing van uw download limiet. Dank u. 😊',
  LANG_DOWNLOAD_ERROR: 'Oeps! Je afbeelding kon niet gedownload worden door een fout.',
  LANG_REFRESH_AND_TRY_AGAIN: "Ververs je browser en probeer het opnieuw. <a href={url} target='_blank'>Laat het ons weten</a>  als dit probleem zich blijft voordoen.",
  LANG_REACHED_LIMIT_PLUS: 'Dagelijkse downloadlimiet bereikt',
  LANG_COME_TOMORROW_PLUS: 'Wauw! U hebt uw downloadlimiet bereikt voor vandaag. Kom morgen terug om meer te downloaden. Dank u wel voor de steun!',
  SEE_YOU_TOMORROW: 'Tot morgen!',
  ORIGINAL: 'Origineel',
  'AUTO_B&W': 'Auto Zwart Wit',
  AUTO_ADJUST: 'Auto aanpassen',
  AUTO_POP: 'Auto Pop',
  NONE: 'Niets',
  LANG_CROP: 'Crop',
  LANG_ENABLE_EDITOR: 'Bewerk afbeelding',
  LANG_DISABLE_EDITOR: 'Sluiten',
  LANG_IMAGE_FREE_CC0: "Dit is een CC0 -afbeelding. Attributie is niet vereist. U kunt het gebruiken volgens de <a class='linkCC0' href={url} target='_blank'>CC0 -licentievoorwaarden</a>.",
  LANG_PREVIEW: 'Voorbeschouwing',
  LANG_ATTRIBUTION_CC0_TITLE: 'CCO -afbeelding: toeschrijving niet vereist',
  LANG_ATTRIBUTION_TITLE: 'Naamsvermelding vereist',
  LANG_ATTRIBUTION_CONTENT: 'Naamsvermeldingslink wordt verstrekt nadat je het bestand hebt gedownload.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'Attributie is niet vereist, omdat dit een CCO -afbeelding is.',
  LANG_SUBCRIBE_PREMIUM: 'Osiągnąłeś dzienny limit pobrań! Aby pobrać więcej zdjęć, zasubskrybuj 123RF PREMIUM.',
  LANG_REMOVE_ATTRIBUTION: 'Naamsvermelding verwijderen?',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Abonneer je op <img src={image} alt='123RF Plus Logo'> voor onbeperkte toegang tot 100 miljoen stockfoto's en illustraties zonder bron vermelding.",
  LANG_CREATOR_ATTRIBUTION: 'Geef de maker de naamsvermelding aub met onderstaande link.',
  LANG_GET_ATTRIBUTION_LINK_2: 'Kopieer deze link en plaats deze op een locatie in de buurt van waar de content wordt gebruikt, de voettekst van de website of een specifieke credits-sectie van de site.',
  LANG_APPLY_BG_REMOVAL: 'Pas achtergrond verwijderaar toe',
  LANG_APPLYING_EDIT: 'Bewerkingen toepassen...',
  LANG_APPLYING_EDIT_ERROR: 'Er is iets mis gegaan,',
  LANG_TRY_AGAIN: 'probeer het opnieuw',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Volledige collectie',
  LANG_SEARCHCOLLECTION_PLUS: 'Essentiële collectie',
  LANG_SEARCHCOLLECTION_FREE: 'Basis collectie',
  LANG_SIMILARIMAGESPAGE_TITLE: "Stockfoto, Rechtenvrije Foto's en Stockbeelden - 123RF",
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: "Vergelijkbare Stockfoto's",
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Oeps! Niets te zien hier.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Beeld niet beschikbaar',
  LANG_PER_IMAGE: '/afbeelding',
  LANG_PLANS_AND_PRICING: 'Pakketten en prijzen',
  LANG_CHOOSE_BEST_PLAN: 'Kies het beste pakket dat aan uw behoeften voldoet.',
  LANG_WANT_MORE_SAVING_1: 'Wil je meer besparingen?',
  LANG_WANT_MORE_SAVING_2: 'Zie meer plannen van {price}/afbeelding',
  LANG_BEST_VALUE: 'Beste waarde, bespaar {percentage}%',
  LANG_YEARLY_PLAN: 'Jaarlijks plan',
  LANG_WITH_MONTHLY_PAYMENTS: 'Met maandelijkse betalingen',
  LANG_GRAB_YEARLY_PLAN: 'Pak een jaarlijks plan en bespaar {percentage}%.',
  LANG_PRICE_PER_MONTH: '{price} per maand',
  LANG_PRICE_PER_YEAR: '{price} per jaar',
  LANG_GET_X_EVERY_MONTH: "Ontvang {quota} foto's of vectoren elke maand",
  LANG_X_EVERY_MONTH: "Ontvang {quota} foto's of vectoren<br>per maand",
  LANG_SINGLE_IMAGE_PURCHASE: 'Aankoop van één afbeelding',
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Krijg alleen wat je nodig hebt voor dit moment.',
  LANG_TOTAL_PRICE: 'Totaal {price}',
  LANG_DOWNLOAD_IMMEDIATELY: 'Download de afbeelding onmiddellijk Na het afrekenen.',
  LANG_NEXT: 'Volgende',
  LANG_TITLE: "Stockfoto, Rechtenvrije Foto's en Stockbeelden - 123RF",
  SIMILAR_STOCK_PHOTOS: "Vergelijkbare Stockfoto's",
  EMPTY_SIMILAR_SEARCH_RESULTS: 'Oeps! Niets te zien hier.',
  UNAVAILABLE_IMAGE: 'Beeld niet beschikbaar',
  LANG_DOWNLOAD_PACK: 'Download pakket',
  LANG_GET_X_ANY_TIME: "{quota} foto's of vectoren op elk moment.",
  LANG_X_CREDITS: '{total} Credits',
  LANG_PER_CREDIT: '/Credit',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: "{photoCount} foto's{videoCount, plural, =0 {} =1 { of # video} other { of # videos}} op elk moment",
  INCLUDES_STANDARD_LICENSE: 'Voeg standaardlicentie toe',
  LANG_MEDIUM_SIZE: 'Middelgroot',
  LANG_LARGE_SIZE: 'Grote maat',
  LANG_EXTRA_LARGE_SIZE: 'Extra groot formaat',
  LANG_STANDARD_SIZES: 'Standaardmaten',
  LANG_ROYALTY_FREE: 'rechten-vrij',
  LANG_OPTIONS: 'Opties',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Profiiteer van ons PLUS pakket vanaf',
  LANG_RELATED_FONT_SEARCH: 'Verwante zoekopdrachten',
  AI_GENERATED_PHOTO: 'AI-gegenereerde afbeelding',
  FONT_PRICING_CREDIT: 'Credits',
  '3_FONTS_3_IMGS': '3 lettertypen <b>of</b> 3 afbeeldingen <b>of</b> 1 video',
  '9_FONTS_9_IMG_3_VIDEOS': "9 lettertypen <b>of</b> 9 afbeeldingen <b>of</b> 3 video's",
  PURCHASE_AND_DOWNLOAD: 'Koop & Download',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Lettertype (OTF, TTF)',
  FONT_PACKS_LICENSE: '123rf Packs licentie',
  FONT_META_DESC: 'Download {product_title} lettertype, lettertype of typografie om ontwerpen voor print en web te maken.',
  FONT_META_TITLE: 'Lettertype - {product_title} | 123rf',
  FONT_SIMILAR: 'Vergelijkbare lettertypen',
  LANG_PLANS: 'PAKKETTEN',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Probeer variaties te maken',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: 'Genereer nieuwe afbeeldingen op basis van de originele afbeelding. Plus, het is een nieuwe manier om onze 123RF fotografen te belonen elke keer dat een AI gegenereerde afbeelding is gelicenseerd!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'Onze gemeenschap bedankt u. Licentie vergoedingen worden gedeeld met de 123RF fotograaf.',
  LANG_CONTRIBUTORS_AI_VARIATION: '123RF fotografen van de originele afbeelding worden gecompenseerd voor elke AI variatie die in licentie wordt gegeven.',
  LANG_AI_VARIATION_LIMIT: 'Oh nee! U heeft de limiet bereikt voor het genereren van AI beelden voor vandaag.',
  LANG_GENERATE_COMEBACK: 'Kom morgen terug voor meer generaties!',
  LANG_LICENSE_AND_DOWNLOAD: 'Licentie en download',
  LANG_CONFIRM_OK: 'Oké',
  LANG_AI_SWITCH_CONTENT: 'Foto-editor wordt niet ondersteund voor AI variaties. Alle bewerkingen gaan verloren als u overschakelt op AI-variaties.',
  LANG_GENERATE_VARIATIONS: 'Variaties genereren',
  LANG_GENERATE_WITH_AI: 'Genereer afbeeldingen met AI',
  LANG_NEW: 'Nieuw',
  LANG_AI_GENERATOR_LICENSE: '123RF AI Generator Algemene Voorwaarden ("Voorwaarden")',
  LANG_I_UNDERSTAND: 'Ik begrijp het.',
  LANG_WHAT_IS_THIS: 'Wat is dit?',
  LANG_AUTHENTIC: 'Authentiek',
  LANG_ABSTRACT: 'Abstrakt',
  LANG_MACRO_CLOSEUP: 'Makro/Close up',
  LANG_OBJECT: 'Object',
  LANG_WATERCOLOR: 'Aquarel',
  LANG_FLAT: 'Vlak',
  LANG_CARTOON: 'Cartoon',
  LANG_GEOMETRIC: 'Geometrisch',
  LANG_GRADIENT: 'Gradatie',
  LANG_ISOMETRIC: 'Isometrisch',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: 'Getekend',
  LANG_NATURAL: 'Natuurlijk',
  LANG_WARM: 'Warm',
  LANG_DRAMATIC: 'Dramatisch',
  LANG_VIVID_BOLD: 'Levendig',
  LANG_BLACK_WHITE: 'Zwart Wit',
  LANG_ASIAN: 'Asiatisch',
  LANG_AFRICAN_AMERICAN: 'African American',
  LANG_STANDARD: 'Standaard',
  LANG_EXTENDED: 'Uitgebreid',
  LANG_STANDARD_CONTENT_1: 'Onbeperkte weergaven voor sociale media, web, e-mail en mobiel.',
  LANG_STANDARD_CONTENT_2: 'Tot 500.000 afdrukken.',
  LANG_STANDARD_CONTENT_3: 'Niet gebruiken op merchandise voor verkoop.',
  LANG_EXTENDED_CONTENT_1: 'Onbeperkte weergaven voor sociale media, web, e-mail en mobiel.',
  LANG_EXTENDED_CONTENT_2: 'Onbeperkte afdrukken.',
  LANG_EXTENDED_CONTENT_3: 'Kan worden gebruikt op merchandise voor verkoop.',
  LANG_ORIGINAL_IMAGE: 'Originele afbeelding',
  LANG_IMPORTANT_NOTE: 'Belangrijke opmerking',
  LANG_AI_ORIGINAL_ALTERED: 'AI-variaties zijn varianten van de originele afbeelding die zijn aangepast met AI-technologie. Het is vergelijkbaar met het laten bijwerken van je gekozen afbeelding door een AI-editor.',
  LANG_GENERATE_VARIATIONS_NOTE: 'Omdat we ons momenteel in de bètafase van AI-gestuurde afbeeldingsvariaties bevinden, kunnen de gegenereerde afbeeldingen onverwachte resultaten opleveren. We werken er hard aan om ervoor te zorgen dat dergelijke resultaten en variaties voldoen aan ethische en verantwoorde AI-richtlijnen.',
  LANG_LICENSE_AI_EDITED: 'Wanneer je een licentie neemt op een van deze AI-bewerkte versies, wordt er een licentievergoeding betaald aan de houder van het auteursrecht van de originele afbeelding.',
  LANG_MOOD: 'stemming',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Bijv. groene kleur, steil haar',
  LANG_SIMILAR_STOCK: 'Vergelijkbare afbeeldingen',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'met Model Release',
  WITH_PROPERTY_RELEASE: 'met Property Release',
  MODEL_RELEASE_CONTENT: "Una liberatoria di immagine è un documento legale firmato dal/dai modello/i di un fotografo, che concede l'autorizzazione a pubblicare / diffondere / utilizzare la sua/loro immagine nella fotografia sotto una o altre forme.",
  PROPERTY_RELEASE_CONTENT: "Una liberatoria di immagine è un documento legale firmato dal proprietario dell'oggetto, soggetto o locale a cui è stata scattata una fotografia, che concede l'autorizzazione a pubblicare / diffondere / utilizzare la fotografia sotto una o altre forme.",

  variousStockGAI: "Een AI-model dat voldoet aan de wettelijke eisen, is getraind met miljoenen stockfoto's en geoptimaliseerd voor zakelijke behoeften.",
  extendedLicense: 'Uitgebreide Licentie',
  sizes: 'Maten',

  downloadMail1st: 'Onze dienstverlener voor het vergroten van afbeeldingen zal je op',
  downloadMail2nd: 'een e-mail sturen met instructies om het uiteindelijke vergrote bestand binnen de volgende werkdag te downloaden',

  aiGenarated: 'AI-gegenereerde afbeelding',

  variousOpenJourney: 'AI Model genereert kunst die lijkt op de kenmerkende stijl van Midjourney.',
  variousTurboVision: 'Snelle generatie bij "normale" XL-resoluties, waardoor de algehele kwaliteit verbetert.',
  variousRealVisXL: 'Het model is gericht op het bereiken van fotorealistische resultaten met precisie en nauwkeurigheid.',
  variousPortraitPlus: 'Model met uniforme staande compositie, ideaal voor 1:1 aspectratio.',
  enterVirifiedCode: 'Verificatiecode invoeren',
  verify: 'verifiëren',
  didNotGetCode: 'Geen code ontvangen?',
  clickToResend: 'Klik om opnieuw te verzenden',
  otpCannotEmpty: 'OTP mag niet leeg zijn.',
  sentCodeToMail: 'We hebben een code naar je geregistreerde e-mailadres gestuurd.',
  invalidCode: 'Ongeldige code.',
  expiredOTP: 'OTP is verlopen, stuur alstublieft opnieuw.',
  sendEmail: 'De e-mail is succesvol verzonden, controleer alstublieft uw postvak om door te gaan.',
  remainingTime: 'Resterende tijd: ',
};
export default nl;
