const fr = {
  // Header
  photos: 'Photos',
  Photos: 'Photos',
  vectors: 'Vecteurs',
  Vectors: 'Vecteurs',
  footageupper: 'VIDEO',
  footage: 'Vidéo',
  video: 'Video',
  audio: 'Audio',
  signin: 'Connexion',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Francais.png',
  language: 'Langue',
  // Footer
  contact: 'Contact',
  contactUs: 'Contact',
  privacy: 'Avis de confidentialité',
  terms: "Conditions d'utilisation",
  cookie: 'Politique de Cookie',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Mot de passe oublié',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Veuillez entrer une adresse e-mail!',
  emailCannotEmpty: "L'email ne peut pas être vide",
  returnToLogin: "Retour à l'écran de connexion",
  email: 'Email',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: 'Nom d`utilisateur',
  pw: 'Mot de passe',
  rememberMe: 'Se souvenir de moi',
  login: 'Connexion',
  forgotPassword: 'Mot de passe oublié',
  loginAsGuest: 'Mot de passe',
  // ResetPassword
  resetPwdDesc:
    'Veuillez entrer votre nouveau mot de passe et nous vous connecterons immédiatement.',
  newPw: 'Nouveau mot de passe',
  confirmPwd: 'Confirmer le mot de passe',
  resetPwdButton: 'Réinitialiser mon mot de passe',
  resetPwdTitle: 'Réinitialisez votre mot de passe',
  enterYourNewPw: 'Veuillez saisir votre nouveau mot de passe.',
  pwMiniMumChars: 'Un minimum de 8 caractères',
  pwMaxChar20: 'Le nombre maximal de caractères autorisés est 20',
  pwLowerCase: 'Au moins une lettre minuscule',
  pwUpperCase: 'Au moins une lettre majuscule',
  pwNumeral: 'Au moins un chiffre',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Confirmation de mot de passe ne correspond pas au mot de passe',
  resetPwdInvalidLink: 'Ce lien est invalide ou a expiré.',
  pwHasBeenReset: 'Le mot de passe a été réinitialisé, veuillez vous connecter pour continuer.',
  // Admin index
  companyName: 'Raison sociale',
  phone: 'Téléphone',
  fax: 'Fax',
  address: 'Adresse',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Total des articles',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Ancien mot de passe',
  confirmNewPassword: 'Confirmer le nouveau mot de passe',
  returnToHome: "Retourner à la page d'accueil",
  // Cookie Policy
  langCookie01:
    'Lorsque vous accédez au site %host%, nous déposons sur votre ordinateur un petit fichier texte, appelé cookie.',
  langCookie02:
    'Ce fichier nous permet de vous suivre dans la gestion des fonctionnalités de base telles que les cartes de fidélité. Ils sont également utilisés pour fournir une meilleure expérience de navigation à l’utilisateur et des services spécifiques comme la « likebox ».',
  langCookie03:
    'Par ailleurs, ils nous permettent d’analyser votre activité sur notre site internet (pages visitées, recherche de termes et images visionnées). Ils fonctionnent de la même manière que vous fassiez ou non des achats.',
  langCookie04:
    'Les cookies que vous consentez, sont également utilisés pour vous profiler dans une liste afin que nous puissions livrer une publicité ciblée adapté à vos intérêts.',
  langCookie05:
    'De nombreux navigateurs vous permettent de désactiver les cookies ; mais si vous le faites, certaines fonctionnalités pourraient être indisponibles et certaines pages pourraient ne pas s’ouvrir correctement.',

  downloadModalTitle: "Téléchargement d'images",
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'Le fichier TIFF d`image, le support d`images et les supports audio ne sont pas pris en charge. Les médias suivants:',
  messageFileNotSupported2ndHalf: 'A été supprimé du téléchargement en bloc.',
  licenseOnBehalf: 'Licence au nom de',
  customizedPortal: 'Customized portal',
  normalDownload: 'Téléchargement normal',
  myCredit: 'Mon crédit',
  lowCreditsReminder: 'Crédit faible',
  downloadModalId: 'Image ID:',
  downloadModalPrice: 'Credit:',
  clearAll: 'Effacer tout',
  myActivities: 'Mes activités',
  info: 'Infos',
  owner: 'Propriétaire',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'Télécharger ce contenu en utilisant',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Tapez votre recherche ici',
  toSearchAIKeyword: "Entrez des mots-clés, un titre d'article ou un titre de blog",
  allImages: 'Tous types',
  freeImages: 'Images Gratuites',
  categoryImage: 'Category',
  recentViewed: 'vu récemment',
  latestIMG: 'Nouvelles images sur le serveur',
  // Setting
  adminControlPanel: 'Accès administrateur',
  downloadHistory: 'Historique des téléchargements',
  hruc: 'HRUC',
  changePassword: 'Modifier le mot de passe',
  logOut: 'Déconnexion',
  hello: 'Bonjour,',
  viewAccount: 'View Account',
  myAccount: 'Mon compte',
  myCollections: 'Mes collections',
  personalCredits: 'Crédits personnels',
  groupTotal: 'Total groupe',
  toTalUnpaidLicense: 'Total des licences impayées',
  highRUnwatermarkComp: 'Images en haute résolution sans filigranes',
  monthSelection: 'Month selection',

  // Menu
  categories: 'Catégories',
  myLikebox: 'Ma Visionneuse',
  settings: 'Mettre à jour vos paramètres',
  notification: 'Notes interne',
  clear: 'Clair',
  likebox: '收藏夹',
  credits: 'Crédits',
  // Credit summary at header
  downloadAndUsege: 'Téléchargement / Utilisation',
  totalDlCredit: 'Total des téléchargements',
  allocatedDlCredit: 'Répartition des crédits',
  personalAvailableCre: 'Crédit personnel disponible',
  groupAvailableCre: 'Groupe Crédit disponible',
  availableDlSlot: 'Nb de téléchargement disponible',
  uNPLD: 'Utilisez maintenant, payez plus tard',
  hiResHRUC: 'Images maquettes en haute résolution',
  monthlyAllocation: 'Allocation mensuelle',
  remainingQuota: 'Quota restant',
  remainingHRUCQ: 'Quota restant de Comps sans Philigrane',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Groupes',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Dites-nous comment nous pouvons vous aider.',
  liveChat: 'Live chat',
  whatsApp: 'WhatsApp',
  hiThere: 'Bonjour,',
  // email response
  emailIsNotAvailable: "L'Email n'est pas disponible.",
  anErrorHaSoccured: "Une erreur s'est produite, veuillez réessayer.",
  emailHasBeenSent:
    "E-mail a été envoyé avec succès, Veuillez vérifier votre boîte mail pour continuer. Nous avons envoyé un email à __email avec des instructions de réinitialisation. Si le courrier électronique n'apparaît pas dans votre boîte de réception dans les 5 minutes, vérifiez votre dossier Spam.",
  // INVALIDPASSWORD
  inValidPassword:
    'Mot de passe invalide! Vérifiez si votre verrouillage des majuscules est activé.',
  userIsBlocked: 'Trop de tentatives infructueuses, vous avez été temporairement bloqué.',
  blockWarrings: 'Vous serez temporairement bloqué après 10 tentatives ! ',
  // Update Password
  resetPwdUpdated: 'Votre mot de passe à été modifié.',
  updatePwDfailed: 'Échec de la mise à jour du mot de passe ! ',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'perc',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'Contrat de licence',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: "Le nom d'utilisateur ne peut pas être vide",
  passWordCannotEmpty: 'Le mot de passe ne peut pas être vide',
  oldPasswordCannotEmpty: "L'ancien mot de passe ne peut pas être vide",
  confirmPasswordCannotBeEmpty: 'Confirmer que le nouveau mot de passe ne peut pas être vide',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Impossible denvoyer plus demails, veuillez vérifier votre boîte de réception.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: 'Utilisez-vous un navigateur plus ancien ?',
  upgradeBrowser:
    "Passez à une version plus récente du navigateur pour profiter d'une expérience plus agréable !",
  clickToDownload: "Cliquez pour télécharger l'une de ces versions :",
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  tooManyAttempts: 'Trop de tentatives infructueuses, vous avez été temporairement bloqué.',
  // Notification
  readAll: 'Read All',
  viewAll: 'Tout voir',
  // Filter bar
  aiGenerate: "Image générée par l'IA",
  standardModels: 'Modèles standards',
  fineTunedModels: 'Modèles affinés',
  aiContent: "Inclure le contenu généré par l'IA",
  filter: 'Filtres',
  safeSearch: 'Recherche sans risques',
  aiAssist: 'Assistance AI',
  freshness: 'Mise en ligne',
  gender: 'Sexe',
  sortBy: 'Trier par',
  mediaType: 'Type de support',
  orientation: 'Orientation',
  modelPreferences: 'Apparence physique',
  people: 'Personnes',
  more: 'Plus',
  numOfPeople: 'Nombre de personnes',
  age: 'Âge',
  ethnicity: 'Ethnicité',
  collection: 'Collections',
  uploaded: 'Uploaded',
  style: 'Style',
  cutOut: 'Fonds transparents/uniformes',
  doNotIncludeWords: 'Ne pas inclure ces mots',
  doNotIncludeWords_ex: 'p. ex. Fromage',
  color: 'Couleur',
  max1Color: 'Max 1 couleur',
  license: 'Type de licence',
  fps: 'FPS',
  resolution: 'Résolution',
  clipDuration: 'Durée du clip (Minutes)',
  loop: 'En boucle',
  category: 'Catégorie',
  genre: 'Genre',
  tempo: 'Tempo (bpm)',
  // Filter button text
  Relevance: 'Aléatoire',
  New: 'Nouveau',
  Georank: 'Georank',
  DownloadHistory: 'Historique des téléchargements',
  Popularity: 'Les plus populaires',
  Photography: 'photographie',
  VectorIllustration: 'Illustration vecteur',
  Horizontal: 'Horizontal',
  Landscape: 'Paysage',
  Portrait: 'Portrait',
  Square: 'Carrée',
  Panorama: 'Panorama',
  numPpl: 'Nombre de personnes',
  WithoutPeople: 'Sans personnages',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Exclure',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: "Moins d'une semaine",
  LastMonth: 'Last month',
  PastThreeMonths: 'Moins de 3 mois',
  PastTwodays: 'Moins de 2 jours',
  Male: 'Masculin',
  Female: 'Féminin',
  Standard: 'Standard',
  extended: 'Étendu',
  Editorial: 'Éditoriale',
  Commercial: 'Commerciale',
  Black: 'Black',
  Caucasian: 'Caucasien',
  AfricanAmerican: 'Afro-américaine',
  Asian: 'Asiatique',
  EastAsian: 'East Asian',
  Hispanic: 'Hispanique',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: 'Enfants',
  Teenagers: 'Adolescents',
  Adults: 'Seniors',
  Seniors: 'personnes âgées',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Oui',
  Nonloopable: 'Non',
  Music: 'musique',
  SoundEffects: 'Effets Sonores',
  LogoAndIdents: 'Logos et identités',
  Veryslow: 'Très lent',
  VeryslowSubText: '(0-79)',
  Slow: 'Lent',
  SlowSubText: '(80-119)',
  Medium: 'Moyen',
  MediumSubText: '(120-139)',
  Fast: 'Rapide',
  FastSubText: '(140-159)',
  Veryfast: 'Très rapide',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Populaire',
  MostDownload: 'Most Download',
  Credits: 'Crédits',
  Freshness: 'Mise en ligne',
  pickAGenre: 'Choisissez un genre',
  // Paginations
  Of: 'de',
  Page: 'Page',
  // home
  recommended: 'Recommandé pour vous',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Mots-clés tendance',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Sons Jeux Vidéos',
  cartoonSounds: 'Sons Dessin Animé',
  soundPacks: 'Packs sonores',
  interfaceSounds: 'Interface Sonore',
  transitionsAndMovementsSounds: 'Transitions & Movement',
  natureSounds: 'nature Sonore',
  domesticSounds: 'Sons domestiques',
  urbanSounds: 'sons urbains',
  industrialSounds: 'sons industriels',
  futuristicSounds: 'Les sons futuristes',
  humanSounds: 'Sons Humains',
  othersSounds: 'autres',
  ambientSounds: "D'ambiance",
  childrenSounds: 'enfants',
  cinematicSounds: 'Cinématique',
  classicalSounds: 'Classique',
  corporateSounds: 'Corporate',
  electronicaSounds: 'Electronica',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  worldBeatSounds: 'World Beat',

  countrySounds: 'Country & Western',
  drumSounds: 'Drum & Bass',

  folkSounds: 'Folk & Acoustique',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Vacances et saisonniers',

  rockSounds: 'Rock',

  individualSounds: 'personnel',
  introSounds: 'Intro & Outro',
  ascSounds: 'Croissant et décroissant',
  businessSounds: 'Business et Communication',
  orchestralSounds: "D'orchestre",
  festiveSounds: 'De fête',
  percussiveSounds: 'Percutant',
  miscellaneousSounds: 'Divers',
  packsSounds: 'Packs',
  percussionSounds: 'Sons de percussion',
  sampleLoopSounds: 'Échantillon en boucle',

  // Search images
  searchImage: 'Photos & Vectors',
  StockPhotosAndImages: 'Photos Et Images',
  StockVectorClipartAndIllustration: 'Vecteur et Illustration Libres de Droits',
  matches: 'Matches',
  editorial: 'Éditoriale',
  downloaded: 'Cette image a été téléchargée avant',
  to: 'to',
  relatedSearches: 'Recherches relatives',
  similarTo: 'Similaire',
  searchByImage: 'Cherchez avec une image',
  options: 'Options',
  searchByImageMsg:
    "Faites une recherche sur 123RF avec une image au lieu d'un texte. Essayez de faire glisser une image dans le champ de recherche.",
  mustBeJPGorPNG: "L'image doit être au format JPG / PNG",
  sizeLessThan5MB: "La taille de l'image doit être inférieure à 5 Mo",
  sizeLessThan4MB: "La taille de l'image doit être inférieure à 4 Mo",
  dragOrDropFile: 'Faire glisser et déposer un fichier ou',
  browse: 'Parcourir',
  uploadedImage: 'Image téléchargée',
  similarStockPhotos: 'Photos similaires',
  // Search footage
  searchFootage: 'Videos',
  StockFootage: 'Séquences Vidéo',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageDetail: ' Détails de la vidéo.',
  footageProperties: 'Propriétés des séquences vidéo ',
  audioProperties: 'Propriétés audio',
  modalDownloadTitle: 'Options de téléchargement de fichiers',
  credit: 'Crédit',
  saveAs: 'Enregistrer sous',
  ownLicense: 'Téléchargement normal (licence personnelle)',
  downloadAs: 'Télécharger en tant que',
  insufficientCredits: 'Crédits insuffisants',
  custom: 'Personnalisé',
  personalAndCom:
    'Utilisation personnelle et commerciale : publicités, présentations, réseaux sociaux, web.',
  licenseType: 'Type de licence',
  requiredForResale:
    'Requis pour la revente ou la distribution dans des performances théâtrales avec accès payant, les vidéos en streaming, les jeux et applications.',
  stockAudioKeywords: 'Mots-clés audio ',
  stockFootageKeywords: 'Mots clés des vidéos',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'Stéréo',
  fileSize: 'Tailles de fichiers',
  footageId: 'Vidéo ID',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  fileName: 'File Name',
  // Search audio
  searchAudio: 'Audio (Musique & Effects Sonores)',
  StockAudio: 'Audio',
  relatedSearch: 'Recherches associées',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Copier le lien.',
  preview: 'Avant-première',
  showMore: 'Montre plus',
  showLess: 'Montrer moins',

  bestSellingFootage: 'Découvrez les vidéos les plus vendues.',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Images similaires',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: "Les images que vous avez consultées récemment s'affichent ici",
  rVVectors: "Les vectoriels que vous avez consultés récemment s'affichent ici",
  rVFootage: "Les vidéos que vous avez visionnées récemment s'affichent ici",
  rVAudio: "Les fichiers audio consultés récemment s'affichent ici",
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: "Oups, votre recherche avec ces filtres n'a donné aucun résultat.",
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle:
    'Essayez de supprimer certains filtres ou de choisir un autre mot-clé.',
  emptyReverseSearchTitle: 'Aucun résultat trouvé !!',
  emptyReverseSearchSubTitle1:
    "L'image téléchargée contient des erreurs, assurez-vous qu’elle respecte les critères suivants:",
  emptyReverseSearchSubTitle2: 'Vérifiez la recherche affinée avec mot-clés',
  emptyReverseSearchSubTitle3: "Le format de l'image doit être en JPG / PNG",
  emptyReverseSearchSubTitle4: "Le poid de l'image doit être inférieur à 5 MB",
  emptySearchResultClearFilter: 'Désactiver tous les filtres',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Veuillez saisir un mot-clé',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: 'Rechercher des collections',
  personal: 'Personnel',
  groups: 'Groupes',
  createNew: 'Créer une nouvelle',
  createNewCollection: 'Créer une nouvelle collection',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: 'Titre',
  selectGroup: 'SÉLECTIONNER LE GROUPE',
  description: 'DESCRIPTION',
  charactersRemaining: '180 Caractères restants ',
  cancel: 'Annuler',
  create: 'Créer',
  defaultCollection: 'Collection par défaut',
  addToMyCollections: 'Ajoutez à la Visionneuse',
  addedToCollection: 'Ajouté à la collection',
  removedFromCollection: 'Retiré de la collection',
  collectionModalPlaceholder: 'p. ex. Nature',
  groupNameShowsHere: "Le nom du groupe s'affiche ici",
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: 'Partagé avec moi',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  Propriétaire: 'Propriétaire',
  images: 'images',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Personel',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: 'Partager',
  or: 'OU',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: "Vous n'avez pas de collection.",
  btnUnDo: 'Annuler',
  // my collection
  dateAdded: 'Date ajoutée',
  collectionName: 'Nom de la collection',
  selectAll: 'Tout sélectionner',
  delete: 'Supprimer',
  seeAll: 'Voir tout',
  deleteCollection: 'Supprimer la collection',
  deleteWarning:
    'En effectuant cette action, vous supprimez définitivement votre ou vos collections ainsi que leur contenu. Sachez que cette action ne peut être annulée',
  createCollection: ' a été créé',
  shareCollection: 'Partager la collection ',
  shareWithTeam: 'Share in team',
  selectUsers: 'Sélectionner les utilisateurs',
  searchUserName: "Rechercher le nom d'un utilisateur",
  copy: 'Copier',
  titleCopyModal: "Copier l'élément séléctionné",
  collectionUppercase: 'COLLECTION',
  groupCollection: 'Groupe',
  moveImage: "Déplacez l'élément séléctionné vers",
  defaultSelect: 'Nom de la collection ici',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'En effectuant cette action, vous supprimez définitivement votre ou vos collections ainsi que leur contenu. Sachez que cette action ne peut être annulée.',
  areYouSure: 'Êtes-vous sûr ?',
  editCollection: 'Modifier la collection',
  collectionNameUpper: 'Nom de la collection',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Editer',
  messageRemoveImage:
    'Êtes-vous sûr de vouloir supprimer le ou les éléments sélectionnés de la collection ?',
  titleRemove: 'Effacez les éléments séléctionnés',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'Sauvegarder',
  shareLikebox: 'Partagé avec moi',
  remove: 'Retirer',
  moveTo: 'Déplacer vers',
  createNewPlaceholder: 'p. ex. Nature',
  shareViaEmail: 'Partager par email',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'Email du destinataire',
  message: 'Message',
  insertReceipmentEmail: "Insérer l'email du destinataire",
  messageText: 'Texte du message.',
  exportCollection: 'Exporter la collection',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: 'Ajouter une nouvelle collection',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'FORMAT',
  lowCredit: 'Crédit faible',
  downloadSuccess: 'Merci de patienter. Le téléchargement débute dans quelques instants.',
  downloadFailed: 'Erreur de téléchargement: Une erreur est survenue. Veuillez essayer à nouveau.',
  downloadedLabel: 'Téléchargés',
  any: 'tout',
  resetFilter: 'Réinitialiser le filtre',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Suivre',
  name: 'Nom',
  location: 'Adresse',
  since: 'Depuis',

  // Mon compte
  updated: 'Update',
  userName: "NOM DE L'UTILISATEUR",
  firstName: 'Prénom',
  lastName: 'Nom',
  myProfile: 'My Profile',
  changeProfiledetail: 'Modifier les détails de votre profil ici',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'Mes Achats',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: 'Utilisez maintenant, payez plus tard',
  bodyUNPL:
    'Use Now, Pay Later (UNPL) est un type de financement à court terme qui permet aux consommateurs de faire des achats et de les payer à une date ultérieure, souvent sans intérêt..',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',

  // audio player
  categoryGenre: 'Catégorie / Genre',
  duration: 'Longueur',
  seeMore: 'Afficher plus',
  seeLess: 'Afficher moins',
  audioID: 'Audio-ID',
  // category
  landscape: 'Paysage',
  Babies: 'Bébés',
  business: 'Entreprise',
  food: 'Alimentation',
  animals: 'Animaux',
  technology: 'Technologie',
  healthcare: 'Santé',
  nature: 'Nature',
  romance: 'Romance',
  // category vector
  graphic: 'Graphique',
  advertising: 'Publicité',
  flower: 'Fleur',
  abstract: 'Abstrait',
  Illustration: 'Illustration',
  pop: 'Pop',
  bird: 'Oiseau',
  pixel: 'Pixel',
  threeD: '3D',

  // footage category
  education: 'Education',
  communication: 'Communication',
  beauty: 'Beauté',
  concept: 'Conceptuel',
  fitness: 'Bien-Être',
  lifestyle: 'Style De Vie',
  celebration: 'Célébrations',
  family: 'Famille',
  fashion: 'Haute Couture',

  // Download-history modal
  reDownload: 'Télécharger à nouveau',
  chooseCustomTagOrCreate: 'Choisir une ou plusieurs tags personnalisés ou en créer un nouveau. ',
  downloadDetails: 'Télécharger les informations',
  image: 'Image',
  downloadType: 'Type de téléchargement',
  groupName: 'Nom du groupe',
  size: 'Taille',
  dateDownloaded: 'Date de Téléchargement',
  reLicense: 'Re-license',
  customFields: 'Champs personnalisés',
  saveAndClose: 'Enregistrer et fermer',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'date',
  export: 'Exporter',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Contenu gratuit',
  searchById: 'Recherche par ID',
  pleaseSelect: 'Veuillez sélectionner',
  totalOutstanding: 'Total en attente',
  viewInvoices: 'Voir la facture',
  // Image detail page - select download size sidebar
  standardSizes: 'Tailles standard',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'Rogner',
  // empty data
  opsNothingToSeeHere: "Oups !  Il n'y a plus rien à voir ici.",
  // search image
  authentic: 'Authentique',
  // search audio
  mostDownloaded: 'Les plus Téléchargées',
  download: 'Télécharger',
  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Business',
  electronica: 'Electronica',
  classical: 'Classique',
  funkAndGrooveMusic: 'Musique Funk & Groove',
  worldBeat: 'World Beat',
  cinematic: 'Cinématique',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Page suivante',
  reverseImageSearch: 'Recherche Par Image',

  group: 'Groupe',
  needHelp: "Besoin d'aide?",
  copied: 'Copié !',
  copyPortfolioLinkSuccess: 'Lien de portefeuille de contributeurs copié au presse-papiers.',

  // my activities
  myActivity: 'Mes activités',
  recentLike: 'Mes derniers contenus aimés',
  recentView: 'Mes dernières vues',
  myActivityViewDesc: "L'historique des vues récentes ne sera stocké que jusqu'à 3 mois seulement",
  myActivityLikeDesc: "Afficher uniquement l'historique des 3 derniers mois",

  // dashboard
  profile: 'Profil',

  // Pricing
  pricingCreditText: 'Crédit',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'Tous',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Réacualiser',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: "Recherche optimisée par l'IA",
  aiPoweredSearchOn: "La recherche assistée par l'IA est activée",
  aiSearchIntroduction:
    "Essayez notre moteur de recherche plus rapide et plus puissant. La recherche assistée par l'IA s'occupe du reste !",
  ok: 'OK',

  // Filter bar ai
  bstract: 'Abstrait',
  macroCloseUp: 'Macro/Plan rapproché',
  object: 'Objet',
  watercolor: 'Aquarelle',
  flat: 'Plat',
  cartoon: 'Dessin animé',
  geometric: 'Géométrique',
  gradient: 'Dégradé',
  isometric: 'Isométrique',
  '3d': '3d',
  handDrawn: 'Dessiné à la main',
  natural: 'Naturel',
  warm: 'Chaud',
  dramatic: 'Dramatique',
  vividAndBold: 'Vif et audacieux',
  blackAndWhite: 'Noir et blanc',
  // introduce Free image research
  imageResearchContentTitle:
    "Présentation du service de recherche d'images gratuit de 123RF : des images parfaites, zéro tracas",
  imageResearchContentDesc:
    "Nous révolutionnons la recherche d'images pour les clients professionnels, facilitant la découverte de l'image parfaite pour donner vie à votre vision.",
  submitRequest: 'Soumettre la demande',
  imageResearchContentInfoTitle1: "Recherche d'images simplifiée",
  imageResearchContentInfoDesc1:
    "Il est facile de commencer. Il suffit de nous fournir une description détaillée de l'image souhaitée, y compris le style, l'ambiance, les couleurs et les éléments spécifiques. Plus vous donnez de détails, mieux nous pouvons répondre à vos besoins.",
  imageResearchContentInfoTitle2: 'Comment cela fonctionne-t-il ?',
  imageResearchContentInfoTitle3: 'Service rapide et fiable',
  imageResearchContentInfoTitle4: 'Laissez-nous faire le travail pour vous',
  imageResearchContentInfoDesc4:
    'Nous trouvons les images parfaites pour vous, afin que vous puissiez vous concentrer sur votre projet. Faites-nous confiance pour donner vie à votre vision avec des images de haute qualité, soigneusement choisies.',
  requestForm: 'Formulaire de demande',
  whatDoYouWantThisImageFor: '"Dans quel but souhaitez-vous utiliser cette image ?',
  websiteBanner: 'Bannière de site web',
  socialMedia: 'Médias sociaux',
  blogPost: 'Article de blog',
  advertisement: 'Publicité',
  print: 'Impression',
  others: 'Autres',
  othersOutputPlaceholder: "Entrez d'autres types de résulats",
  whatIsPrimaryMessage:
    'Quel est le message principal ou le thème que vous souhaitez transmettre avec cette image ?',
  whatIsPrimaryMessagePlaceholder: 'Par exemple : un chien  courant avec une femme dans le parc.',
  areThereSubjectYouWantInThePicture:
    "Souhaitez-vous inclure des sujets ou des éléments spécifiques dans l'image ?",
  egPeopleNatureTechnologyAbstract:
    'Par exemple : des personnes, la nature, la technologie, des concepts abstraits.',
  areThereImageOrStyleReference:
    'Avez-vous vu des images ou des styles que vous aimeriez que nous utilisions comme inspiration ou référence ?',
  ifPeopleIncludeWhatTheirDemographic:
    'Si des personnes doivent être incluses, quelles sont les caractéristiques démographiques souhaitées ?',
  egAgeGenderEthnicityOccupation: 'Par exemple : âge, sexe, origine ethnique, profession.',
  areThereAnyThingToAvoid:
    "Y a-t-il des éléments, sujets ou thèmes que vous souhaitez éviter dans l'image ?",
  whoisYourTargetAudience: 'Qui est votre public cible ?',
  whoisYourTargetAudiencePlaceholder: 'Par exemple : des jeunes adultes passionnés de lecture.',
  typeOfImage: "Type d'image",
  whatMoodShouldImageEvoke: "Quelle émotion ou quel sentiment l'image devrait-elle évoquer ?",
  happy: 'Heureux',
  sad: 'Triste',
  calm: 'Calme',
  excited: 'Excité',
  angry: 'En colère',
  enterCustomMood: 'Indiquer une ambiance personnalisée',
  areThereAnyOrientationForImage:
    "Y a-t-il des orientations spécifiques nécessaires pour l'image ?",
  enterCustomOrientation: 'Indiquer une orientation personnalisée',
  isThereLocationYouWantImageToBeIn:
    "Y a-t-il un lieu ou un cadre particulier que vous voulez pour l'image ?",
  no: 'Non',
  yes: 'Oui',
  shareWithUsAReference: 'Faire glisser et déposer le fichier ou Parcourir',
  replace: 'Remplacer',
  areThereAnySpecificRequirements: "Y a-t-il d'autres exigences ou préférences spécifiques ?",
  enterOtherComments: "Entrer d'autres commentaires",
  submit: 'Soumettre',
  fieldCannotEmpty: 'Ce champ ne peut pas rester vide',
  selectAtLeastOne: 'Veuillez sélectionner au moins une option',
  fileMustBeJpgOrPdf: 'Le fichier doit être au format JPG ou PDF',
  fileSizeLessThan4MB: 'La taille du fichier doit être inférieure à 4 Mo',
  maximumFileNameAllow255: 'Le nombre maximal de caractères autorisé est de 255',
  updateSuccessfully: 'Autres',
  freeImageResearch: "Recherche d'images gratuite",
  newService: 'Nouveau service !',
  freeImageResearchIntroduction:
    "Essayez notre service de recherche d'images et découvrez pourquoi nos images correspondent parfaitement à vos besoins.",
  later: 'Plus tard',
  letTryIt: 'Essayons !',
  viewPdfOfSampleOutput: 'Voir un exemple de résultat au format PDF.',
  characterMaxChar255: 'Le nombre maximal de caractères autorisé est de 255',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'est requis ',

  requiredWithVariable: '{{var}} est requis',
  imageResearchContentInfoDesc2: (
    <>
      Notre équipe combine la
      {' '}
      <strong>technologie de l&apos;IA</strong>
      {
        " et l'expérience pour trouver des images idéales pour vous. Des algorithmes avancés réduisent les options à partir de notre vaste bibliothèque en se basant sur vos indications. Notre équipe de chercheurs dévoués et expérimentés affine les résultats pour répondre parfaitement à vos critères."
      }
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      Nous livrons les résultats en
      {' '}
      <strong>2 jours ouvrables</strong>
      , en fournissant une explication
      complète pour les sélections d&apos;images. Concentrez-vous sur votre entreprise pendant que
      nous prenons soin du reste
    </>
  ),
  mood: 'Ambiance',
  person: 'personne',
  doNotIncludeWords_ex2: 'Par exemple, couleur verte, cheveux raides',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: "Générateur d'images IA",
  imageGenerator: "Générateur d'Images",
  aiImageGeneratorDesc: 'La créativité à la vitesse de votre imagination.',
  generate: 'Générer',
  exploreVisualsCreatedWithThePowerOfAI: "Découvrez des visuels créés grâce à la puissance de l'IA",
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Transformez votre imagination en réalité en 3 étapes simples.',
  startWithGoodTextPrompt: (
    <>
      Commencez par
      {' '}
      <br />
      <span>un bon texte</span>
    </>
  ),
  startWithGoodTextPromptDesc:
    "Un bon prompt garantit d'excellents résultats. Pour obtenir les meilleurs résultats, le prompt doit inclure la perspective, le sujet, l'action, les mots clés, l'éclairage et le style.",
  reviewYourAIGeneratedImages: (
    <>
      Examinez vos images
      {' '}
      <br />
      <span>générées par l&apos;IA</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Notre générateur IA générera et affichera 3 images de prévisualisation qui correspondent le mieux à votre description.',
  downloadAndLicenseVisuals: (
    <>
      Téléchargement et
      {' '}
      <br />
      <span>licence des visuels</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'Vous pouvez désormais télécharger et obtenir une licence pour ces images à des fins personnelles et commerciales avec une couverture légale de 25 000 dollars.',
  needSomeIdeas: "Besoin d'idées ? Commencez par ces exemples et régénérez vos idées !",
  tryPrompt: 'Essayer le prompt suivant',
  creativityAtTheSpeedOfYourImagination: 'La créativité à la vitesse de votre imagination',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Créez vos propres images IA à partir de textes avec Corporate+ en quelques secondes !',
  tryNow: 'Essayer maintenant',

  /// Random prompt
  prompt1: "Dépeindre un chanteur coréen masculin de 28 ans, sûr de lui mais accessible, avec une présence scénique captivante. Mettre en valeur ses capacités vocales dynamiques et son charme magnétique à travers un portrait expressif qui souligne ses traits saisissants. Rendre ses cheveux noirs épais et ses yeux intenses et émotifs avec des détails vifs, en transmettant la profondeur de sa passion artistique. L'habiller d'une tenue élégante et contemporaine qui reflète sa personnalité musicale, peut-être avec une touche d'audace ou de sophistication. Le placer dans un décor qui suggère un spectacle en direct ou un studio d'enregistrement, capturant l'énergie et l'excitation de son art. Utiliser un éclairage et un cadrage dramatiques pour créer une qualité cinématographique, plus grande que nature, soulignant son statut d'étoile montante. Infuser la composition d'un sentiment de mouvement et de dynamisme, comme s'il était en train de livrer une performance vocale captivante. L'objectif est de créer une image qui attire immédiatement l'attention du spectateur et met en valeur la présence scénique et le talent vocal indéniables du chanteur.",
  prompt2: "Une image photoréaliste d'un lion majestueux se prélassant sur un rocher avec la vaste savane africaine en arrière-plan. Utiliser un appareil photo avec un objectif de 400 mm à une ouverture de F 2.8 pour flouter l'arrière-plan et faire une mise au point nette sur le lion. L'éclairage doit être naturel et chaud, capturant les teintes dorées du coucher du soleil. Mettre l'accent sur le photoréalisme pour créer une image vive et détaillée qui transmet la beauté et la grandeur de la faune.",
  prompt3: "Une œuvre en techniques mixtes représentant un samouraï futuriste dans une pose d'action, maniant un katana incandescent, sur fond de ville cyberpunk avec des néons et des hologrammes. Incorporer une variété de textures et de matériaux, y compris des éléments métalliques et numériques, pour créer une scène visuellement riche et dynamique.",
  prompt4: "Une illustration numérique joyeuse d'une jeune princesse dans une robe fluide, jouant avec des créatures magiques dans une forêt enchantée. L'entourer de fleurs colorées, de papillons et de poussière de fée scintillante, sur un fond vibrant et fantaisiste.",
  prompt5: "Un dessin au graphite détaillé d'un guerrier antique au visage buriné et à l'expression déterminée, tenant une épée. Utiliser un ombrage et des textures complexes pour mettre en évidence l'armure et les cicatrices de bataille du guerrier, créant une image intemporelle et puissante.",
  prompt6: "Un collage surréaliste en techniques mixtes d'un explorateur voyageant dans le temps à travers différentes époques historiques, avec des éléments comme des cartes anciennes, des artefacts anciens et des gadgets futuristes. Utilisez une combinaison de coupures de vieux journaux, de photographies et d'art numérique pour créer une œuvre humoristique et stimulante.",
  prompt7: "Une illustration numérique détaillée d'un paysage urbain dystopique, avec des gratte-ciel imposants, des voitures volantes et des nuages sombres et menaçants. Concentrez-vous sur la création d'une impression de profondeur et d'échelle, avec des détails complexes dans l'architecture et un éclairage dramatique pour rehausser l'ambiance.",
  prompt8: "Un portrait d'un sage aîné tenant un bâton magique, capturé dans un éclairage clair-obscur dramatique pour accentuer les traits du visage de l'aîné et les runes lumineuses du bâton. Utilisez des textures et des ombres riches pour créer un sentiment de mystère et de sagesse.",
  prompt9: "Une illustration fantastique vibrante d'un cavalier de dragon s'élançant dans le ciel, le cavalier vêtu d'une armure et les ailes du dragon grandes ouvertes. Entourez-les de nuages et de montagnes lointaines, ajoutant un sentiment d'aventure et de majesté.",
  prompt10: "Une photographie de rue en noir et blanc spontanée d'une scène de marché animée dans une ville asiatique, avec des vendeurs vendant des fruits et des légumes colorés. Utilisez une faible profondeur de champ pour vous concentrer sur les expressions et les interactions des vendeurs, capturant l'atmosphère animée.",
  prompt11: "Une peinture aquarelle fantaisiste d'une licorne à crinière arc-en-ciel, se tenant dans une forêt magique entourée de champignons lumineux et de lucioles scintillantes. Utilisez des couleurs pastel douces et des coups de pinceau fluides pour créer une scène onirique et enchanteresse.",
  prompt12: "Un rendu 3D d'une cuisine moderne au design épuré et minimaliste, avec des appareils électroménagers en acier inoxydable et un grand îlot central. Utilisez des textures et un éclairage photoréalistes pour mettre en valeur les lignes épurées et le style contemporain, en mettant l'accent sur les reflets et les matériaux.",
  prompt13: "Une peinture de paysage fantastique d'une île flottante dans le ciel, avec des cascades qui s'écoulent de ses bords et une végétation luxuriante qui recouvre sa surface. Entourez l'île de nuages moelleux et d'un ciel bleu vibrant, créant un sentiment d'émerveillement et de magie.",
  prompt14: "Un portrait détaillé d'un guerrier viking aux cheveux tressés et à l'expression féroce, tenant une hache de bataille. L'arrière-plan doit représenter une mer orageuse et des falaises escarpées, avec un éclairage dramatique pour mettre en valeur les traits et l'armure du guerrier.",
  prompt15: "Un collage surréaliste de techniques mixtes d'un inventeur steampunk dans un atelier encombré rempli d'engrenages, de mécanismes d'horlogerie et de machines à vapeur. Utilisez des photographies anciennes, de vieux plans et des textures métalliques pour créer une scène visuellement riche et imaginative.",
  prompt16: "Une illustration numérique d'une ville futuriste la nuit, avec des gratte-ciel imposants, des enseignes au néon et des véhicules volants. Utilisez des couleurs vibrantes et un éclairage dynamique pour créer une atmosphère animée et immersive, en soulignant les progrès technologiques et l'énergie bouillonnante de la ville.",
  prompt17: "Un rendu 3D photoréaliste d'un coin lecture confortable avec un fauteuil confortable, une petite table avec une lampe et des étagères remplies de livres. Le décor doit avoir un éclairage chaud et accueillant et des textures douces, capturant l'ambiance sereine et relaxante de l'espace.",
  prompt18: "Représentez un sac à main de mode transparent, semblable à du verre, avec un aquarium entièrement fonctionnel intégré à la conception. Rendez méticuleusement les poissons délicats qui nagent à l'intérieur du sac à main, créant un accessoire fascinant et non conventionnel. Assurez-vous que l'aquarium est parfaitement intégré à la structure du sac à main, avec des transitions harmonieuses entre les deux éléments. Placez le sac à main de manière visible dans un grand magasin haut de gamme, entouré d'un arrière-plan d'étagères élégantes et modernes, d'éclairage et d'autres présentoirs de vente au détail de luxe. Utilisez un éclairage dramatique et directionnel pour créer un sentiment de drame et captiver l'attention du spectateur, attirant son regard directement sur le sac à main aquatique. Capturez le sac à main sous un angle qui met en valeur sa qualité transparente, presque éthérée, permettant aux poissons d'être clairement visibles et créant une impression d'apesanteur ou de fluidité.",
  prompt19: "Capturez une scène chaleureuse et accueillante mettant en vedette des fauteuils moelleux dans une palette de tons bruns et vert olive. Présentez les fauteuils dans un cadre chaleureux et intime, entourés de détails qui évoquent un sentiment de détente et de confort, tels que des textiles doux, des accents en bois riche et peut-être un aperçu d'une cheminée ou d'une fenêtre donnant sur un paysage naturel paisible. Mettez l'accent sur la qualité somptueuse et texturée du rembourrage et sur le sentiment général de confort et de retraite.",
  prompt20: "Capturez une représentation vibrante et réaliste d'une perdrix huppée perchée au sommet d'une branche recouverte de mousse, sur un fond vert émeraude luxuriant qui complète le plumage magnifique de l'oiseau. Rendez les plumes complexes de la perdrix avec des détails exquis, en utilisant un dégradé subtil de lumière et d'ombre pour accentuer leurs textures. Mettez en évidence la huppe riche et vert foncé de la perdrix, son bec brun terre chaud et ses yeux noirs brillants et curieux, créant un portrait captivant de la beauté naturelle de l'oiseau. Imprégnez la scène générale d'une atmosphère chaleureuse et tranquille, invitant le spectateur à entrer dans le cadre naturel serein.",
  prompt21: "Concevez une image visuellement attrayante présentant un bol de yaourt glacé crémeux surmonté d'un assortiment vibrant de fruits frais et de noix croquantes. Capturez les qualités rafraîchissantes et nutritives de ce dessert sain, en mettant en valeur le contraste entre le yaourt lisse et clair et les éclats de couleur vibrants des garnitures fruitières. Disposez les ingrédients de manière artistique et appétissante, permettant au spectateur d'apprécier l'attrait visuel et d'anticiper l'expérience rafraîchissante et savoureuse. Assurez-vous que l'image globale dégage un sentiment de bien-être et d'indulgence, invitant le spectateur à savourer les qualités délicieuses et nourrissantes de cette friandise alléchante.",
  prompt22: "Plongez-vous dans un festin visuel, explorant un jardin de fleurs surréaliste débordant de couleurs vibrantes et de détails complexes. Capturez l'essence captivante des fleurs avec des pétales recourbés et spiralés, un feuillage varié et d'autres teintes fascinantes - chacune étant une œuvre d'art à part entière. Utilisez la fluidité de l'aquarelle et l'expressivité de l'encre, en employant une technique « humide sur humide » pour créer un sentiment de mouvement et de profondeur. Transmettez la qualité surréaliste et onirique de ce pays des merveilles floral grâce au jeu de couleurs riches et saturées et à la représentation délicate et complexe de chaque pétale et de chaque feuille.",
  prompt23: "Créez une illustration captivante de scène nocturne mettant en vedette un ancien phare se dressant fièrement sur une île rocheuse. Le phare, orné de multiples fenêtres, émet une lueur chaude et accueillante, soulignant son architecture complexe et sa riche histoire. L'île rocheuse est recouverte d'arbres luxuriants et verdoyants, dont le reflet danse sur les eaux calmes et éclairées par la lune qui reflètent la radiance argentée de la pleine lune. L'astre céleste orne le ciel, projetant une lueur éthérée sur les nuages et la mer, tandis que des nuages vaporeux obscurcissent partiellement son éclat, ajoutant au mystère de la scène. Des étoiles scintillantes parsèment l'étendue d'encre, créant une tapisserie céleste qui s'harmonise avec la radiance de la lune. Cette œuvre d'art enchanteur capture une nuit sereine et paisible, où le phare illuminé se tient résolu au milieu de la beauté céleste, servant de sentinelle et créant une expérience cinématographique et onirique pour le spectateur.",
  /// Tool list
  // ratio
  ratio: 'Ratio',
  square1_1: 'Carré (1:1)',
  square: 'Carré',
  portrait2_3: 'Portrait (2:3)',
  portrait: 'Portrait',
  landscape3_2: 'Paysage (3:2)',
  wide16_9: 'Grand écran (16:9)',
  wide: 'Grand écran',
  // style
  none: 'Aucun',
  enhance: 'Améliorer',
  anime: 'Anime',
  photographic: 'Photographique',
  digitalArt: 'Art numérique',
  comicBook: 'Bande dessinée',
  fantasyArt: 'Art fantastique',
  analogFilm: 'Film analogique',
  neonPunk: 'Néon punk',
  lowPoly: 'Low poly (polygone bas)',
  origami: 'Origami',
  lineArt: 'Dessin linéaire',
  tileTexture: 'Texture de tuile',
  threeDModel: 'Modèle 3D',
  pixelArt: 'Art pixelisé',
  craftClay: 'Argile artisanale',
  // color
  coolTone: 'Tons froids',
  mutedColors: 'Couleurs atténuées',
  pastelColors: 'Couleurs pastel',
  vibrantColors: 'Couleurs vives',
  warmTone: 'Tons chauds',
  // lighting
  lighting: 'Éclairage',
  backlight: 'Contre-jour',
  crepuscularRays: 'Rayons crépusculaires',
  dimlyLit: 'Faiblement éclairé',
  goldenHour: 'Heure dorée',
  lowLight: 'Faible luminosité',
  rimLighting: 'Éclairage en bordure',
  studio: 'Studio',
  sunlight: 'Lumière du soleil',
  volumetric: 'Volumétrique',
  // composition
  composition: 'Composition',
  blurryBackground: 'Arrière-plan flou',
  closeUp: 'Gros plan',
  macrophotograph: 'Macrophotographie',
  narrowDepth: 'Profondeur de champ étroite',
  shotFromAbove: "Prise d'en haut",
  shotFromBelow: "Prise d'en bas",
  wideAngle: 'Grand angle',

  /// FQAs
  allAIImageQuestionsAnswered:
    "Toutes les réponses à vos questions sur les images générées par l'IA.",
  TTI_Q1: "Qu'est-ce qu'une image générée par l'IA ?",
  TTI_A1:
    "Les images générées par l'IA sont des images créées à l'aide d'algorithmes d'intelligence artificielle (IA). Ces algorithmes sont conçus pour simuler la créativité humaine et peuvent générer des images similaires à celles produites par des artistes humains, mais avec des éléments uniques et souvent surréalistes ou abstraits.",
  TTI_Q2: "Y a-t-il une limite au nombre d'images que je peux générer par jour ?",
  TTI_A2:
    "Oui, il y a une limite au nombre d'images que vous pouvez générer chaque jour. Cette limite vise à garantir que tous les utilisateurs aient un accès équitable et une bonne expérience utilisateur lorsqu'ils utilisent le service de génération d'images IA.",
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: "Quelle est la différence entre la création d'une image et l'octroi d'une licence ?",
  TTI_A4:
    "Lorsque vous générez une image, vous créez un ensemble d'images IA à visualiser. Lorsque vous octroyez leur licence avec votre abonnement ou vos crédits, vous obtenez officiellement l'accès pour télécharger une version haute résolution de l'image IA accompagnée de sa propre licence d'utilisation.",
  TTI_Q5:
    "Une image que j'ai créée contient une marque, un point de repère ou une personnalité publique reconnaissable. Puis-je encore l'utiliser ?",
  TTI_A5:
    "Si l'image contient une personne ou un objet reconnaissable, ou si elle se concentre principalement sur un monument distinctif, vous devrez obtenir la permission directe de la partie représentée dans l'image, en particulier si vous avez l'intention d'utiliser l'image à des fins commerciales.",
  TTI_Q6: 'Qui sont les personnes qui apparaissent dans les images générées ?',
  TTI_A6:
    "Les personnes qui apparaissent dans les images générées par l'IA ne sont pas des personnes réelles, mais des créations numériques.",
  TTI_Q7:
    "Qu'advient-il du contenu que j'ai généré ? Seront-ils mis à la disposition d'autres utilisateurs ?",
  TTI_A7:
    "Le contenu que vous générez peut également être mis à la disposition d'autres utilisateurs. Vous pouvez vous référer à notre licence pour plus d'informations.",
  TTI_Q8: "Pourquoi le chargement prend-il du temps lorsque j'essaie de générer une image ?",
  TTI_A8:
    "La page prendra quelques secondes pour générer l'image. Le temps nécessaire dépend de la complexité du prompt, du matériel et des ressources informatiques disponibles, ainsi que du nombre de demandes que l'IA doit traiter simultanément.",
  TTI_Q9: "Comment puis-je obtenir une licence pour les images IA que j'ai générées ?",
  TTI_A9:
    "Vous pourrez obtenir la licence des images IA que vous avez créées en les téléchargeant depuis votre compte Corporate+. À chaque téléchargement, vous recevrez la version haute résolution de l'image générée disponible, ainsi qu'une garantie légale de 25 000 $.",
  TTI_Q10: 'Puis-je consulter les générations précédentes ?',
  TTI_A10:
    "Oui, vous pouvez le faire en mettant en signet et en rééditant l'URL de vos générations passées. Vous pouvez également partager les résultats des générations avec vos amis.",
  TTI_Q11: "Comment générer des images de haute qualité avec l'IA ?",
  TTI_A11: `<ol>
              <li>Commencez par un objectif clair : "Image de haute qualité d'un paysage de montagne pour un blog de voyage".</li>
              <li>Précisez le style et l'ambiance souhaités : "L'image doit être lumineuse et vibrante, avec un aspect naturel et organique."</li>
              <li>Décrivez le sujet : Précisez le sujet de l'image, en incluant des détails tels que le cadre, l'éclairage et toute autre caractéristique ou élément important. Par exemple, "Mettez en scène un pic montagneux enneigé avec une rivière sinueuse au premier plan et un ciel bleu clair à l'arrière-plan".</li>
              <li>Donnez des exemples : Incluez des exemples d'autres images dont le style ou l'ambiance sont similaires à ceux de l'image que vous recherchez. Cela permet de guider l'algorithme d'IA et de s'assurer que le résultat correspond à vos attentes.</li>
              <li>Utilisez des mots-clés pertinents : "paysage de montagne", "pic enneigé" ou "lumineux et vibrant".</li>
              <li>Évitez toute ambiguïté : Soyez aussi clair et précis que possible lorsque vous donnez des instructions, et évitez d'utiliser un langage vague ou ambigu qui pourrait conduire à des résultats inattendus.</li>
            </ol>`,

  /// Generating page
  bringingYourImagesToLife: 'Donner vie à vos images...',
  cplusAiGenerator: "Générateur d'IA",
  generatedImages: 'Images générées',
  promptSettings: 'Paramètres de prompts',
  pastGeneration: 'Générations précédentes',
  generateVariation: 'Générer une variation',
  iLikeThis: "J'aime cela",
  iDislikeThis: "Je n'aime pas cela",
  shareOptions: 'Options de partage',
  variations: 'Variations',
  copiedSharedUrl: 'URL partagée copiée dans le presse-papiers.',

  galleryPrompt1: "Sirène éthérée, sous l'eau, couronne de coquillages entourée de poissons, portrait",
  galleryPrompt2: 'Rôti dominical copieux sur la table, plein de nourriture et de bougies',
  galleryPrompt3: "Renard mignon souriant dans une forêt, très détaillé, avec d'autres animaux de la forêt",
  galleryPrompt4: "Haute qualité, 8K Ultra HD, notes en forme d'instruments de musique à l'intérieur d'une citrouille en cristal, très détaillée, lampe citrouille magique, ambiance automnale, relax",
  galleryPrompt5: "Paysage cinématographique panoramique de l'Écosse, belle journée lumineuse",
  galleryPrompt6: 'Petite fille en style 3D, 4k, 8k, rendu Octane photoréaliste, HDR, photographie, haute définition, visage symétrique, éclairage volumétrique, brume poussiéreuse, photo, rendu Octane, 24mm, 4k, 24mm, DSLR, haute qualité, 60 ips, ultra réaliste',
  galleryPrompt7: "Gars avec la peau foncée frappant la planète Jupiter dans l'espace, vu de face",
  galleryPrompt8: 'Biscuit dans du café chaud, dans un environnement de Noël',
  galleryPrompt9: "Bouteille d'eau artistique sur un fond minimaliste blanc avec des feuilles de Montserra",
  galleryPrompt10: 'Dragon de fantaisie diamant évanouissant',
  galleryPrompt11: 'Planètes en gouttes de rosée, gouttes de rosée en forme de planètes, lever du soleil, gros plan, hyper détaillé, mise au point nette, photo en studio, détails complexes, très détaillé',
  galleryPrompt12: "Une pie assise sur un coffre d'or, dans le style d'art fantastique réaliste, gemme, contes de fées sombres, mystérieux.",
  galleryPrompt13: 'Une montagne de neige et un sentier de glace sur le sommet de la montagne allant dans la direction opposée, ultra hd, réaliste, couleurs vives, hautement détaillé, dessin UHD, plume et encre, composition parfaite, beau détaillé complexe follement détaillé rendu octane tendance sur artstation, 8k photographie artistique, art conceptuel photoréaliste, lumière parfaite naturelle volumétrique cinématographique douce',
  galleryPrompt14: 'Civilisation interstellaire, regardant vers le bas un espace numérique mystérieux, bâtiments de villes extraterrestres, super science-fiction, bel extérieur de super gratte-ciel, route, pont dans le ciel, architecture de super science-fiction flottant dans le ciel, machine de guerre, haute technologie, innombrables écrans lumineux électronique',
  galleryPrompt15: "Belle plage avec l'océan en arrière-plan dans les Florida Keys la nuit, couleurs néon, fond noir, vagues calmes, teinte naturelle, coucher de soleil, calme, zoom relaxant sur les vagues, plage, horizon redressé, rose, bleu, orange, violet",
  galleryPrompt16: "Meilleure qualité, double exposition, art de carte postale, art sur papier ruiné, gros plan d'un chaton mignon jouant avec une boule de laine, intérieur détaillé, étincelant, lumière du soleil, rayons de soleil, pose dynamique, illustration de livre conte de fées, 2D, plat, mignon , adorable, vintage, conte de fées, patchwork, vitrail, illustration détaillée de livre de contes, cinématique, très détaillé, petits détails, beaux détails, mystique, couleurs vibrantes, fond complexe",

  galleryPrompt17:
    "pyramide sur l'eau avec des reflets de feu, montagnes, surréalisme sombre, portraits atmosphériques, mise au point nette",
  galleryPrompt18: 'panda roux dans la forêt, cinématographique',
  galleryPrompt19: 'mer, lune, poissons, ciel étoilé, bateau, illustration colorée',
  galleryPrompt20:
    "Les reflets urbains vibrants dansent au milieu des bâtiments audacieux et de l'eau scintillante dans un style abstrait minimaliste, capturant l'énergie animée du soir, abstraction géométrique, style d'art contemporain pop avec éclairage néon et technique d'écoulement acrylique",
  galleryPrompt21:
    "vue arrière d'une femme silhouette avec une épée lumineuse, portail circulaire dans l'Arctique menant aux icebergs, plan moyen cinématographique, figure monumentale",

  /// error page
  copyrightDetected:
    "Termes, personnalités ou marques commerciales protégés par le droit d'auteur détectés",
  copyrightDetectedDesc:
    "Nous nous engageons à être un générateur d'IA responsable et nous ne générerons pas de résultats pour les marques déposées, termes, marques ou personnalités incluses dans les prompts pour éviter toute violation potentielle. Nos conditions d'utilisation stipulent que vous ne devez PAS utiliser les services d'une manière qui enfreint, s'approprie ou viole des droits de propriété intellectuelle. Veuillez modifier votre prompt et réessayer.",
  errorMesTTI: 'Oops ! Nous sommes désolés, un problème est survenu.',
  errorMesTTIDesc:
    'Le problème a été enregistré pour être examiné. Veuillez réessayer ultérieurement.',
  tryAgain: 'Réessayer',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished:
    'You have reached the maximum quota available. Please contact your system administrator.',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Trouvez de superbes images. Recherchez par mots-clés ou phrases.',
  searchBarPlaceholderAllImageTablet: 'Trouvez de superbes images.',
  searchBarPlaceholderAllImageMobile: 'Trouvez des images.',
  searchBarPlaceholderPhotoDesktop: 'Trouvez de superbes photos. Recherchez par mots-clés ou phrases.',
  searchBarPlaceholderPhotoTablet: 'Trouvez de superbes photos.',
  searchBarPlaceholderPhotoMobile: 'Trouvez des photos.',
  searchBarPlaceholderVectorDesktop: 'Trouvez de superbes vecteurs. Recherchez par mots-clés ou phrases.',
  searchBarPlaceholderVectorTablet: 'Trouvez de superbes vecteurs.',
  searchBarPlaceholderVectorMobile: 'Trouvez des vecteurs.',
  searchBarPlaceholderFootageDesktop: 'Trouvez de superbes vidéos. Recherchez par mots-clés ou phrases.',
  searchBarPlaceholderFootageTablet: 'Trouvez de superbes vidéos.',
  searchBarPlaceholderFootageMobile: 'Trouvez des vidéos.',
  searchBarPlaceholderAudioDesktop:
    'Trouvez de superbes fichiers audio et musique. Recherchez par mots-clés ou phrases.',
  searchBarPlaceholderAudioTablet: 'Trouvez de superbes fichiers audio et musique.',
  searchBarPlaceholderAudioMobile: 'Trouvez des fichiers audio et musique.',
  searchBarPlaceholderFreeDesktop: 'Trouvez de superbes images gratuites. Recherchez par mots-clés ou phrases.',
  searchBarPlaceholderFreeTablet: 'Trouvez de superbes images gratuites.',
  searchBarPlaceholderFreeMobile: 'Trouvez des images gratuites.',
  // editorial warning
  forEditorialUseOnly: 'Pour usage éditorial uniquement',
  forEditorialUseOnlyDesc:
    "L'utilisation de cette image pour la publicité ou à des fins promotionnelles est interdite, sauf autorisations supplémentaires.",

  // download comp
  downloadHiResComp: 'TÉLÉCHARGER IMAGE HAUTE-RES',
  downloadComp: 'TÉLÉCHARGER COMP',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),
  textToImage: 'Texte à image',
  descTourTTI: "Laissez libre cours à votre imagination et créez des images à partir d'un texte !",
  colorAndTone: 'Couleur et tonalité',
  titleSuccessDownload: 'Nous sommes ravis que vous ayez trouvé ce dont vous aviez besoin.',
  hangInThere: 'Tenez bon ! Ce ne sera pas long.',
  adultContentTitle: 'Termes potentiels de contenu pour adultes détectés',
  adultContentDesc:
    "Nous nous engageons à une IA responsable, par conséquent nous ne générons pas de résultats pour certaines phrases, concepts et/ou idées. Veuillez modifier votre requête (le texte que vous avez fourni pour générer l'image) et réessayer.",
  reachedTheLimit:
    "Oh non ! Vous avez atteint la limite de génération d'images AI pour aujourd'hui.",
  backTomorrow: 'Revenez demain pour obtenir de nouveaux contenus !',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: 'Pour commencer, décrivez votre vision ici et nous lui donnerons vie !',

  downloadAgain: 'Download again',
  btnClose: 'Fermer',

  fluxPro: 'Flux offre une génération d’images avancée, de haute qualité et variée.',
  flux: 'Notre modèle le plus rapide, conçu pour le développement local.',
  ideogram: 'Transformer une description textuelle en magnifiques images en quelques secondes.',
  realStockPhoto: 'Créez facilement des photos de stock captivantes avec notre modèle IA avancé.',
  realCinematic: 'Un modèle spécialisé dans la production de résultats cinématographiques, excellent pour des rapports largeur/hauteur étendus.',
  realFood: 'Améliorez votre marque culinaire avec notre modèle de visualisation alimentaire.',
  realMacro: 'Un excellent modèle pour générer des images macro détaillées.',
  realBokeh: 'Un modèle de style photoréaliste, parfait pour créer des images avec un effet bokeh époustouflant.',
  aiLicenseHeader: "Ce fichier a été généré par l'IA (Intelligence Artificielle).",
  aiLicenseContent: "Le contenu généré par l'intelligence artificielle n'est pas soumis au processus standard de vérification de conformité de 123RF. Il incombe au créateur de s'assurer que ces matériaux générés par l'IA ne violent pas les droits de propriété intellectuelle ou autres droits de tiers. La production de spam, ainsi que tout contenu faux, trompeur, nuisible, violent, pornographique ou illégal, est strictement interdite par nos politiques.",
  variousTitle: 'Découvrez nos différents modèles',
  variousSDXL: 'Donnez vie à des sujets et des scénarios photoréalistes incroyables.',
  variousEimisAnime: "Modèle d'IA générative conçu pour créer des images de style anime.",
  variousDynaVisionXL: 'Générer des personnages de dessin animé stylisés en 3D.',
  variousJuggernautXL:
    'Concentrez-vous sur les éléments architecturaux et les paysages/arrière-plans.',
  variousDreamShaperXL:
    "Mise à l'échelle en haute résolution, génération améliorée de personnages et d'éléments, réduction des problèmes de flou.",
  variousRealismEngineSDXL: 'Ce modèle est spécialisé dans les images détaillées et réalistes.',
  variousDALLE:
    'DALL·E 3, un modèle multiforme et complet qui excelle dans la transformation de concepts en contenu visuel.',
  aiEngine: "Moteur d'IA",
  'DALL-E': 'DALL-E 3',

  langText: 'Texte',
  langElements: 'Éléments',
  langAuto: 'Auto',
  langBgRemoval: "Suppression d'arrière-plan",
  resetAll: 'Réinitialiser Tous',
  facebookImage: 'Image Facebook',
  facebookLink: 'Lien Facebook',
  facebookCover: 'Photo de couverture Facebook',
  facebookStory: 'Story Facebook',
  instagramPost: 'Post Instagram',
  instagramStory: 'Story Instagram',
  twitterPost: 'Post Twitter',
  twitterHeader: 'Header Twitter',
  pinterestPost: 'Post Pinterest',
  emailHeader: 'Header e-mail',
  presentation: 'Présentation',
  presentationWide: 'Présentation large',
  ebookCover: 'Couverture E-book',
  linkedinPost: 'Post LinkedIn',
  brochureCover: 'Couverture brochure (A4)',
  postcard: 'Carte postale',
  photoPrint: 'Impression photo',
  youtubeProfileImage: 'Image profil YouTube',
  youtubeChannelCoverPhoto: 'Couverture chaîne YouTube',
  langApplyBgRemoval: "Appliquer la suppression de l'arrière-plan",
  autoBnW: 'Auto N&B',
  autoAdjust: 'Auto Réglage',
  autoPop: 'Auto Pop',
  original: 'Original',
  addHeader: 'Ajouter un en-tête',
  addTitle: 'Ajouter un titre',
  addBody: 'Ajouter un corps de texte',
  addAssistive: "Ajouter un texte d'assistance",
  langCompare: 'Comparer',
  langEnableEditor: "Éditer l'image",
  langSimilarStock: 'Images de stock similaires',
  langTextUndo: 'Annuler',
  langTextRedo: 'Refaire',
  langTextFont: 'Police de caractère',
  langTextFontSize: 'Taille de police',
  langTextColor: 'Couleur du texte',
  langTextHighlight: 'Surligner',
  langTextAlignment: 'Alignement',
  langTextBold: 'Gras',
  langTextItalic: 'Italique',
  langTextUnderline: 'Souligner',
  langTextLineSpacing: 'Interligne',
  langTextLetterSpacing: "L'espacement des lettres",
  langBringForward: 'Mettre au premier plan',
  langBringBackward: "Ramener à l'arrière",
  langDuplicate: 'Dupliquer',
  langOpacity: 'Opacité',
  langDelete: 'Supprimer',
  imageProperties: 'Propriétés image',
  noEditCompareMsg: "Aucune modification n'a été apportée pour comparer.",
  stockPhoto: "Banque d'images",
  basicShape: 'Formes de base',
  chitChat: 'Bulles de chat',
  shapes: 'Formes',
  elementChitChat: 'Élément chat',
  organicAbstractShapes: 'Formes abstraites organiques',
  elementShapes: 'Éléments formes',
  langApplyingEdit: 'Appliquer les modifications...',
  langTextAllFonts: 'Toutes les polices',
  langSearchFontType: 'Type de police de recherche',
  editorCompareImageBeforeTitle: 'Avant',
  editorCompareImageAfterTitle: 'Après',
  langSizePx:
    "Un pixel est l'unité de base d'une image numérique. La taille de l'image, mesurée en pixels, détermine le niveau de détail et la taille du téléchargement.",
  langSizeDpi:
    "Les points par pouce mesurent la résolution d'impression. Un dpi plus élevé signifie plus de détails. Pour l'impression, la résolution recommandée pour toutes les images est de 300 dpi.",
  cautionTTI:
    "Attention : Nous avons détecté l'utilisation de phrases dans l'invite qui sont associées à des marques déposées, des droits d'auteur, des monuments célèbres et/ou des individus. Veuillez noter que Vous êtes seul responsable de l'utilisation du Contenu, y compris qu'il ne viole aucune loi applicable ou n'enfreigne aucun droit.",

  addImageReference: "Ajouter une référence d'image (Facultatif)",
  uploadImageOnly: 'Télécharger uniquement des fichiers JPG ou PNG. Max 4MB.',
  uploadJPGImageOnly: 'Télécharger uniquement des fichiers JPG. Max 4MB.',
  dragAndDrop: 'Glissez et déposez le fichier ou',
  errorFileSize: 'Ce fichier est trop gros. Maximum autorisé 4MB.',
  adjustScale: 'Choisissez la plage la plus appropriée pour générer des résultats très similaires ou différents de votre image téléchargée. La plage recommandée est de 7 à 15.',
  similar: 'Similaire',
  different: 'Différent',
  uploadNew: 'Chargez de nouveau',

  imageGuideGeneration: 'Votre image guidera votre prompt pendant la génération.',
  notAvailableForEngine: "Non disponible pour le moteur d'IA sélectionné.",

  imagePromptDescribe: "Pour commencer, fournissez une invite de l'image que vous souhaitez que notre IA génère en décrivant ce que vous avez en tête.",
  fileTypeNotSp: "Le type de fichier n'est pas pris en charge.",
  theFileSizeTooSmall: 'La taille du fichier est trop petite. Minimum de 512px autorisé.',

  relatedSearchPhoto: 'Recherche de photos stock associées',

  STOCK_PHOTO_PATH: 'images-libres-de-droits',
  STOCK_FOOTAGE_PATH: 'stock-video',
  STOCK_AUDIO_PATH: 'stock-audio',
  CLIPART_VECTOR_PATH: 'clipart-vecteurs',
  ALL_IMAGES: 'Tous types',
  PHOTO: 'Photo',
  PHOTOS: 'Photos',
  VECTORS: 'Vecteurs',
  FOOTAGE: 'Vidéos',
  AUDIO: 'Audio',
  LANG_MORETHANMILLION: 'DE PHOTOS LIBRES DE DROITS',
  LANG_SVG_OR_EPS: 'SVG ou EPS',
  LANG_OR: 'Ou',
  STANDARD_LICENSE: 'Licence standard',
  EXTENDED_LICENSE: 'Licence étendue',
  CREDIT_PACK: 'Pack de crédits',
  DOWNLOAD_PACK: 'Pack de Téléchargement',
  SUBSCRIPTION_PLAN: 'Formule d’abonnement',
  LANG_DOWNLOAD: 'Télécharger',
  STOCKPHOTO: "Banque d'images",
  STOCK_PHOTO: 'Photos',
  STOCK_VECTOR: 'Vecteurs',
  PREMIUM_STOCKPHOTO: 'Photos PREMIUM',
  PLUS_STOCK_PHOTO: 'Photos PLUS',
  PLUS_STOCK_VECTOR: 'Vectoriels PLUS',
  MOBILE_STOCKPHOTO: 'Photo Mobile',
  STOCKFOOTAGE: 'Vidéos Libres De Droits',
  IMAGEID: 'image ID',
  FONTID: 'ID',
  MEDIATYPE: 'Type de média',
  COPYRIGHT_TEXT: "Droit d'auteur",
  KEYWORDS: 'Mots-clés',
  LANG_CANCEL: 'Annuler',
  LANG_PURCHASE: 'Acheter',
  LANG_STOCKVECTORSANDILLUS: 'Vecteur et Illustration Libres de Droits',
  LANG_STOCKPHOTOSANDIMAGES: "Banque D'Images",
  LANG_MATCHES: 'correspondances',
  LANG_VECTORRESULTS: '{number} {keyword} images vectorielles libres de droits retrouvées pour vous.',
  LANG_PHOTORESULTS: '{number} {keyword} photos et images libres de droits retrouvées pour vous.',
  LANG_SIMILARIMGS: 'Photos libres de droits similaires',
  LANG_COLLECTIONS: 'Collections',
  LANG_ALL: 'Tous',
  LANG_STD: 'Standard',
  LANG_EXTD: 'Étendue',
  LANG_COMMERCIAL: 'Commerciale',
  LANG_SUBSCRIPTION: 'Abonnement',
  LANG_EDITORIAL: 'Éditorial',
  LANG_UPLOADED: 'Téléchargé',
  LANG_FRESHNESS: 'Mise en ligne',
  LANG_ANYTIME: "N'importe quand",
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '< 2 jours',
  LANG_PASTWEEK: 'La semaine dernière',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/ mois",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/ an",
  LANG_PASTMONTH: 'Le mois dernier',
  LANG_PAST3MONTHS: '3 mois',
  LANG_NUMOFPPL: 'Nombre de personnes',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: "Vous n'avez pas reçu l'e-mail de vérification?",
  LANG_WITHOUTPEOPLE: 'Sans personnes',
  LANG_PERSON: 'personne',
  LANG_PEOPLE: 'Personnes',
  LANG_INFANTS: 'Bébés',
  LANG_TODDLERS: 'Enfants',
  LANG_TEENS: 'Les adolescents',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'Caucasien',
  LANG_BLACK: 'Noir',
  LANG_HISPANIC: 'Ispanica',
  LANG_EAST_ASIAN: "Asie de l'Est",
  LANG_SOUTHEAST_ASIAN: 'Asie du Sud-Est',
  LANG_INDIAN: 'Indien',
  LANG_MIDDLE_EASTERN: 'Moyen-Orient',
  LANG_AGE: 'Âge',
  LANG_ALL_SHAPE: 'Afficher tout',
  LANG_ETHNICITY: 'Ethnicité',
  LANG_PEOPLEORMORE: '4 personnes et plus',
  LANG_COLOR: 'Couleur',
  LANG_OTHEROPTION: 'Autres Options',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Fonds transparents/uniformes',
  LANG_FILTER_BY: 'Filtrer par',
  LANG_FILTER: 'Filtre',
  LANG_PHOTOGRAPHY: 'Photos',
  LANG_VECTORILLUST: 'Illustrations vectorielles',
  LANG_SORTBY: 'Trier par',
  LANG_MORE: 'Plus',
  LANG_RELEVANCE: 'Pertinence',
  LANG_RESEND: 'Renvoyer',
  LANG_RESEND_VERIFICATION: 'Renvoyer la vérification e-mail',
  LANG_NEWEST: 'Nouveau',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: 'Orientation',
  LANG_HORIZONTAL: 'Horizontal',
  LANG_PORTRAIT: 'Portrait',
  LANG_SQUARE: 'Carré',
  LANG_PANORAMA: 'Panorama',
  LANG_SELECTIVE_FOCUS: 'Focus sélectif',
  LANG_PATTERN: 'Motif',
  LANG_VIBRANCE: 'Vibrance',
  LANG_VERIFICATION_DESCRIPTION: 'Vous devez vérifier votre compte avant de télécharger du contenu à partir de 123RF. Veuillez consulter votre e-mail ou cliquer sur le lien fourni ci-dessous si vous souhaitez le renvoyer.',
  LANG_VERIFICATION_TITLE: 'Veuillez vérifier votre e-mail.',
  VERIFICATIONRESETUNABLE: "Oups ! Votre e-mail n'a pas pu être envoyé. Veuillez contacter notre ",
  LANG_CHOOSEUPTO3COLORS: '3 couleurs maximum',
  LANG_ISOLATED: 'Isolé',
  LANG_STYLE: 'Style',
  LANG_SAFESEARCH: 'Recherche sécurisée',
  LANG_ONLY_AUTHENTIC: 'Authentique uniquement',
  LANG_EXCLUDE_PLACEHOLDER: 'ex. Fromage',
  LANG_EXCLUDEWORDS: 'Ne pas inclure ces mots',
  LANG_EXCLUDE: 'Exclure',
  LANG_INCLUDE_AICONTENT: "Inclure le contenu généré par l'IA",
  VISUAL_SEARCH: 'Recherche visuelle',
  VISUAL_SEARCH_TOOLTIP_TITLE: 'Des moyens simples de personaliser votre recherche!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: "1. Cliquez sur les points d'une image pour identifier votre élément de recherche.",
  VISUAL_SEARCH_TOOLTIP_INFO_2: "2. Cadrez la zone de l'image en glissant sur la zone pour zoomer ou réduire la zone de recherche.",
  VISUAL_SEARCH_TOOLTIP_INFO_3: "3. Clicquez sur 'X' pour revenir à la recherche",
  VISUAL_SEARCH_NO_RESULTS: "Votre recherche n'a produit aucun résultat.",
  LANG_RIS_TITLE: "Recherche d'image inversée",
  LANG_RIS_NOTFOUND: 'Aucun résultat trouvé !',
  LANG_RIS_ERROR_HEADER: "L'image importée contient des erreurs, assurez-vous que l'image respecte les critères suivants :",
  LANG_RIS_REFINE_SRCH_MSG: 'Vérifiez votre recherche affinée avec le mot-clé',
  LANG_RIS_UPLOADED_IMAGE: 'Image importée',
  LANG_TELL_ME_MORE: "M'en dire plus",
  LANG_ENTERKEYWORD: 'Veuillez saisir un mot-clé',
  LANG_SIZE: 'Taille',
  LANG_STANDARD_SIZE: 'Taille standard',
  LANG_EXTENDED_SIZE: 'TAILLES ÉTENDUES',
  LANG_SIZE_PX: "Un pixel est l'unité de base d'une image numérique. La taille de l'image, mesurée en pixels, détermine le niveau de détail et la taille du téléchargement.",
  LANG_SIZE_DPI: "Les points par pouce mesurent la résolution d'impression. Un dpi plus élevé signifie plus de détails. Pour l'impression, la résolution recommandée pour toutes les images est de 300 dpi.",
  LANG_SOCMED_SIZE: 'Tailles Médias Sociaux',
  LANG_COMPARE: 'Comparer',
  LANG_AUTO_ENHANCE: 'Amélioration auto',
  LANG_AUTO: 'Auto',
  LANG_BG_REMOVAL: "Suppression d'arrière-plan",
  LANG_TEXT: 'Texte',
  LANG_ELEMENTS: 'Éléments',
  LANG_IMAGE_INFO: 'Informations image',
  LANG_FOLLOW: 'Suivre',
  LANG_FOLLOWED: 'Suivi',
  LANG_FOLLOWING: 'Following',
  FACEBOOK_IMAGE: 'Image Facebook',
  FACEBOOK_LINK: 'Lien Facebook',
  FACEBOOK_COVER: 'Photo de couverture Facebook',
  FACEBOOK_STORY: 'Story Facebook',
  INSTAGRAM_POST: 'Post Instagram',
  INSTAGRAM_STORY: 'Story Instagram',
  TWITTER_POST: 'Post Twitter',
  TWITTER_HEADER: 'Header Twitter',
  PINTEREST_POST: 'Post Pinterest',
  EMAIL_HEADER: 'Header e-mail',
  PRESENTATION: 'Présentation',
  PRESENTATION_WIDE: 'Présentation large',
  EBOOK_COVER: 'Couverture E-book',
  LINKEDIN_POST: 'Post LinkedIn',
  BROCHURE_COVER: 'Couverture brochure (A4)',
  POSTCARD: 'Carte postale',
  PHOTO_PRINT: 'Impression photo',
  YOUTUBE_PROFILE_IMAGE: 'Image profil YouTube',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'Couverture chaîne YouTube',
  AUTO_COLOR: 'Colorimétrie auto',
  BALANCE: 'Équilibre',
  AUTO_LEVEL: 'Niveau auto',
  AUTO_TONE: 'Teinte auto',
  ENRICH: 'Enrichir',
  RESET: 'Réinitialiser',
  RESET_ALL: 'Réinitialiser Tous',
  FILE_DOWNLOAD_OPTIONS: 'Options téléchargement fichier',
  FILE_DOWNLOAD_INITIATED: 'Télécharger le fichier Initié',
  IMAGE_PROPERTIES: 'Propriétés image',
  DOWNLOAD_DETAILS: 'Télécharger les propriétés',
  FILE_SIZE: 'Taille fichier',
  LICENSE: 'Licence',
  FILEFORMAT: 'Filetype',
  CHANGE: 'Modifier',
  PRICING: 'Tarifs',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Enregistrer sous',
  IMAGE_USAGE: 'Utilisation image',
  PRINT: 'Impression',
  ELECTRONIC: 'Électronique',
  COMPREHENSIVE: 'Complète',
  DOWNLOAD_CREDITS: '<b>{quota}</b> crédits: téléchargez à partir de votre Pack de crédit',
  BASIC_SHAPE: 'Formes de base',
  CHIT_CHAT: 'Bulles de chat',
  SHAPES: 'Formes',
  ELEMENT_CHIT_CHAT: 'Élément chat',
  ORGANIC_ABSTRACT_SHAPES: 'Formes abstraites organiques',
  ELEMENT_SHAPES: 'Éléments formes',
  BUY_SINGLE_IMAGE: 'Acheter une seule image <br/><b>{quota} crédits</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Acheter un pack de crédit <br/><b>{quota} crédits</b>',
  BUY_CREDIT_PACK: 'Achetez un pack de crédit et <br/><b>économiser plus</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota} Crédits Pack à <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'Aucun engagement mensuel.',
  BUY_CREDIT_PACK_POINT_3: "Validité d'un an.",
  BUY_SINGLE_IMAGE_PSUB: 'Acheter une seule image <br/><b>{quota} téléchargement</b>',
  BUY_DOWNLOAD_PACK: 'Achetez le pack de téléchargement et <br/><b>économisez encore plus</b>',
  BUY_DOWNLOAD_PACK_POINT_1: 'Pack de téléchargement de {quota} images à <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Aucun engagement mensuel.',
  BUY_DOWNLOAD_PACK_POINT_3: "Validité d'un an.",
  LOWEST_PRICE: "<b>Prix le plus bas</b> <br/>avec Plan d'abonnement",
  LOWEST_PRICE_POINT_1: 'Essayez 1 mois à <b>{price}</b>',
  LOWEST_PRICE_POINT_2: 'Téléchargez 10 photos ou vecteurs.',
  LOWEST_PRICE_POINT_3: 'Sans limite de téléchargement quotidien, les téléchargements inutilisés sont reportés au mois suivant.',
  PER_IMAGE_SIZE: 'par image <b>{size} taille</b>',
  PER_IMAGE_ANY: 'par image <b>toute taille</b>',
  PER_IMAGE: 'par image',
  VIEW_PRICING: 'Plus de plans et de prix',
  DOWNLOAD_BEGIN: 'Votre téléchargement va débuter dans un instant. Veuillez Patienter.',
  DOWNLOAD_NOT_STARTING: "Téléchargement n'a pas encore commencé?",
  SELECT_MIRROR: '[Sélectionnez un miroir]',
  SHOW_MORE: 'Afficher plus',
  SHOW_LESS: 'Afficher moins',
  LICENSE_TYPE: 'Type de licence',
  LICENSE_SUMMARY: 'Tableau récapitulatif des licences',
  BACK: 'Retour',
  LANG_VECTOR: 'Fichier vectoriel',
  PREMIUM_VECTOR: 'Vectoriels PREMIUM',
  SCALE_ANY_SIZE: 'Redimensionner',
  LANDINGPAGE_VECTOR_HEADLINE: 'Vecteurs, Illustrations et Cliparts Libres de Droits sur 123RF',
  LANDINGPAGE_PHOTO_HEADLINE: "Banque d'Images et Photos Libres de Droits a Bas Prix sur 123RF",
  CATEGORIES: 'Catégories',
  LANDINGPAGE_PRICING_1: 'À la demande',
  LANDINGPAGE_PRICING_2: "S'abonner",
  LANDINGPAGE_AS_LOW_AS: 'à partir de',
  EMPTY_SEARCH_TITLE: "Oups, votre recherche n'a trouvé aucun résultat.",
  LANG_SMALL: 'Petit',
  LANG_MEDIUM: 'Moyen',
  LANG_LARGE: 'Grand',
  LANG_EXTRA_LARGE: 'Extra large',
  LANG_LOW_CREDITS: "Vous n'avez plus de crédits de téléchargement!",
  LANG_TOPUP: 'Top up',
  LANG_TOPUP_NOW: 'Recharger maintenant',
  SEO_DETAILS_TITLE_STOCKPHOTO: "{description} Banque D'Images et Photos Libres De Droits. Image {mediaId}",
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} Clip Art Libres De Droits, Svg, Vecteurs Et Illustration. Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: 'Image de la catégorie {description}. Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Image de la catégorie {description}. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Image et Photographie Gratuites {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Image gratuite de {description}. photo gratuite {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Vecteur et graphique gratuits {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'Vecteur gratuit {description}. Graphique gratuit {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF - Des millions de photos, vecteurs, vidéos et fichiers musicaux créatifs pour votre inspiration et vos projets.',
  LIVECHAT_LOOKINGHELP: "Souhaitez vousde l'aide?",
  LIVECHAT_CHATWITHUS: 'Nous Contacter!',
  FREE_TRIAL: "<b>Recevez 10 images gratuites</b> <br/>avec les formules d'abonnement",
  LANG_RELATEDSEARCH: 'Recherches associées',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'Recherche de photos stock associées',
  LANG_PROPERATTRIBUTE: 'Voici notre attribution:',
  LANG_ATTRIBUTEDESC: "Veuillez attribuer cette image à son photographe en plaçant cette ligne de crédit ci-dessous dans n'importe quelle zone de votre produit, site web ou garantie",
  LANG_ATTRIBUTE_TITLE: "L'attribution est facultative.",
  LANG_ATTRIBUTE_CREDIT: "Si vous souhaitez mentionner l'auteur, veuillez utiliser ce qui suit :",
  LANG_ATTRIBUTE_COPYLINK: 'Copier le lien',
  LANG_ATTRIBUTE_COPYTEXT: 'Copier le texte',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>Voici</b></a> le contrat de licence pour ce téléchargement',
  LANG_NEXTPAGE: 'Page Suivante',
  LANG_IMAGE_NO_AVAILABLE: "L'image # {mediaId} n'est plus disponible.",
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Visionnez notre vaste collection de stock images et acheter des millions de photos libres de droits à bas prix.',
  LANG_VECTOR_METAIMG_DESC: 'Rechercher et télécharger des millions de illustration libres de droits, vecteur graphique et clipart à bas prix.',
  LANG_ANOTHERMETAKWDEFAULT: "Banque d'image, phototèque, libre de droit, image, photo, photographe, illustration, vecteur, photo libre de droit, abonnement, photo pas cher , achat image, vecteur, vecteurs libre, vector graphique, graphique, cliparts, clipart, clip art",
  LANG_ANOTHERMETADESCDEFAULT: 'Rechercher et télécharger des millions de photos et illustrations libre de droit, cliparts, vecteurs et illustrations a bas prix.',
  EMPTY_FILTERED_RESULT_TITLE: "Oups, votre recherche avec ces filtres n'a donné aucun résultat.",
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Essayez de supprimer certains filtres ou de choisir un autre mot-clé.',
  CLEAR_ALL_FILTER: 'Désactiver tous les filtres',
  PROMOBAR_FREE10_TEXT: "Recevez <b>10 images GRATUITES</b> lors de votre essai gratuit d'un mois.",
  PROMOBAR_FREE10_BTNLABEL: 'COMMENCEZ DÈS MAINTENANT',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Réduction massive des tarifs de tous les abonnements.',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Afficher les plans',
  LANG_MODEL_RELEASED: "Droits cédés d'un Modèle",
  LANG_PROPERTY_RELEASED: "Droits cédés d'un Propriétaire",
  LANG_MODEL_RELEASE: 'Avec autorisations des modèles',
  LANG_PROPERTY_RELEASE: 'Avec autorisations de propriété',
  LANG_YES: 'Oui',
  LANG_NO: 'Non',
  LANG_MREXPLAIN: "Les autorisations des modèles est un document juridique signé par le modèle (s) d'une photographie ,ceci vous accorde la permission de publier / distribuer / et d'utiliser sa ressemblance a travers la photographie sous une forme ou une autre.",
  LANG_PREXPLAIN: "Les autorisations des propriétaires , est un document juridique signé par le propriétaire de l'objet/batiment où une photographie a été prise,ceci vous donne l'autorisation de publier / distribuer / utiliser la photographie sous une forme ou une autre.",
  LANG_DOWNLOADS: 'Téléchargements',
  LANG_OLDFILENAME: 'Ancien nom de fichier',
  LANG_DOWNLOAD_PREVIEW: 'Aperçu',
  LANG_SHARE: 'Partager',
  LANG_RESTRICTIONS: 'Restrictions',
  LANG_READ_MORE: "Plus d'informations",
  LANG_EDITORIAL_USE: 'Pour usage éditorial uniquement',
  LANG_EDITORIALDESCP: "Utilisation réservée à des fins éditoriales : L’utilisation de cette image dans des publicités, du contenu marketing ou promotionnel est interdite sauf autorisation supplémentaire de l’ayant droit. 123RF.com n'est pas en mesure d'effectuer la demarche d'autorisation.",
  LANG_LOGO_OWNERSHIP: "Droits d'auteur du logo",
  LANG_PHOTO_SHOT_SMARTPHONE: 'Cette photo a été prise avec un smartphone.',
  LANG_VECTORNOLOGO: 'If you wish to use this as your company logo, we may arrange for a <b>rights buy out</b> on your behalf. Please contact us at <b>{phonenum}</b> to find out more!',
  LANG_DOWNLOAD_QUOTA: "<b>{quota}</b> image: téléchargez à partir de Formules d'abonnement",
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> image: téléchargez à partir de Pack de Téléchargement',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: 'Utilisez-vous un navigateur plus ancien ?',
  UPGRADE_NEWER_BROWSER: "Passez à une version plus récente du navigateur pour profiter d'une expérience plus agréable !",
  CLICK_TO_DOWNLOAD: "Cliquez pour télécharger l'une de ces versions :",
  LANG_VECTOR_EDITABLE: 'Fichiers modifiables en SVG et EPS',
  PROMOBAR_GOOD15_TEXT1: 'Payez moins, téléchargez plus. ',
  PROMOBAR_GOOD15_TEXT2: '-15% sur tous les plans avec:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Payez moins, téléchargez plus. ',
  PROMOBAR_SUMMER25_TEXT2: '25% de remise sur TOUS les plans avec le code promo:',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '30% de remise sur TOUS les plans avec le code promo:',
  NO_EDIT_COMPARE_MSG: "Aucune modification n'a été apportée pour comparer.",
  SWITCH_TO_VECTOR_MSG: "Le Éditeur de photos n'es pas pris en charge pour les images de taille vectorielle. Toutes les modifications seront perdues si vous passez au format vectoriel.",
  SWITCH_TO_XL_MSG: "Le Éditeur de photos n'es pas pris en charge pour les images de taille XL. Toutes les modifications seront perdues si vous passez au format XL.",
  UNAVAILABLE_FOR_THIS_SIZE: 'Non disponible pour la taille {size}',
  SWITCH: 'CHANGER',
  SWITCH_LICENSE_EXT_MSG: "Souhaitez-vous d'abord télécharger cette image au format {type} ? Si vous passez à la licence étendue, toutes les modifications seront perdues",
  LANG_SESSION_EXPIRED: 'Votre session a expiré. Veuillez vous <a href={url}>reconnecter ici</a>',
  LANG_TEXT_COLOR: 'Couleur du texte',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Surligner',
  LANG_TEXT_ALIGNMENT: 'Alignement',
  LANG_TEXT_BOLD: 'Gras',
  LANG_TEXT_ITALIC: 'Italique',
  LANG_TEXT_UNDERLINE: 'Souligner',
  LANG_TEXT_BULLET: 'Bulle',
  LANG_TEXT_LINE_SPACING: 'Interligne',
  LANG_TEXT_LETTER_SPACING: "L'espacement des lettres",
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'Mettre au premier plan',
  LANG_BRING_BACKWARD: "Ramener à l'arrière",
  LANG_DUPLICATE: 'Dupliquer',
  LANG_OPACITY: 'Opacité',
  LANG_DELETE: 'Supprimer',
  LANG_TEXT_ADD: 'Ajouter un texte',
  LANG_TEXT_EDIT: 'Modifier le texte',
  LANG_TEXT_FONT_TYPE: 'Type de police',
  LANG_TEXT_FONT_SIZE: 'Taille de police',
  LANG_TEXT_ALL_FONTS: 'Toutes les polices',
  LANG_TEXT_UNDO: 'Annuler',
  LANG_TEXT_REDO: 'Refaire',
  LANG_TEXT_FONT: 'Police de caractère',
  LANG_SEARCH_FONT_TYPE: 'Type de police de recherche',
  LANG_SEARCH_SHAPE_TYPE: 'Rechercher des éléments',
  LANG_DONE: 'Effectué',
  ADD_HEADER: 'Ajouter un en-tête',
  ADD_TITLE: 'Ajouter un titre',
  ADD_BODY: 'Ajouter un corps de texte',
  ADD_ASSISTIVE: "Ajouter un texte d'assistance",
  STOCK_PHOTO_BANNER_TITLE: "Des millions d'images libres de droits pour tout créer !",
  STOCK_PHOTO_BANNER_DESC: 'Trouvez toutes les superbes images dont vous avez besoin pour créer des visuels et donner vie à vos idées de projet dès maintenant.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Illustrez vos histoires en utilisant les bonnes images ',
  STOCK_PHOTO_SUBSCRIBE_DESC: "Accédez instantanément à plus de 180 millions d'images pour créer vos propres histoires. Nos images et vos histoires sont la combinaison parfaite pour optimiser votre potentiel créatif !",
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Pack de téléchargement',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'Pour seulement',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: "Plan d'abonnement",
  STOCK_PHOTO_CARD_IMAGE: 'image',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: "S'inscrire maintenant",
  STOCK_PHOTO_CARD_BUY_NOW: 'Acheter maintenant',
  STOCK_PHOTO_POPULAR_ROYALTY: "Catégories populaires d'images libres de droits",
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Découvrez des plans et des tarifs abordables pour les photos',
  STOCK_PHOTO_DISCOVER_NOW: 'Voir plus',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Paysage',
  STOCK_PHOTO_BABIES: 'Bébés',
  STOCK_PHOTO_BUSINESS: 'Entreprise',
  STOCK_PHOTO_FOOD: 'Alimentation',
  STOCK_PHOTO_ANIMALS: 'Animaux',
  STOCK_PHOTO_TECHNOLOGY: 'Technologie',
  STOCK_PHOTO_HEALTHCARE: 'Santé',
  STOCK_PHOTO_NATURE: 'Nature',
  STOCK_PHOTO_ROMANCE: 'Romance',
  CLIPART_VECTOR_EXPLORE_MILLIONS: "Faites vos créations à l'aide de millions de vecteurs libres de droits à portée de main.",
  CLIPART_VECTOR_NEED_AN_EYE: 'Trouvez des illustrations vectorielles, des infographies, des cliparts, des icônes et plus encore pour concrétiser vos incroyables idées',
  CLIPART_VECTOR_FIND_VECTOR: 'Trouvez les vecteurs idéals pour illustrer vos projets.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: "Des millions d'infographies, illustrations, images clipart, caricatures, arrière-plans, icônes et plus encore vous attendent, donnez vie à vos créations design dès maintenant.",
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Catégories populaires de vecteurs libres de droits',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Découvrez des plans et des tarifs abordables pour les photos',
  CLIPART_VECTOR_DISCOVER_NOW: 'Voir plus',
  SPEND_AND_WIN_SUBTITLE: 'Pour chaque {currency} dépensé = 1 entrée',
  SPEND_AND_WIN_IMGALT: 'Dépensez et gagnez un iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: 'Graphique',
  CLIPART_VECTOR_ADVERTISING: 'Publicité',
  CLIPART_VECTOR_FLOWER: 'Fleur',
  CLIPART_VECTOR_ABSTRACT: 'Résumé',
  CLIPART_VECTOR_POP: 'Pop',
  CLIPART_VECTOR_BIRD: 'Oiseau',
  CLIPART_VECTOR_ILLUSTRATION: 'Illustration',
  CLIPART_VECTOR_PIXEL: 'Pixel',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'Vecteur, SVG et EPS',
  FREE_UPGRADE: 'Upgrade gratuit',
  FREE_UPGRADE_MESSAGE: 'Upgrade GRATUIT vers la taille XL pour tous les abonnements ou les packs de téléchargement. Achetez maintenant.',
  LANG_FREE_IMAGE: '123RF Gratuit',
  LANG_IMAGE_FREE: "Cette photo est gratuite pour un usage commercial et personnel. L'attribution est requise.",
  LANG_HOW_TO_ATTRIBUTE: 'Comment attribuer?',
  LANG_FREE_DOWNLOAD: 'Téléchargement Gratuit',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Télécharger',
  LANG_GO_PLUS_BUTTON: 'Choisissez PLUS',
  LANG_ATTRIBUTION_IS_KEY: "L'attribution est un élément important pour les créateurs de contenu qui rendent leur travail accessible publiquement. Nos créateurs de contenu apprécient l'exposition acquise par leurs créations utilisées, partagées et créditées par des millions d'utilisateurs comme vous.",
  LANG_GET_ATTRIBUTION_LINK: "Copiez ce lien et placez-le dans un emplacement proche de l'endroit où le contenu est utilisé.",
  LANG_CREDIT_OWNER: 'Veuillez créditer le créateur:',
  LANG_USE_ATTRIBUTION: "Utilisez le lien d'attribution ci-dessous.",
  LANG_ATTRIBUTION_LOCATION: "Si ce n'est pas faisable, d'autres bons emplacements sont au pied de page du site Web, du blog ou de la newsletter ou même une section de crédits spécifiques du site seront suffisants pour créditer le propriétaire du {media_type}.",
  LANG_GOT_A_QUESTION: "Vous avez des questions ? <a href={url} target='_blank'><u>Nous contacter</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'Vous devez créditer le propriétaire de cette image gratuite.',
  LANG_ATTR_FREE_DOWNLOADING: 'Tenez bon ! Ce ne sera pas long.',
  LANG_PLUS_CONTENT_1: 'Vous obtiendrez des téléchargements illimités',
  LANG_PLUS_CONTENT_2: 'Accès à plus de 100 millions de photos et de vecteurs',
  LANG_PLUS_CONTENT_3: 'Illustrations 3D exclusives uniquement sur 123RF !',
  LANG_PLUS_CONTENT_4: 'Téléchargements illimités',
  LANG_PLUS_CONTENT_5: 'Liberté créative illimitée',
  FREE_VECTOR: 'Vectoriels FREE',
  FREE_STOCKPHOTO: 'Photos FREE',
  FREE_PUBLIC_DOMAIN_CC0: 'Domaine public CC0',
  BUY_5_DOWNLOAD_PACK: "<b>Offre d'une durée limitée!</b><br/>3 + <b><span style='color:red'>2 GRATUITS</span></b> = 5 photos ou vecteurs",
  BUY_5_DOWNLOAD_PACK_POINT_2: "Téléchargement à tout moment (jusqu'à un an)",
  BUY_5_GET_2_FREE: 'Recevez 2 GRATUITS !',
  LANG_ASK_DOWNLOAD_PLUS: 'Voulez-vous télécharger du contenu PLUS ?',
  LANG_SIGN_UP_PLUS: 'Souscrivez à notre abonnement 123RF PLUS',
  LANG_FOR_ONLY: "<span class='text'>pour seulement</span> <span class='price'>{price}</span>/mois",
  LANG_FOR_ONLY_2: "<span class='text'>pour seulement</span> <span class='price'>{price}</span>",
  LANG_BENEFIT_PLUS: 'Profitez de notre abonnement PLUS',
  LANG_RELEASES: 'Cessions de Droits',
  LANG_UNAVAILABLE: 'Non disponible',
  LANG_ATTR_FREE_IMG_TITLE2: 'Nous sommes heureux que vous ayez trouvé ce dont vous aviez besoin. 🎉',
  LANG_PLEASE_NOTE: "Veuillez noter que cette image n'est pas accompagnée d'un modèle ou d'une autorisation de propriété. L'utilisation commerciale est à vos propres risques.",
  LANG_PLEASE_ADVISED: "Veuillez noter que les images contenant des individus reconnaissables ne viennent pas avec des versions. Pour utiliser ces images commercialement, l'autorisation doit être obtenue auprès des parties concernées. Si l'image ne contient pas d'individus reconnaissables, il ne devrait pas y avoir de problèmes de droit d'auteur lors de l'utilisation commerciale.",
  LANG_REACHED_LIMIT: 'Ops ! Vous avez atteint votre limite de téléchargement quotidienne.',
  LANG_COME_TOMORROW: 'Nous sommes ravis que vous aimiez nos images gratuites ! Revenez demain pour une mise à jour de votre limite de téléchargement. Merci. 😊',
  LANG_DOWNLOAD_ERROR: "Oups ! Votre image n'a pas pu être téléchargée en raison d'une erreur.",
  LANG_REFRESH_AND_TRY_AGAIN: "Veuillez rafraîchir votre navigateur et réessayer. <a href={url} target='_blank'>Faites-nous savoir</a> si ce problème persiste.",
  LANG_REACHED_LIMIT_PLUS: 'Limite de téléchargement quotidien atteint',
  LANG_COME_TOMORROW_PLUS: "Ouah! Vous avez atteint votre limite de téléchargement pour aujourd'hui. Veuillez revenir demain pour télécharger plus. Merci pour votre soutien!",
  SEE_YOU_TOMORROW: 'Ci vediamo domani!',
  ORIGINAL: 'Original',
  'AUTO_B&W': 'Auto N&B',
  AUTO_ADJUST: 'Auto Réglage',
  AUTO_POP: 'Auto Pop',
  NONE: 'Aucun',
  LANG_CROP: 'Rogner',
  LANG_ENABLE_EDITOR: "Éditer l'image",
  LANG_DISABLE_EDITOR: 'Fermer',
  LANG_IMAGE_FREE_CC0: "Ceci est une image CC0. L'attribution n'est pas requise. Vous pouvez l'utiliser conformément aux termes et <a class='linkCC0' href={url} target='_blank'>conditions de la licence CC0</a>.",
  LANG_PREVIEW: 'Avant-première',
  LANG_ATTRIBUTION_CC0_TITLE: 'Image CCO : attribution non requise',
  LANG_ATTRIBUTION_TITLE: 'Attribution requise',
  LANG_ATTRIBUTION_CONTENT: "Le lien d'attribution sera fourni après que vous aurez téléchargé le fichier.",
  LANG_ATTRIBUTION_CC0_CONTENT: "L'attribution n'est pas nécessaire car il s'agit d'une image CCO.",
  LANG_SUBCRIBE_PREMIUM: 'Vous avez atteint votre limite quotidienne de téléchargement ! Pour en télécharger davantage, inscrivez-vous à 123RF PREMIUM.',
  LANG_REMOVE_ATTRIBUTION: "Supprimer l'attribution ?",
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Souscrivez à l'abonnement <img src={image} alt='123RF Plus Logo'> pour bénéficier d'un accès illimité à 100 millions de photos et d'illustrations sans aucune attribution requise.",
  LANG_CREATOR_ATTRIBUTION: "Veuillez mentionner le créateur en utilisant le lien d'attribution ci-dessous.",
  LANG_GET_ATTRIBUTION_LINK_2: "Copiez ce lien et placez-le à côté de l'espace où le contenu est utilisé, dans le pied de page du site ou dans la section contenant les informations principales du site.",
  LANG_APPLY_BG_REMOVAL: "Appliquer la suppression de l'arrière-plan",
  LANG_APPLYING_EDIT: 'Appliquer les modifications...',
  LANG_APPLYING_EDIT_ERROR: 'Un problème est survenu.',
  LANG_TRY_AGAIN: 'Essayez à nouveau ?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Collection complète',
  LANG_SEARCHCOLLECTION_PLUS: 'Collection essentielle',
  LANG_SEARCHCOLLECTION_FREE: 'Collection basique',
  LANG_SIMILARIMAGESPAGE_TITLE: "Banque D'Images, Photos, Vecteurs Libres De Droits sur 123RF",
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Photos similaires',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Oops! Rien à voir ici.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Image Non disponible',
  LANG_PER_IMAGE: '/image',
  LANG_PLANS_AND_PRICING: 'Plans et tarifs',
  LANG_CHOOSE_BEST_PLAN: 'Choisissez le meilleur plan qui correspond à vos besoins.',
  LANG_WANT_MORE_SAVING_1: "Vous voulez plus d'économies?",
  LANG_WANT_MORE_SAVING_2: ' Voir plus de plans à partir de {price} / image',
  LANG_BEST_VALUE: 'Le meilleur rapport qualité/prix, économisez {percentage}%',
  LANG_YEARLY_PLAN: 'Plan annuel',
  LANG_WITH_MONTHLY_PAYMENTS: 'Avec des paiements mensuels',
  LANG_GRAB_YEARLY_PLAN: 'Optez pour un plan annuel et économisez {percentage}%.',
  LANG_PRICE_PER_MONTH: '{price} par mois',
  LANG_PRICE_PER_YEAR: '{price} par an',
  LANG_GET_X_EVERY_MONTH: 'Obtenez {quota} photos ou vectoriels chaque mois',
  LANG_X_EVERY_MONTH: '{quota} Photos ou vectoriels<br>chaque mois',
  LANG_SINGLE_IMAGE_PURCHASE: "Achat d'image unique",
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Obtenez uniquement ce dont vous avez besoin pour le moment.',
  LANG_TOTAL_PRICE: 'Total {price}',
  LANG_DOWNLOAD_IMMEDIATELY: "Téléchargez l'image immédiatement Après le paiement.",
  LANG_NEXT: 'Suivant',
  LANG_TITLE: "Banque D'Images, Photos, Vecteurs Libres De Droits sur 123RF",
  SIMILAR_STOCK_PHOTOS: 'Photos similaires',
  EMPTY_SIMILAR_SEARCH_RESULTS: 'Oops! Rien à voir ici.',
  UNAVAILABLE_IMAGE: 'Image Non disponible',
  LANG_DOWNLOAD_PACK: 'Télécharger le pack',
  LANG_GET_X_ANY_TIME: '{quota} photos ou vectoriels à tout moment.',
  LANG_X_CREDITS: '{total} Crédits',
  LANG_PER_CREDIT: '/Crédit',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount} photos{videoCount, plural, =0 {} =1 { ou # vidéo} other { ou # vidéos}} à tout moment',
  INCLUDES_STANDARD_LICENSE: 'Inclure la licence standard',
  LANG_MEDIUM_SIZE: 'Taille moyenne',
  LANG_LARGE_SIZE: 'Grande taille',
  LANG_EXTRA_LARGE_SIZE: 'Taille extra large',
  LANG_STANDARD_SIZES: 'Taille standard',
  LANG_ROYALTY_FREE: 'libre de droits',
  LANG_OPTIONS: 'Options',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Profitez de notre abonnement PLUS',
  LANG_RELATED_FONT_SEARCH: 'recherches associées',
  AI_GENERATED_PHOTO: "Image générée par l'IA",
  FONT_PRICING_CREDIT: 'Crédits',
  '3_FONTS_3_IMGS': '3 polices <b>ou</b> 3 images <b>ou</b> 1 vidéo',
  '9_FONTS_9_IMG_3_VIDEOS': '9 polices <b>ou</b> 9 images <b>ou</b> 3 vidéos',
  PURCHASE_AND_DOWNLOAD: 'Acheter et télécharger ',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'FONT (OTF, TTF)',
  FONT_PACKS_LICENSE: 'Licence 123RF Packs',
  FONT_META_DESC: "Téléchargez {product_title} Police, Fonde ou Typographie pour créer des conceptions étonnantes pour l'impression et le Web.",
  FONT_META_TITLE: 'FONT - {product_title} | 123rf',
  FONT_SIMILAR: 'Polices similaires',
  LANG_PLANS: 'PLANS',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Essayer de créer des variations',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: "Générez de nouvelles images à partir de l'image originale fournie. De plus, c'est une nouvelle façon de récompenser nos contributeurs 123RF chaque fois qu'une image générée par l'IA est utilisée sous licence !",
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'Notre communauté vous remercie. Les frais de licence seront partagés avec le contributeur 123RF.',
  LANG_CONTRIBUTORS_AI_VARIATION: "Les contributeurs 123RF de l'image originale sont rémunérés pour chaque variation d'IA faisant l'objet d'une licence.",
  LANG_AI_VARIATION_LIMIT: "Oh non ! Vous avez atteint la limite de génération d'images IA pour aujourd'hui.",
  LANG_GENERATE_COMEBACK: "Revenez demain pour d'autres générations !",
  LANG_LICENSE_AND_DOWNLOAD: 'Licence et téléchargement',
  LANG_CONFIRM_OK: "D'accord",
  LANG_AI_SWITCH_CONTENT: "Le Éditeur de photos  n'est pas pris en charge pour les variations IA. Toutes les modifications seront perdues si vous passez aux variations IA.",
  LANG_GENERATE_VARIATIONS: 'Générer des variations',
  LANG_GENERATE_WITH_AI: "Générer des images avec l'IA",
  LANG_NEW: 'Nouveau',
  LANG_AI_GENERATOR_LICENSE: "Conditions d'utilisation du Générateur IA 123RF (“Conditions”)",
  LANG_I_UNDERSTAND: 'Je comprends',
  LANG_WHAT_IS_THIS: "De quoi s'agit-il ?",
  LANG_AUTHENTIC: 'Authentique',
  LANG_ABSTRACT: 'Abstrait',
  LANG_MACRO_CLOSEUP: 'Macro/Plan rapproché',
  LANG_OBJECT: 'Objet',
  LANG_WATERCOLOR: 'Aquarelle',
  LANG_FLAT: 'Plat',
  LANG_CARTOON: 'Dessin animé',
  LANG_GEOMETRIC: 'Géométrique',
  LANG_GRADIENT: 'Dégradé',
  LANG_ISOMETRIC: 'Isométrique',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: 'Dessiné à la main',
  LANG_NATURAL: 'Naturel',
  LANG_WARM: 'Chaud',
  LANG_DRAMATIC: 'Dramatique',
  LANG_VIVID_BOLD: 'Vif et audacieux',
  LANG_BLACK_WHITE: 'Noir et blanc',
  LANG_ASIAN: 'Asiatique',
  LANG_AFRICAN_AMERICAN: 'Afro-américain',
  LANG_STANDARD: 'Standard',
  LANG_EXTENDED: 'Étendu',
  LANG_STANDARD_CONTENT_1: 'Vues illimitées pour les réseaux sociaux, le web, les e-mails et les appareils mobiles.',
  LANG_STANDARD_CONTENT_2: "Jusqu'à 500 000 tirages.",
  LANG_STANDARD_CONTENT_3: 'Ne pas utiliser sur des produits à vendre.',
  LANG_EXTENDED_CONTENT_1: 'Vues illimitées pour les réseaux sociaux, le web, les e-mails et les appareils mobiles.',
  LANG_EXTENDED_CONTENT_2: 'Exemplaires illimités.',
  LANG_EXTENDED_CONTENT_3: 'Peuvent être utilisés sur des produits à vendre.',
  LANG_ORIGINAL_IMAGE: 'Image originale',
  LANG_IMPORTANT_NOTE: 'Note importante',
  LANG_AI_ORIGINAL_ALTERED: "Les variations IA sont des variantes de l'image originale qui ont été modifiées à l'aide de la technologie IA. C'est un peu comme si un éditeur d'IA retouchait l'image que vous avez choisie.",
  LANG_GENERATE_VARIATIONS_NOTE: "Étant donné que nous en sommes actuellement à la phase bêta des variations d'images basées sur l'IA, les images générées peuvent produire des résultats inattendus. Nous nous efforçons de veiller à ce que ces résultats et ces variations soient conformes à des lignes directrices éthiques et responsables en matière d'IA.",
  LANG_LICENSE_AI_EDITED: "Lorsque vous obtenez une licence pour l'une de ces versions éditées par l'IA, le détenteur des droits d'auteur de l'image originale reçoit une redevance de licence.",
  LANG_MOOD: 'Ambiance',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Par exemple, couleur verte, cheveux raides',
  LANG_SIMILAR_STOCK: 'Images de stock similaires',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'Avec autorisations des modèles',
  WITH_PROPERTY_RELEASE: 'Avec autorisations de propriété',
  MODEL_RELEASE_CONTENT: "Les autorisations des modèles est un document juridique signé par le modèle (s) d'une photographie ,ceci vous accorde la permission de publier / distribuer / et d'utiliser sa ressemblance a travers la photographie sous une forme ou une autre.",
  PROPERTY_RELEASE_CONTENT: "Les autorisations des propriétaires , est un document juridique signé par le propriétaire de l'objet/batiment où une photographie a été prise,ceci vous donne l'autorisation de publier / distribuer / utiliser la photographie sous une forme ou une autre.",

  variousStockGAI: "Un modèle d'IA conforme à la loi, entraîné avec des millions de photos de stock et optimisé pour les besoins des entreprises, offrant la tranquillité d'esprit avec une couverture de responsabilité.",
  extendedLicense: 'Licence Étendue',
  sizes: 'Tailles',

  downloadMail1st: "Notre fournisseur de services d'agrandissement d'images vous enverra par courriel à",
  downloadMail2nd:
    'les instructions pour télécharger le fichier final agrandi dans le jour ouvrable suivant',

  aiGenarated: "Image générée par l'IA",

  variousOpenJourney: "Le modèle IA génère de l'art ressemblant au style distinctif de Midjourney.",
  variousTurboVision: 'Génération à haute vitesse à des résolutions "normales" XL, améliorant la qualité globale.',
  variousRealVisXL: 'Le modèle vise à obtenir des résultats photoréalistes avec précision et exactitude.',
  variousPortraitPlus: 'Modèle avec une composition de portrait uniforme, idéal pour un rapport 1:1.',
  enterVirifiedCode: 'Entrez le code de vérification',
  verify: 'Vérifier',
  didNotGetCode: 'Vous n\'avez pas reçu de code ?',
  clickToResend: 'Cliquez pour renvoyer',
  otpCannotEmpty: 'OTP ne peut pas être vide.',
  sentCodeToMail: 'Nous avons envoyé un code à votre e-mail enregistré.',
  invalidCode: 'Code invalide',
  expiredOTP: 'Le code OTP a expiré, veuillez le renvoyer.',
  sendEmail: 'L\'e-mail a été envoyé avec succès, veuillez vérifier votre boîte de réception pour continuer.',
  remainingTime: 'Temps restant: ',
};

export default fr;
