const cn = {
  // Header
  photos: '摄影图像',
  Photos: '摄影图像',
  vectors: '矢量图',
  Vectors: '矢量图',
  footageupper: '视频',
  footage: '视频',
  video: 'Video',
  audio: '音频',
  signin: '登录',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Chinese.png',
  language: '语言',
  // Footer
  contact: '联系方式',
  contactUs: '联系我们',
  privacy: '隐私协议',
  terms: '使用条款',
  cookie: 'Cookie Policy',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: '忘记密码',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: '请输入一个有效的邮箱地址。',
  emailCannotEmpty: '邮箱地址不能为空',
  returnToLogin: '返回登录',
  email: '邮箱',
  // Login
  username: '用户名',
  pw: '密码',
  rememberMe: '记住我',
  login: '登录',
  forgotPassword: '忘记密码',
  loginAsGuest: '访客登录',
  // ResetPassword
  resetPwdDesc: '请输入您的新密码，马上开始登录。',
  newPw: '新的密码',
  confirmPwd: '确认密码',
  resetPwdButton: '重置我的密码',
  resetPwdTitle: '重置您的密码',
  enterYourNewPw: '请输入新密码。',
  pwMiniMumChars: '最少需要8个字符',
  pwMaxChar20: '最多可输入20个字符',
  pwLowerCase: '至少1个小写字母',
  pwUpperCase: '至少1个大写字母',
  pwNumeral: '至少1个数字',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: '确认密码与原密码不符',
  resetPwdInvalidLink: '链接无效或已过期。',
  pwHasBeenReset: '密码重置成功，请重新登录以继续。',
  // Admin index
  companyName: '公司名称',
  phone: '电话',
  fax: '传真',
  address: '地址',
  idd: 'IDD',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: '全部项目',
  // Update Password
  updatePassTitle: '更新 密码',
  updatePassDes: '出发前更新您的密码 出发前更新您的密码！',
  oldPassword: '旧的密码',
  confirmNewPassword: '确认新的密码',
  returnToHome: '返回首页',
  // Cookie Policy
  langCookie01:
    'When you visit %host%, we place a small text file called a cookie in your computer hard disk.',
  langCookie02:
    'This file allows us to track you so that we are able to provide basic functionality to you such as shopping carts. They are also used to provide an enhanced online user experience and provide you with functionality such as "likebox".',
  langCookie03:
    'In addition, it allows us to analyse your interaction with our website (including pages visited, search terms and images viewed). This is irrespective of whether you choose to make a purchase or not.',
  langCookie04:
    'The cookies you consent to, are also used to profile you into an audience list so that we can deliver targeted advertising tailored to your interests.',
  langCookie05:
    'Most browsers allow you to reject cookies; but if you do so, certain functionality may be unavailable and certain webpages may not display properly.',

  downloadModalTitle: '图片下载',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf: '不支持TIFF格式文档，视频脚本以及音频脚本。 以下媒体：',
  messageFileNotSupported2ndHalf: '已从批量下载中删除。',
  licenseOnBehalf: '代理许可',
  customizedPortal: 'Customized portal',
  normalDownload: '正常下载',
  myCredit: '我的点数',
  lowCreditsReminder: '低点数',
  downloadModalId: '图像ID:',
  downloadModalPrice: '点数:',
  clearAll: '清空所有',
  myActivities: '我的活动',
  info: '资料',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: '下载此内容使用',
  // homepage page
  home3Title: '免费浏览',
  toSearchKeyword: '在这里输入您要搜索的内容',
  toSearchAIKeyword: '输入关键词、文章标题或博客标题',
  allImages: '全部图像',
  freeImages: '免费图像',
  categoryImage: 'Category',
  recentViewed: '最近浏览',
  latestIMG: '最新图像',
  // Setting
  adminControlPanel: '管理员控制面板',
  downloadHistory: '下载记录',
  hruc: 'HRUC',
  changePassword: '更改密码',
  logOut: '退出',
  hello: '您好,',
  viewAccount: 'View Account',
  myAccount: '我的帐户',
  myCollections: '我的收藏',
  personalCredits: '个人点数',
  groupTotal: '群组总点数',
  toTalUnpaidLicense: '所有未付款许可证',
  highRUnwatermarkComp: '高清无水印样图',
  monthSelection: 'Month selection',

  // Menu
  categories: '类别',
  myLikebox: '我的收藏夹',
  settings: '设置',
  notification: '通知',
  clear: '明确',
  likebox: '收藏夹',
  credits: '点数',
  // Credit summary at header
  downloadAndUsege: '下载及使用',
  totalDlCredit: '个人下载点数',
  allocatedDlCredit: '已分配的下载点数',
  personalAvailableCre: '个人可用点数',
  groupAvailableCre: '群组可用点数',
  availableDlSlot: '能够下载席位',
  uNPLD: '现在使用，以后付款',
  hiResHRUC: '高清无水印样图/样片（HRUC）',
  monthlyAllocation: '月度分配',
  remainingQuota: '剩余额度',
  remainingHRUCQ: '剩余高清无水印样图',
  useNowPayLater: '先用后付(UNPL)',
  totalPendingLicense: '所有待下载的授权',
  enterprisePlan: '企业素材库定制套餐',
  unallocatedCredits: '未分配的点数',
  unutilizedAllocatedCredits: '已分配点数',
  usersTitleTotalCredits: '用户',
  groupsTitleTotalCredits: '群组',
  titleTotalCreditsAvailable: '可用点数合计',
  // live chat
  liveChatLookIngHelp: '请告诉我们如何为您提供帮助。',
  liveChat: '在线聊天',
  whatsApp: 'WhatsApp',
  hiThere: '您好，',
  // email response
  emailIsNotAvailable: '邮件不存在。',
  anErrorHaSoccured: '发生错误，请重试。',
  emailHasBeenSent:
    '邮件发送成功，请进入您的邮箱查看。 我们已向您发送带有重置指引的邮件__email. 如果邮件5分钟内还没有收到邮件，请检查您的垃圾邮件文件夹。',
  tooManyAttempts: '尝试次数过多，请重试',
  // INVALIDPASSWORD
  inValidPassword: '密码无效！请检查capslock按键是否开启。',
  userIsBlocked: '尝试太多次，您的账号被暂时锁定了。',
  blockWarrings: '温馨提示，10次尝试失败后，您的账号将被暂时锁定。',
  // Update Password
  resetPwdUpdated: '您的密码已更新。',
  updatePwDfailed: '无法更新密码！',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: '与您共享一个名字是[{VAR1}]的收藏夹。',
  notificationOutofCredits: '下载点数已用完。',
  notificationAllcateCredit: '分配[{VAR1}]的下载点数给您。',
  notificationRemoveCredits: '从您的帐户删除 [{VAR1}] 下载点数。',
  notificationAssignGroups: '把您分配进 [{VAR1}] 的群组。',
  notificationRemoveFromGroup: '把您从 [{VAR1}] 群组移除。',
  purcahsedCredits: '您刚刚购买了 [{VAR1}] 点数。',
  notificationAssignAdmin: '把您设置为管理员。',
  notificationRemoveAdmin: '取消了您的管理员身份。',
  notificationAssignGroupAdmin: '设置您作为 [{VAR1}] 组管理员。',
  notificationRemoveGroupAdmin: '取消您作为 [{VAR1}] 组管理员身份。',
  // Notification Side Bar - admin
  adminNtificationShareLikebox: '与 [{VAR1}] 共享一个名为 [{RECEIVER}] 的收藏夹。.',
  adminNtificationAllcateCredits: '分配 [{VAR1}] 下载点数到 [{RECEIVER}]。',
  adminNtificationRemoveCredist: '从 [{RECEIVER}] 账户删除 [{VAR1}] 下载点数。',
  adminNtificationAssignGroup: '分配 [{RECEIVER}] 到 [{VAR1}] 群组。',
  adminNtificationRemoveFromGroup: '从 [{VAR1}] 群组移除 [{RECEIVER}]。',
  adminPurchasedCredits: '[{RECEIVER}] 刚刚购买了 [{VAR1}] 点数。',
  adminNtificationAssignAdmin: '设置 [{RECEIVER}] 为管理员。',
  adminNtificationRemoveAdmin: '取消 [{RECEIVER}] 管理员身份。',
  adminNtificationAssignGroudAdmin: '把 [{RECEIVER}] 设置为 [{VAR1}] 组管理员。',
  adminNtificationremoveGroupAdmin: '取消 [{RECEIVER}] 作为 [{VAR1}] 组管理员身份。',
  // datetime
  year: '年',
  month: '月',
  week: '周',
  day: '天',
  hour: '小时',
  minute: '分钟',
  second: '第二',
  ago: '前',
  justNow: '現在',
  // datetimes
  yearS: '年',
  months: '月',
  weeks: '周',
  days: '天',
  hours: '小时',
  minutes: '分钟',
  seconds: '秒',
  // LicenseAgreement
  licenseAgreement: '许可证合约 ',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: '用户名不能为空',
  passWordCannotEmpty: '新密码不能为空',
  oldPasswordCannotEmpty: '旧密码不能为空',
  confirmPasswordCannotBeEmpty: '确认密码不能为空',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: '无法发送更多电子邮件，请检查您的邮箱。',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: '您使用的是旧版浏览器吗？',
  upgradeBrowser: '升级到新的浏览器版本，享受更流畅的体验!',
  clickToDownload: '点击下载其中任何一个。',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: '观看全部',
  // Filter bar
  aiGenerate: 'AI生成的图像',
  standardModels: '标准模型',
  fineTunedModels: '微调模型',
  aiContent: '包括AI生成的内容',
  filter: '过滤',
  safeSearch: '安全搜索',
  aiAssist: '人工智能辅助',
  freshness: '更新时间',
  gender: '性别',
  sortBy: '排列',
  mediaType: '素材类型',
  orientation: '分类筛选',
  modelPreferences: '偏好模特',
  people: '人',
  more: '更多',
  numOfPeople: '人数',
  age: '年龄',
  ethnicity: '种族',
  collection: '图片类型',
  uploaded: 'Uploaded',
  style: '类型',
  cutOut: '单色或透明背景',
  doNotIncludeWords: '不包含以下文字',
  doNotIncludeWords_ex: '例如，芝士',
  color: '顏色',
  max1Color: '最多 1 种颜色',
  license: '许可证类型',
  fps: 'FPS',
  resolution: '分辨率',
  clipDuration: '音档长度 (分钟)',
  loop: '循环',
  category: '分类',
  genre: '流派',
  tempo: '节拍 (bpm)',
  // Filter button text
  Relevance: '关联的',
  New: '最新',
  Georank: '依照所在位置排序',
  DownloadHistory: '下载记录',
  Popularity: '流行',
  Photography: '照片',
  VectorIllustration: '矢量图像',
  Horizontal: '纵向',
  Landscape: '横向',
  Portrait: '肖像',
  Square: '正方形',
  Panorama: '全景',
  numPpl: '人数',
  WithoutPeople: '无人物',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: '不包含字詞',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: '过去一周',
  LastMonth: 'Last month',
  PastThreeMonths: '过去3个月',
  PastTwodays: '过去两天',
  Male: '男性',
  Female: '女性',
  Standard: '标准',
  extended: '扩展',
  Editorial: '新闻类图片',
  Commercial: '商业用途',
  Black: 'Black',
  Caucasian: '高加索人',
  AfricanAmerican: '非裔美国人',
  Asian: '亚洲人',
  EastAsian: 'East Asian',
  Hispanic: '拉丁美洲人',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: '孩子',
  Teenagers: '青少年',
  Adults: '成人',
  Seniors: '老年人',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: '是',
  Nonloopable: '否',
  Music: '音乐',
  SoundEffects: '特效声音',
  LogoAndIdents: '商标 & 识别',
  Veryslow: '非常慢',
  VeryslowSubText: '(0-79)',
  Slow: '慢',
  SlowSubText: '(80-119)',
  Medium: '中等',
  MediumSubText: '(120-139)',
  Fast: '快',
  FastSubText: '(140-159)',
  Veryfast: '非常快',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: '欢迎程度',
  MostDownload: 'Most Download',
  Credits: '点数',
  Freshness: '更新时间',
  pickAGenre: '选择一个类型',
  // Paginations
  Of: '/',
  Page: '页数',
  // home
  recommended: '推荐给你',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: '热门关键词',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: '游戏',
  cartoonSounds: '卡通',
  soundPacks: '音效',
  interfaceSounds: '界面音效',
  transitionsAndMovementsSounds: '转换与运动',
  natureSounds: '天籁',
  domesticSounds: '国内声音',
  urbanSounds: '都会风格',
  industrialSounds: '工业类',
  futuristicSounds: '未来派',
  humanSounds: '人类音乐',
  othersSounds: '其他',
  ambientSounds: '环境',
  childrenSounds: '孩童',
  cinematicSounds: '戏剧',
  classicalSounds: '古典',
  corporateSounds: '商业',
  electronicaSounds: '电子乐',
  soulSounds: '灵魂R&B',
  jazzSounds: '爵士乐',
  popSounds: '流行乐',
  worldBeatSounds: '世界节奏',

  countrySounds: '西部与乡村',
  drumSounds: '鼓与Bass',

  folkSounds: '民俗风',
  funkSounds: '放克音乐',

  hiphopSounds: '嘻哈',
  holidaySounds: '节庆与季节',

  rockSounds: '摇滚',

  individualSounds: '独立',
  introSounds: '开场与谢幕',
  ascSounds: '升序&降序',
  businessSounds: '企划与媒体',
  orchestralSounds: '交响乐',
  festiveSounds: '节日',
  percussiveSounds: '打击乐',
  miscellaneousSounds: '其他',
  packsSounds: '懒人素材包',
  percussionSounds: '敲击声',
  sampleLoopSounds: '样本循环',

  // Search images
  searchImage: '照片和矢量',
  StockPhotosAndImages: '照片与图片库',
  StockVectorClipartAndIllustration: '股票向量和插图',

  matches: '配对',
  editorial: '新闻类图片',
  downloaded: '此图片之前已下载',
  to: 'to',
  relatedSearches: '相关搜索',
  similarTo: '相似',
  searchByImage: '按图搜寻',
  options: '选项',
  searchByImageMsg: '搜索123RF，通过图像而不是文本。尝试将图像拖动到搜索框。',
  mustBeJPGorPNG: '图片必须为 JPG / PNG',
  sizeLessThan5MB: '图像大小必须小于5MB',
  sizeLessThan4MB: '图像大小必须小于4MB',
  dragOrDropFile: '拖放文件或',
  browse: '浏览',
  uploadedImage: '上传的图片',
  similarStockPhotos: '相似图',
  // Search footage
  searchFootage: '视频素材和影片',
  StockFootage: '视频素材库',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  audioProperties: '音频属性',
  footageProperties: '影片属性',
  modalDownloadTitle: '文件下载选项',
  credit: '点数',
  saveAs: '另存为',
  ownLicense: '正常下载（自有许可证）',
  downloadAs: '下载为',
  insufficientCredits: '点数不足',
  custom: '自定义',
  personalAndCom: '个人和商业用途 广告、演示文稿、社交媒体、网络。',
  licenseType: '许可证类型',
  requiredForResale: '需要在剧院演出、付费访问、流媒体视频、游戏和应用程序中转售或分发。',
  footageDetail: '视频素材详情',
  stockAudioKeywords: '音档关键字',
  stockFootageKeywords: '库存视频素材关键词',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: '立体声',
  fileSize: '文件大小',
  footageId: '视频素材编号',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  fileName: 'File Name',
  // Search audio
  searchAudio: '音频（音乐和声效）',
  StockAudio: '音档',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: '复制连结',
  preview: '预览',

  bestSellingFootage: '浏览最畅销的版税视频素材',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: '相似的图像',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: '您最近查看的图片将显示在此处',
  rVVectors: '您最近查看的矢量图像将在此处显示',
  rVFootage: '您最近观看的视频将在此处显示',
  rVAudio: '您最近观看的音频将显示在此处',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: '您的搜索用这些过滤器没有返回任何结果。',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: '尝试删除一些过滤器或尝试不同的关键词。',
  emptyReverseSearchTitle: '没有搜寻结果！！',
  emptyReverseSearchSubTitle1: '上传的图片中含有错误，请确保图像符合以下条件',
  emptyReverseSearchSubTitle2: '利用关键字来缩小您的搜寻范围',
  emptyReverseSearchSubTitle3: '图片必须是JPG/PNG',
  emptyReverseSearchSubTitle4: '图片大小必须小于5MB',
  emptySearchResultClearFilter: '清除所有过滤器',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: '输入关键字',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: '搜索收藏',
  personal: '个人',
  groups: '群组',
  createNew: '创建新的',
  createNewCollection: '创建一个新集合',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: '标题',
  selectGroup: '选择组别',
  description: '描述',
  charactersRemaining: '剩余 180 个字符',
  cancel: '取消',
  create: '创建',
  or: '或',
  defaultCollection: '默认收藏',
  addToMyCollections: '加入收藏夹',
  addedToCollection: '已添加到收藏',
  removedFromCollection: '已从收藏中删除',
  collectionModalPlaceholder: '例子：大自然',
  groupNameShowsHere: '组名显示在这里',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: '与我分享',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: '所有者',
  images: '图片',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: '个人',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: '分享',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: '您没有任何收藏',
  btnUnDo: '撤消',
  // my collection
  dateAdded: '添加日期',
  collectionName: '收藏名称',
  selectAll: '全选',
  delete: '删除',
  seeAll: '查看全部',
  deleteCollection: '删除收藏',
  deleteWarning: '完成此操作将永久删除您的收藏及其内容。 请注意，此操作无法撤消。',
  createCollection: ' 已创建',
  shareCollection: '分享收藏',
  shareWithTeam: 'Share in team',
  selectUsers: '选择用户',
  searchUserName: '搜索用户名',
  copy: '复制',
  titleCopyModal: '复制所选项目到',
  collectionUppercase: '收藏',
  groupCollection: '小組',
  moveImage: '移动所选项目到',
  defaultSelect: '收藏名称在这里',
  moveLikebox: 'Move',
  messsageDeleteLikebox: '完成此操作将永久删除您的收藏及其内容。 请注意，此操作无法撤消。',
  areYouSure: '您确定吗？',
  editCollection: '编辑收藏',
  collectionNameUpper: '收藏名称',
  deleteSuccessful: 'has been successfully deleted',
  edit: '编辑',
  messageRemoveImage: '您确定要从收藏中删除所选项目吗？',
  titleRemove: '删除所选项目',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: '保存',
  shareLikebox: '与我分享',
  remove: '删除',
  moveTo: '移动到',
  createNewPlaceholder: '例子：大自然',
  shareViaEmail: '透过电子邮件分享',
  sharePublicly: 'Share publicly',
  receipnetEmail: '收件人电子邮件',
  message: '信息',
  insertReceipmentEmail: '输入收件人电子邮件',
  messageText: '文字信息',
  exportCollection: '导出收藏',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: '添加新收藏',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: '格式',
  lowCredit: '低点数',
  downloadSuccess: '下载马上开始。',
  downloadFailed: '下载错误：发生错误，请重试。',
  downloadedLabel: '下载记录',
  any: '任何',
  resetFilter: '过滤重置',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: '追踪',
  name: '名字',
  location: '地点',
  since: '源自',

  // 我的帐户
  updated: 'Update',
  userName: '用户名称',
  firstName: '姓',
  lastName: '名',
  myProfile: 'My Profile',
  changeProfiledetail: '在这里更改您的个人详细信息',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: '我的购买计划',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: '现在使用，以后付款',
  bodyUNPL:
    '现在使用，以后付款(UNPL)是一种短期融资，允许消费者购买并在未来某一天付款，通常是无息的。也称为“分期贷款”销售点。',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',
  // audio player
  categoryGenre: '分类 / 流派',
  duration: '音长',
  seeMore: '显示更多',
  seeLess: '显示更少',
  audioID: '音频ID',
  // category
  landscape: '景色',
  business: '商業',
  food: '商業',
  animals: '商業',
  technology: '科技',
  healthcare: '醫療',
  nature: '自然',
  romance: '浪漫',
  // category vector
  graphic: '绘图',
  advertising: '广告',
  flower: '花',
  abstract: '抽象',
  Illustration: '插图',
  pop: '流行',
  bird: '鸟',
  pixel: '像素',
  threeD: '3D',

  // footage category
  education: 'Education',
  Babies: '婴儿',
  communication: 'Communication',
  beauty: 'Beauty',
  concept: 'Concept',
  fitness: 'Fitness',
  lifestyle: 'Lifestyle',
  celebration: '庆典',
  family: '家庭',
  fashion: '时尚',

  // Download-history modal
  reDownload: '重新下载',
  chooseCustomTagOrCreate: '选择自定义标记或创建一个新标记',
  downloadDetails: '下载详情',
  image: 'Image',
  downloadType: '下载类型',
  groupName: '组别名称',
  size: '大小',
  dateDownloaded: '下载日期',
  reLicense: '重新许可',
  customFields: '自定义域',
  saveAndClose: '储存并关闭',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: '日期',
  export: '导出',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: '免费资源',
  searchById: '按ID搜索',
  pleaseSelect: '请选择',
  totalOutstanding: '总余额',
  viewInvoices: '查看相关发票',
  // Image detail page - select download size sidebar
  standardSizes: '标准尺寸',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: '剪裁',
  // empty data
  opsNothingToSeeHere: '哦!这里没什么好看的。',
  // search image
  authentic: '真实图片',
  // search audio
  mostDownloaded: '最多下载',
  download: '下载',
  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Corporate',
  electronica: 'Electronica',
  classical: 'Classical',
  funkAndGrooveMusic: 'Funk & Groove music',
  worldBeat: 'World Beat',
  cinematic: 'Cinematic',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: '下一页',
  reverseImageSearch: '回头搜寻图片',

  group: '群组',
  needHelp: '需要帮忙？',
  copied: '成功复制！',
  copyPortfolioLinkSuccess: '贡献者作品集链接复制到剪贴板。',

  // my activities
  myActivity: '我的活动',
  recentLike: '我最近的喜欢',
  recentView: '我最近的查看',
  myActivityViewDesc: '最近的查看历史记录将只存储最多3个月',
  myActivityLikeDesc: '只显示最近3个月的历史',

  // dashboard
  profile: '档案',

  // Pricing
  pricingCreditText: '点数',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: '全部',
  active: 'Active',
  deleted: 'Deleted',
  reset: '重置',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: '人工智能搜索',
  aiPoweredSearchOn: '人工智能搜索开启',
  aiSearchIntroduction: '试试我们更快更强大的搜索引擎。剩下的就交给我们的人工智能搜索吧！',
  ok: '好的',

  // Filter bar ai

  bstract: '抽象',
  macroCloseUp: '宏观/特写',
  object: '对象',
  watercolor: '水彩画',
  flat: '平面',
  cartoon: '卡通',
  geometric: '几何图形',
  gradient: '梯度',
  isometric: '等距',
  '3d': '3D',
  handDrawn: '手绘的',
  natural: '自然的',
  warm: '暖色调',
  dramatic: '戏剧性',
  vividAndBold: '大胆生动',
  blackAndWhite: '黑白色',
  // introduce Free image research
  imageResearchContentTitle: '介绍 123RF 的免费图像研究服务： 完美图像，零烦恼',
  imageResearchContentDesc:
    '我们彻底改变了企业客户的图像调研方式，让您轻松找到完美图像，将您的愿景变为现实。',
  submitRequest: '提交请求',
  imageResearchContentInfoTitle1: '图像研究变得简单',
  imageResearchContentInfoDesc1:
    '轻松开始。只需向我们提供您所需图片的详细描述，包括风格、基调、色彩和特定元素。您提供的细节越多，我们就越能满足您的需求。',
  imageResearchContentInfoTitle2: '它是如何工作的？',
  imageResearchContentInfoTitle3: '快速、可靠的服务',
  imageResearchContentInfoTitle4: '让我们为您工作',
  imageResearchContentInfoDesc4:
    '我们为您寻找完美的图片，让您专注于您的项目。请相信我们，我们会用精心挑选的高质量图片将您的愿景变为现实。',
  requestForm: '申请表',
  whatDoYouWantThisImageFor: '您想将此图片用于何处？',
  websiteBanner: '网站横幅',
  socialMedia: '社交媒体',
  blogPost: '博客文章',
  advertisement: '广告',
  print: '印刷品',
  others: '其他',
  othersOutputPlaceholder: '输入其他输出类型',
  whatIsPrimaryMessage: '您希望图片传达的主要信息或主题是什么？',
  whatIsPrimaryMessagePlaceholder: '例如：毛茸茸的狗和女人在公园里奔跑',
  areThereSubjectYouWantInThePicture: '您希望图像中包含哪些特定主题或元素？',
  egPeopleNatureTechnologyAbstract: '例如：人物、自然、技术、抽象概念',
  areThereImageOrStyleReference: '是否有您以前见过的图片或风格，希望我们将其作为灵感或参考？',
  ifPeopleIncludeWhatTheirDemographic: '如果要包含人物，他们的人口统计学特征是什么？',
  egAgeGenderEthnicityOccupation: '例如：年龄、性别、种族、职业',
  areThereAnyThingToAvoid: '图片中是否有您想要避免的元素、主题？',
  whoisYourTargetAudience: '谁是您的目标受众？',
  whoisYourTargetAudiencePlaceholder: '例如：热爱阅读的年轻人',
  typeOfImage: '图片类型',
  whatMoodShouldImageEvoke: '图片应唤起什么样的情绪或情感？',
  happy: '快乐',
  sad: '悲伤',
  calm: '平静',
  excited: '兴奋',
  angry: '愤怒',
  enterCustomMood: '输入自定义情绪',
  areThereAnyOrientationForImage: '图片是否需要特定的方向？',
  enterCustomOrientation: '输入自定义方向',
  isThereLocationYouWantImageToBeIn: '是否有特定的位置或设置您想要的图像？',
  no: '没有',
  yes: '有',
  shareWithUsAReference: '与我们分享参考资料（仅限JPG或PDF格式，最大4MB）',
  replace: '替换',
  areThereAnySpecificRequirements: '是否有其他特殊要求或偏好？',
  enterOtherComments: '输入其他意见',
  submit: '提交',
  fieldCannotEmpty: '此栏不能为空',
  selectAtLeastOne: '请至少选择一项',
  fileMustBeJpgOrPdf: '文件必须是JPG或PDF格式',
  fileSizeLessThan4MB: '文件大小必须小于4MB',
  maximumFileNameAllow255: '允许的最大字符数为255',
  updateSuccessfully: '我们已收到您的请求。',
  freeImageResearch: '免费图像研究',
  newService: '新服务！',
  freeImageResearchIntroduction: '试用我们的图像研究服务，了解为什么我们的图像完全符合您的要求。',
  later: '稍后',
  letTryIt: '来试试吧',
  viewPdfOfSampleOutput: '查看范例输出的PDF文件',
  imageResearchContentInfoDesc2: (
    <>
      我们的团队结合人
      <strong>工智能技术</strong>
      和经验为您寻找理想的图片，先进的算法根据您的输入从我们庞大的图片库中缩小选择范围。我们的团队由
      <strong>经验丰富的专业研究人员</strong>
      组成，他们会根据您的标准完善搜索结果。
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      我们在
      <strong>2个工作日</strong>
      内提供结果，并对图片选择提供全面的解释。您只需专注于您的业务，其余的交给我们。
    </>
  ),
  characterMaxChar255: '允许的最大字符数为255',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: '需要标签',

  relatedSearch: 'Related searches',
  showMore: '请显示更多',
  showLess: '显示更少',

  requiredWithVariable: '需要{{var}}',
  person: '人',
  doNotIncludeWords_ex2: '例如，绿色，直发',
  mood: '情绪',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: '图像生成器',
  imageGenerator: '图像生成器',
  aiImageGeneratorDesc: '以你的想象力的速度提供创造力。',
  generate: '生成',
  exploreVisualsCreatedWithThePowerOfAI: '探索AI人工智能创造的视觉效果',
  turnYourImaginationIntoRealityIn3SimpleSteps: '通过3个简单的步骤将你的想象力变成现实',
  startWithGoodTextPrompt: (
    <>
      从
      {' '}
      <span>
        一个好的
        <br />
        提示开始
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    '一个好的提示可以保证很好的结果。一个提示应该包括视角、主题、动作、关键词、灯光和风格，以获得最佳效果。',
  reviewYourAIGeneratedImages: (
    <>
      审查你的
      {' '}
      <br />
      <span>AI生成的图像</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc: '我们的AI发电机将生成并显示3个与您的提示最匹配的预览图像。',
  downloadAndLicenseVisuals: (
    <>
      下载和
      {' '}
      <br />
      <span>许可视觉效果</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    '您现在可以下载和许可这些图像，以供个人和商业用途，并具有 25,000 美元的法律保障。',
  needSomeIdeas: '需要一些想法吗？从这些样本开始，然后再生您的想法！',
  tryPrompt: '尝试提示',
  creativityAtTheSpeedOfYourImagination: '以你的想象力的速度提供创造力。',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    '用Corporate+在几秒钟内从文本提示中生成你自己的AI图像!',
  tryNow: '立即尝试',

  /// Random prompt
  prompt1: '描绘一位自信而平易近人的28岁韩国男歌手，他拥有迷人的舞台表现力。通过富有表现力的肖像画，展现他充满活力的声音和磁性的魅力，突出他醒目的五官。细致地刻画他浓密的黑发和深邃动人的双眼，传达他艺术热情之深。为他穿上时尚的现代服装，反映他的音乐个性，也许带有一丝前卫或精致。将他置于暗示现场表演或录音棚的环境中，捕捉他技艺的能量和激情。运用戏剧性的灯光和构图，营造出一种电影般、超越现实的品质，强调他作为冉冉升起的新星的地位。为作品注入动感和活力，仿佛他正在进行一场引人入胜的演唱。目标是创作一幅立即抓住观众眼球的图像，展现歌手不可否认的舞台表现力和演唱才能。',
  prompt2: '一张逼真的照片，展现一只雄狮慵懒地躺在岩石上，广阔的非洲大草原作为背景。使用焦距为400毫米、光圈为F2.8的镜头，使背景模糊，并清晰地聚焦在狮子身上。光线应自然温暖，捕捉日落的金色色调。强调逼真度，以创造生动、细致的图像，传达野生动物的美丽和宏伟。',
  prompt3: '一件混合媒介作品，描绘一位身处未来主义朋克城市景观中的未来武士，他摆出战斗姿势，挥舞着发光的武士刀，背景是霓虹灯和全息图。结合各种纹理和材料，包括金属和数字元素，以创造一个视觉丰富而动态的场景。',
  prompt4: '一幅充满欢快气氛的数字插画，描绘一位穿着飘逸长裙的年轻公主，在一个魔法森林里与魔法生物嬉戏。用五颜六色的花朵、蝴蝶和闪闪发光的仙尘环绕着她，背景是充满活力和奇幻的景象。',
  prompt5: '一幅细致的石墨画，描绘一位面容沧桑、表情坚定的古代武士，手持宝剑。运用复杂的阴影和纹理来突出武士的盔甲和战斗伤疤，创造出一幅永恒而有力的图像。',
  prompt6: '一幅超现实主义的混合媒介拼贴画，描绘一位时间旅行探险家穿梭于不同的历史时期，其中包含复古地图、古代文物和未来主义小工具等元素。结合旧报纸剪报、照片和数字艺术，创作出一件幽默且发人深省的作品。',
  prompt7: '一幅细节丰富的数字插画，描绘一座反乌托邦城市景观，其中有高耸的摩天大楼、飞行汽车和阴暗的乌云。重点刻画深度和规模感，建筑细节精细，运用戏剧性的灯光来增强氛围。',
  prompt8: '一幅睿智长者的肖像，他手持一根魔法权杖，运用戏剧性的明暗对比光线来突出长者的面部特征和权杖上发光的符文。运用丰富的纹理和阴影来营造神秘和智慧的感觉。',
  prompt9: '一幅充满活力的奇幻插画，描绘一位身穿盔甲的龙骑士在空中翱翔，巨龙双翼展开。周围环绕着云朵和远处的山脉，营造出冒险和庄严的感觉。',
  prompt10: '一张亚洲城市繁华市场场景的黑白纪实街拍照片，摊贩们出售色彩鲜艳的水果和蔬菜。运用浅景深来突出摊贩的表情和互动，捕捉热闹的氛围。',
  prompt11: '一幅奇趣的水彩画，描绘了一匹鬃毛彩虹般的独角兽，它站在一个神奇的森林里，周围环绕着发光的蘑菇和闪闪发光的萤火虫。运用柔和的粉彩和流畅的笔触，营造出梦幻般、令人着迷的场景。',
  prompt12: '一幅现代厨房的3D渲染图，设计简洁明快，采用不锈钢电器，中央设有一个大型岛台。运用逼真的纹理和灯光来突出简洁的线条和现代风格，重点展现反射和材质。',
  prompt13: '一幅奇幻风景画，描绘了空中漂浮的岛屿，瀑布从边缘倾泻而下，岛屿表面覆盖着茂密的植被。岛屿周围环绕着蓬松的云朵和湛蓝的天空，营造出一种奇妙和魔法般的感觉。',
  prompt14: '一幅维京战士的详细肖像，他留着辫子，表情凶猛，手里拿着战斧。背景应描绘暴风雨的海面和崎岖的悬崖，运用戏剧性的灯光来突出战士的特征和盔甲。',
  prompt15: '一幅超现实主义的混合媒体拼贴画，描绘了一位蒸汽朋克发明家在一个杂乱的工作室里，工作室里充满了齿轮、钟表装置和蒸汽动力装置。运用复古照片、旧图纸和金属质感，创造出一个视觉丰富、富有想象力的场景。',
  prompt16: '夜幕下未来都市的数字插画，高耸的摩天大楼、霓虹灯牌和飞行车辆。运用鲜艳的色彩和动态的光影效果，营造出活泼而身临其境的氛围，突出城市的科技进步和繁忙的能量。',
  prompt17: '一张舒适阅读角的逼真3D渲染图，其中包含舒适的扶手椅、带台灯的小桌子和摆满书籍的书架。场景应采用温暖、温馨的灯光和柔软的纹理，捕捉空间宁静祥和的氛围。',
  prompt18: '描绘一个透明的、玻璃状的时尚手提包，其设计中内置了一个功能齐全的水族箱。细致地描绘手提包内精致的游动鱼类，创造出令人着迷且别具一格的配饰。确保水族箱与手提包的结构完美融合，两者之间过渡自然。将手提包醒目地放置在高端百货商店的环境中，周围环绕着时尚现代的货架、灯光和其他奢侈品零售展示。运用戏剧性的定向照明，营造出戏剧感，吸引观众的注意力，将他们的目光直接吸引到水族箱手提包上。从一个角度捕捉手提包，展示其透明、几乎空灵的质感，让鱼清晰可见，营造出失重或流动感。',
  prompt19: '捕捉一个舒适温馨的场景，其中包含采用棕色和橄榄绿色调的柔软扶手椅。在一个温暖私密的氛围中展示这些椅子，周围环绕着唤起放松和舒适感的细节，例如柔软的纺织品、丰富的木质装饰，以及可能可以看到壁炉或俯瞰宁静自然景观的窗户。强调装饰的奢华质感和整体的舒适感和隐居感。',
  prompt20: '捕捉一只凤头鹧鸪生动逼真的画面，它栖息在苔藓覆盖的树枝上，背景是郁郁葱葱的翠绿色，与鸟类令人惊叹的羽毛相得益彰。以精美的细节渲染鹧鸪复杂的羽毛，运用微妙的光影渐变来突出其纹理。突出鹧鸪丰富的深绿色羽冠、温暖的土褐色喙和明亮好奇的黑眼睛，创造出令人着迷的鸟类自然美肖像。为整个场景营造温暖宁静的氛围，邀请观众步入宁静的自然环境。',
  prompt21: '设计一幅视觉上诱人的图片，展现一碗奶油状的冷冻酸奶，上面装饰着各种鲜艳的水果和松脆的坚果。捕捉这款健康甜点的清爽和营养品质，展现光滑浅色酸奶与鲜艳水果配料之间形成的色彩对比。以巧妙诱人的方式摆放配料，让观看者欣赏其视觉吸引力并期待清爽美味的体验。确保整体图像散发健康和美味感，邀请观看者细细品味这款美味佳肴的美味和营养品质。',
  prompt22: '沉浸在视觉盛宴中，探索一个充满鲜艳色彩和复杂细节的超现实花卉花园。捕捉花朵迷人的本质，包括卷曲的螺旋形花瓣、色彩斑驳的叶子和其他令人着迷的色调——每一朵花都是一件艺术品。运用水彩的流畅性和墨水的表现力，采用湿画湿的技法来营造动感和深度。通过丰富饱和的色彩和对每一瓣花瓣和叶子的细腻细致的描绘，传达出这个梦幻般花卉仙境的超现实、梦幻般的品质。',
  prompt23: '制作一幅引人入胜的夜景插图，描绘一座古老的灯塔矗立在岩石岛上。灯塔装饰着多个窗户，发出温暖宜人的光芒，突显其复杂的建筑和悠久的历史。岩石岛上覆盖着茂密的翠绿树木，它们的倒影在平静的月光照耀的海面上舞蹈，映照着满月的银色光芒。天体球体照亮天空，在云层和海面上投下朦胧的光辉，而缕缕薄云部分遮挡了它的光芒，增加了场景的神秘感。闪烁的星星点缀着墨色的天空，创造出一幅与月亮光芒和谐的天体织锦。这幅迷人的作品捕捉了一个宁静祥和的夜晚，灯塔在星光下坚定地矗立，如同哨兵一般，为观看者创造出一种电影般梦幻般的体验。',
  /// Tool list
  // ratio
  ratio: '比率',
  square1_1: '正方形 (1:1)',
  square: '正方形',
  portrait2_3: '肖像 (2:3)',
  portrait: '肖像',
  landscape3_2: '景观 (3:2)',
  wide16_9: '宽 (16:9)',
  wide: '宽',
  // style
  none: '没有任何',
  enhance: '提高',
  anime: '日本动画片',
  photographic: '摄影',
  digitalArt: '数字艺术',
  comicBook: '漫画书',
  fantasyArt: '幻想艺术',
  analogFilm: '模拟',
  neonPunk: '霓虹灯朋克',
  lowPoly: '低聚',
  origami: '折纸',
  lineArt: '线条艺术',
  tileTexture: '瓷砖纹理',
  threeDModel: '3D模型',
  pixelArt: '像素艺术',
  craftClay: '手工粘土',
  // color
  coolTone: '酷音',
  mutedColors: '柔和的颜色',
  pastelColors: '柔和的颜色',
  vibrantColors: '鲜艳的颜色',
  warmTone: '温暖的语气',
  // lighting
  lighting: '灯光',
  backlight: '背光',
  crepuscularRays: '侧面射线',
  dimlyLit: '昏暗',
  goldenHour: '黄金时光',
  lowLight: '弱光',
  rimLighting: '轮辋照明',
  studio: '工作室',
  sunlight: '阳光',
  volumetric: '体积',
  // composition
  composition: '作品',
  blurryBackground: '模糊背景',
  closeUp: '特写',
  macrophotograph: '巨型摄影',
  narrowDepth: '深度狭窄',
  shotFromAbove: '从上方拍摄',
  shotFromBelow: '从下方拍摄',
  wideAngle: '广角',

  /// FQAs
  allAIImageQuestionsAnswered: '所有AI人工智能生成图像的问题都得到了解答。',
  TTI_Q1: '什么是AI生成的图像？',
  TTI_A1:
    '人工智能生成的图像是使用人工智能（AI）算法创建的图像。这些算法旨在模拟人类的创造力，可以生成类似于人类艺术家制作的图像，但具有独特的、通常是超现实的或抽象的元素。',
  TTI_Q2: '我每天能生成多少张图片有限制吗？',
  TTI_A2:
    '是的，您每天能够生成的图像数量是有限制的。这个限制确保所有用户在使用人工智能图像生成服务时都能公平地使用，并获得良好的用户体验。',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: '生成图片和授权图片之间有什么区别？',
  TTI_A4:
    '生成图像时，您将创建一组可供查看的 AI 图像。在 Corporate+ 上获得许可后，您就正式获得了下载高分辨率 AI 图像的权限，该图像自带使用许可。',
  TTI_Q5: '我生成的图像包含一个可识别的商标、地标或公众人物。我还可以使用它吗？',
  TTI_A5:
    '如果图片中包含可识别的人或物，或主要集中在一个独特的地标上，您必须直接获得图片中描述的当事人的许可，尤其是当您打算在商业环境中使用图片时。',
  TTI_Q6: '在生成的图像中出现的人是谁？',
  TTI_A6: '出现在人工智能生成的图像中的人并不是真实的人，而是数字创造的。',
  TTI_Q7: '我生成的内容会怎样？它们是否会被提供给其他用户？',
  TTI_A7: '你产生的内容也可能被提供给其他用户使用。你可以参考我们的许可证以了解更多信息。',
  TTI_Q8: '为什么当我尝试生成图像时，需要花费一段时间来加载？',
  TTI_A8:
    '该页面将需要几秒钟来生成图像。所需时间取决于提示的复杂性、可用的硬件和计算资源，以及人工智能必须同时处理的请求量。',
  TTI_Q9: '我如何许可我生成的AI图像？',
  TTI_A9:
    '您可以从您的 Corporate+ 账户下载您创建的 AI 图像，从而获得许可。每次下载，您都将获得生成图像的高分辨率版本和 25,000 美元的法律保证金。',
  TTI_Q10: '我可以查看过去的几代人吗？',
  TTI_A10:
    '是的，你可以通过将过去几代人的网址加入书签并重新浏览。你也可以与你的朋友分享各代的结果。',
  TTI_Q11: '如何用人工智能生成高质量的图像？',
  TTI_A11: `<ol>
              <li>开始时要有一个明确的目标："为旅游博客提供高质量的山地景观图片"。</li>
              <li>指定所需的风格和情绪："图像应该是明亮的，充满活力的，具有自然、有机的感觉。"。</li>
              <li>描述主题事项：具体说明图像的主题，包括细节，如环境、灯光和任何其他重要特征或元素。例如，"以一座白雪皑皑的山峰为特色，前景是蜿蜒的河流，背景是清澈的蓝天"。</li>
              <li>提供例子：包括其他与你正在寻找的图片具有类似风格或情绪的例子。这可以帮助指导人工智能算法，确保输出结果符合你的期望。</li>
              <li>使用相关的关键词："山地景观"、"白雪皑皑的山峰"或 "明亮而富有活力"。</li>
              <li>避免模棱两可：在提供指示时要尽可能清楚和具体，避免使用模糊或模棱两可的语言，以免导致意外的结果。</li>
            </ol>`,

  /// Generating page
  bringingYourImagesToLife: '让您的图像栩栩如生…',
  cplusAiGenerator: 'AI生成器',
  generatedImages: '生成的图像',
  promptSettings: '提示设置',
  pastGeneration: '过去几代',
  generateVariation: '产生变化',
  iLikeThis: '我喜欢这个',
  iDislikeThis: '我不喜欢这个',
  shareOptions: '分享选项',
  variations: '变化',
  copiedSharedUrl: '复制的共享URL到剪贴板',

  galleryPrompt1: '水下的飘逸的美人鱼，头戴贝壳冠，四周环绕着鱼群，肖像',
  galleryPrompt2: '桌上的丰盛周日烤肉，满是食物与蜡烛',
  galleryPrompt3: '在森林中微笑的可爱蓬松狐狸，细节丰富，与其他森林动物一起',
  galleryPrompt4: '高品质，8K超高清，水晶制南瓜内的音符乐器形状，高度细节，魔法南瓜灯，秋天感觉，平静',
  galleryPrompt5: '苏格兰全景电影风景，明亮美好的一天',
  galleryPrompt6: '3D风格小女孩，4k、8k，Octane Render照片写实，HDR，摄影，高清晰度，对称脸孔，体积光，灰尘霾，照片，Octane Render，24mm，4k，DSLR，高品质，60 fps，超真实',
  galleryPrompt7: '深色肤色男子，英雄在太空中打击木星，面向前方',
  galleryPrompt8: '温暖咖啡中的曲奇，在圣诞环境中',
  galleryPrompt9: '艺术水瓶放在白色简约背景上，配以 Montserra 叶子',
  galleryPrompt10: '绚烂钻石幻龙',
  galleryPrompt11: '露珠中的行星，行星露珠，在日出时，特写，超高细节，锐利焦点，工作室照片，复杂细节，高度细致',
  galleryPrompt12: '一只喜鹊坐在一个装满黄金的箱子上，风格是写实幻想艺术，宝石，黑暗童话，神秘。',
  galleryPrompt13: '一座雪山和山顶上的一条冰道朝着相反方向走，超高清，真实感，色彩鲜艳，高度细致，UHD绘画，笔和墨，完美构图，美丽的详细复杂度疯狂的详细八景渲染，流行于artstation，8k艺术摄影，超现实概念艺术，柔和自然的体积感电影般完美光线',
  galleryPrompt14: '星际文明时代，高高站立俯瞰神秘数位空间：超科幻外星城市建筑物，精美美丽的超级摩天大楼外观，街道，天空桥梁，天空中漂浮的超科幻建筑，战争机器，高科技，无数电子萤幕灯光',
  galleryPrompt15: '佛罗里达群岛夜晚背景为海洋的美丽海滩，霓虹色彩，黑色背景，平静波浪，自然色调，日落，平静，放松的波浪变焦，海滩，直线地平线，粉红色，蓝色，橙色，紫色',
  galleryPrompt16: '最佳数量，双重曝光，邮件艺术，破纸艺术，一只可爱的小猫玩线球的特写细节舒适的室内，闪闪发光，阳光，阳光束，动态姿势，故事书插图，2D，平面，可爱，迷人，复古，童话，拼布，彩色玻璃，故事书详细插图，电影，超高解析度，微小细节，美丽细节，神秘，鲜艳色彩，复杂背景',
  galleryPrompt17: '在水上的金字塔，带有火反射，山脉，黑暗超现实主义，大气肖像，敏锐的焦点',
  galleryPrompt18: '森林里的红熊猫，电影',
  galleryPrompt19: '海，月亮，鱼，星空，船，五颜六色的插图',
  galleryPrompt20:
    '充满活力的城市反射舞蹈崇拜者大胆的建筑物和微妙的抽像风格的闪闪发光的水，捕捉活泼的夜晚能量，几何抽象，现代波普艺术风格，带有霓虹灯照明和丙烯酸纯化技术',
  galleryPrompt21: '带有发光剑的轮廓女人的背景，北极的圆形门户，通往冰山，电影中型镜头，巨大的身材',

  /// error page
  copyrightDetected: '检测到有版权的术语/人物或商标',
  copyrightDetectedDesc:
    '我们致力于成为一个负责任的AI生成器，并且不会为提示中的受版权保护的商标、术语、品牌或人物生成结果，以防止潜在的侵权行为。我们的服务条款规定您不得以侵犯、擅自使用或违反任何知识产权的方式使用本服务。请修改您的提示并再试一次。',
  errorMesTTI: '糟糕! 我们很抱歉，但出了点问题。',
  errorMesTTIDesc: '该问题已被记录下来供调查。请稍后再试。',
  tryAgain: '再试一次',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',

  // download feature
  downloadQuotaFinished: '下载额度已使用完毕',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: '找到惊人的图片。按关键字或短语搜索。',
  searchBarPlaceholderAllImageTablet: '找到惊人的图片。',
  searchBarPlaceholderAllImageMobile: '找到图片。',
  searchBarPlaceholderPhotoDesktop: '找到惊人的摄影图像。按关键字或短语搜索。',
  searchBarPlaceholderPhotoTablet: '找到惊人的摄影图像。',
  searchBarPlaceholderPhotoMobile: '找到摄影图像。',
  searchBarPlaceholderVectorDesktop: '找到惊人的矢量图。按关键字或短语搜索。',
  searchBarPlaceholderVectorTablet: '找到惊人的矢量图。',
  searchBarPlaceholderVectorMobile: '找到矢量图。',
  searchBarPlaceholderFootageDesktop: '找到惊人的视频。按关键字或短语搜索。',
  searchBarPlaceholderFootageTablet: '找到惊人的视频。',
  searchBarPlaceholderFootageMobile: '找到视频。',
  searchBarPlaceholderAudioDesktop:
    '找到惊人的音频和音乐文件。按关键字或短语搜索。',
  searchBarPlaceholderAudioTablet: '找到惊人的音频和音乐文件。',
  searchBarPlaceholderAudioMobile: '找到音频和音乐文件。',
  searchBarPlaceholderFreeDesktop: '找到惊人的免费图像。按关键字或短语搜索。',
  searchBarPlaceholderFreeTablet: '找到惊人的免费图像。',
  searchBarPlaceholderFreeMobile: '找到免费图像。',
  // editorial warning
  forEditorialUseOnly: '只供新闻类用途',
  forEditorialUseOnlyDesc:
    '除获得特别许可，否则不可将该图片用于广告或促销等商业用途。',

  // download comp
  downloadHiResComp: '下载高清小样图',
  downloadComp: '下载小样图',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),
  textToImage: '文本到图像',
  descTourTTI: '让你的想象力飞扬，用文字创造图像!',
  colorAndTone: '颜色和色调',
  titleSuccessDownload: '我们很高兴你找到了你需要的东西。',
  hangInThere: '挺住！挺住 它不会花很长时间。',
  adultContentTitle: '检测到的潜在的成人内容术语',
  adultContentDesc:
    '我们致力于负责任的 AI，因此不会为特定词语、概念和/或想法生成结果。请修改您的提示（用于生成图像的描述）并重试。',
  reachedTheLimit: '哦，不！您已达到了生成AI图像的每日限额。',
  backTomorrow: '明天再回来生成更多图像吧！',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: '开始吧，请在这里描述您的愿景，我们将为您实现！',

  downloadAgain: 'Download again',
  btnClose: '关闭',

  fluxPro: 'Flux 提供高级、高质量和多样化的图像生成。',
  flux: '我们最快的模型，专为本地开发而设计。',
  ideogram: '在几秒钟内将文本描述转化为美丽的图像。',
  realStockPhoto: '通过我们的高级 AI 模型轻松创建引人注目的库存照片。',
  realCinematic: '一个专注于制作宽屏比例电影输出的模型。',
  realFood: '使用食物可视化模型提升您的餐饮品牌。',
  realMacro: '一个很棒的模型，用于生成详细的微距图像。',
  realBokeh: '一个逼真的风格模型，非常适合创建惊艳的散景图像。',
  aiLicenseHeader: '该文件由人工智能 (AI) 生成。',
  aiLicenseContent: '人工智能生成的内容不受123RF标准合规审查流程的约束。 创作者有责任确保此类AI生成的材料不会侵犯或违反第三方的知识产权或其他权利。 严禁生成垃圾邮件以及任何虚假、误导性、有害、暴力、色情或非法材料的内容。',
  variousTitle: '发现我们的各种模型',
  variousSDXL: '将令人惊叹的逼真的人和场景带入生活。',
  variousEimisAnime: '创作动漫风格的生成式AI模型图像。',
  variousDynaVisionXL: '生成3D风格化的卡通人物。',
  variousJuggernautXL: '以建筑元素和风景/背景为中心。',
  variousDreamShaperXL: '高分辨率升级，改进的角色和龙，减少了边缘模糊。',
  variousRealismEngineSDXL: '这个模型专门于高度详细、逼真的图像。',
  variousDALLE: 'DALL·E 3，一个全圆形模型以精确的概念与视觉转换为出色。',
  aiEngine: 'AI引擎',
  'DALL-E': 'DALL-E 3',

  langText: '文本',
  langElements: '元素',
  langAuto: '自动',
  langBgRemoval: '消除背景',
  resetAll: '重置所有',
  facebookImage: '脸书图片',
  facebookLink: 'Facebook链接',
  facebookCover: 'Facebook封面',
  facebookStory: '脸书故事',
  instagramPost: 'Instagram帖子',
  instagramStory: 'Instagram故事',
  twitterPost: '微博',
  twitterHeader: '推特头',
  pinterestPost: 'Pinterest帖子',
  emailHeader: '电子邮件标题',
  presentation: '介绍',
  presentationWide: '呈现范围',
  ebookCover: '电子书封面',
  linkedinPost: 'LinkedIn职位',
  brochureCover: '小册子封面(A4)',
  postcard: '明信片',
  photoPrint: '照片打印',
  youtubeProfileImage: 'YouTube简介图片',
  youtubeChannelCoverPhoto: 'YouTube频道封面照片',
  langApplyBgRemoval: '去除背景',
  autoBnW: '黑白平衡',
  autoAdjust: '自动优化',
  autoPop: '自动鲜明',
  original: '原始图片',
  addHeader: '添加标题',
  addTitle: '添加副标题',
  addBody: '添加正文',
  addAssistive: '添加辅助文本',
  langCompare: '对比',
  langEnableEditor: '编辑图片',
  langSimilarStock: '类似图片库',
  langTextUndo: '撤消',
  langTextRedo: '重做',
  langTextFont: '字体',
  langTextFontSize: '字体大小',
  langTextColor: '文字颜色',
  langTextHighlight: '强调',
  langTextAlignment: '对齐',
  langTextBold: '粗体',
  langTextItalic: '斜体',
  langTextUnderline: '下划线',
  langTextLineSpacing: '间距',
  langTextLetterSpacing: '字母间距',
  langBringForward: '前移',
  langBringBackward: '后移',
  langDuplicate: '复制',
  langOpacity: '透明度',
  langDelete: '删除',
  imageProperties: '图像属性',

  noEditCompareMsg: '没有编辑可比较',
  stockPhoto: '免版税图像',
  basicShape: '基本形状',
  chitChat: '闲聊',
  shapes: '形状',
  elementChitChat: '元素聊天',
  organicAbstractShapes: '有机抽象形状',
  elementShapes: '元素形状',
  langApplyingEdit: '应用编辑 。。。',
  langTextAllFonts: '所有字体',
  langSearchFontType: '搜索字体类型',
  editorCompareImageBeforeTitle: '之前',
  editorCompareImageAfterTitle: '之后',
  langSizePx: '像素是数字图像的基本单位。以像素为单位的图像大小，决定了细节水平和下载大小。',
  langSizeDpi:
    '每英寸点数衡量打印分辨率。更高的dpi意味着更多的细节。对于打印而言，所有图像的推荐分辨率为300dpi。',
  cautionTTI:
    '注意：我们检测到提示中使用了与商标、版权、著名地标和/或个人相关的短语。 请注意，您必须对内容的使用承担全部责任，包括确保其不违反任何适用法律或侵犯任何权利。',

  addImageReference: '添加图片参考（可选）',
  uploadImageOnly: '仅上传JPG或PNG。最大4MB。',
  uploadJPGImageOnly: '仅上传JPG。最大4MB。',
  dragAndDrop: '拖放文件或',
  errorFileSize: '此文件太大。最多允许4MB。',
  adjustScale: '调整此比例尺以生成与您上传的图片非常相似或不同的结果。建议范围是7至15。',
  similar: '相似',
  different: '不同',
  uploadNew: '重新上传',

  imageGuideGeneration: '您的图片将在生成过程中指导您的提示。',
  notAvailableForEngine: '不适用于选定的 AI 引擎。',

  imagePromptDescribe: '首先，描述您的想法提示我们的 AI 产生的图像。',
  fileTypeNotSp: '不支持的文件类型。',
  theFileSizeTooSmall: '文件大小太小。最少需要512px。',

  relatedSearchPhoto: '相关图片搜索',

  STOCK_PHOTO_PATH: '免版税图像',
  STOCK_FOOTAGE_PATH: '影片素材',
  STOCK_AUDIO_PATH: '音效素材',
  CLIPART_VECTOR_PATH: '矢量图',
  ALL_IMAGES: '全部图像',
  PHOTO: 'Photo',
  PHOTOS: '摄影图像',
  VECTORS: '矢量图',
  FOOTAGE: '视频',
  AUDIO: '音频',
  LANG_MORETHANMILLION: '张免版税创意图像',
  LANG_SVG_OR_EPS: 'SVG 或 EPS',
  LANG_OR: '或',
  STANDARD_LICENSE: '标准许可证',
  EXTENDED_LICENSE: '扩展许可证',
  CREDIT_PACK: '点数配套',
  DOWNLOAD_PACK: '下载配套',
  SUBSCRIPTION_PLAN: '方案',
  LANG_DOWNLOAD: '下载',
  STOCKPHOTO: '免版税图像',
  STOCK_PHOTO: '图片',
  STOCK_VECTOR: '矢量',
  PREMIUM_STOCKPHOTO: 'PREMIUM 库存照片',
  PLUS_STOCK_PHOTO: 'PLUS 库存照片',
  PLUS_STOCK_VECTOR: 'PLUS 库存矢量图像',
  MOBILE_STOCKPHOTO: '手机图片',
  STOCKFOOTAGE: '影像素材',
  IMAGEID: '图像ｉｄ',
  FONTID: 'ID',
  MEDIATYPE: '作品类型',
  COPYRIGHT_TEXT: '版权',
  KEYWORDS: '关键字',
  LANG_CANCEL: '取消',
  LANG_PURCHASE: '购买',
  LANG_STOCKVECTORSANDILLUS: '股票向量和插图',
  LANG_STOCKPHOTOSANDIMAGES: '图像',
  LANG_MATCHES: '相配',
  LANG_VECTORRESULTS: '为您找到了 {number} {keyword} 免版税矢量图像',
  LANG_PHOTORESULTS: '为您找到了 {number} {keyword} 免版税库存照片和图像',
  LANG_SIMILARIMGS: '类似的免版税照片',
  LANG_COLLECTIONS: '图像类型',
  LANG_ALL: '全部',
  LANG_STD: '标准',
  LANG_EXTD: '扩展',
  LANG_COMMERCIAL: '商业用途',
  LANG_SUBSCRIPTION: '签购配套',
  LANG_EDITORIAL: '新闻类图片',
  LANG_UPLOADED: 'Uploaded',
  LANG_FRESHNESS: '更新时间',
  LANG_ANYTIME: '时间不限',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '过去两天',
  LANG_PASTWEEK: '过去1周内',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/月",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/年",
  LANG_PASTMONTH: '过去1个月内',
  LANG_PAST3MONTHS: '过去3个月内',
  LANG_NUMOFPPL: '人数',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: '没有收到验证电子邮件？',
  LANG_WITHOUTPEOPLE: '无人物',
  LANG_PERSON: '人',
  LANG_PEOPLE: '人',
  LANG_INFANTS: '婴儿',
  LANG_TODDLERS: 'Toddlers',
  LANG_TEENS: '青少年',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: '白种人',
  LANG_BLACK: '黑种人',
  LANG_HISPANIC: '西班牙语拉美人',
  LANG_EAST_ASIAN: '东亚人',
  LANG_SOUTHEAST_ASIAN: '东南亚人',
  LANG_INDIAN: 'Indian',
  LANG_MIDDLE_EASTERN: '中东人',
  LANG_AGE: '年龄',
  LANG_ALL_SHAPE: '查看全部',
  LANG_ETHNICITY: '种族',
  LANG_PEOPLEORMORE: '4人及以上',
  LANG_COLOR: '顏色',
  LANG_OTHEROPTION: '其他选项',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: '单色或透明背景',
  LANG_FILTER_BY: '筛选条件',
  LANG_FILTER: '过滤',
  LANG_PHOTOGRAPHY: '摄影图像',
  LANG_VECTORILLUST: '矢量图片',
  LANG_SORTBY: '排列',
  LANG_MORE: '更多',
  LANG_RELEVANCE: '贴切',
  LANG_RESEND: '重发',
  LANG_RESEND_VERIFICATION: '重新发送验证电子邮件',
  LANG_NEWEST: '最新',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: '图像形状',
  LANG_HORIZONTAL: '纵向',
  LANG_PORTRAIT: '直立',
  LANG_SQUARE: '正方形',
  LANG_PANORAMA: '周视图',
  LANG_SELECTIVE_FOCUS: '选择性的焦点',
  LANG_PATTERN: '样式',
  LANG_VIBRANCE: '鲜艳',
  LANG_VERIFICATION_DESCRIPTION: '在从 123RF 下载内容之前，您需要验证您的帐户。请检查您的邮件信箱，或点击下方的链接重新发送验证电子邮件。',
  LANG_VERIFICATION_TITLE: '请验证您的电子邮件。',
  VERIFICATIONRESETUNABLE: '哎呀! 您的电子邮件无法发送。请联系我们的',
  LANG_CHOOSEUPTO3COLORS: '您最多可选择三种颜色',
  LANG_ISOLATED: '无背景',
  LANG_STYLE: '类型',
  LANG_SAFESEARCH: '安全搜索',
  LANG_ONLY_AUTHENTIC: '仅限真实图片',
  LANG_EXCLUDE_PLACEHOLDER: 'ex. cheese',
  LANG_EXCLUDEWORDS: '不包含这些字词',
  LANG_EXCLUDE: '不包含字詞',
  LANG_INCLUDE_AICONTENT: '包括AI生成的内容',
  VISUAL_SEARCH: '搜寻图片',
  VISUAL_SEARCH_TOOLTIP_TITLE: '简单3个步骤 自订图像搜寻范围 ',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1.单击图像区块来标签搜寻类别。',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2.在搜索区域移动裁剪功能框放大或缩小',
  VISUAL_SEARCH_TOOLTIP_INFO_3: '3.点击“X”返回搜寻',
  VISUAL_SEARCH_NO_RESULTS: '您的搜寻没有任何结果。',
  LANG_RIS_TITLE: '反向图片搜寻',
  LANG_RIS_NOTFOUND: '未找到结果!',
  LANG_RIS_ERROR_HEADER: '上载的图片有误，请确保图片符合以下标准:',
  LANG_RIS_REFINE_SRCH_MSG: '使用关键字检查您的精确搜寻',
  LANG_RIS_UPLOADED_IMAGE: '已上载图片',
  LANG_TELL_ME_MORE: '告诉我更多',
  LANG_ENTERKEYWORD: '请输入关键字',
  LANG_SIZE: '尺寸',
  LANG_STANDARD_SIZE: '标准尺寸',
  LANG_EXTENDED_SIZE: '扩展尺寸',
  LANG_SIZE_PX: '像素是数字图像的基本单位。以像素为单位的图像大小，决定了细节水平和下载大小。',
  LANG_SIZE_DPI: '每英寸点数衡量打印分辨率。更高的dpi意味着更多的细节。对于打印而言，所有图像的推荐分辨率为300dpi。',
  LANG_SOCMED_SIZE: '社交媒体尺寸',
  LANG_COMPARE: '对比',
  LANG_AUTO_ENHANCE: '自动增强',
  LANG_AUTO: '自动',
  LANG_BG_REMOVAL: '消除背景',
  LANG_TEXT: '文本',
  LANG_ELEMENTS: '元素',
  LANG_IMAGE_INFO: '图片信息',
  LANG_FOLLOW: '关注',
  LANG_FOLLOWED: '已关注',
  LANG_FOLLOWING: '关注',
  FACEBOOK_IMAGE: '脸书图片',
  FACEBOOK_LINK: 'Facebook链接',
  FACEBOOK_COVER: 'Facebook封面',
  FACEBOOK_STORY: '脸书故事',
  INSTAGRAM_POST: 'Instagram帖子',
  INSTAGRAM_STORY: 'Instagram故事',
  TWITTER_POST: '微博',
  TWITTER_HEADER: '推特头',
  PINTEREST_POST: 'Pinterest帖子',
  EMAIL_HEADER: '电子邮件标题',
  PRESENTATION: '介绍',
  PRESENTATION_WIDE: '呈现范围',
  EBOOK_COVER: '电子书封面',
  LINKEDIN_POST: 'LinkedIn职位',
  BROCHURE_COVER: '小册子封面(A4)',
  POSTCARD: '明信片',
  PHOTO_PRINT: '照片打印',
  YOUTUBE_PROFILE_IMAGE: 'YouTube简介图片',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'YouTube频道封面照片',
  AUTO_COLOR: '自动着色',
  BALANCE: '余额',
  AUTO_LEVEL: '自动水平仪',
  AUTO_TONE: '自动调音',
  ENRICH: '丰富',
  RESET: '重置',
  RESET_ALL: '重置所有',
  FILE_DOWNLOAD_OPTIONS: '文件下载选项',
  FILE_DOWNLOAD_INITIATED: '启动文件下载',
  IMAGE_PROPERTIES: '图像属性',
  DOWNLOAD_DETAILS: '下载属性',
  FILE_SIZE: '文件大小',
  LICENSE: '许可证',
  FILEFORMAT: '文件类型',
  CHANGE: '变化',
  PRICING: '价格',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: '保存为',
  IMAGE_USAGE: '图片使用',
  PRINT: '打印',
  ELECTRONIC: '电子类',
  COMPREHENSIVE: '综合',
  DOWNLOAD_CREDITS: '<b>{quota}</b> 个下载点数从您的 购买点数',
  BASIC_SHAPE: '基本形状',
  CHIT_CHAT: '闲聊',
  SHAPES: '形状',
  ELEMENT_CHIT_CHAT: '元素聊天',
  ORGANIC_ABSTRACT_SHAPES: '有机抽象形状',
  ELEMENT_SHAPES: '元素形状',
  BUY_SINGLE_IMAGE: '购买单张图片<br/><b>{quota}个积分</b>。',
  BUY_CREDIT_PACK: '购买信用包和<b>节省更多</b>。',
  BUY_SINGLE_IMAGE_CREDIT: '购买点数配套 <br/><b>{quota} 点数</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota}个积分包，价格为<b>{price}美元</b>。',
  BUY_CREDIT_PACK_POINT_2: '没有月租限制。',
  BUY_CREDIT_PACK_POINT_3: '1年有效期。',
  BUY_SINGLE_IMAGE_PSUB: '购买单张图片<br/><b>{quota}个下载</b>',
  BUY_DOWNLOAD_PACK: '购买下载配套能省更多哦',
  BUY_DOWNLOAD_PACK_POINT_1: '{quota}张图片下载配套 <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: '没有月租限制。',
  BUY_DOWNLOAD_PACK_POINT_3: '1年有效期。',
  LOWEST_PRICE: '<b>最低价格</b>，含订阅计划',
  LOWEST_PRICE_POINT_1: '试用1个月{price}美元',
  LOWEST_PRICE_POINT_2: '下载10张照片或矢量。',
  LOWEST_PRICE_POINT_3: '每日没有下载限制，未使用的额度过渡到下个月',
  PER_IMAGE_SIZE: '每张图片<b>{size}尺寸</b>。',
  PER_IMAGE_ANY: '每张图片<b>任何尺寸</ b>。',
  PER_IMAGE: '每张图片',
  VIEW_PRICING: '更多方案和价格',
  DOWNLOAD_BEGIN: '您的下载将马上开始。请稍等。',
  DOWNLOAD_NOT_STARTING: '还没开始下载？',
  SELECT_MIRROR: '[选择一个镜像]',
  SHOW_MORE: '请显示更多',
  SHOW_LESS: '显示更少',
  LICENSE_TYPE: '许可证类型',
  LICENSE_SUMMARY: '授权协议概要',
  BACK: '返回',
  LANG_VECTOR: '矢量图片',
  PREMIUM_VECTOR: 'PREMIUM 库存矢量图像',
  SCALE_ANY_SIZE: '依比例至任何大小',
  LANDINGPAGE_VECTOR_HEADLINE: '向量绘图、创意插画、壁纸设计和网页素材 - 123RF',
  LANDINGPAGE_PHOTO_HEADLINE: '正版授权图像、广告照片素材和商用摄影作品集 - 123RF',
  CATEGORIES: '种类',
  LANDINGPAGE_PRICING_1: '点数',
  LANDINGPAGE_PRICING_2: '购买下载配套',
  LANDINGPAGE_AS_LOW_AS: '套餐低至',
  EMPTY_SEARCH_TITLE: '抱歉，您的搜寻没有任何结果。',
  LANG_SMALL: '规模小',
  LANG_MEDIUM: '中型',
  LANG_LARGE: '大号',
  LANG_EXTRA_LARGE: '特大号',
  LANG_LOW_CREDITS: '看来你的下载点数已经不多了!',
  LANG_TOPUP: '充值',
  LANG_TOPUP_NOW: '现在储值',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} 正版图像 123RF中国 - 高质量免版税图像库. Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} 免版税剪贴画，向量插图和库存图片. Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: '图像库的{description} 图像 Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: '{description} 剪贴画、和美工. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} 免费图像和照片 {mediaId}。',
  SEO_DETAILS_METADESC_FREE_PHOTO: '{description} 的免费图片。 免费图像 {mediaId}。',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} 免费矢量图像和绘图 {mediaId}。',
  SEO_DETAILS_METADESC_FREE_VECTOR: '{description} 的免费矢量图像。 免费绘图 {mediaId}。',
  SEO_DETAILS_OGDESCRIPTION: '123RF图库 - 数百万张高创意商用图片，向量分层素材，影像短片和音乐特效以满足你每天的灵感创作！',
  LIVECHAT_LOOKINGHELP: '请问您需要帮忙吗?',
  LIVECHAT_CHATWITHUS: '请与我们联络',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: '相关搜索',
  LANG_RELATED_STOCKPHOTO_SEARCH: '相关图片搜索',
  LANG_PROPERATTRIBUTE: '这是我们的归属。',
  LANG_ATTRIBUTEDESC: '请在您的产品、网站或抵押品中的任何区域，将此信用行放在下面，将此图片归于其摄影师。',
  LANG_ATTRIBUTE_TITLE: '归属是可选的。',
  LANG_ATTRIBUTE_CREDIT: '如果你想给创作者署名，请使用下面的信用行。',
  LANG_ATTRIBUTE_COPYLINK: '复制链接',
  LANG_ATTRIBUTE_COPYTEXT: '复制文字',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>以下</b></a>是本次下载的许可协议',
  LANG_NEXTPAGE: '下一页',
  LANG_IMAGE_NO_AVAILABLE: '图片#{mediaId}已失效。',
  LANG_PHOTOGRAPHY_METAIMG_DESC: '正版授权图像、广告照片素材和商用摄影作品集 - 123RF',
  LANG_VECTOR_METAIMG_DESC: '拥有数千万张高解析度正版图像、广告照片素材和商业摄影作品集任你选择与下载。',
  LANG_ANOTHERMETAKWDEFAULT: '摄影图像,免版税,图像,图像库,图像,照片',
  LANG_ANOTHERMETADESCDEFAULT: '从123RF图库下载最新高解析度正版商业用途图像，向量绘图，网页设计和影音素材。',
  EMPTY_FILTERED_RESULT_TITLE: '您的搜索用这些过滤器没有返回任何结果。',
  EMPTY_FILTERED_RESULT_SUBTITLE: '尝试删除一些过滤器或尝试不同的关键词。',
  CLEAR_ALL_FILTER: '清除所有过滤器',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: '所有订阅皆大幅度降价。',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: '查看计划',
  LANG_MODEL_RELEASED: '人物肖像权协议',
  LANG_PROPERTY_RELEASED: '物产权协议',
  LANG_MODEL_RELEASE: '具有肖像使用同意权',
  LANG_PROPERTY_RELEASE: '具有财产形象授权书',
  LANG_YES: '需要',
  LANG_NO: '不需要',
  LANG_MREXPLAIN: '人物肖像授权是由模特本人签署的法律文件，并授权包含其肖像的图像进行发布/分发/使用于一种或某种形式',
  LANG_PREXPLAIN: '物产授权是由在图像中所体现的某物、某主题、某建筑的拥有者或管理者签署的法律文件，并授权包含上述体现内容的图像进行发布/分发/使用于一种或某种形式',
  LANG_DOWNLOADS: '下载',
  LANG_OLDFILENAME: '原文件名',
  LANG_DOWNLOAD_PREVIEW: '下载预览',
  LANG_SHARE: '分享',
  LANG_RESTRICTIONS: 'Restrictions',
  LANG_READ_MORE: '阅读更多',
  LANG_EDITORIAL_USE: 'For Editorial Use Only',
  LANG_EDITORIALDESCP: '仅限编辑用途：若非有特别许可或担保，图像将禁止用于一切商业广告以及转销售类用途，123RF将不能够在其中提供申请任何特别许可的服务',
  LANG_LOGO_OWNERSHIP: 'Logo Ownership Rights',
  LANG_PHOTO_SHOT_SMARTPHONE: 'This photo was shot with a smartphone.',
  LANG_VECTORNOLOGO: '如果您需将此图用作公司商标，我们可以为您提供<b>买断服务</b>。详情请联系<b>{phonenum}</b>。',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> 个下载额度从您的 签购配套',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> 个下载配额从您的 下载配套',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: '您使用的是旧版浏览器吗？',
  UPGRADE_NEWER_BROWSER: '升级到新的浏览器版本，享受更流畅的体验!',
  CLICK_TO_DOWNLOAD: '点击下载其中任何一个。',
  LANG_VECTOR_EDITABLE: '可编辑档案格式包含SVG与EPS',
  PROMOBAR_GOOD15_TEXT1: '付一点点的钱，可以下载更多。',
  PROMOBAR_GOOD15_TEXT2: '所有订阅方案可享有15%的折扣，请使用礼品代码:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: '付一点点的钱，可以下载更多。',
  PROMOBAR_SUMMER25_TEXT2: '所有订阅方案皆可享有25%的折扣，请使用<b>促销代码</b>：',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '所有订阅方案皆可享有30%的折扣，请使用<b>促销代码</b>',
  NO_EDIT_COMPARE_MSG: '没有编辑可比较',
  SWITCH_TO_VECTOR_MSG: '向量尺寸圖像不支圖像編輯器。如果切換到向量格式，所有編輯都將遺失。',
  SWITCH_TO_XL_MSG: 'XL大小圖像不支圖像編輯器。如果切換到XL格式，所有編輯都將遺失。',
  UNAVAILABLE_FOR_THIS_SIZE: '无法提供{size}尺寸',
  SWITCH: '转变',
  SWITCH_LICENSE_EXT_MSG: '您想将此图片下载{type}档吗？如果您切换到扩展许可证，您将遗失所有编辑动作。',
  LANG_SESSION_EXPIRED: '您的排程已過期。請<a href={url}>在此處登入</a>',
  LANG_TEXT_COLOR: '文字颜色',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: '强调',
  LANG_TEXT_ALIGNMENT: '对齐',
  LANG_TEXT_BOLD: '粗体',
  LANG_TEXT_ITALIC: '斜体',
  LANG_TEXT_UNDERLINE: '下划线',
  LANG_TEXT_BULLET: '列表',
  LANG_TEXT_LINE_SPACING: '间距',
  LANG_TEXT_LETTER_SPACING: '字母间距',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: '前移',
  LANG_BRING_BACKWARD: '后移',
  LANG_DUPLICATE: '复制',
  LANG_OPACITY: '透明度',
  LANG_DELETE: '删除',
  LANG_TEXT_ADD: '添加文字',
  LANG_TEXT_EDIT: '编辑文本',
  LANG_TEXT_FONT_TYPE: '字体',
  LANG_TEXT_FONT_SIZE: '字体大小',
  LANG_TEXT_ALL_FONTS: '所有字体',
  LANG_TEXT_UNDO: '撤消',
  LANG_TEXT_REDO: '重做',
  LANG_TEXT_FONT: '字体',
  LANG_SEARCH_FONT_TYPE: '搜索字体类型',
  LANG_SEARCH_SHAPE_TYPE: '搜索元素',
  LANG_DONE: '完成',
  ADD_HEADER: '添加标题',
  ADD_TITLE: '添加副标题',
  ADD_BODY: '添加正文',
  ADD_ASSISTIVE: '添加辅助文本',
  STOCK_PHOTO_BANNER_TITLE: '可创造一切的数百万免版税库存图片！',
  STOCK_PHOTO_BANNER_DESC: '找到您所需的精美图像用以创造令人惊叹不已的视觉效果，将您的创意想法在现实中可视化。',
  STOCK_PHOTO_SUBSCRIBE_TITLE: '使用正确的图片将您的精彩故事可视化',
  STOCK_PHOTO_SUBSCRIBE_DESC: '立即访问超过1.8亿的图片以加强您的故事叙述和表达。我们的精美图像和您的精彩故事，绝对是可让您的创作潜力最大化的完美组合！',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: '下载包',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: '可低至',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: '订阅方案',
  STOCK_PHOTO_CARD_IMAGE: '图片',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: '立即订阅',
  STOCK_PHOTO_CARD_BUY_NOW: '立即购买',
  STOCK_PHOTO_POPULAR_ROYALTY: '热门免版税图片类别',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: '查询图片的定价和订阅方案',
  STOCK_PHOTO_DISCOVER_NOW: '看一看',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: '景色',
  STOCK_PHOTO_BABIES: '婴儿',
  STOCK_PHOTO_BUSINESS: '商业',
  STOCK_PHOTO_FOOD: '食物',
  STOCK_PHOTO_ANIMALS: '动物',
  STOCK_PHOTO_TECHNOLOGY: '科技',
  STOCK_PHOTO_HEALTHCARE: '医疗',
  STOCK_PHOTO_NATURE: '自然',
  STOCK_PHOTO_ROMANCE: '浪漫',
  CLIPART_VECTOR_EXPLORE_MILLIONS: '数百万的免版税图片尽在您的指尖。',
  CLIPART_VECTOR_NEED_AN_EYE: '查找精美的矢量图像，信息图表，剪贴画，图标等等，帮助您把别出心裁的想法转化为现实',
  CLIPART_VECTOR_FIND_VECTOR: '找到正确的矢量图像让您的作品项目增添亮点。',
  CLIPART_VECTOR_FIND_VECTOR_DESC: '无论是酷炫的信息图表、插图、卡通、剪贴画、背景、图标，或是您用以创作所需的一切，都可在这里找到。立即使用矢量图像提升您的设计！',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: '热门免版税矢量类别',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: '查询图片的定价和订阅方案',
  CLIPART_VECTOR_DISCOVER_NOW: 'Discover Now',
  SPEND_AND_WIN_SUBTITLE: '每消费{currency}=1个抽奖机会',
  SPEND_AND_WIN_IMGALT: '消費並贏得iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: '绘图',
  CLIPART_VECTOR_ADVERTISING: '广告',
  CLIPART_VECTOR_FLOWER: '花',
  CLIPART_VECTOR_ABSTRACT: '抽象',
  CLIPART_VECTOR_POP: '流行',
  CLIPART_VECTOR_BIRD: '鸟',
  CLIPART_VECTOR_ILLUSTRATION: '插图',
  CLIPART_VECTOR_PIXEL: '像素',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: '矢量图片，SVG与EPS',
  FREE_UPGRADE: '免费升级',
  FREE_UPGRADE_MESSAGE: '订阅方案或下载包的全面免费 XL 升级。立即购买。',
  LANG_FREE_IMAGE: '123RF 免费',
  LANG_IMAGE_FREE: '该库存图片可免费用于商业和个人用途。需要注明归属。',
  LANG_HOW_TO_ATTRIBUTE: '我如何归因？',
  LANG_FREE_DOWNLOAD: '免费下载',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE下载',
  LANG_GO_PLUS_BUTTON: '前往PLUS',
  LANG_ATTRIBUTION_IS_KEY: '归因对于内容创作者是个极其重要的元素，尤其是选择将其公开供公众使用的内容。我们的内容创作者感谢从数百万像您一样的用户使用、分享和认可他们的创作所获得的曝光率。 ',
  LANG_GET_ATTRIBUTION_LINK: '复制此链接并将其放置在使用内容中或附近的位置。',
  LANG_CREDIT_OWNER: '请注明作者归属：',
  LANG_USE_ATTRIBUTION: '使用以下的归因链接。',
  LANG_ATTRIBUTION_LOCATION: '若该位置不可行，尚有其它好的选择，如放置在网站、博客、新闻通讯等的页脚上或网站上的一特定位置都足以注明 {media_type} 的作者归属。',
  LANG_GOT_A_QUESTION: "有什么问题吗？<a href={url} target='_blank'><u>联系我们</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: '该免费图像需要您注明作者归属。',
  LANG_ATTR_FREE_DOWNLOADING: '坚持住！不会花很长时间的。',
  LANG_PLUS_CONTENT_1: '您将获得无限下载',
  LANG_PLUS_CONTENT_2: '访问超过 10000 万张照片和矢量图像',
  LANG_PLUS_CONTENT_3: '仅限123RF独家3D插图！',
  LANG_PLUS_CONTENT_4: '无限下载',
  LANG_PLUS_CONTENT_5: '无限的创意自由',
  FREE_VECTOR: 'FREE 库存矢量图像',
  FREE_STOCKPHOTO: 'FREE 库存照片',
  FREE_PUBLIC_DOMAIN_CC0: '公共领域CC0',
  BUY_5_DOWNLOAD_PACK: "<b>限时优惠！</b><br/>3 + <b><span style='color:red'>2 免费</span></b> = 5张照片或矢量图像",
  BUY_5_DOWNLOAD_PACK_POINT_2: '随时下载（最多一年)',
  BUY_5_GET_2_FREE: '免费获得2张!',
  LANG_ASK_DOWNLOAD_PLUS: '想要下载这个PLUS内容？',
  LANG_SIGN_UP_PLUS: '注册我们的123RF PLUS计划',
  LANG_FOR_ONLY: "<span class='text'>仅</span> <span class='price'>{price}</span>/月",
  LANG_FOR_ONLY_2: "<span class='text'>仅</span> <span class='price'>{price}</span>",
  LANG_BENEFIT_PLUS: '从我们的PLUS计划获取',
  LANG_RELEASES: '权协议',
  LANG_UNAVAILABLE: '不提供',
  LANG_ATTR_FREE_IMG_TITLE2: '我们很高兴你能找到你需要的东西。🎉',
  LANG_PLEASE_NOTE: '请注意，该图片没有附加任何模型或财产说明。商业用途的风险由你自己承担。',
  LANG_PLEASE_ADVISED: '请注意，包含可识别个人的图像不会带有任何版本。要在商业上使用这些图像，必须从相关方获得许可。如果图像不包含可识别的个人，则商业使用时不应存在版权问题。',
  LANG_REACHED_LIMIT: '你已经达到了你的每日下载上限。',
  LANG_COME_TOMORROW: '我们很高兴你喜欢我们的免费图片! 明天再来更新您的下载限额。谢谢你，😊',
  LANG_DOWNLOAD_ERROR: 'Ops! 由于错误，您的图片无法被下载。',
  LANG_REFRESH_AND_TRY_AGAIN: "请刷新您的浏览器并再次尝试。如果这个问题持续存在， <a href={url} target='_blank'>请联系我们。</a>",
  LANG_REACHED_LIMIT_PLUS: '每日下载次数已上限',
  LANG_COME_TOMORROW_PLUS: '哇！您已经达到了今日下载限制。请明天回来下载更多。谢谢您的支持',
  SEE_YOU_TOMORROW: '明天见！',
  ORIGINAL: '原始图片',
  'AUTO_B&W': '黑白平衡',
  AUTO_ADJUST: '自动优化',
  AUTO_POP: '自动鲜明',
  NONE: '无效果',
  LANG_CROP: '剪裁',
  LANG_ENABLE_EDITOR: '编辑图片',
  LANG_DISABLE_EDITOR: '关闭',
  LANG_IMAGE_FREE_CC0: "这是CC0图像。不需要归因。您可以根据<a class='linkCC0' href={url} target='_blank'>CC0许可条款和条件</a>使用它。",
  LANG_PREVIEW: '预览',
  LANG_ATTRIBUTION_CC0_TITLE: 'CCO图像：不需要署名',
  LANG_ATTRIBUTION_TITLE: '需注明归属',
  LANG_ATTRIBUTION_CONTENT: '署名链接将在文件下载后提供。',
  LANG_ATTRIBUTION_CC0_CONTENT: '不需要注明出处，因为这是CCO图像。',
  LANG_SUBCRIBE_PREMIUM: '您已达到每日下载限制！ 欲下载更多内容，请订阅 123RF PREMIUM。',
  LANG_REMOVE_ATTRIBUTION: '移除署名？',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "订阅 <img src={image} alt='123RF Plus Logo'> 即可无限制访问 10000万 库存照片和插图，无需注明出处。",
  LANG_CREATOR_ATTRIBUTION: '请通过下面的链接署名该创作者。',
  LANG_GET_ATTRIBUTION_LINK_2: '复制此链接并将其放置在靠近使用内容的位置、网站的页脚或网站的特定信用部分的位置。',
  LANG_APPLY_BG_REMOVAL: '去除背景',
  LANG_APPLYING_EDIT: '应用编辑 。。。',
  LANG_APPLYING_EDIT_ERROR: '出问题了。',
  LANG_TRY_AGAIN: '再试一次？',
  LANG_SEARCHCOLLECTION_PREMIUM: '全套系列',
  LANG_SEARCHCOLLECTION_PLUS: '必备系列',
  LANG_SEARCHCOLLECTION_FREE: '基本系列',
  LANG_SIMILARIMAGESPAGE_TITLE: '123rf免版税图像库 - 高质量图像无限下载',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: '相关图像',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: '哎呀！这没东西看。',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: '图片无效',
  LANG_PER_IMAGE: '/圖片',
  LANG_PLANS_AND_PRICING: '方案和定價',
  LANG_CHOOSE_BEST_PLAN: '選擇適合您需求的最佳方案。',
  LANG_WANT_MORE_SAVING_1: '想要更多的节省吗？',
  LANG_WANT_MORE_SAVING_2: '查看更多计划，从{price}/图片起',
  LANG_BEST_VALUE: '最佳價值，節省{percentage}％',
  LANG_YEARLY_PLAN: '年度方案',
  LANG_WITH_MONTHLY_PAYMENTS: '每月付款',
  LANG_GRAB_YEARLY_PLAN: '抓住年度方案並節省{percentage}％。',
  LANG_PRICE_PER_MONTH: '每月{price}',
  LANG_PRICE_PER_YEAR: '每年{price}',
  LANG_GET_X_EVERY_MONTH: '獲取{quota}張照片或向量每個月',
  LANG_X_EVERY_MONTH: '每月有{quota}個照片與向量圖可下載',
  LANG_SINGLE_IMAGE_PURCHASE: '單圖像購買',
  LANG_GET_ONLY_WHAT_YOU_NEED: '目前只能獲得您需要的東西。',
  LANG_TOTAL_PRICE: '总计 {price}',
  LANG_DOWNLOAD_IMMEDIATELY: '立即下載圖像結帳後。',
  LANG_NEXT: '下一个',
  LANG_DOWNLOAD_PACK: '下载套餐',
  LANG_GET_X_ANY_TIME: '任何时候都有{quota}张照片或插图',
  LANG_X_CREDITS: '{total} 点数',
  LANG_PER_CREDIT: '/点数',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '任何时候都有{photoCount}张照片{videoCount, plural, =0 {} other {或#段视频}}',
  INCLUDES_STANDARD_LICENSE: '包括标准许可证',
  LANG_MEDIUM_SIZE: '中等尺寸',
  LANG_LARGE_SIZE: '大尺寸',
  LANG_EXTRA_LARGE_SIZE: '超大尺寸',
  LANG_STANDARD_SIZES: '标准尺寸',
  LANG_ROYALTY_FREE: '免版税',
  LANG_OPTIONS: '选项',
  LANG_BENEFIT_FROM_PLUS_PLAN: '从我们的PLUS计划获取',
  LANG_RELATED_FONT_SEARCH: '相关搜索',
  AI_GENERATED_PHOTO: 'AI生成的图像',
  FONT_PRICING_CREDIT: '点数',
  '3_FONTS_3_IMGS': '3个字体<b>或</b>3张图像<b>或</b>1个视频',
  '9_FONTS_9_IMG_3_VIDEOS': '9个字体<b>或</b>9张图像<b>或</b>3个视频',
  PURCHASE_AND_DOWNLOAD: '购买 & 下载',
  FONT_PATH: 'fonts',
  FONT_ZIP: '字体（OTF，TTF）',
  FONT_PACKS_LICENSE: '123RF配套许可证',
  FONT_META_DESC: '下载{product_title}字体，字型或字模，以创建用于印刷和网页设计的惊人构思。',
  FONT_META_TITLE: '字体 -  {product_title} | 123RF',
  FONT_SIMILAR: '类似字体',
  LANG_PLANS: '计划',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: '尝试创造变化',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: '根据提供的原始图像生成全新图像。此外，这也是一种崭新的方式来奖励我们的123RF贡献者，每当AI人工智能生成的图像被许可!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: '我们的社区感谢你。许可费用将与123RF贡献者共享。',
  LANG_CONTRIBUTORS_AI_VARIATION: '原始图像的123RF贡献者，会因为每个AI人工智能的创新变化得到补偿。',
  LANG_AI_VARIATION_LIMIT: '噢,不！您今天已经达到了生成AI人工智能图像的上限。',
  LANG_GENERATE_COMEBACK: '明天再来，为了更多的图像生成!',
  LANG_LICENSE_AND_DOWNLOAD: '许可证和下载',
  LANG_CONFIRM_OK: '好的',
  LANG_AI_SWITCH_CONTENT: '圖像編輯器不支持AI人工智能变化。如果您切换到AI变化，所有的编辑都会丢失。',
  LANG_GENERATE_VARIATIONS: '生成变化',
  LANG_GENERATE_WITH_AI: '用人工智能生成图像',
  LANG_NEW: '新的',
  LANG_AI_GENERATOR_LICENSE: '123RF AI 生成器的服务条款 (“条款”)',
  LANG_I_UNDERSTAND: '我理解',
  LANG_WHAT_IS_THIS: '这是什么？',
  LANG_AUTHENTIC: '真实图片',
  LANG_ABSTRACT: '抽象',
  LANG_MACRO_CLOSEUP: '宏观/特写',
  LANG_OBJECT: '对象',
  LANG_WATERCOLOR: '水彩画',
  LANG_FLAT: '平面',
  LANG_CARTOON: '卡通',
  LANG_GEOMETRIC: '几何图形',
  LANG_GRADIENT: '梯度',
  LANG_ISOMETRIC: '等角图形',
  LANG_3D: '3D',
  LANG_HAND_DRAWN: '手绘的',
  LANG_NATURAL: '自然的',
  LANG_WARM: '暖色调',
  LANG_DRAMATIC: '戏剧性',
  LANG_VIVID_BOLD: '大胆生动',
  LANG_BLACK_WHITE: '黑白色',
  LANG_ASIAN: '亚洲人',
  LANG_AFRICAN_AMERICAN: '非裔美国人',
  LANG_STANDARD: '标准',
  LANG_EXTENDED: '扩展',
  LANG_STANDARD_CONTENT_1: '社交媒体、网站、电子邮件和移动设备的无限浏览。',
  LANG_STANDARD_CONTENT_2: '最多可达 500,000 次印刷。',
  LANG_STANDARD_CONTENT_3: '请勿用于出售的商品。',
  LANG_EXTENDED_CONTENT_1: '社交媒体、网站、电子邮件和移动设备的无限浏览。',
  LANG_EXTENDED_CONTENT_2: '无限印刷。',
  LANG_EXTENDED_CONTENT_3: '可用于出售的商品。',
  LANG_ORIGINAL_IMAGE: '原始图',
  LANG_IMPORTANT_NOTE: '重要说明',
  LANG_AI_ORIGINAL_ALTERED: 'AI变体是使用人工智能技术改变的原始图像的变体。这类似于使用人工智能编辑器来修饰您所选择的图像。',
  LANG_GENERATE_VARIATIONS_NOTE: '由于我们目前处于AI人工智能图像变体的测试阶段，生成的图像可能会产生意想不到的结果。我们正在努力确保这些结果和变化符合道德和负责任的人工智能准则。',
  LANG_LICENSE_AI_EDITED: '当您授权其中一个人工智能编辑版本时，原始图像的版权所有者将获得许可费。',
  LANG_MOOD: '情绪',
  LANG_AI_EXCLUDE_PLACEHOLDER: '例如，绿色，直发',
  LANG_SIMILAR_STOCK: '类似图片库',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: '具有肖像使用同意权',
  WITH_PROPERTY_RELEASE: '具有财产形象授权书',
  MODEL_RELEASE_CONTENT: '人物肖像授权是由模特本人签署的法律文件，并授权包含其肖像的图像进行发布/分发/使用于一种或某种形式',
  PROPERTY_RELEASE_CONTENT: '物产授权是由在图像中所体现的某物、某主题、某建筑的拥有者或管理者签署的法律文件，并授权包含上述体现内容的图像进行发布/分发/使用于一种或某种形式',

  variousStockGAI: '符合法律规定的人工智能模型经过数百万张图片库的训练，并针对企业需求进行了优化，可提供责任保险，让用户高枕无忧。',
  extendedLicense: '扩展许可证',
  sizes: '尺寸',

  downloadMail1st: '我们的图像放大服务提供商将在下一个工作日内通过电子邮件',
  downloadMail2nd: '向您发送下载最终放大文件的说明。',

  aiGenarated: 'AI生成的图像',

  variousOpenJourney: '人工智能模型可生成类似 Midjourney 独特风格的艺术作品。',
  variousTurboVision: '以 "正常 "XL 分辨率高速生成，提高整体质量。',
  variousRealVisXL: '模型目标是以精确和准确的方式实现逼真的效果。',
  variousPortraitPlus: '模型采用统一的肖像构图，是 1:1 长宽比的理想选择。',
  enterVirifiedCode: '输入验证码',
  verify: '验证',
  didNotGetCode: '没有收到验证码？',
  clickToResend: '点击重新发送',
  otpCannotEmpty: 'OTP 不能为空。',
  sentCodeToMail: '我们已将验证码发送到您的注册邮箱。',
  invalidCode: '无效代码',
  expiredOTP: 'OTP 已过期，请重新发送。',
  sendEmail: '电子邮件已成功发送，请检查您的邮箱以继续。',
  remainingTime: '剩余时间：',
};

export default cn;
