export * from './routes';

export const ACCESS_TOKEN = 'access_token';
export const AUTHORIZATION = 'Authorization';
export const SHARED_SESSION = 'login_session';
export const FORCE_UPDATE_PASSWORD = 'force_update_password';
export const FORCE_2FA_AUTHENTICATION = 'force_2fa_authentication';
export const CLIENT_DOMAIN = 'client-domain';
export const CLIENT_URL_DOMAIN = 'ypimages.assetsdelivery.com';
export const CLIENT_COUNTRY = 'client-country';
export const LANG = 'lang';
export const AI_SEARCH = 'ai-search';
export const HIDE_AI_INTRODUCTION = 'hide-ai-introduction';
export const HIDE_TTI_INTRODUCTION = 'hide-tti-introduction';
export const HIDE_FREE_IMAGE_RESEARCH_INTRODUCTION = 'hide-free-image-research-introduction';
export const PHPSESSID = 'PHPSESSID';
export const REMEMBER = 'remember_me';
export const TYPE_USER = 'user';
export const TYPE_ADMIN = 'admin';
export const GET_DATA = 'get';
export const UPDATE_DATA = 'update';
export const DEFAULT_AGREE_STATUS = 0;
export const SUCCESSFUL = 'successful';
export const ACCEPT = 1;
export const DECLINE = 0;
export const CRM_AGENT = 'crm_agent';
export const OPEN_ACCESS_NORMAL_USER = 0;
export const OPEN_ACCESS_UNPL = 1;
export const LAST_ACCESS = 'last_access';
export const EXTENSIONS_IGNORED_MIDDLEWARE = ['.css', '.js', '.tiff', '.jpg', '.png', '.webv'];
export const CRM_ROUTE = '/crmLogin';
export const DEBUG_ROUTE = '/debugInfo';
export const DEFAULT_VALUE_MEDIA_TYPE = 0;
export const USER_NAME = 'user_name';
export const USER_RIGHT = 'user_right';
export const ACCESS_USER_DASHBOARD = 'access_user_dashboard';
export const CONTENT_TERM_LICENSE_1 = 'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles.';
export const CONTENT_TERM_LICENSE_2 = 'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.';
export const CONTENT_TERM_LICENSE_3 = 'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,';
export const CONTENT_TERM_LICENSE_ONLY_US_1 = 'This Agreement shall be governed by the laws of the state of Delaware in the United States of America, without regard to the conflict of laws principles.';
export const CONTENT_TERM_LICENSE_ONLY_US_2 = 'the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.';
export const CONTENT_TERM_LICENSE_ONLY_US_3 = 'that damages and remedies under the applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law, including without limitation, any injunctive or enjoining procedural orders or interlocutory awards.';
export const CONTENT_CORPORATE_ONLY_US = 'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.';
export const CONTENT_CORPORATE = 'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.';
export const DOMAIN = 'feebdesign.assetsdelivery.com';
export const LLC_123R = '123RF LLC';
export const ILPL = 'Inmagine Lab Pte. Ltd';
export const ILPL_UPPER = 'INMAGINE LAB PTE. LTD';
export const WEB_DOMAIN = '123RF.COM';
export const DATE_UPDATE = '1 November 2020';
export const DATE_LAST_UPDATE = '30 November 2021';
export const US_COUNTRY = 'US';
export const GET_SETTING = 'getSetting';
export const HOME_PATH = 'homePath';
// Filter bar
export const IMAGE_TYPE = 1;
export const FOOTAGE_TYPE = 2;
export const AUDIO_TYPE = 3;
export const AI_TYPE = 5;
export const DEFAULT_AI_ASSIST = true;
export const DEFAULT_AUTHENTIC = false;
export const DEFAULT_AICONTENT = false;
export const DEFAULT_SAFE_SEARCH = true;
export const DEFAULT_CUT_OUT = false;
export const DEFAULT_SORT_BY = 'Relevance';
export const DEFAULT_SORT_BY_AUDIO = 'Popular';
export const PRIMARY_BUTTON = 'primary';
export const DEFAULT_BUTTON = 'default';
export const DEFAULT_BUTTON_CLASS = 'filter-button-body';
export const DEFAULT_TEXT_CLASS = 'filter-small-text';
export const DEFAULT_COLOR_FILTER_CLASS = 'filter-color';
export const MAX_SELECTED_COLOR = 1;
export const DEFAULT_WIDTH_FILTER_BAR = 301;
// Ribbon bar
export const NAVIGATE_WIDTH = 120;
// Notification
export const DISPLAY_NONE = 'none';
export const NOTI_READ_COLOR = '#A3A3A3';
export const STYLE_UNSET = 'unset';
// Menu
export const IS_ADMIN_ACCOUNT = [1, 2, 3];
export const ADMIN_ACCOUNT = [1, 2];
export const PERSONAL_GROUPID = 0;
// Type User
export const TYPE_GROUP_ADMIN = 3;
export const TYPE_NORMAL_USER = 0;
export const TYPE_SUPER_ADMIN = 2;
export const TYPE_NORMAL_ADMIN = 1;
export const MAX_LENGTH_NAME_USER = 50;
export const MAX_LENGTH_EMAIL = 75;
export const MAX_LENGTH_PASSWORD = 20;
export const MAX_LENGTH_INPUT = 100;
export const MAX_LENGTH_TITLE_CUSTOM = 25;
export const MAX_LENGTH_DESC_CUSTOM = 50;
export const MAX_LENGTH_255 = 255;
export const HTTP_CODE = {
  SUCCESS: 200,
  UNAUTHORIZED: 403,
  SERVER_ERROR: 500,
  NOT_FOUND: 404,
  BAD_REQUESTED: 400,
  TOO_MANY_REQUESTS: 429,
};

export const ALERT_TYPE = {
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
  NORMAL: 'normal',
};

export const REMEMBER_ME = {
  TRUE: 1,
  FALSE: 0,
  CHECKED: 7, // days (remember login session for 7 days)
  UNCHECKED: 1 / 3, // 8 hours
};

// Filter bar params
export const SAFE_SEARCH = 'safeSearch';
export const MEDIA_TYPE = 'mediaType';
export const SORTBY = 'sortBy';
export const FRESHNESS = 'freshness';
export const GENDER = 'gender';
export const COLLECTION = 'collection';
export const ORIENTATION = 'orientation';
export const NUM_PPL = 'numPpl';
export const AGE = 'age';
export const ETHNICITY = 'ethnicity';
export const COLOR = 'color';
export const RESOLUTION = 'resolution';
export const FPS = 'fps';
export const AUTHENTIC = 'authentic';
export const CUT_OUT = 'cutOut';
export const AI_CONTENT = 'aiContent';
export const CLIP_DURATION = 'clipDuration';
export const LOOP = 'loop';
export const CATEGORY = 'category';
export const GENRE = 'genre';
export const TEMPO = 'tempo';
export const CREDITS = 'credits';
export const EXCLUDE = 'exclude';
export const GROUPMEMBER = 'group_member';
export const USERIDFILTER = 'userid';
export const GROUPIDFILTER = 'group_id';

// Filter bar params AI
export const AI_MEDIA_TYPE = 'imgtype';
export const AI_LICENSE_TYPE = 'collection';
export const AI_STYLE_TYPE = 'style';
export const AI_COLOR_TYPE = 'color';

// Filter bar
export const AGE_FILTER = ['Babies', 'Children', 'Teenagers', 'Adults', 'Seniors'];
export const ETHNICITY_FILTER = ['Caucasian', 'AfricanAmerican', 'Asian', 'Hispanic'];
export const MEDIA_FILTER = ['Photography', 'VectorIllustration'];
export const SORTBY_FILTER = ['Relevance', 'New'];
export const ORIENTATION_FILTER = ['Landscape', 'Portrait', 'Square', 'Panorama'];
export const NUMPPL_FILTER = ['WithoutPeople', '1', '2', '3', '4+'];
export const COLLECTION_FILTER = ['Commercial', 'Editorial'];
export const FRESHNESS_FILTER = ['PastTwodays', 'PastWeek', 'PastThreeMonths'];
export const CLIP_DURATION_FILTER = ['ZeroToOne', 'OneToTwo', 'TwoToThree', 'LagerThree'];
export const LOOP_FILTER = ['Loopable', 'Nonloopable'];
export const CATEGORY_FILTER = ['Music', 'SoundEffects', 'LogoAndIdents'];
export const TEMPO_FILTER = ['Veryslow', 'Slow', 'Medium', 'Fast', 'Veryfast'];
export const TEMPO_SUB_FILTER = [
  'VeryslowSubText',
  'SlowSubText',
  'MediumSubText',
  'FastSubText',
  'VeryfastSubText',
];
export const CREDITS_FILTER = [
  'TwoToEight',
  'TwentyToThirTy',
  'ThirtySixToSixty',
  'OneHundredAndTwenty',
];
export const CREDITS_SUB_FILTER = ['Credits', 'Credits', 'Credits', 'Credits'];
export const SORT_BY_AUDIO_FILTER = ['Popular', 'mostDownloaded', 'Credits', 'Freshness'];

// AI Filter bar
export const AI_ORIENTATION_FILTER = ['Horizontal', 'Portrait', 'Square', 'Panorama'];
export const AI_LICENSE_TYPE_FILTER = ['Commercial', 'Editorial'];
export const AI_MEDIA_FILTER = ['Photos', 'Vectors'];
export const AI_STYLE_PHOTO_FILTER = ['authentic', 'abstract', 'macroCloseUp', 'object'];
export const AI_STYLE_VECTOR_FILTER = [
  'watercolor',
  'flat',
  'cartoon',
  'geometric',
  'gradient',
  'isometric',
  '3d',
  'handDrawn',
];
export const AI_COLOR_FILTER = ['natural', 'warm', 'dramatic', 'vividAndBold', 'blackAndWhite'];
export const AI_ETHNICITY_FILTER = ['Caucasian', 'AfricanAmerican', 'Asian'];
export const AI_AGE_FILTER = [
  'InfantsToddlers',
  'Child',
  'Teenager',
  'YoungAdult',
  'Adult',
  'Senior',
];

export const GENRE_FILTER = [
  'gameSounds',
  'cartoonSounds',
  'soundPacks',
  'interfaceSounds',
  'transitionsAndMovementsSounds',
  'natureSounds',
  'domesticSounds',
  'urbanSounds',
  'industrialSounds',
  'futuristicSounds',
  'humanSounds',
  'othersSounds',
  'ambientSounds',
  'childrenSounds',
  'cinematicSounds',
  'classicalSounds',
  'corporateSounds',
  'countrySounds',
  'drumSounds',
  'electronicaSounds',
  'folkSounds',
  'funkSounds',
  'soulSounds',
  'hiphopSounds',
  'holidaySounds',
  'jazzSounds',
  'popSounds',
  'rockSounds',
  'worldBeatSounds',
  'individualSounds',
  'introSounds',
  'ascSounds',
  'businessSounds',
  'orchestralSounds',
  'festiveSounds',
  'percussiveSounds',
  'miscellaneousSounds',
  'packsSounds',
  'percussionSounds',
  'sampleLoopSounds',
];
export const GENDER_FILTER = ['Male', 'Female'];
export const COLOR_PICKER = [
  '#000000',
  '#996100',
  '#636300',
  '#006300',
  '#006366',
  '#000080',
  '#636399',
  '#636363',
  '#800000',
  '#FF6600',
  '#808000',
  '#8000FF',
  '#008080',
  '#0000FF',
  '#666699',
  '#808080',
  '#FF0000',
  '#FF9900',
  '#99CC00',
  '#639966',
  '#63CCCC',
  '#6366FF',
  '#800080',
  '#999999',
  '#FF00FF',
  '#FFCC00',
  '#FFFF00',
  '#00FF00',
  '#00FFFF',
  '#00CCFF',
  '#996366',
  '#C0C0C0',
  '#FF99CC',
  '#FFCC99',
  '#FFFF99',
  '#CCFFCC',
  '#CCFFFF',
  '#99CCFF',
  '#CC99FF',
  '#FFFFFF',
];
export const FILTER_VALUE_DEFAULT_BY_TRUE_CONST = ['safeSearch', 'aiContent'];
export const FILTER_VALUE_DEFAULT_BY_FALSE_CONST = ['cutOut', 'authentic'];
export const FILTER_MULTI_CHOICE = [AGE, ETHNICITY, NUM_PPL, ORIENTATION, GENRE];
export const SORTBY_GEORANK = 'Georank';
export const SORTBY_DOWNLOADHISTORY = 'DownloadHistory';
export const EDITORIAL_ICON_IMAGE = 'icon_ed';
export const VECTOR_ICON = 'icon_vector';
export const ADDED_LIKEBOX_ICON = 'favorite';
export const AI_ICON = 'icon_ai';

// Filter bar Footage seach page
export const RESOLUTION_FILTER = ['720p', '1080', '4K'];
export const FPS_FILTER = ['FPS1', 'FPS2', 'FPS3', 'FPS4', 'FPS5'];
export const EDITORIAL_ICON_FOOTAGE = 'icon_ed_black.png';

// Fill color
export const ICON_COLOR_DEFAULT = '#A3A3A3';
export const WHITE_COLOR = '#FFFFFF';
export const BLACK_BORDER_COLOR = '#212121';
export const BLACK_COLOR = '#000000';
export const MAIN_COLOR = '#ffd44d';
export const LINK_DARK = '#333333';
export const ICON_ADD_COLOR = '#E0E0E0';
// TYPE landing page
export const TYPE_LANDING_PAGE = {
  PHOTO: 'type=1',
  VECTOR: 'type=2',
  FOOTAGE: 'type=3',
  AUDIO: 'type=4',
};
// TYPE search bar
export const SEARCH_BAR_ALL = 'all';
export const SEARCH_BAR_VECTOR = 'illustration';
export const SEARCH_BAR_PHOTOS = 'photography';
export const SEARCH_BAR_FOOTAGE = 'footage';
export const SEARCH_BAR_AUDIO = 'audio';

// TYPE mycollections
export const PERSONAL = '1';
export const SEE_ALL_GROUP = '4';
export const GROUP = '2';
export const SHARE_WITH_ME = '3';

// Search Result Pages
export const GEOMETRIES_DESKTOP_SEARCH_IMAGES_PAGE_1 = [
  1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.6, 0.6, 1.4, 2, 1.4,
  1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.4, 1.4, 1.4,
  1.4, 1.4, 1, 1.6, 1.6, 1.6, 0.6, 1.4, 2, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.4, 1.4,
  1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.6, 0.6, 1.4, 2, 1.4, 1.4, 1.4,
  1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.4, 1.4,
];
export const GEOMETRIES_DESKTOP_SEARCH_IMAGES_PAGE_2 = [
  1.4, 1.6, 1.6, 1, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.6,
  0.6, 1.4, 2, 1, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1.6, 1.6, 1, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6,
  1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.6, 0.6, 1.4, 2, 1, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1.6, 1.6,
  1, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.6, 0.6, 1.4, 2,
  1, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1, 1.6, 1.6, 1.4, 1.4, 1.4,
];
export const GEOMETRIES_TABLET_SEARCH_IMAGES_PAGE = [
  1, 1, 1, 1.5, 1.5, 1, 1, 1, 1.5, 1.7, 1.7, 0.6, 1, 1, 1, 1.5, 1, 1, 1, 1.5, 1, 1, 1, 1, 1, 1, 1,
  1, 1.5, 1.7, 1, 1, 1, 1.5, 1.5, 1, 1, 1, 1.5, 1.7, 1.7, 0.6, 1, 1, 1, 1.5, 1, 1, 1, 1.5, 1, 1, 1,
  1, 1, 1, 1, 1, 1.5, 1.7, 1, 1, 1, 1.5, 1.5, 1, 1, 1, 1.5, 1.7, 1.7, 0.6, 1, 1, 1, 1.5, 1, 1, 1,
  1.5, 1, 1, 1, 1, 1, 1, 1, 1, 1.5, 1.7, 1.7, 0.6, 1, 1, 1, 1.5, 1, 1, 1, 1.5,
];
export const GEOMETRIES_MOBILE_SEARCH_IMAGES_PAGE = [
  1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
  1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
  1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
  1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
  1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
  1.2, 1.2, 1.2, 1.2, 1.2,
];
export const RECENTLY_VIEWED_GEOMETRIES = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
export const STRING_VALUE_TRUE = '1';
export const STRING_VALUE_FALSE = '0';
export const STRING_VALUE_ZERO = '0';
export const NUMBERS_OF_ITEM_CONTENT_LOADER_FOOTAGES = 24;
export const SIMILAR_SEARCH_KEYWORD = 'similar:';
export const LANG_CODE = {
  BR: 'BR',
  CN: 'CN',
  CZ: 'CZ',
  DE: 'DE',
  EN: 'EN',
  ES: 'ES',
  FR: 'FR',
  HU: 'HU',
  IT: 'IT',
  JP: 'JP',
  KO: 'KO',
  NL: 'NL',
  PL: 'PL',
  PT: 'PT',
  RU: 'RU',
  TR: 'TR',
  TW: 'TW',
};
export const RECOMMENDED_IMAGES = 1;
export const RECOMMENDED_FOOTAGES = 3;
export const RECOMMENDED_MAX_ROWS_DESKTOP = 3;
export const RECOMMENDED_MAX_ROWS_MOBILE = 6;

export const DEFAULT_BAGELIST_FOR_IMAGE = {
  sortBy: DEFAULT_SORT_BY,
  orientation: [],
  numPpl: [],
  age: [],
  ethnicity: [],
  color: [],
  cutOut: DEFAULT_CUT_OUT,
  safeSearch: DEFAULT_SAFE_SEARCH,
};

export const DEFAULT_BAGELIST_FOR_FOOTAGE = {
  sortBy: DEFAULT_SORT_BY,
  numPpl: [],
  ethnicity: [],
  color: [],
};

export const DEFAULT_BAGELIST_FOR_AUDIO = {
  sortBy: DEFAULT_SORT_BY_AUDIO,
  genre: [],
};

export const DASHBOARD_OPTIONS = {
  DASHBOARD: 'dashboardCredits',
  USERS: 'users',
  CREDITS: 'credits',
  DOWNLOAD_HISTORY: 'downloadHistory',
  INVOICES: 'invoices',
  COMPANY_INFO: 'companyInfo',
  SETTING: 'settings',
  MY_COLLECTION: 'myCollections',
  MY_ACTIVITES: 'myActivities',
  MY_ACCOUNT: 'myAccount',
  CUSTOMIZED_PORTAL_LANDING_PAGE: 'landingPage',
  CP_LOGIN_PAGE: 'loginPage',
  CP_CUSTOM_TAGS: 'customTags',
  INVOICE_LIST: 'invoicesList',
  MANAGE_CUSTOMER_ADDRESS: 'manageCustomerAddress',
};
export const FILE_TYPE_FOOTAGE = [5, 6];
export const FILE_TYPE_AUDIO = 7;

export const RECENT_LIKE = 'like';
export const RECENT_VIEW = 'view';

export const PORTFOLIO_TAB = {
  IMAGES: '1',
  FOOTAGE: '2',
  AUDIO: '3',
};

// Input Value MyAccount
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const EMAIL = 'email';
export const OLD_PASSWORD = 'old_password';
export const NEW_PASSWORD = 'new_password';
export const CONFIRM_PASSWORD = 'confirm_password';

// Name Modal in My Collection page
export const SHARE_LIKE_BOX = '1';
export const EDIT_LIKEBOX = '2';
export const EXPORT_LIKE_BOX = '3';
export const DELETE_LIKEBOX = '4';
export const COPY_IMAGE_LIKEBOX = '5';
export const MOVE_IMAGE_LIKEBOX = '6';
export const REMOVE_IMAGE_LIKEBOX = '7';
export const DOWNLOAD_ITEM = '8';
export const HRUC_DOWNLOAD_ITEM = '9';
// type select search
export const DATECREATED = 'datecreated';
export const LIKEBOX_NAME = 'lightbox_name';

// type handle tag data in download details modal
export const TYPE_TAG_DOWNLOAD_DETAILS_MODAL = {
  add: 'add',
  remove: 'remove',
};

// Width of window to display
export const WIDTH_TABLET = 992;
export const WIDTH_TABLET_768 = 768;
export const WIDTH_MOBILE = 576;
export const WIDTH_SMALL_DESKTOP = 1200;
// downloaded size for detail pages
export const DOWNLOADED_IMAGE = 'DownloadedImage';
export const DOWNLOADED_FOOTAGE = 'DownloadedFootage';
export const DOWNLOADED_AUDIO = 'DownloadedAudio';

export const PLAY_RATE_FOOTAGE = [5, 2, 1, 0.5, 0.1];
// customized landing pages
export const LIST_DEFAULT_IMAGES = [
  'https://static-cdn.assetsdelivery.com/images/template6/backgrounds/bg_1-min.jpg',
  'https://static-cdn.assetsdelivery.com/images/template6/backgrounds/bg_2-min.jpg',
  'https://static-cdn.assetsdelivery.com/images/template6/backgrounds/bg_3-min.jpg',
  'https://static-cdn.assetsdelivery.com/images/template6/backgrounds/bg_4-min.jpg',
  'https://static-cdn.assetsdelivery.com/images/template6/backgrounds/bg_5-min.jpg',
  'https://static-cdn.assetsdelivery.com/images/template6/backgrounds/bg_6-min.jpg',
  'https://static-cdn.assetsdelivery.com/images/template6/backgrounds/bg_7-min.jpg',
  'https://static-cdn.assetsdelivery.com/images/template6/backgrounds/bg_8-min.jpg',
  'https://static-cdn.assetsdelivery.com/images/template6/backgrounds/bg_9-min.jpg',
  'https://static-cdn.assetsdelivery.com/images/template6/backgrounds/bg_10-min.jpg',
];

export const CHARACTERS_MESSAGE_BANNER_MAX_LENGTH = 255;
export const IMAGE_BANNER_FILE_UPLOAD_MAX_SIZE = 2000000;
export const IMAGE_LOGO_FILE_UPLOAD_MAX_SIZE = 5000000;
export const IMAGE_FILE_UPLOAD_PNG = 'image/png';
export const IMAGE_FILE_UPLOAD_JPEG = 'image/jpeg';
export const IMAGE_FILE_UPLOAD_JPG = 'image/jpg';

export const ACCEPTED_TYPES_FLUX = ['image/jpg', 'image/jpeg'];

export const ACCEPTED_TYPES_DEFAULT = [
  'image/jpg',
  'image/png',
  'image/jpeg',
];

export const MONTH_IN_YEAR = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
export const EXPORT_CSV = 'csv';
export const EXPORT_PDF = 'pdf';
export const tabbarListName = ['all', 'photos', 'vectors', 'footage', 'audio'];
export const FILE_TYPE_CVS1 = 1;
export const FILE_TYPE_CVS2 = 3;

export const VIEW_TYPE = {
  listView: 'listview',
  gridView: 'gridView',
};
export const TYPE_MANAGE = {
  user: 'user',
  group: 'group',
};

export const MODAL_NAME = {
  addMember: 'addMember',
  assignCredits: 'assignCredits',
  editGroupDetails: 'editGroupDetails',
  assignGroupAdmin: 'assignGroupAdmin',
  assignGroupPersonalCredits: 'assignGroupPersonalCredits',
  drawerDetail: 'drawerDetail',
  addUser: 'addUser',
  addNewUser: 'addNewUser',
  createGroup: 'createGroup',
  delete: 'delete',
  removeAssignTag: 'removeAssignTag',
};

export const ADD_MEMBER_MODAL_TYPE = {
  add: 'add',
  edit: 'edit',
};
export const ROLE_TYPE_TO_ARRANGE = {
  user: 0,
  admin: 1,
  superAdmin: 2,
  groupAdmin: 3,
};
export const QOUTE_TYPE_TO_ARRANGE = {
  recentUse: 0,
  inactive: 1,
  lowToHigh: 2,
  highToLow: 3,
};
export const TAB_ACTIVE_KEY = {
  user: '0',
  group: '1',
};

export const ACTIVE_STATUS_LIST = ['', 'active', 'delete'];
export const ROLE_STATUS_LIST = ['', 'user', 'admin', 'super_admin', 'group_admin'];
// export const QOUTA_STATUS_LIST = ['all', 'user', 'admin'];

export const OBJECT_LANGUAGE = {
  en: 'English',
  de: 'Deutsch',
  nl: 'Dutch',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  pl: 'Język polski',
  pt: 'Português (PT)',
  ru: 'Pусский язык',
  tr: 'Türkçe',
  jp: '日本語',
  ko: '한국어',
  tw: '繁體中文',
};
export const ASC_SORT = 'ascend';
export const DESC_SORT = 'descend';

// Invoice PerPage
export const INVOICE_PERPAGE = 10;

export const URL_DASHBOARD = {
  DASHBOARD_CREDITS: `/dashboard/${DASHBOARD_OPTIONS.DASHBOARD}`,
  USERS: `/dashboard/${DASHBOARD_OPTIONS.USERS}`,
  CREDITS: `/dashboard/${DASHBOARD_OPTIONS.CREDITS}`,
  DOWNLOAD_HISTORY: `/dashboard/${DASHBOARD_OPTIONS.DOWNLOAD_HISTORY}`,
  INVOICES: `/dashboard/${DASHBOARD_OPTIONS.INVOICES}`,
  COMPANY_INFO: `/dashboard/${DASHBOARD_OPTIONS.COMPANY_INFO}`,
  SETTING: `/dashboard/${DASHBOARD_OPTIONS.SETTING}`,
  MY_COLLECTION: `/dashboard/${DASHBOARD_OPTIONS.MY_COLLECTION}`,
  MY_ACTIVITES: `/dashboard/${DASHBOARD_OPTIONS.MY_ACTIVITES}`,
  MY_ACCOUNT: `/dashboard/${DASHBOARD_OPTIONS.MY_ACCOUNT}`,
  CUSTOMIZED_PORTAL_LANDING_PAGE: `/dashboard/${DASHBOARD_OPTIONS.CUSTOMIZED_PORTAL_LANDING_PAGE}`,
  CP_LOGIN_PAGE: `/dashboard/${DASHBOARD_OPTIONS.CP_LOGIN_PAGE}`,
  CP_CUSTOM_TAGS: `/dashboard/${DASHBOARD_OPTIONS.CP_CUSTOM_TAGS}`,
  INVOICE_LIST: `/dashboard/${DASHBOARD_OPTIONS.INVOICE_LIST}`,
  MANAGE_CUSTOMER_ADDRESS: `/dashboard/${DASHBOARD_OPTIONS.MANAGE_CUSTOMER_ADDRESS}`,
  DETAIL_MY_COLLECTION: '/dashboard/detailMyCollection',
  DETAIL_INVOICE: '/dashboard/viewDetailInvoice',
  DETAIL_MY_ACTIVITIES: '/dashboard/detailMyActivities',
};
export const URL_PRICING = {
  PRICING_PACKAGE: '/pricingPackage',
};
// invoice UNPL
export const TAB_ACTIVE_DRAFT = '0';
export const TYPE_MODAL_NEW_INVOICE = 'newInvoice';

// tutorial customized landing page
export const TUTORIAL_CLP_MODE = {
  SHOW: 1,
  HIDE: 0,
};
export const TOTAL_TUTORIAL_CLP = 4;
export const CREDIT_CLIENT = 0;
export const UNPL_CLIENT = 1;
export const DEFAULT_HOMEPAGE_HEADER_TEXT = 'Welcome to 123RF Corporate+';
export const DEFAULT_HOMEPAGE_BODY_TEXT = 'Over 200 million photos, vectors, and 3D illustrations for you and your team to create';

// Resize image before uploading
export const MAX_WIDTH_IMAGE_UPLOAD = 1000;
export const MAX_HEIGHT_IMAGE_UPLOAD = 1000;

export const ADMIN_AND_SUPERADMIN = [1, 2];

export const NAME_PATTERN = new RegExp(/^[_A-z0-9]*((|\s)*[_A-z0-9])*$/g);

export const TERM_VALUE = 'This license is pursuant to and the terms are those of our Preferred Provider Agreement dated 8/1/12';

export const CLIENT_ID_ENABLE_HRUC_BULKDOWNLOAD = 6288;

export const TAB_SHARE_WITH_ME_ACTIVE_KEY = '3';

// editor tool
export const CANVAS_EDITOR_ID = 'editor-tools-canvas';
export const ALIGNMENT = ['justify-left', 'center', 'justify-right'];
export const EDITOR_CANVAS_NAME = 'editorCanvas';
export const EDITOR_FILTER_VALUES = [
  'brooklyn',
  'amaro',
  'lofi',
  'xpro2',
  'valencia',
  'gingham',
  'ludwig',
  'lark',
  'juno',
  'clarendon',
];
export const EDITOR_ENHANCE_VALUES = ['autopop', 'autoadjust', 'autobw'];
export const COLOR_PICKER_TYPE = {
  FILL: 'fill',
  TEXT_BG: 'textBackgroundColor',
};

// export const TYPE_COLOR = {
//   TEXT: 'text',
//   HIGHLIGHT: 'highlight',
//   SVG: 'svg',
// };

export const TYPE_RANGE_BAR = {
  LINE_HEIGHT: 'lineHeight',
  LETTER_SPACING: 'charSpacing',
  OPACITY: 'opacity',
  FONT_SIZE: 'fontSize',
};

export const cropList = [
  {
    title: 'facebookImage',
    titleShow: 'FACEBOOK_IMAGE',
    size: {
      width: 1200,
      height: 1200,
    },
    imgSize: [47, 40],
  },
  {
    title: 'facebookLink',
    titleShow: 'FACEBOOK_LINK',
    size: {
      width: 1200,
      height: 627,
    },
    imgSize: [90, 40],
  },
  {
    title: 'facebookCover',
    titleShow: 'FACEBOOK_COVER',
    size: {
      width: 1702,
      height: 630,
    },
    imgSize: [108, 40],
  },
  {
    title: 'facebookStory',
    titleShow: 'FACEBOOK_STORY',
    size: {
      width: 1080,
      height: 1920,
    },
    imgSize: [31, 40],
  },
  {
    title: 'instagramPost',
    titleShow: 'INSTAGRAM_POST',
    size: {
      width: 1080,
      height: 1080,
    },
    imgSize: [47, 40],
  },
  {
    title: 'instagramStory',
    titleShow: 'INSTAGRAM_STORY',
    size: {
      width: 1080,
      height: 1920,
    },
    imgSize: [31, 40],
  },
  {
    title: 'twitterPost',
    titleShow: 'TWITTER_POST',
    size: {
      width: 1024,
      height: 512,
    },
    imgSize: [94, 40],
  },
  {
    title: 'twitterHeader',
    titleShow: 'TWITTER_HEADER',
    size: {
      width: 1500,
      height: 500,
    },
    imgSize: [109, 37],
  },
  {
    title: 'printerestPost',
    titleShow: 'PINTEREST_POST',
    size: {
      width: 736,
      height: 1128,
    },
    imgSize: [35, 40],
  },
  {
    title: 'emailHeader',
    titleShow: 'EMAIL_HEADER',
    size: {
      width: 600,
      height: 300,
    },
    imgSize: [94, 40],
  },
  {
    title: 'presentation',
    titleShow: 'PRESENTATION',
    size: {
      width: 1024,
      height: 768,
    },
    imgSize: [63, 40],
  },
  {
    title: 'presentationWide',
    titleShow: 'PRESENTATION_WIDE',
    size: {
      width: 1920,
      height: 1080,
    },
    imgSize: [83, 40],
  },
  {
    title: 'ebookCover',
    titleShow: 'EBOOK_COVER',
    size: {
      width: 1600,
      height: 2400,
    },
    imgSize: [31, 40],
  },
  {
    title: 'linkedlnPost',
    titleShow: 'LINKEDIN_POST',
    size: {
      width: 1200,
      height: 627,
    },
    imgSize: [91, 40],
  },
  {
    title: 'brochureCover',
    titleShow: 'BROCHURE_COVER',
    size: {
      width: 2490,
      height: 3510,
    },
    imgSize: [33, 40],
  },
  {
    title: 'postCard',
    titleShow: 'POSTCARD',
    size: {
      width: 1500,
      height: 2100,
    },
    imgSize: [33, 40],
  },
  {
    title: 'photoPrint',
    titleShow: 'PHOTO_PRINT',
    size: {
      width: 1200,
      height: 1800,
    },
    imgSize: [31, 40],
  },
  {
    title: 'youtubeProfileImage',
    titleShow: 'YOUTUBE_PROFILE_IMAGE',
    size: {
      width: 800,
      height: 800,
    },
    imgSize: [47, 40],
  },
  {
    title: 'youtubeChannelCoverPhoto',
    titleShow: 'YOUTUBE_CHANNEL_COVER_PHOTO',
    size: {
      width: 2560,
      height: 1440,
    },
    imgSize: [79, 40],
  },
];

export const MODAL_EDITOR_NAME = {
  ADD_TEXT: 'ADD_TEXT',
  FONT_TYPE: 'FONT_TYPE',
  FONT_SIZE: 'FONT_SIZE',
  LINE_SPACING: 'LINE_SPACING',
  LETTER_SPACING: 'LETTER_SPACING',
  OPACITY: 'OPACITY',
  TEXT_COLOR: 'TEXT_COLOR',
  HIGHLIGHT: 'HIGHLIGHT',
  ADD_SHAPE: 'ADD_SHAPE',
};

export const TYPE_DOWNLOAD_MODAL = {
  IMAGE: 'image',
  FOOTAGE: 'footage',
  AUDIO: 'audio',
};
export const CLIENT_ID_HEADER_PIXLR_DESIGN = 6482;

export const QUESTIONS_LIST = [
  {
    QUESTION: 'TTI_Q1',
    ANSWER: 'TTI_A1',
  },
  {
    QUESTION: 'TTI_Q2',
    ANSWER: 'TTI_A2',
  },
  // {
  //   QUESTION: 'TTI_Q3',
  //   ANSWER: 'TTI_A3',
  // },
  {
    QUESTION: 'TTI_Q4',
    ANSWER: 'TTI_A4',
  },
  {
    QUESTION: 'TTI_Q5',
    ANSWER: 'TTI_A5',
  },
  {
    QUESTION: 'TTI_Q6',
    ANSWER: 'TTI_A6',
  },
  {
    QUESTION: 'TTI_Q7',
    ANSWER: 'TTI_A7',
  },
  {
    QUESTION: 'TTI_Q8',
    ANSWER: 'TTI_A8',
  },
  {
    QUESTION: 'TTI_Q9',
    ANSWER: 'TTI_A9',
  },
  {
    QUESTION: 'TTI_Q10',
    ANSWER: 'TTI_A10',
  },
  {
    QUESTION: 'TTI_Q11',
    ANSWER: 'TTI_A11',
  },
];

export const ACTIVE_TOOL_NAME = {
  MODEL: 'model',
  RATIO: 'ratio',
  STYLE: 'style',
  COLOR: 'color',
  LIGHTING: 'lighting',
  COMPOSITION: 'composition',
};

export const PAGE_ACTIVE = {
  MAIN_PAGE: 0,
  GENERATED_PAGE: 1,
};

export const CONTENT_LIMIT_REACH_TTI_PAGE = 'Unable to generate image due to quota exceed / no active plan';
export const SENSITIVE_ERROR = 'Sensitive keywords contain copyrighted image text';
export const PARAM_DISPLAY_ONE_IMAGE = 'one_image';
export const URL_S3_TTI_PAGE = 'https://static-cdn.assetsdelivery.com/assets/images/tti';
export const INTSUFFICIEN_CREDITS_MSG = 'Insufficient Credits';
export const LICENSE_TYPE = {
  STANDARD: 'Standard',
  EXTENDED: 'extended',
  LIMITED: 'limited',
};
export const LICENSE_TYPE_TITLE_TRANSLATE_KEY = {
  [LICENSE_TYPE.STANDARD]: 'LANG_STANDARD_SIZE',
  [LICENSE_TYPE.EXTENDED]: 'LANG_EXTENDED_SIZE',
  [LICENSE_TYPE.LIMITED]: 'custom',
};

export const PROMPT_NAME_TTI = 'prompt_tti';
export const LAST_PAGE = 'last_page';

export const EXCLUDE_AI_CONTENT_SEARCH_FILTER = 'exclude-ai-content-search-filter';
export const EXCLUDE_SAFE_SEARCH_FILTER = 'exclude-safe-search-filter';
export const BRIA_LIST_DISABLE = ['anime', 'comicBook', 'fantasyArt', 'pixelArt', 'craftClay', 'crepuscularRays', 'dimlyLit', 'goldenHour', 'studio'];
export const BRIA_LIST_DISABLE_PAYLOAD = ['style_preset', 'lighting'];

export const SETTING_ONLY_STOCK_GAI = 2;

export const AI_MODELS = [
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/icon-landing-model-flux-schnell.jpg`,
    id: 'Flux',
    title: 'Our quickest model, tailored for local development.',
    value: 21,
    isFinedTuned: false,
    desc: 'Flux',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/icon-landing-model-ideogram.jpg`,
    id: 'Ideogram',
    title: 'Turning a text description into beautiful images in a matter of seconds.',
    value: 25,
    isFinedTuned: false,
    desc: 'Ideogram',
  }, {
    imgSrc: `${URL_S3_TTI_PAGE}/models/icon-landing-model-flux-pro.jpg`,
    id: 'FluxPro',
    title: 'Flux offers advanced, high-quality, and varied image generation.',
    value: 22,
    isFinedTuned: false,
    desc: 'FluxPro',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/SDXL.png`,
    id: 'SDXL',
    title: 'Bring stunning photorealistic people and scenes to life.',
    value: 2,
    isFinedTuned: false,
    desc: 'SDXL',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/EimisAnime.png`,
    id: 'EimisAnime',
    title: 'Generative AI model designed for creating anime-style images.',
    value: 8,
    isFinedTuned: false,
    desc: 'Eimis Anime',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/OpenJourney.png`,
    id: 'OpenJourney',
    title: "AI Model generates art resembling Midjourney's distinctive style.",
    value: 12,
    isFinedTuned: false,
    desc: 'Open Journey',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/TurboVisionXL.png`,
    id: 'TurboVisionXL',
    title: 'High-speed generation at &quot;normal&quot; XL resolutions, enhancing overall quality.',
    value: 13,
    isFinedTuned: false,
    desc: 'TurboVisionXL',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/RealVisXL.png`,
    id: 'RealVisXL',
    title: 'Model targets achieving photorealistic results with precision and accuracy.',
    value: 14,
    isFinedTuned: false,
    desc: 'RealVisXL 4.0',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/PortraitPlus.png`,
    id: 'PortraitPlus',
    title: 'Model with uniform portrait composition, ideal for 1:1 aspect ratio.',
    value: 11,
    isFinedTuned: false,
    desc: 'Portrait Plus',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/RealismEngineSDXL.png`,
    id: 'RealismEngineSDXL',
    title: 'This model specialises in highly detailed, realistic images.',
    value: 7,
    isFinedTuned: false,
    desc: 'RealismEngine',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/DreamShaperXL.png`,
    id: 'DreamShaperXL',
    title: 'High-res upscaling, improved characters and dragons generations, reduced edge blur.',
    value: 4,
    isFinedTuned: false,
    desc: 'DreamShaper',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/DynaVisionXL.png`,
    id: 'DynaVisionXL',
    title: 'Generate 3D stylised cartoon characters.',
    value: 3,
    isFinedTuned: false,
    desc: 'DynaVisionXL',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/JuggernautXL.png`,
    id: 'JuggernautXL',
    title: 'Centered around architectural elements and landscapes/backgrounds.',
    value: 1,
    isFinedTuned: false,
    desc: 'JuggernautXL',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/DALL-E.png`,
    id: 'DALL-E',
    title: 'DALL·E 3, an all rounder model excels at precise concept-to-visual transformations.',
    value: 9,
    isFinedTuned: false,
    desc: 'DALL-E 3',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/StockGAI.png`,
    id: 'StockGAI',
    title: 'StockGAI',
    value: 10,
    isFinedTuned: false,
    desc: 'StockGAI',
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/icon-model-landing-real-stock-photo-x3-v1.png`,
    id: 'RealStockPhoto',
    title: 'Create captivating stock photos effortlessly with our advanced AI model.',
    value: 19,
    desc: 'Real Stock Photo',
    isFinedTuned: true,
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/icon-model-landing-real-cinematic-x3-v1.png`,
    id: 'RealCinematic',
    title: 'A model specialized in producing cinematic outputs, excelling with wider aspect ratios.',
    value: 16,
    desc: 'Real Cinematic',
    isFinedTuned: true,
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/icon-model-landing-real-food-x3-v1.png`,
    id: 'RealFood',
    title: 'Enhance your culinary brand with food visualization model.',
    value: 17,
    desc: 'Real Food',
    isFinedTuned: true,
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/icon-model-landing-real-macro-x3-v2.png`,
    id: 'RealMacro',
    title: 'A Great model for generating detailed macro images.',
    value: 18,
    desc: 'Real Macro',
    isFinedTuned: true,
  },
  {
    imgSrc: `${URL_S3_TTI_PAGE}/models/icon-model-landing-real-bokeh-x3-v1.png`,
    id: 'RealBokeh',
    title: 'A photorealistic style model, perfect for creating stunning bokeh images.',
    value: 15,
    desc: 'Real Bokeh',
    isFinedTuned: true,
  },
];
