/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
const en = {
  // Header
  photos: 'Photos',
  Photos: 'Photos',
  vectors: 'Vectors',
  Vectors: 'Vectors',
  footageupper: 'VIDEO',
  footage: 'Video',
  video: 'Video',
  audio: 'Audio',
  signin: 'Sign In',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_English.png',
  language: 'Language',
  // Footer
  contact: 'Contact',
  contactUs: 'Contact Us',
  privacy: 'Privacy Policy',
  terms: 'Terms of Use',
  cookie: 'Cookie Policy',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Forgot Your Password',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Please enter a valid email address.',
  emailCannotEmpty: 'E-mail cannot be empty.',
  returnToLogin: 'Return to Login',
  email: 'Email',
  // Login
  username: 'User name',
  pw: 'Password',
  rememberMe: 'Remember Me',
  login: 'Log In',
  forgotPassword: 'Forgot Password',
  loginAsGuest: 'Continue as Guest',
  // ResetPassword
  resetPwdDesc: "Please enter your new password, and we'll log you in right away.",
  newPw: 'New password',
  confirmPwd: 'Confirm Password',
  resetPwdButton: 'Reset My Password',
  resetPwdTitle: 'Reset Your Password',
  enterYourNewPw: 'Please enter your new password.',
  pwMiniMumChars: 'A minimum of 8 characters',
  pwMaxChar20: 'The maximum characters allowed is 20',
  pwLowerCase: 'At least one lower case letter',
  pwUpperCase: 'At least one upper case letter',
  pwNumeral: 'At least one numeral',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Confirm Password does not match with Password',
  resetPwdInvalidLink: 'This link is invalid or has expired.',
  pwHasBeenReset: 'Password has been reset, please login to proceed.',
  // Admin index
  companyName: 'Company Name',
  phone: 'Phone',
  fax: 'Fax',
  address: 'Address',
  idd: 'IDD',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Old password',
  confirmNewPassword: 'Confirm new password',
  returnToHome: 'Return to Home',
  // Cookie Policy
  langCookie01:
    'When you visit %host%, we place a small text file called a cookie in your computer hard disk.',
  langCookie02:
    'This file allows us to track you so that we are able to provide basic functionality to you such as shopping carts. They are also used to provide an enhanced online user experience and provide you with functionality such as "likebox".',
  langCookie03:
    'In addition, it allows us to analyse your interaction with our website (including pages visited, search terms and images viewed). This is irrespective of whether you choose to make a purchase or not.',
  langCookie04:
    'The cookies you consent to, are also used to profile you into an audience list so that we can deliver targeted advertising tailored to your interests.',
  langCookie05:
    'Most browsers allow you to reject cookies; but if you do so, certain functionality may be unavailable and certain webpages may not display properly.',
  // My Collections
  by: 'by',
  backToMyCollection: 'Back to My collections',
  clearAll: 'Clear all',
  info: 'Info',
  dashboard: 'Dashboard',
  users: 'Users',
  creditUsage: 'Credit usage',
  invoices: 'Invoices',
  licenseOnBehalf: 'License on Behalf',
  customizedPortal: 'Customized portal',
  companyInfo: 'Company info',
  myActivities: 'My activities',
  group: 'GROUP',
  groupCapitalized: 'Group',
  addNewCollection: 'Add New Collection',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  removeImage: 'Delete',
  downloadAgain: 'Download again',
  myCredit: 'My credit',
  downloadModalTitle: 'Image download',
  bulkDownloadTitle: 'Bulk download',
  messageFileNotSupported1stHalf:
    'Image TIFF file, video media and audio media are not supported. The following media:',
  messageFileNotSupported2ndHalf: 'has been removed from Bulk Download.',
  selectedMedia: 'Selected media:',
  downloadModalId: 'Image ID:',
  downloadModalPrice: 'Credit:',
  price: 'Price',
  downloadUsing: 'download content via',
  normalDownload: 'Normal download (You own the license)',
  normalDownloadTTI: 'Normal download',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  idCapitialization: 'ID',
  monthSelection: 'Month selection',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Type in your search here',
  toSearchAIKeyword: 'Enter keywords, article title or blog title',
  allImages: 'All Images',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Recently Viewed',
  latestIMG: 'Latest Images',
  // Setting
  hello: 'Hello,',
  viewAccount: 'View Account',
  adminControlPanel: 'Admin Dashboard',
  downloadHistory: 'Download history',
  myAccount: 'My account',
  myCollections: 'My collections',
  hruc: 'HRUC',
  changePassword: 'Change password',
  logOut: 'Logout',
  personalCredits: 'Personal Credits',
  groupTotal: 'Group Total',
  toTalUnpaidLicense: 'Total Unpaid License',
  highRUnwatermarkComp: 'High-Res Unwatermark Comps',

  // Menu
  categories: 'Categories',
  myLikebox: 'My Likebox',
  settings: 'Settings',
  notification: 'Notification',
  clear: 'Clear',
  likebox: 'Likebox',
  credits: 'Credits',
  // Credit summary at header
  downloadAndUsege: 'Download and Usage',
  totalDlCredit: 'Total Download Credits',
  allocatedDlCredit: 'Allocated Download Credits',
  personalAvailableCre: 'Personal Available Credit',
  groupAvailableCre: 'Group Available Credit',
  availableDlSlot: 'Available Download Slot(s)',
  uNPLD: 'Use Now, Pay Later Downloads',
  hiResHRUC: 'Hi-Res Unwatermarked Comps (HRUC)',
  monthlyAllocation: 'Monthly Allocation',
  remainingQuota: 'Remaining quota',
  remainingHRUCQ: 'Remaining Hi-Res Unwatermarked Comps Quota',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Groups',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Please let us know how we can help you.',
  liveChat: 'Live chat',
  whatsApp: 'WhatsApp',
  needHelp: 'Need help?',
  hiThere: 'Hi there,',
  // email response
  emailIsNotAvailable: 'E-mail is not available.',
  anErrorHaSoccured: 'An error has occured, please try again.',
  emailHasBeenSent:
    "We have sent an email to your email with reset instructions. If the email doesn't appear in your inbox within 5 minutes, please check your Spam folder.",
  tooManyAttempts: 'Too many attempts, please try again',
  // INVALIDPASSWORD
  inValidPassword: 'Invalid password! Check if your caps lock is on.',
  userIsBlocked: 'Too many failed attempts, you have been temporarily blocked.',
  blockWarrings: 'You will be temporarily blocked after 10 attempts!',
  // Update Password
  resetPwdUpdated: 'Your password has been updated!',
  updatePwDfailed: 'Failed to update password!',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'License Agreement',
  endUserLicenseAgreement: 'End user license agreement',
  licenseAgreementCaption:
    'PLEASE READ THE TERMS AND CONDITIONS TO THE SERVICES PROVIDED IN CORPORATE+. YOUR ACCEPTANCE IS AN ABSOLUTE CONDITIONS OF YOUR USE OF THE CORPORATE+ PLATFORM AND THE PRODUCTS AND SERVICES PROVIDED HEREIN. BY ACCESSING CORPORATE+, USING THE PRODUCTS AND/ OR SERIVCES, OR ANY PART THEREOF AS MAY BE PROVIDED ON CORPORATE+, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS APPLICABLE TO SUCH ACCESS AND USAGE. 123RF RESERVES THE ABSOLUTE RIGHT TO CHANGE THE TERMS AND CONDITIONS AT ANY TIME, WITHOUT PRIOR NOTICE AND WITHOUT ANY LIABILITY TO YOU. BY CONTINUING ACCESS AND/OR USAGE, YOUR FURTHER AGREE TO BE BOUND SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Username cannot be empty',
  enterVirifiedCode: 'Enter verification code',
  sentCodeToMail: 'We have sent a code to your registered email.',
  sendEmail: 'E-mail has been sent successfully, please check your mailbox to proceed.',
  remainingTime: 'Remaining time: ',
  invalidCode: 'Invalid code.',
  expiredOTP: 'OTP is expired, please send again.',
  verify: 'Verify',
  didNotGetCode: 'Didn\'t get a code?',
  clickToResend: 'Click to resend',
  otpCannotEmpty: 'OTP can not be empty',
  passWordCannotEmpty: 'Password cannot be empty',
  oldPasswordCannotEmpty: 'Old password cannot be empty',
  confirmPasswordCannotBeEmpty: 'Confirm new password cannot be empty',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Cannot send more emails, please check your mailbox.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: 'Are you using an older browser?',
  upgradeBrowser: 'Upgrade to a newer browser version to enjoy a smoother experience!',
  clickToDownload: 'Click to download any of these:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'View all',
  // Filter bar
  aiContent: 'Include AI-generated content',
  filter: 'Filter',
  safeSearch: 'Safe Search',
  aiAssist: 'AI Assist',
  authentic: 'Authentic',
  freshness: 'Freshness',
  gender: 'Gender',
  sortBy: 'Sort by',
  mediaType: 'Media type',
  orientation: 'Orientation',
  modelPreferences: 'Model Preferences',
  people: 'People',
  more: 'More',
  numOfPeople: 'Number of people',
  age: 'Age',
  ethnicity: 'Ethnicity',
  collection: 'Collections',
  uploaded: 'Uploaded',
  style: 'Style',
  cutOut: 'Solid/Transparent Backgrounds',
  doNotIncludeWords: 'Do not include these words',
  doNotIncludeWords_ex: 'ex. cheese',
  doNotIncludeWords_ex2: 'Eg. Green colour, straight hair',
  color: 'Color',
  mood: 'Mood',
  max1Color: 'Max 1 color',
  license: 'License type',
  fps: 'FPS',
  resolution: 'Resolution',
  clipDuration: 'Clip duration (minutes)',
  loop: 'Loop',
  category: 'Category',
  genre: 'Genre',
  tempo: 'Tempo (bpm)',
  downloadedBy: 'Downloaded by',
  // Filter button text
  Relevance: 'Relevance',
  New: 'New',
  Georank: 'Georank',
  DownloadHistory: 'Download history',
  Popularity: 'Popularity',
  Photography: 'Photography',
  VectorIllustration: 'Vector Illustration',
  Horizontal: 'Horizontal',
  Landscape: 'Landscape',
  Portrait: 'Portrait',
  Square: 'Square',
  Panorama: 'Panorama',
  numPpl: 'Number of People',
  WithoutPeople: 'Without People',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Exclude',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  InfantsToddlers: 'Infant / Toddler',
  Teens: 'Teens',
  Teenager: 'Teenager',
  YoungAdult: 'Young Adult',
  PastWeek: 'Past week',
  LastMonth: 'Last month',
  PastThreeMonths: 'Past 3 months',
  PastTwodays: 'Past 2 days',
  Male: 'Male',
  Female: 'Female',
  Standard: 'Standard',
  extended: 'Extended',
  Editorial: 'Editorial',
  Black: 'Black',
  Caucasian: 'Caucasian',
  AfricanAmerican: 'African American',
  Asian: 'Asian',
  EastAsian: 'East Asian',
  Hispanic: 'Hispanic',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Child: 'Child',
  Children: 'Children',
  Teenagers: 'Teenagers',
  Adults: 'Adults',
  Adult: 'Adult',
  Seniors: 'Seniors',
  Senior: 'Senior',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Yes',
  Nonloopable: 'No',
  Music: 'Music',
  SoundEffects: 'Sound Effects',
  LogoAndIdents: 'Logos & Idents',
  Veryslow: 'Very Slow',
  VeryslowSubText: '(0-79)',
  Slow: 'Slow',
  SlowSubText: '(80-119)',
  Medium: 'Medium',
  MediumSubText: '(120-139)',
  Fast: 'Fast',
  FastSubText: '(140-159)',
  Veryfast: 'Very Fast',
  VeryfastSubText: '(>160)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Popular',
  MostDownload: 'Most Download',
  Credits: 'Credits',
  Freshness: 'Freshness',
  pickAGenre: 'Pick a genre',
  // Paginations
  Of: 'of',
  Page: 'Page',
  // home
  recommended: 'Recommended for you',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Trending Keywords',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Game Sounds',
  cartoonSounds: 'Cartoon Sounds',
  soundPacks: 'Sound Packs',
  interfaceSounds: 'Interface Sounds',
  transitionsAndMovementsSounds: 'Transitions & Movement',
  natureSounds: 'Nature Sounds',
  domesticSounds: 'Domestic Sounds',
  urbanSounds: 'Urban Sounds',
  industrialSounds: 'Industrial Sounds',
  futuristicSounds: 'Futuristic Sounds',
  humanSounds: 'Human Sounds',
  othersSounds: 'Others',
  ambientSounds: 'Ambient',
  childrenSounds: "Children's",
  cinematicSounds: 'Cinematic',
  classicalSounds: 'Classical',
  corporateSounds: 'Corporate',
  electronicaSounds: 'Electronica',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  worldBeatSounds: 'World Beat',

  countrySounds: 'Country & Western',
  drumSounds: 'Drum & Bass',

  folkSounds: 'Folk & Acoustic',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Holiday & Seasonal',

  rockSounds: 'Rock',

  individualSounds: 'Individual',
  introSounds: 'Intro & Outro',
  ascSounds: 'Ascending & Descending',
  businessSounds: 'Business & Media',
  orchestralSounds: 'Orchestral',
  festiveSounds: 'Festive',
  percussiveSounds: 'Percussive',
  miscellaneousSounds: 'Miscellaneous',
  packsSounds: 'Packs',
  percussionSounds: 'Percussion Sounds',
  sampleLoopSounds: 'Sample Loop',

  // Search images
  searchImage: 'Photos & Vectors',
  StockPhotosAndImages: 'Stock Photos And Images',
  StockVectorClipartAndIllustration: 'Stock Vectors, Clipart and Illustrations',

  matches: 'matches',
  editorial: 'Editorial',
  aiGenerate: 'AI-generated image',
  standardModels: 'Standard Models',
  fineTunedModels: 'Fine-tuned Models',
  downloaded: 'This image has been downloaded before',
  to: 'to',
  relatedSearches: 'Related Searches',
  similarTo: 'Similar to',
  similarStockPhotos: 'Similar Stock Photos',
  searchByImage: 'Search by image',
  options: 'Options',
  searchByImageMsg:
    'Search 123RF with an image instead of text.Try dragging an image to the search box.',
  mustBeJPGorPNG: 'Image must be JPG / PNG',
  sizeLessThan5MB: 'Image size must be less than 5 MB',
  sizeLessThan4MB: 'Image size must be less than 4 MB',
  dragOrDropFile: 'Drag and drop file or',
  browse: 'Browse',
  uploadedImage: 'Uploaded Image',

  // Search footage
  searchFootage: 'Footage & Videos',
  StockFootage: 'Stock Video',
  businessFootage: 'Hacks to make the most of your business videos',

  // Search audio
  searchAudio: 'Audio (Music & Sound Effects)',
  StockAudio: 'Stock Audio',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Copy Link',
  preview: 'Preview',
  download: 'Download',
  copied: 'Copied!',

  bestSellingFootage: 'Browse best-selling royalty video',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Similar images',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'Your recently viewed images will show here',
  rVVectors: 'Your recently viewed vectors will show here',
  rVFootage: 'Your recently viewed video will show here',
  rVAudio: 'Your recently viewed audio will show here',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'Oops, your search with those filters did not return with any results.',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: 'Try remove some filters or try a different keyword.',
  emptyReverseSearchTitle: 'No Result Found!',
  emptyReverseSearchSubTitle1:
    'Uploaded Image contains errors, make sure the image has the following criteria:',
  emptyReverseSearchSubTitle2: 'Check your refine search with keyword',
  emptyReverseSearchSubTitle3: 'Image must be JPG / PNG',
  emptyReverseSearchSubTitle4: 'Image size must be less than 5 MB',
  emptySearchResultClearFilter: 'Clear all filters',
  emptyDownloadHistoryResult: 'Your download history will show here',

  // Please enter a keyword
  keywordEmpty: 'Please enter a keyword',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: 'Search collections',
  personal: 'Personal',
  groups: 'GROUPS',
  createNew: 'Create new',
  createNewCollection: 'Create new collection',
  titleItem: 'TITLE',
  selectGroup: 'SELECT GROUP',
  description: 'DESCRIPTION',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  charactersRemaining: '180 characters remaining',
  cancel: 'Cancel',
  create: 'Create',
  defaultCollection: 'Default Collection',
  addToMyCollections: 'Add to my collections',
  addedToCollection: 'Added to collection',
  removedFromCollection: 'Removed from collection',
  collectionModalPlaceholder: 'e.g. Nature',
  groupNameShowsHere: 'Group name shows here',
  // like box
  shareWithMe: 'Shared with me',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Owner',
  images: 'images',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Personal',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: "You don't have any collections.",
  btnUnDo: 'UNDO',
  // my collection
  btnViewCollection: 'View Collection',
  dateAdded: 'Date added',
  collectionName: 'Collection name',
  selectAll: 'Select all',
  delete: 'Delete',
  seeAll: 'See all',
  deleteCollection: 'Are you sure you want to delete?',
  deleteWarning:
    'Completing this action will permanently delete your Collection along with its contents. Be aware that this action cannot be undone.',
  createCollection: ' has been created',
  shareCollection: 'Share Collection',
  shareWithTeam: 'Share in team',
  selectUsers: 'Select users',
  searchUserName: "Search a user's name",
  copy: 'Copy to',
  titleCopyModal: 'Copy selected items to',
  collectionUppercase: 'COLLECTION',
  groupCollection: 'Group',
  moveImage: 'Move selected items to',
  defaultSelect: 'Collection name here',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Completing this action will permanently delete your Collection along with its contents. Be aware that this action cannot be undone.',
  areYouSure: 'Are you sure?',
  editCollection: 'Edit collection',
  collectionNameUpper: 'COLLECTION NAME',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Edit',
  or: 'OR',
  messageRemoveImage: 'Are you sure you want to remove the selected item(s) from the collection?',
  titleRemove: 'Remove selected item',
  popupActionMove: ' item has been moved to ',
  popupActionMoveMany: ' items has been moved to ',
  popupActionCopy: ' item has been copied to ',
  popupActionCopyMany: ' items has been copied to ',
  popupActionRemove: ' item has been removed from this collection',
  popupActionRemoveMany: ' items have been removed from this collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'Save',
  shareLikebox: 'Shared with me',
  remove: 'Remove',
  moveTo: 'Move to',
  createNewPlaceholder: 'e.g. Nature',
  lowCredit: 'low credit',
  downloadSuccess: 'Thank you for your download! Your download will begin in a moment.',
  downloadFailed: 'Download Error: An error has occured, please try again',
  downloadMail1st: 'Our Image Enlargement Service provider will email you at',
  downloadMail2nd:
    'the instructions to download the final enlarged file within the next business day',
  shareViaEmail: 'Share via Email',
  sharePublicly: 'Share publicly',
  receipnetEmail: "Recipient's email",
  message: 'Message',
  insertReceipmentEmail: 'Insert recipient email',
  messageText: 'Message text.',
  exportCollection: 'Export collection',
  pdf: 'PDF',
  csv: 'CSV',
  format: 'FORMAT',
  any: 'Any',
  resetFilter: 'Reset filter',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Follow',
  name: 'Name',
  location: 'Location',
  since: 'Since',
  copyPortfolioLinkSuccess: 'Contributor Portfolio link copied to clipboard.',
  downloadContentAsPersonal: 'Download this content as: Personal',

  // my activities
  recentLike: 'Recently added to my collections',
  recentView: 'Recently viewed',
  myActivity: 'My activities',
  myActivityViewDesc: 'Recent view history will only store up to 3 months only',
  myActivityLikeDesc: 'Only showing history in the recent 3 months',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // My account
  updated: 'Update',
  myProfile: 'My Profile',
  userName: 'user name',
  firstName: 'First name',
  lastName: 'Last name',
  changeProfiledetail: 'Change your profile details here',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'My plan',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: 'Use Now, Pay Later',
  bodyUNPL:
    'Use Now, Pay Later (UNPL) is a type of short-term financing that allows consumers to make purchases and pay for them at a future date, often interest-free. Also referred to as "point of sale installment loans".',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Last name cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',

  // audio player
  categoryGenre: 'Category / Genre',
  duration: 'Duration',
  seeMore: 'See more',
  seeLess: 'See less',
  audioID: 'Audio ID',
  imageID: 'Image ID',
  // Download-history modal
  addYourKeywordHere: 'Add your keyword here',
  reDownload: 'Re-download',
  chooseCustomTagOrCreate: 'Choose custom tag(s) or create a new one',
  downloadDetails: 'Download details',
  image: 'Image',
  downloadType: 'Download type',
  groupName: 'Group name',
  size: 'Size',
  dateDownloaded: 'Date downloaded',
  reLicense: 'Re-license',
  customFields: 'Custom fields',
  saveAndClose: 'Save',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterMaxChar50: 'The maximum characters allowed is 50',
  characterMaxChar255: 'The maximum characters allowed is 255',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Downloaded by members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'date',
  export: 'Export',
  selectRange: 'select range',
  fileType: 'file type',
  deleteConfirm: 'Are you sure you want to delete the selected items?',
  downloadedLabel: 'Downloaded',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Hi-res comp',
  licenseContent: 'LICENSE CONTENT',
  licenseImage: 'License Images',
  totalOutstanding: 'Total Outstanding',
  viewInvoices: 'View invoices',
  searchById: 'Search by ID',
  invoiceAddedSuccess: 'Your invoice has been successfully added',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageDetail: 'Video Detail',
  share: 'Share',
  stockAudioKeywords: 'Stock audio keywords',
  stockFootageKeywords: 'Stock video keywords',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License',
  stereo: 'Stereo',
  fileSize: 'File size',
  footageId: 'Footage ID',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  fileName: 'File Name',
  licenseType: 'License type',
  imageUsage: 'Image Usage',
  normalDl: 'Normal download (Own License)',
  // Image detail page - select download size sidebar
  standardSizes: 'Standard sizes',
  extendedSizes: 'Extended sizes',
  limitedSizes: 'Limited sizes',
  extendedLicenseSizes: 'Extended sizes',
  crop: 'CROP',
  // empty data
  opsNothingToSeeHere: 'Oops! Nothing to see here.',
  // Dashboard - Landing page
  landingPage: 'Landing page',
  customizedLandingPage: 'Customized landing page',
  descriptionLandingPage:
    'Customized your own landing page with your own company logo and custom hero image',
  publishedAt: 'Published at',
  companyLogo: 'Company logo',
  heroBanner: 'Hero banner',
  uploadCompanyLogo: 'Upload your company logo',
  logoShouldNotExceed5mb: 'Logo should not exceed 5MB',
  fileExtensions: 'File extensions',
  replaceImage: 'Replace image',
  uploadYourCoverPhoto: 'Upload your cover photo',
  recommendedResoulution: 'Recommended resoulution',
  maxFileSizeAllowed: 'Max file size allowed',
  chooseDefaultImage: 'Choose default image',
  changeYourHomepageDescriptions: 'Change your homepage descriptions',
  charactersRemainingCustom: 'characters remaining',
  welcomeMessage: 'Welcome message',
  homepageMessage: 'Homepage message',
  btnSelect: 'Select',
  tutorialDescPublishButton: 'Double confirm the full page look and feel before publish.',
  tutorialTitlePublishButton: 'Full page preview before update',
  tutorialDescPublishTime:
    'We’ve added Last Published Time for you to keep track on when is your last update made on the website.',
  tutorialTitlePublishTime: 'Last published history',
  tutorialDescLandingPage:
    'We’ve improve the user interface to help you have a better visualisation on the outcome.',
  tutorialTitleLandingPage: 'Easy edit',
  tutorialDescTooltip: 'Click the tag here for an easy navigation to the editable section.',
  tutorialTitleTooltip: 'Easy navigation',
  next: 'Next',
  skip: 'Skip',
  okay: 'Okay',

  // Dashboard - custom login page
  titleCustomLoginPage: 'Customized login page',
  btnClose: 'Close',
  btnPublish: 'Publish',
  document: 'Headline and description',
  descriptionCustomLoginPage: 'Customized your login page with your desire message',
  changeData: 'Change the headline title and description',
  headlineTitle: 'Headline title',
  successfully: 'Changes has been successfully publised!',
  iD: 'id',
  lastUpdateData: 'Published at:',
  loginPage: 'Login page',
  profile: 'My account',
  // Dashboard - custom tags
  customTags: 'Custom tags',
  mandatory: 'Mandatory',
  mandatoryDesc: 'By enable this, all users must fill in this field before download',
  activated: 'Activated',
  activatedDesc: 'To show visibility for users. Users won’t see it if is inactive',
  fieldName: 'Field name',
  customTagDesc: 'Tag your projects with custom labels to organize them for better accesibility',
  createNewTag: 'Create new tag',
  title: 'Title',
  deleteWarningTitle: 'Are you sure you want to remove this field?',
  deleteWarningText1st: 'You are about to remove',
  deleteWarningText2nd:
    '. This action can not be undone. All media falls under this field won’t be categorised',
  fieldNameEmptyErrorMessage: 'Field name cannot be empty',
  add: 'Add',
  updateCustomTagsSuccess: 'You have successfully updated your custom tags.',
  createCustomTagSuccess: 'You have successfully created a new custom tag.',
  removeCustomTagSuccess: 'You have successfully removed the custom tag.',
  reOrderCustomTagsSuccess: 'You have successfully reordered the custom tags.',
  changesSaved: 'Your changes are saved.',

  headlineDescMaxLength100: 'The maximum characters allowed are 100',
  en: 'English',
  de: 'Deutsch',
  nl: 'Dutch',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  pl: 'Język polski',
  pt: 'Português (PT)',
  ru: 'Pусский язык',
  tr: 'Türkçe',
  jp: '日本語',
  ko: '한국국어',
  tw: '繁體中文',
  cn: '简体中文',
  cz: 'Česky',
  br: ' Português (BR)',

  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Total items',
  // Dashboard - Company Info
  companyInfoDesc: 'This will be visible in the ‘Contact Us’ section and it is customisable.',
  contactNum: 'Contact number',
  companyAddress: 'Company address',
  // Dashboard - Credit Usage
  user: 'User',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'All',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Reset',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super Admin',
  groupAdmin: 'Group admin',
  admin: 'Admin',
  // Date
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  // Invoices page
  datePurchased: 'Date purchased',
  plan: 'Plan',
  slots: 'Slots',
  invoiceNo: 'Invoice No.',
  amount: 'Amount',
  action: 'Action',
  invoiceList: 'Invoice list',
  viewInvoice: 'View invoice',
  viewYourPurchaseHistoryHere: 'View your purchase history here',
  viewDetailInvoice: 'Invoice preview',
  // UnpaidInvoice Table
  invoiceDate: 'Invoice Date',
  dueDate: 'Due Date',
  view: 'View',
  unpaidInvoices: 'Unpaid invoices',

  // setting dashboard
  POEL: 'Print Only Extended License',
  EOEL: 'Electronic Only Extended License',
  CEL: 'Comprehensive Extended License',
  e720: '720p Electronic Only Extended License',
  e1080: '1080p Electronic Only Extended License',
  e4000: '4K Electronic Only Extended License',
  EMP3: 'MP3 Electronic Only Extended License',
  EWAV: 'WAV Electronic Only Extended License',
  EAIF: 'AIF Electronic Only Extended License',
  downloadResolution: 'Download resolution',
  general: 'General',
  defaultLanguage: 'Default language',
  senWellComeEmail: 'Send a welcome e-mail to new users',
  emailNoti: 'Email notification / Reminder',
  lowCreditsReminder: 'Low credits reminder',
  alerMe: 'Alert me when account has less than',
  display: 'Display',
  hideNudeContent: 'Hide nude content from search result',
  displayEditorialImage: 'Display Editorial Images in search result',
  displayEnterprise: 'Display Enterprise Live chat support',
  spLanguage: 'Supported languages: English',
  displayDlType: 'Display Download type: License on behalf of your client',
  messageErrorSettingLang:
    'Only administrators of Feebdesign can change the Default language. This feature is not available to the public',
  standardLicense: 'Standard license',
  extendedLicense: 'Extended license',

  // manage users
  addUser: 'Add user',
  createGroup: 'Create group',
  role: 'Role',
  listView: 'List view',
  gridView: 'Grid view',
  assignCredits: 'Assign credits',
  assignGroup: 'Assign group',
  recentActivities: 'Recent activities',
  deleteGroup: 'Delete group',
  addMember: 'Add member',
  editGroupDetails: 'Edit group details',
  assignGroupAdmin: 'Assign group admin',
  assignGroupCredits: 'Assign group credits',
  assignGroupPersonalCredits: 'Assign group personal credits',
  removeGroup: 'Remove group',
  viewUserDetails: 'View user details',
  editUserDetails: 'Edit user details',
  manageYourUsersIn: 'Manage your users in',
  addNewUser: 'Add new user',
  editUser: 'Edit user',
  assignedGroup: 'Assigned group',
  groupCredits: 'Group credits',
  assignPersonalCredits: 'Assign personal credits',
  searchLanguage: 'Search language',
  deleteUser: 'Delete user',
  creditUsed: 'Credit used',
  quotaUsed: 'Quota Used',
  unlimited: 'Unlimited',
  atLeast8Characters: 'At least 8 characters',
  mixBothUpAndLowercaseLetters: 'A mix of both uppercase and lowercase letters',
  mixOfLettersAndNumbers: 'A mix of letters and numbers',
  atLeastSpecialCharacter: 'At least one special character, e.g., ! @ # ? ]',
  availableCredits: 'Available credits',
  viewAllDownloadHistory: 'View all download history',
  setAsAdministrator: 'Set as Administrator',
  downloadHiResComp: 'Download hi-res comp',
  downloadComp: 'DOWNLOAD COMP',
  ableToUseCorporateEnterprise: 'Able to use Corporate Enterprise Plan downloads',
  placeholderSearchInputUser: 'Search by name or email',
  placeholderSearchInputGroup: 'Search by Name',
  assignGroupAdminFor: 'Assign a Group Admin for',
  searchAUsername: 'Search a user’s name',
  downloadCreditBalance: 'Download credit balance',
  groupDownloadCredits: 'Group download credits',
  unallocatedGroupCredits: 'Unallocated group credits',
  personalDownloadCredits: 'Personal download credits',
  allowMembersViewBalance: 'Allow members to view balance',
  sureWantToDelete: 'Are you sure you want to delete',
  actionCannotBeUndone:
    'This action cannot be undone. Deleted user will no longer able to access the page',
  actionCannotBeUndo:
    'Are you sure you want to remove this group? This action cannot be undone. All content saved into group collections will be removed.',
  allowMemberViewBalance: 'Allow member to view balance',
  removeUserFrom: 'Remove user from',
  addToAGroup: 'Add to a group',
  searchByName: 'Search By Name',
  // invoice UNPL
  draft: 'draft',
  paid: 'paid',
  due: 'Due',

  status: 'Status',
  failed: 'Failed',
  allTime: 'All time',
  pastWeek: 'Past Week',
  pastMonth: 'Past Month',
  pastYear: 'Past Year',

  descriptionInvoiceList:
    'Manage and create invoices for your company or on behalf of your customers here.',
  btnGeneral: 'Generate new invoice',
  searchInvoice: 'Seach Invoice No.',
  deleteDrafts: 'Delete draft (s)',
  titleRemoveDraft: 'Remove Draft?',
  decRemoveDraft: 'Are you sure you want to delete the selected item?',
  jobReft: 'Job Ref./ PO No.',
  paidDate: 'Paid Date',
  btnPayNow: 'Pay now',
  viewReceipt: 'View Receipt',
  outstanding: 'Outstanding',
  downloadBy: 'Downloaded by',
  amountUsd: 'Amount(USD)',
  clientName: 'Client Name',
  address1: 'Address 1',
  address2: 'Address 2',
  backToInvoice: 'Back to Invoice list',
  saveAndBack: 'Save and Back to UNPL',
  invoiceSummary: 'Invoice Summary',
  deleteDraft: ' Delete draft',
  btnGeneralInvoice: 'Generate Invoice',
  attn: 'Attn',
  changeAddress: 'Change Address',
  summary: 'Summary',
  manageAddress: 'Manage customer address',
  addACustomer: 'Add a customer',
  desManageAddress: "Manage your customer's addresses for third party downloads licensing. ",
  titleDeleteAddress: 'Are you sure you want to remove selected customer address?',
  decDeleteAddress:
    'You are about to remove [field name]. This action can not be undo. You may create it later on if you change your mind',
  telephone: 'Telephone',
  street: 'Street',
  zipCode: 'Zip/Postal Code',
  state: 'State',
  country: 'Country',
  editCustomerAddress: 'Edit Customer Address',
  personals: 'Personals',
  newInvoice: 'New Invoice',
  addItem: 'Add Item',
  normalDwl: 'Normal download(You own the license)',
  licenseDwl: 'License on behalf',
  selectItem: 'Select Item',
  nextPage: 'Next page',
  btnContinue: 'Continue',
  searchImageId: 'Search image ID',
  dataAdded: 'Date Added',
  licenOnBehafOf: 'License on behalf of',
  approved: 'Approved',
  pendding: 'Pendding',
  rejected: 'Rejected',
  // Pricing
  pricingContentTitle: 'Affordable plans with really simple pricing!',
  pricingContentSubtitle:
    'Choose a cost-saving plan now to download what you need and enjoy the flexibility of a payment method that suits your content needs and budget the best.',
  pricingPanelTitle: 'On Demand Packages',
  pricingPanelSubtitle: 'Select a credit plan that fits your usage',
  pricingFeature1Text: 'Unuse credit rollover next month',
  pricingFeature2Text: 'No daily download limit',
  pricingFeature3Text: 'Life-long rights to use RF + Editorial images',
  pricingFeature4Text: 'Use for marketing and advertising',
  butNowParkageBtn: 'Buy now',
  pricingCreditText: 'credit',
  pricingCreditsText: 'Credits',
  pricingTotalText: 'Total',
  pricingPopularText: 'Popular',
  // search audio
  mostDownloaded: 'Most Downloaded',
  // category
  landscape: 'Landscape',
  Babies: 'Babies',
  business: 'Business',
  food: 'Food',
  animals: 'Animals',
  technology: 'Technology',
  healthcare: 'Healthcare',
  nature: 'Nature',
  romance: 'Romance',
  // category vector
  graphic: 'Graphic',
  advertising: 'Advertising',
  flower: 'Flower',
  abstract: 'Abstract',
  Illustration: 'Illustration',
  pop: 'Pop',
  bird: 'Bird',
  pixel: 'Pixel',
  threeD: '3D',
  // footage category
  education: 'Education',
  communication: 'Communication',
  beauty: 'Beauty',
  concept: 'Concept',
  fitness: 'Fitness',
  lifestyle: 'Lifestyle',
  celebration: 'Celebration',
  family: 'Family',
  fashion: 'Fashion',

  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Corporate',
  electronica: 'Electronica',
  classical: 'Classical',
  funkAndGrooveMusic: 'Funk & Groove music',
  worldBeat: 'World Beat',
  cinematic: 'Cinematic',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // Reverse search page
  reverseImageSearch: 'Reverse Image Search',
  // manage User UNPL
  mothlyQuota: 'Monthly Quota Assignment (Value',
  unlimitedQuota: 'Unlimited Quota',
  currentBalance: 'Current Balance',
  groupQuota: 'Group Quota',
  personalQuota: 'Personal Quota',
  downloadQuota: 'Download Quota',
  assignTo: 'Assign to',
  tittleQuotaUser: 'Set Personal Quota Limit',
  tittleQuotaGroup: 'Set Group Quota Limit',
  assignDownloadQuota: 'Assign Download Quota',
  lastActive: 'Last Active',
  search: 'Search',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',

  termsLanguage: 'Terms language',
  licensePursuant:
    'This license is pursuant to and the terms are those of our Preferred Provider Agreement dated 8/1/12',
  aiPoweredSearch: 'AI Powered Search',
  aiPoweredSearchOn: 'AI Powered Search is on',
  aiSearchIntroduction:
    'Experience faster, precision-driven searches with our new AI-powered stock image search engine. Discover more, effortlessly.',
  ok: 'OK',

  // editor tool
  text: 'Text',
  elements: 'Elements',
  auto: 'Auto',
  backgroundRemoval: 'Background removal',
  resetAll: 'Reset all',
  close: 'Close',
  facebookImage: 'Facebook image',
  facebookLink: 'Facebook link',
  facebookCover: 'Facebook cover',
  facebookStory: 'Facebook story',
  instagramPost: 'Instagram post',
  instagramStory: 'Instagram story',
  twitterPost: 'Twitter post',
  twitterHeader: 'Twitter header',
  printerestPost: 'Printerest post',
  emailHeader: 'Email header',
  presentation: 'Presentation',
  presentationWide: 'Presentation wide',
  ebookCover: 'E-book cover',
  linkedlnPost: 'Linkedln post',
  brochureCover: 'Brochure cover (A4)',
  postCard: 'Post card',
  photoPrint: 'Photo print',
  youtubeProfileImage: 'Youtube profile image',
  youtubeChannelCoverPhoto: 'Youtube channel cover photo',
  applyBackgroundRemovalButton: 'Apply Background Removal',
  applyResetBackgroundButton: 'Reset',
  autoEnhanceBWButton: 'Auto B/W',
  autoEnhanceAdjustButton: 'Auto Adjust',
  autoEnhancePopButton: 'Auto Pop',
  autoEnhanceNoneButton: 'None',
  editorLoadingText: 'Applying edit...',
  editorErrorText: 'Something went wrong.',
  editorTryAgainButton: 'try again?',
  editorCompareImageBeforeTitle: 'Before',
  editorCompareImageAfterTitle: 'After',
  editorCompareImageButton: 'Compare',
  editorCompareTooltip: 'No edits were made to compare.',

  ownLicense: 'Normal download (own license)',
  audioProperties: 'Audio properties',
  footageProperties: 'Footage properties',
  imageProperties: 'Image properties',
  modalDownloadTitle: 'File download options',
  credit: 'Credit',
  saveAs: 'Save as',
  downloadAs: 'Download as',
  insufficientCredits: 'Insufficient Credits',
  myCredits: 'My Credits',
  limitedLicense: 'Limited license',
  unlimitedTooltip: 'Unlimited views for social, web, email and mobile.',
  upToPrintRuns: 'Up to 500,000 print-runs.',
  notToBeUsed: 'Not to be used on merchandise for sale.',
  canBeUsed: 'Can be used on merchandise for sale.',
  unlimitedPrintRuns: 'Unlimited print-runs.',
  standard: 'Standard',
  limited: 'Limited',
  custom: 'custom',
  personalAndCom: 'Personal and Commercial Use Ads, presentations, social media, web.',
  requiredForResale:
    'Required for resale or distribution in Theatrical performance, paid-access, streaming video, games and apps.',
  selectAtLeastOne: 'Please select at least one',
  updateSuccessfully: 'We have received your request.',
  characterNameMaxChar255: 'The maximum file name allowed is 255',
  // Filter bar ai,
  macroCloseUp: 'Macro/Close up',
  object: 'Object',
  watercolor: 'Watercolor',
  flat: 'Flat',
  cartoon: 'Cartoon',
  geometric: 'Geometric',
  gradient: 'Gradient',
  isometric: 'Isometric',
  '3d': '3d',
  handDrawn: 'Hand-drawn',
  natural: 'Natural',
  warm: 'Warm',
  dramatic: 'Dramatic',
  vividAndBold: 'Vivid and bold',
  blackAndWhite: 'Black and white',

  // introduce Free image research
  freeImageResearch: 'Free Image Research',
  newService: 'New service!',
  freeImageResearchIntroduction:
    'Try our image research service and see why our images match your requirements perfectly.',
  later: 'Later',
  letTryIt: 'Let’s try it!',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'is required',

  relatedSearch: 'Related searches',
  showMore: 'Show more',
  showLess: 'Show less',

  requiredWithVariable: '{{var}} is required',

  // image research page
  imageResearchContentTitle:
    "Introducing 123RF's free image research service: Perfect images, Zero hassle",
  imageResearchContentDesc:
    ' We revolutionize image research for corporate customers, making it effortless to find the perfect image and bring your vision to life.',
  submitRequest: 'Submit request',
  viewPdfOfSampleOutput: 'View PDF of sample output',
  imageResearchContentInfoTitle1: 'Image research made simple',
  imageResearchContentInfoDesc1:
    'Getting started is easy. Just provide us with a detailed description of your desired image, including style, mood, colors, and specific elements. The more details you give, the better we can match your needs.',
  imageResearchContentInfoTitle2: 'How does it work?',
  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoTitle3: 'Fast, reliable service',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  imageResearchContentInfoTitle4: 'Let us do the work for you',
  imageResearchContentInfoDesc4:
    'We find the perfect images for you, so you can focus on your project. Trust us to bring your vision to life with high-quality, carefully chosen images. Start now!',
  requestForm: 'Request form',
  whatDoYouWantThisImageFor: 'What do you want to use this image for?',
  websiteBanner: 'Website banner',
  socialMedia: 'Social media',
  blogPost: 'Blog post',
  advertisement: 'Advertisement',
  print: 'Print',
  others: 'Others',
  othersOutputPlaceholder: 'Enter other types of output',
  whatIsPrimaryMessage: 'What is the primary message or theme you want the image to convey?',
  whatIsPrimaryMessagePlaceholder: 'Eg. Dog with fur running with woman in the park',
  areThereSubjectYouWantInThePicture:
    'Are there specific subjects or elements you want to be included in the image?',
  egPeopleNatureTechnologyAbstract: 'E.g. people, nature, technology, abstract concepts',
  areThereImageOrStyleReference:
    "Are there any images or styles you've seen before that you'd like us to use as inspiration or reference?",
  ifPeopleIncludeWhatTheirDemographic:
    'If people are to be included, what should their demographic be?',
  egAgeGenderEthnicityOccupation: 'E.g. age, gender, ethnicity, occupation',
  areThereAnyThingToAvoid:
    'Are there any elements, subjects, or themes you want to avoid in the image?',
  whoisYourTargetAudience: 'Who is your target audience?',
  whoisYourTargetAudiencePlaceholder: 'Eg. Young adults who love to read',
  typeOfImage: 'Type of image',
  whatMoodShouldImageEvoke: 'What mood or emotion should the image evoke?',
  happy: 'Happy',
  sad: 'Sad',
  calm: 'Calm',
  excited: 'Excited',
  angry: 'Angry',
  enterCustomMood: 'Enter custom mood',
  areThereAnyOrientationForImage: 'Are there any specific orientations needed for the image?',
  enterCustomOrientation: 'Enter custom orientation',
  isThereLocationYouWantImageToBeIn:
    'Is there a particular location or setting you want the image to be in?',
  no: 'No',
  yes: 'Yes',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  shareWithUsAReference: 'Share with us a reference (JPG or PDF only. Max 4MB)',
  areThereAnySpecificRequirements: 'Are there any other specific requirements or preferences?',
  enterOtherComments: 'Enter other comments',
  submit: 'Submit',
  replace: 'Replace',
  fileMustBeJpgOrPdf: 'File must be either a JPG or a PDF',
  maximumFileNameAllow255: 'The maximum file name allowed is 255',
  fileSizeLessThan4MB: 'File size must be less than 4MB',
  fieldCannotEmpty: 'This field cannot be empty.',
  imageResearchContentInfoDesc2: (
    <>
      Our team combines
      {' '}
      <strong>AI technology</strong>
      {' '}
      and experience to find ideal images for you
      and advanced algorithms narrow down options from our vast library based on your input. Our
      <strong>team of dedicated and experienced researchers</strong>
      {' '}
      refine the results to meet your
      criteria perfectly.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      We deliver results within
      {' '}
      <strong>2 working days</strong>
      , providing a comprehensive
      explanation for image selections. Focus on your business while we take care of the rest.
    </>
  ),

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'AI Image Generator',
  imageGenerator: 'Image Generator',
  aiImageGeneratorDesc: 'Delivering creativity at the speed of your imagination.',
  generate: 'Generate',
  exploreVisualsCreatedWithThePowerOfAI: 'Explore visuals created with the power of AI',
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Turn your imagination into reality in 3 simple steps',
  startWithGoodTextPrompt: (
    <>
      Start with a
      {' '}
      <span>
        good
        <br />
        text prompt
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'A prompt should include perspective, subject, action, keywords, lighting and style for the best results.',
  reviewYourAIGeneratedImages: (
    <>
      Review your
      {' '}
      <br />
      <span>AI-generated images</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Our AI generator will generate and display 3 preview images that most closely matches your prompt.',
  downloadAndLicenseVisuals: (
    <>
      Download
      {' '}
      <br />
      <span>high-quality visuals</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'You can now effortlessly download your custom AI-generated visuals in stunning high-resolution, giving you endless creative possibilities for your every need and purpose.',
  needSomeIdeas: 'Need some ideas? Start from these samples and regenerate your ideas!',
  tryPrompt: 'Try prompt',
  creativityAtTheSpeedOfYourImagination: 'Creativity at the speed of your imagination',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Generate your very own AI images from text prompts with Corporate+ in seconds!',
  tryNow: 'Try now',

  /// Random prompt

  prompt1: "Depict a confident, yet approachable 28-year-old Korean male singer with a captivating stage presence. Showcase his dynamic vocal abilities and magnetic charm through an expressive portrait that highlights his striking features. Render his thick, black hair and intense, emotive eyes in vivid detail, conveying the depth of his artistic passion. Dress him in stylish, contemporary attire that reflects his musical persona, perhaps with a touch of edginess or sophistication. Position him in a setting that suggests a live performance or recording studio, capturing the energy and excitement of his craft. Use dramatic lighting and framing to create a cinematic, larger-than-life quality, emphasizing his status as a rising star. Infuse the composition with a sense of movement and dynamism, as if he is in the midst of delivering a captivating vocal performance. Aim to craft an image that immediately grabs the viewer's attention and showcases the singer's undeniable stage presence and vocal talent.",
  prompt2: 'A photo-realistic image of a majestic lion lounging on a rock with the vast African savanna in the background. Use a camera with a 400mm lens at F 2.8 aperture setting to blur the background and focus sharply on the lion. The lighting should be natural and warm, capturing the golden hues of the sunset. Emphasize photorealism to create a vivid, detailed image that conveys the beauty and grandeur of the wildlife.',
  prompt3: 'A mixed media piece depicting a futuristic samurai in an action pose, wielding a glowing katana, set against a cyberpunk cityscape with neon lights and holograms. Incorporate a variety of textures and materials, including metallic and digital elements, to create a visually rich and dynamic scene.',
  prompt4: 'A cheerful digital illustration of a young princess in a flowing dress, playing with magical creatures in an enchanted forest. Surround her with colorful flowers, butterflies, and sparkling fairy dust, against a vibrant and whimsical background.',
  prompt5: "A detailed graphite drawing of an ancient warrior with a weathered face and a determined expression, holding a sword. Use intricate shading and textures to highlight the warrior's armor and battle scars, creating a timeless and powerful image.",
  prompt6: 'A surrealistic mixed media collage of a time-traveling explorer navigating through different historical eras, with elements like vintage maps, ancient artifacts, and futuristic gadgets. Use a combination of old newspaper clippings, photographs, and digital art to create a humorous and thought-provoking piece.',
  prompt7: 'A detailed digital illustration of a dystopian cityscape, with towering skyscrapers, flying cars, and dark, ominous clouds. Focus on creating a sense of depth and scale, with intricate details in the architecture and dramatic lighting to enhance the mood.',
  prompt8: 'A portrait of a wise elder holding a magical staff, captured in dramatic chiaroscuro lighting to accentuate the elder’s facial features and the staff’s glowing runes. Use rich textures and shadows to create a sense of mystery and wisdom.',
  prompt9: 'A vibrant fantasy illustration of a dragon rider soaring through the skies, with the rider clad in armor and the dragon’s wings spread wide. Surround them with clouds and distant mountains, adding a sense of adventure and majesty.',
  prompt10: 'A candid black and white street photograph of a bustling market scene in an Asian city, with vendors selling colorful fruits and vegetables. Use a shallow depth of field to focus on the vendor’s expressions and interactions, capturing the lively atmosphere.',
  prompt11: 'A whimsical watercolor painting of a unicorn with a rainbow mane, standing in a magical forest surrounded by glowing mushrooms and sparkling fireflies. Use soft, pastel colors and fluid brushstrokes to create a dreamy, enchanting scene.',
  prompt12: 'A 3D render of a modern kitchen with sleek, minimalist design, stainless steel appliances, and a large island in the center. Use photorealistic textures and lighting to highlight the clean lines and contemporary style, with a focus on the reflections and materials.',
  prompt13: 'A fantasy landscape painting of a floating island in the sky, with waterfalls cascading off the edges and lush vegetation covering the surface. Surround the island with fluffy clouds and a vibrant blue sky, creating a sense of wonder and magic.',
  prompt14: 'A detailed portrait of a Viking warrior with braided hair and a fierce expression, holding a battle axe. The background should depict a stormy sea and rugged cliffs, with dramatic lighting to highlight the warrior’s features and armor.',
  prompt15: 'A surrealistic mixed media collage of a steampunk inventor in a cluttered workshop filled with gears, clockwork mechanisms, and steam-powered contraptions. Use vintage photographs, old blueprints, and metallic textures to create a visually rich and imaginative scene.',
  prompt16: 'A digital illustration of a futuristic cityscape at night, with towering skyscrapers, neon signs, and flying vehicles. Use vibrant colors and dynamic lighting to create a lively and immersive atmosphere, emphasizing the technological advancements and bustling energy of the city.',
  prompt17: 'A photorealistic 3D render of a cozy reading nook with a comfortable armchair, a small table with a lamp, and shelves filled with books. The setting should have warm, inviting lighting and soft textures, capturing the serene and relaxing ambiance of the space.',
  prompt18: "Depict a transparent, glass-like fashion handbag with a fully functional aquarium built into the design. Meticulously render the delicate, swimming fish within the handbag, creating a mesmerizing and unconventional accessory. Ensure the aquarium is flawlessly integrated into the handbag's structure, with seamless transitions between the two elements. Position the handbag prominently within a high-end department store setting, surrounded by a backdrop of sleek, modern shelving, lighting, and other luxury retail displays. Utilize dramatic, directional lighting to create a sense of drama and captivate the viewer's attention, drawing their eye directly to the aquatic handbag. Capture the handbag from an angle that showcases its transparent, almost ethereal quality, allowing the fish to be clearly visible and creating an impression of weightlessness or fluidity.",
  prompt19: 'Capture a cozy, inviting scene featuring plush armchairs in a muted palette of brown and olive green tones. Showcase the chairs in a warm, intimate setting, surrounded by details that evoke a sense of relaxation and comfort, such as soft textiles, rich wood accents, and perhaps a glimpse of a fireplace or window overlooking a peaceful natural landscape. Emphasize the sumptuous, textural quality of the upholstery and the overall sense of coziness and retreat.',
  prompt20: "Capture a vibrant, lifelike depiction of a Crested Partridge perched atop a moss-covered branch, set against a lush, emerald green backdrop that complements the bird's stunning plumage. Render the partridge's intricate feathers with exquisite detail, using a subtle gradient of light and shadow to accentuate their textures. Highlight the partridge's rich, dark green crest, warm earthy-brown beak, and bright, inquisitive black eyes, creating a captivating portrait of the bird's natural beauty. Imbue the overall scene with a warm, tranquil atmosphere, inviting the viewer to step into the serene natural setting.",
  prompt21: 'Design a visually enticing image featuring a creamy, frozen yogurt bowl topped with a vibrant assortment of fresh fruits and crunchy nuts. Capture the refreshing and nutritious qualities of this healthy dessert, showcasing the contrast between the smooth, light-colored yogurt and the vibrant pops of color from the fruit toppings. Arrange the ingredients in an artful, appetizing manner, allowing the viewer to appreciate the visual appeal and anticipate the refreshing, flavorful experience. Ensure the overall image emanates a sense of wholesomeness and indulgence, inviting the viewer to savor the delightful and nourishing qualities of this delectable treat.',
  prompt22: 'Immerse yourself in a visual feast, exploring a surreal flowers garden bursting with vibrant colors and intricate details. Capture the captivating essence of blooms with curling, spiral petals, variegated foliage, and other mesmerizing hues - each one a work of art in its own right. Utilize the fluidity of watercolor and the expressiveness of ink, employing a wet-on-wet technique to create a sense of movement and depth. Convey the surreal, dreamlike quality of this floral wonderland through the interplay of rich, saturated colors and the delicate, intricate rendering of every petal and leaf.',
  prompt23: "Craft a captivating night scene illustration featuring an ancient lighthouse standing tall on a rocky island. The lighthouse, adorned with multiple windows, emits a warm, welcoming glow, highlighting its intricate architecture and rich history. The rocky island is covered in lush, verdant trees, their reflection dancing on the calm, moonlit sea waters that mirror the silvery radiance of the full moon. The celestial orb graces the sky, casting an ethereal glow on the clouds and sea, while wispy clouds partially obscure its brilliance, adding to the scene's mystique. Twinkling stars dot the inky expanse, creating a celestial tapestry that harmonizes with the moon's radiance. This enchanting artwork captures a serene and peaceful night, where the illuminated lighthouse stands resolute amidst the celestial beauty, serving as a sentinel and creating a cinematic, dreamlike experience for the viewer.",

  /// Tool list
  // ratio
  ratio: 'Ratio',
  model: 'Model',
  square1_1: 'Square (1:1)',
  square: 'Square',
  portrait2_3: 'Portrait (2:3)',
  portrait: 'Portrait',
  landscape3_2: 'Landscape (3:2)',
  wide16_9: 'Wide (16:9)',
  wide: 'Wide',
  // style
  none: 'None',
  enhance: 'Enhance',
  anime: 'Anime',
  photographic: 'Photographic',
  digitalArt: 'Digital art',
  comicBook: 'Comic book',
  fantasyArt: 'Fantasy art',
  analogFilm: 'Analog film',
  neonPunk: 'Neon punk',
  lowPoly: 'Low poly',
  origami: 'Origami',
  lineArt: 'Line art',
  tileTexture: 'Tile texture',
  threeDModel: '3D model',
  pixelArt: 'Pixel art',
  craftClay: 'Craft clay',
  colorpop: 'Colorpop',
  cybertech: 'Cybertech',
  darkArts: 'Dark arts',
  digitalPainting: 'Digital painting',
  fantasyIcons: 'Fantasy icons',
  glasscore: 'Glasscore',
  glowwave: 'Glowwave',
  psychedelicArt: 'Psychedelic art',
  simpleFlat: 'Simple flat',
  sparklecore: 'Sparklecore',
  // color
  coolTone: 'Cool tone',
  mutedColors: 'Muted colors',
  pastelColors: 'Pastel colors',
  vibrantColors: 'Vibrant colors',
  warmTone: 'Warm tone',
  // lighting
  lighting: 'Lighting',
  backlight: 'Backlight',
  crepuscularRays: 'Crepuscular rays',
  dimlyLit: 'Dimly lit',
  goldenHour: 'Golden hour',
  lowLight: 'Low light',
  rimLighting: 'Rim lighting',
  studio: 'Studio',
  sunlight: 'Sunlight',
  volumetric: 'Volumetric',
  // composition
  composition: 'Composition',
  blurryBackground: 'Blurry background',
  closeUp: 'Close up',
  macrophotograph: 'Macrophotography',
  narrowDepth: 'Narrow depth',
  shotFromAbove: 'Shot from above',
  shotFromBelow: 'Shot from below',
  wideAngle: 'Wide angle',

  /// FQAs
  allAIImageQuestionsAnswered: 'All your AI-generated image questions answered.',
  TTI_Q1: 'What are AI-generated images?',
  TTI_A1:
    'AI-generated images are images created using artificial intelligence (AI) algorithms. These algorithms are designed to simulate human creativity and can generate images similar to those produced by human artists but with unique and often surreal or abstract elements.',
  TTI_Q2: 'Is there a limit to how many images I can generate per day?',
  TTI_A2:
    'Yes, there is a limit to the number of images that you are able to generate daily. The limit ensures that all users would have fair access and a good user experience when accessing the AI image generation service.',
  TTI_Q3: 'Can we resell the photos generated by the Corporate+ Image Generator?',
  TTI_A3: (
    <>
      Yes, you can. Generating images using the
      {' '}
      <a id="ai-gen-page-link" className="TextLink TextLink--primary" href="#banner">
        Corporate+ Generator
      </a>
      {' '}
      will grant you the right to sell or commercialize them. The generated images shall be subject
      to copyright laws, intellectual property rights, and the terms and conditions set forth by the
      {' '}
      <a
        id="ai-gen-license"
        target="_blank"
        className="TextLink TextLink--primary"
        href="/license.php?type=123rf_ai"
      >
        Corporate+ Generator License
      </a>
      . For additional details please refer to the
      {' '}
      <a
        id="ai-gen-license"
        target="_blank"
        className="TextLink TextLink--primary"
        href="/license.php?type=123rf_ai"
      >
        Corporate+ Generator License
      </a>
      .
    </>
  ),
  TTI_Q4:
    'What is the difference between generating an image and downloading the generated output?',
  TTI_A4:
    'When you generate the output, you produce a collection of AI-generated images for viewing. However, by subscribing, you’ll gain the ability to download the high-resolution version of the AI-generated image for your specific use.',
  TTI_Q5:
    'An image I generated contains a recognizable trademark, landmark, or public personality. Can I still use it?',
  TTI_A5:
    'If it contains a recognizable person or object, or it primarily focuses on a distinctive landmark, you would have to secure permission directly from the party depicted in the image, especially when you intend to use the image in a commercial setting.',
  TTI_Q6: 'Who are the people that appear in generated images?',
  TTI_A6:
    'The people that appear in AI-generated images are not real people but rather digital creations.',
  TTI_Q7:
    'What happens with the content that I generated? Will they be made available to other users?',
  TTI_A7: ({ onClick }) => (
    <>
      The content that you generate may be made available to other users as well. You may refer to
      our
      {' '}
      <strong
        style={{ cursor: 'pointer' }}
        aria-hidden
        onClick={() => {
          onClick();
        }}
      >
        AI Generator Terms of Service
      </strong>
      {' '}
      for more information.
    </>
  ),
  TTI_Q8: 'Why does it take a while to load when I try generating an image?',
  TTI_A8:
    'The page will take a few seconds to generate the image. The time taken depends on the complexity of the prompt, the hardware and computing resources available, as well as the amount of request that the AI has to handle simultaneously.',
  TTI_Q9: 'How can I use the images I generated?',
  TTI_A9: ({ onClick }) => (
    <>
      You will have the capability to download high-resolution versions of the AI-generated images
      you've created and use them for any purpose. For more information, please refer to our
      {' '}
      <strong
        style={{ cursor: 'pointer' }}
        aria-hidden
        onClick={() => {
          onClick();
        }}
      >
        AI Generator Terms of Service
      </strong>
      .
    </>
  ),
  TTI_Q10: 'Can I view past generations?',
  TTI_A10:
    'Yes, you can do so by bookmarking and revisting the URL of your past generations. You may also share the results of the generations with your friends.',
  TTI_Q11: 'How do I generate high-quality images with AI?',
  TTI_A11: `<ol>
  <li>Start with a clear objective: "High-quality image of a mountain landscape for a travel blog."</li>
  <li>Specify the desired style and mood: "The image should be bright and vibrant with a natural, organic feel."</li>
  <li>Describe the subject matter: Be specific about the subject matter of the image, including details such as the setting, lighting, and any other important features or elements. For example, "Feature a snow-capped mountain peak with a winding river in the foreground and a clear blue sky in the background."</li>
  <li>Provide examples: Include examples of other images that have a similar style or mood to the one you are looking for. This can help guide the AI algorithm and ensure that the output meets your expectations.</li>
  <li>Use relevant keywords: "mountain landscape," "snow-capped peak," or "bright and vibrant."</li>
  <li>Avoid ambiguity: Be as clear and specific as possible when providing instructions, and avoid using vague or ambiguous language that could lead to unexpected results.</li>
</ol>
`,

  /// Generating page
  bringingYourImagesToLife: 'Bringing your images to life...',
  cplusAiGenerator: 'AI Generator',
  generatedImages: 'Generated images',
  promptSettings: 'Prompt settings',
  pastGeneration: 'Past generations',
  generateVariation: 'Generate variation',
  iLikeThis: 'I like this',
  iDislikeThis: 'I dislike this',
  shareOptions: 'Share options',
  variations: 'Variations',
  copiedSharedUrl: 'Copied shared URL to clipboard.',

  galleryPrompt1: 'Ethereal modest mermaid, underwater, seashell crown surrounded by fishes, portrait',
  galleryPrompt2: 'hearty sunday roast on table, full of food and candles',
  galleryPrompt3: 'Cute fluffy fox smiling in a forest, highly detailed, zoomed out with other forest animals',
  galleryPrompt4: 'high quality, 8K Ultra HD, musical notes instrument shapes inside an pumpkin made of crystal, high detailed, magical pumpkin lamp, autumn feel, calm',
  galleryPrompt5: 'Scotland panoramic cinematic landscape, bright beautiful day',
  galleryPrompt6: '3d style little girl, 4k, 8k, octane render photorealistic, hdr, photography, high definition, symmetrical face, volumetric lighting, dusty haze, photo, octane render, 24mm, 4k, 24mm, DSLR, high quality, 60 fps, ultra realistic',
  galleryPrompt7: 'brown-skinned guy, hero punching planet jupiter in space, facing front',
  galleryPrompt8: 'cookie in warm coffee, in a christmas enviroments',
  galleryPrompt9: 'Artistic water bottle on a white Minimalist background with Montserra leaves',
  galleryPrompt10: 'evanescent diamond fantasy dragon',
  galleryPrompt11: 'planets in dew drops, planetary dewdrops, at sunrise, close-up, hyper detailed, sharp focus, studio photo, intricate details, highly detailed',
  galleryPrompt12: 'a magpie sitting on a chest of gold, in the style of realistic fantasy artwork, gemstone, dark fairy tales, mysterious',
  galleryPrompt13: 'a mountain of snow and a trail of ice on top of the mountain going the other way, ultra hd, realistic, vivid colors, highly detailed, UHD drawing, pen and ink, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8k artistic photography, photorealistic concept art, soft natural volumetric cinematic perfect light',
  galleryPrompt14: 'Interstellar civilization era, standing high down overlooking the mysterious digital space super sci-fi alien city buildings, Exquisite beautiful exterior super skyscraper tower building, street, sky bridge, sky floating super sci-fi architecture, war machine, high-tech, countless electronic screen lights, Super Sci-Fi Visual Feast Space, Sci-Fi, Mystery, sci-fi, surreal, super-clear pictures, HD pixels, ultra-HD, 5D, 8K resolution, pixel perfect, perfect ultra-fine detail, sharp focus, studio photo, intricate details, highly detailed',
  galleryPrompt15: 'Beautiful beach with ocean in the background in the Florida Keys at night, neon colours, black background, calm waves, natural hue, sunset, calm, relaxing zoom in waves, beach, straightened horizon, pink, blue, orange, purple',
  galleryPrompt16: 'best quality, double exposure, mail art, art on a cracked paper, a close up cute kitten playing with a ball of wool detailed cozy room interior, glittering, sunlight, sunbeams, dynamic pose, storybook illustration, 2d, flat, cute, adorable, vintage, fairytale, patchwork, stained glass, storybook detailed illustration, cinematic, ultra highly detailed, tiny details, beautiful details, mystical, vibrant colors, complex background',

  // galleryPrompt17:
  //   'Pyramid on the water with fire reflections, mountains, dark surrealism, atmospheric portraits, sharp focus',
  // galleryPrompt18: 'Red panda in the forest, cinematic',
  // galleryPrompt19: 'Sea, moon, fish, starry sky, boat, colorful illustration',
  // galleryPrompt20:
  //   'Vibrant urban reflections dance admist bold buildings and shimmering water in minimalist abstract style, capturing the lively evening energy, geometric abstraction, contemporary pop art style with neon lighting and acrylic puring technique',
  // galleryPrompt21:
  //   'Back view of a silhouette woman with glowing sword, circular portal in the arctic leading towards to icebergs, cinematic medium shot, monumental figure',

  /// error page
  copyrightDetected: 'Copyrighted terms / personalities or trademarks detected',
  copyrightDetectedDesc:
    'We are committed to being a responsible AI generator and will not generate results for copyrighted trademarks, terms, brands or personalities included in prompts to prevent potential infringement. Our Terms of Service stipulates that you shall NOT use the services in a way that infringes, misappropriates or violates any intellectual property rights. Kindly amend your prompt and try again.',
  errorMesTTI: 'Oops! We’re sorry, but something went wrong.',
  errorMesTTIDesc: 'The issue has been logged for investigation. Please try again later.',
  tryAgain: 'Try again',
  adultContentTitle: 'Potential adult-content terms detected',
  adultContentDesc:
    'We are committed to responsible AI and therefore do not generate results for certain phrases, concepts and/or ideas. Please amend your prompt (the description you provided to generate the image) and try again.',

  pastGenerations: 'Past generations',
  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),
  generateTermService: 'AI Generator Terms of Service',
  titleSuccessDownload: 'We are happy you found what you needed.',
  hangInThere: 'Hang in there! It won’t take long.',
  reachedTheLimit: 'Oh no! You have reached the limit to generate AI images for today.',
  backTomorrow: 'Come back tomorrow for more generations!',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',

  // download feature
  downloadQuotaFinished: 'The download quota is finished',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Find stunning images. Search by keywords or phrase',
  searchBarPlaceholderAllImageTablet: 'Find stunning images',
  searchBarPlaceholderAllImageMobile: 'Find images',
  searchBarPlaceholderPhotoDesktop: 'Find stunning photos. Search by keywords or phrase',
  searchBarPlaceholderPhotoTablet: 'Find stunning photos',
  searchBarPlaceholderPhotoMobile: 'Find photos',
  searchBarPlaceholderVectorDesktop: 'Find stunning vectors. Search by keywords or phrase',
  searchBarPlaceholderVectorTablet: 'Find stunning vectors',
  searchBarPlaceholderVectorMobile: 'Find vectors',
  searchBarPlaceholderFootageDesktop: 'Find stunning video. Search by keywords or phrase',
  searchBarPlaceholderFootageTablet: 'Find stunning video',
  searchBarPlaceholderFootageMobile: 'Find video',
  searchBarPlaceholderAudioDesktop:
    'Find stunning audio and music files. Search by keywords or phrase',
  searchBarPlaceholderAudioTablet: 'Find stunning audio and music files',
  searchBarPlaceholderAudioMobile: 'Find audio and music files',
  searchBarPlaceholderFreeDesktop: 'Find stunning free images. Search by keywords or phrase',
  searchBarPlaceholderFreeTablet: 'Find stunning free images',
  searchBarPlaceholderFreeMobile: 'Find free images',
  // editorial warning
  forEditorialUseOnly: 'For Editorial Use Only',
  aiGenarated: 'AI-generated image',
  forEditorialUseOnlyDesc:
    'Editorial Use Only: Use of this image in advertising or for promotional purposes is prohibited unless additional clearances are secured.',

  textToImage: 'Text to image',
  descTourTTI: 'Let your imagination fly and create images with text!',

  colorAndTone: 'Color and tone',
  aiPromptPlaceholder: 'To get started, describe your vision here, and we’ll bring it to life!',

  fluxPro: 'Flux offers advanced, high-quality, and varied image generation.',
  flux: 'Our quickest model, tailored for local development.',
  ideogram: 'Turning a text description into beautiful images in a matter of seconds.',
  variousTitle: 'Discover our various models',
  variousSDXL: 'Bring stunning photorealistic people and scenes to life.',
  variousEimisAnime: 'Generative AI model designed for creating anime-style images.',
  variousDynaVisionXL: 'Generate 3D stylised cartoon characters.',
  variousJuggernautXL: 'Centred around architectural elements and landscapes/backgrounds.',
  variousDreamShaperXL:
    'High-res upscaling, improved characters and dragons generations, reduced edge blur.',
  variousRealismEngineSDXL: 'This model specialises in highly detailed, realistic images.',
  variousDALLE:
    'DALL·E 3, an all rounder model excels at precise concept-to-visual transformations.',
  realStockPhoto: 'Create captivating stock photos effortlessly with our advanced AI model.',
  realCinematic: 'A model specialized in producing cinematic outputs, excelling with wider aspect ratios.',
  realFood: 'Enhance your culinary brand with food visualization model.',
  realMacro: 'A Great model for generating detailed macro images.',
  realBokeh: 'A photorealistic style model, perfect for creating stunning bokeh images.',
  aiLicenseHeader: 'This file was generated by AI (Artificial Intelligence).',
  aiLicenseContent: 'Content generated by artificial intelligence is not subject to 123RF\'s standard compliance review process. It is the responsibility of the creator to ensure that such AI-generated materials do not infringe upon or violate any third-party intellectual property or other rights. The production of spam, along with any content that is false, misleading, harmful, or depicts violence, as well as pornographic or illegal materials, is strictly against our policies.',
  aiEngine: 'AI Engine',
  'DALL-E': 'DALL-E 3',
  variousOpenJourney: "AI Model generates art resembling Midjourney's distinctive style.",
  variousTurboVision: 'High-speed generation at "normal" XL resolutions, enhancing overall quality.',
  variousRealVisXL: 'Model targets achieving photorealistic results with precision and accuracy.',
  variousPortraitPlus: 'Model with uniform portrait composition, ideal for 1:1 aspect ratio.',

  langText: 'Text',
  langElements: 'Elements',
  langAuto: 'Auto',
  langBgRemoval: 'Background removal',
  pinterestPost: 'Pinterest post',
  linkedinPost: 'Linkedin post',
  postcard: 'Postcard',
  langApplyBgRemoval: 'Apply Background Removal',
  autoBnW: 'Auto B/W',
  autoAdjust: 'Auto Adjust',
  autoPop: 'Auto Pop',
  original: 'Original',
  addHeader: 'Add a header',
  addTitle: 'Add a title',
  addBody: 'Add a body text',
  addAssistive: 'Add an assistive text',
  langCompare: 'Compare',
  langEnableEditor: 'Edit image',
  langSimilarStock: 'Similar stock images',
  langTextUndo: 'Undo',
  langTextRedo: 'Redo',
  langTextFont: 'Font',
  langTextFontSize: 'Font size',
  langTextColor: 'Text color',
  langTextHighlight: 'Highlight',
  langTextAlignment: 'Alignment',
  langTextBold: 'Bold',
  langTextItalic: 'Italic',
  langTextUnderline: 'Underline',
  langTextLineSpacing: 'Line spacing',
  langTextLetterSpacing: 'Letter spacing',
  langBringForward: 'Bring to front',
  langBringBackward: 'Bring to back',
  langDuplicate: 'Duplicate',
  langOpacity: 'Opacity',
  langDelete: 'Delete',
  noEditCompareMsg: 'No edits were made to compare.',
  stockPhoto: 'Stock Photo',
  basicShape: 'Basic shapes',
  chitChat: 'Chit chat',
  shapes: 'Shapes',
  elementChitChat: 'Element Chit Chat',
  organicAbstractShapes: 'Organic abstract shapes',
  elementShapes: 'Element shapes',
  langApplyingEdit: 'Applying Edit...',
  langTextAllFonts: 'All Fonts',
  langSearchFontType: 'Search Font Type',
  langSizePx:
    'A pixel is the basic unit of a digital image. Image size, measured in pixels, determines the detail level and download size.',
  langSizeDpi:
    'Dots per inch measures print resolution. Higher dpi means more detail. For printing, the recommended resolution for all images is 300 dpi',
  cautionTTI:
    'Caution : We detected the use of phrases in the prompt that are associated with trademarks, copyrights, famous landmarks and/or individuals.  Do note that You are solely responsible for the usage of the Content, including that it does not violate any applicable law or infringe any rights.',

  addImageReference: 'Add reference image (Optional)',
  uploadImageOnly: 'Upload JPG or PNG only. Max 4MB.',
  uploadJPGImageOnly: 'Upload JPG only. Max 4MB.',
  dragAndDrop: 'Drag and drop file or',
  errorFileSize: 'This file size is too big. Maximum of 4MB allowed.',
  adjustScale: 'Adjust this scale to generate very similar or different results from your uploaded image. The recommended range is 7 to 15.',
  similar: 'Similar',
  different: 'Different',
  uploadNew: 'Upload new',

  imageGuideGeneration: 'Your image will guide your prompt during generation.',
  notAvailableForEngine: 'Not available for selected AI Engine.',
  imagePromptDescribe: "To begin, provide a prompt of the image you want our AI to generate by describing what's in your mind.",
  fileTypeNotSp: 'The file type is not supported.',
  theFileSizeTooSmall: 'The file size is too small. Minimum of 512px allowed.',

  editor: 'Editor',
  relatedSearchPhoto: 'Related Stock Photo Searches',
  STOCK_PHOTO_PATH: 'stock-photo',
  STOCK_FOOTAGE_PATH: 'stock-footage',
  STOCK_AUDIO_PATH: 'stock-audio',
  CLIPART_VECTOR_PATH: 'clipart-vector',
  ALL_IMAGES: 'All Images',
  PHOTO: 'Photo',
  PHOTOS: 'Photos',
  VECTORS: 'Vectors',
  FOOTAGE: 'Footage',
  AUDIO: 'Audio',
  LANG_MORETHANMILLION: 'Royalty Free Stock Photos',
  LANG_SVG_OR_EPS: 'SVG or EPS',
  LANG_OR: 'Or',
  STANDARD_LICENSE: 'Standard License',
  EXTENDED_LICENSE: 'Extended license',
  CREDIT_PACK: 'Credit Pack',
  DOWNLOAD_PACK: 'Download Pack',
  SUBSCRIPTION_PLAN: 'Subscription Plan',
  LANG_DOWNLOAD: 'Download',
  STOCKPHOTO: 'Stock Photo',
  STOCK_PHOTO: 'Stock Photo',
  STOCK_VECTOR: 'Stock Vector',
  PREMIUM_STOCKPHOTO: 'PREMIUM Stock Photo',
  PLUS_STOCK_PHOTO: 'PLUS Stock Photo',
  PLUS_STOCK_VECTOR: 'PLUS Stock Vector',
  MOBILE_STOCKPHOTO: 'Mobile Stock Photo',
  STOCKFOOTAGE: 'Stock Footage',
  IMAGEID: 'Image ID',
  FONTID: 'ID',
  MEDIATYPE: 'Media type',
  COPYRIGHT_TEXT: 'Copyright',
  KEYWORDS: 'Keywords',
  LANG_CANCEL: 'Cancel',
  LANG_PURCHASE: 'Purchase',
  LANG_STOCKVECTORSANDILLUS: 'Stock Vectors, Clipart and Illustrations',
  LANG_STOCKPHOTOSANDIMAGES: 'Stock Photos And Images',
  LANG_MATCHES: 'matches',
  LANG_VECTORRESULTS: '{number} {keyword} royalty-free vector images found for you.',
  LANG_PHOTORESULTS: '{number} {keyword} royalty-free stock photos and images found for you.',
  LANG_SIMILARIMGS: 'Similar Royalty-Free Photos',
  LANG_COLLECTIONS: 'Collections',
  LANG_ALL: 'All',
  LANG_STD: 'Standard',
  LANG_EXTD: 'Extended',
  LANG_COMMERCIAL: 'Commercial',
  LANG_SUBSCRIPTION: 'Subscription',
  LANG_EDITORIAL: 'Editorial',
  LANG_UPLOADED: 'Uploaded',
  LANG_FRESHNESS: 'Freshness',
  LANG_ANYTIME: 'Anytime',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '< 2 days',
  LANG_PASTWEEK: 'Last week',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/month",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/year",
  LANG_PASTMONTH: 'Last month',
  LANG_PAST3MONTHS: '3 months',
  LANG_NUMOFPPL: 'Number of people',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: 'Did not receive the Verification Email?',
  LANG_WITHOUTPEOPLE: 'Without People',
  LANG_INFANTS: 'Infants',
  LANG_TODDLERS: 'Toddlers',
  LANG_TEENS: 'Teens',
  LANG_PERSON: 'person',
  LANG_PEOPLE: 'People',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'Caucasian',
  LANG_BLACK: 'Black',
  LANG_HISPANIC: 'Hispanic',
  LANG_EAST_ASIAN: 'East Asian',
  LANG_SOUTHEAST_ASIAN: 'Southeast Asian',
  LANG_INDIAN: 'Indian',
  LANG_MIDDLE_EASTERN: 'Middle Eastern',
  LANG_AGE: 'Age',
  LANG_ALL_SHAPE: 'See All',
  LANG_ETHNICITY: 'Ethnicity',
  LANG_PEOPLEORMORE: '4 people or more',
  LANG_COLOR: 'Color',
  LANG_OTHEROPTION: 'Other Options',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Solid/Transparent Backgrounds',
  LANG_FILTER_BY: 'Filter by',
  LANG_FILTER: 'Filter',
  LANG_PHOTOGRAPHY: 'Photography',
  LANG_VECTORILLUST: 'Vector Illustration',
  LANG_SORTBY: 'Sort by',
  LANG_MORE: 'More',
  LANG_RELEVANCE: 'Relevance',
  LANG_RESEND: 'Resend',
  LANG_RESEND_VERIFICATION: 'Resend Verification e-mail',
  LANG_NEWEST: 'New',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: 'Orientation',
  LANG_HORIZONTAL: 'Horizontal',
  LANG_PORTRAIT: 'Portrait',
  LANG_SQUARE: 'Square',
  LANG_PANORAMA: 'Panorama',
  LANG_SELECTIVE_FOCUS: 'Selective Focus',
  LANG_PATTERN: 'Pattern',
  LANG_VIBRANCE: 'Vibrance',
  LANG_VERIFICATION_DESCRIPTION: 'You would need to verify your account before downloading content from 123RF. Kindly check your email or click on the link provided below if you would like to resend it.',
  LANG_VERIFICATION_TITLE: 'Please verify your email.',
  VERIFICATIONRESETUNABLE: 'Oops! Your email was unable to send. Please contact our ',
  LANG_CHOOSEUPTO3COLORS: 'Max 3 colors',
  LANG_ISOLATED: 'Isolated',
  LANG_STYLE: 'Style',
  LANG_SAFESEARCH: 'Safe Search',
  LANG_ONLY_AUTHENTIC: 'Only Authentic',
  LANG_EXCLUDE_PLACEHOLDER: 'ex. cheese',
  LANG_EXCLUDEWORDS: 'Do not include these words',
  LANG_EXCLUDE: 'Exclude',
  LANG_INCLUDE_AICONTENT: 'Include AI-generated content',
  VISUAL_SEARCH: 'Visual Search',
  VISUAL_SEARCH_TOOLTIP_TITLE: '3 Easy Ways to Customize a Search within an Image!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1. Click on the dots of an image to identify your search item.',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. Drag the crop function box to zoom in or reduce the area of search.',
  VISUAL_SEARCH_TOOLTIP_INFO_3: "3. Click 'X' to return to the search",
  VISUAL_SEARCH_NO_RESULTS: 'Your search did not return any results.',
  LANG_RIS_TITLE: 'Reverse Image Search',
  LANG_RIS_NOTFOUND: 'No Result Found!',
  LANG_RIS_ERROR_HEADER: 'Uploaded Image contains errors, make sure the image has the following criteria:',
  LANG_RIS_REFINE_SRCH_MSG: 'Check your refine search with keyword',
  LANG_RIS_UPLOADED_IMAGE: 'Uploaded Image',
  LANG_ENTERKEYWORD: 'Please enter a keyword',
  LANG_SIZE: 'Size',
  LANG_STANDARD_SIZE: 'Standard Sizes',
  LANG_EXTENDED_SIZE: 'Extended Sizes',
  LANG_SIZE_PX: 'A pixel is the basic unit of a digital image. Image size, measured in pixels, determines the detail level and download size.',
  LANG_SIZE_DPI: 'Dots per inch measures print resolution. Higher dpi means more detail. For printing, the recommended resolution for all images is 300 dpi',
  LANG_SOCMED_SIZE: 'Social media sizes',
  LANG_COMPARE: 'Compare',
  LANG_AUTO_ENHANCE: 'Auto enhance',
  LANG_AUTO: 'Auto',
  LANG_BG_REMOVAL: 'Background removal',
  LANG_TEXT: 'Text',
  LANG_SHAPES: 'Shapes',
  LANG_ELEMENTS: 'Elements',
  LANG_IMAGE_INFO: 'Image information',
  LANG_FOLLOW: 'Follow',
  LANG_FOLLOWED: 'Followed',
  LANG_FOLLOWING: 'Following',
  FACEBOOK_IMAGE: 'Facebook image',
  FACEBOOK_LINK: 'Facebook link',
  FACEBOOK_COVER: 'Facebook cover',
  FACEBOOK_STORY: 'Facebook story',
  INSTAGRAM_POST: 'Instagram post',
  INSTAGRAM_STORY: 'Instagram story',
  TWITTER_POST: 'Twitter post',
  TWITTER_HEADER: 'Twitter header',
  PINTEREST_POST: 'Pinterest post',
  EMAIL_HEADER: 'Email header',
  PRESENTATION: 'Presentation',
  PRESENTATION_WIDE: 'Presentation wide',
  EBOOK_COVER: 'E-book cover',
  LINKEDIN_POST: 'LinkedIn post',
  BROCHURE_COVER: 'Brochure cover (A4)',
  POSTCARD: 'Postcard',
  PHOTO_PRINT: 'Photo print',
  YOUTUBE_PROFILE_IMAGE: 'YouTube profile image',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'YouTube channel cover photo',
  AUTO_COLOR: 'Auto-colour',
  BALANCE: 'Balance',
  AUTO_LEVEL: 'Auto-level',
  AUTO_TONE: 'Auto-tone',
  ENRICH: 'Enrich',
  RESET: 'Reset',
  RESET_ALL: 'Reset All',
  FILE_DOWNLOAD_OPTIONS: 'File download options',
  FILE_DOWNLOAD_INITIATED: 'File download initiated',
  IMAGE_PROPERTIES: 'Image properties',
  DOWNLOAD_DETAILS: 'Download Details',
  FILE_SIZE: 'File size',
  LICENSE: 'License',
  FILEFORMAT: 'File Format',
  CHANGE: 'Change',
  PRICING: 'Pricing',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Save as',
  IMAGE_USAGE: 'Image usage',
  PRINT: 'Print',
  ELECTRONIC: 'Electronic',
  COMPREHENSIVE: 'Comprehensive',
  DOWNLOAD_CREDITS: '<b>{quota} Download Credits</b> from Credit Pack',
  BASIC_SHAPE: 'Basic Shapes',
  CHIT_CHAT: 'Chit Chat',
  SHAPES: 'Shapes',
  ELEMENT_CHIT_CHAT: 'Element Chit Chat',
  ORGANIC_ABSTRACT_SHAPES: 'Organic Abstract Shapes',
  ELEMENT_SHAPES: 'Element Shapes',
  BUY_SINGLE_IMAGE: 'Buy single image <br/><b>{quota} credits</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Buy credit pack <br/><b>{quota} credits</b>',
  BUY_CREDIT_PACK: 'Buy credit pack and <br/><b>save more</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota} Credits Pack at <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'No monthly commitment.',
  BUY_CREDIT_PACK_POINT_3: '<b>1-year</b> validity.',
  BUY_SINGLE_IMAGE_PSUB: 'Buy single image <br/><b>{quota} download slot</b>',
  BUY_DOWNLOAD_PACK: 'Buy download pack and <br/><b>save more</b>',
  BUY_DOWNLOAD_PACK_POINT_1: '{quota} images download pack at <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'No monthly commitment.',
  BUY_DOWNLOAD_PACK_POINT_3: '<b>1-year</b> validity.',
  LOWEST_PRICE: '<b>Lowest price</b> <br/>with Subscription Plan',
  LOWEST_PRICE_POINT_1: 'Try 1-month at <b>{price}</b>',
  LOWEST_PRICE_POINT_2: 'Download 10 photos or vectors.',
  LOWEST_PRICE_POINT_3: 'No daily download limit, unused downloads rollover to next month.',
  PER_IMAGE_SIZE: 'per image <b>{size} size</b>',
  PER_IMAGE_ANY: 'per image <b>any size</b>',
  PER_IMAGE: 'per image',
  VIEW_PRICING: 'View more plans and pricing',
  DOWNLOAD_BEGIN: 'Your download will begin in a moment. Please wait.',
  DOWNLOAD_NOT_STARTING: 'Download not starting?',
  SELECT_MIRROR: '[Select a mirror]',
  SHOW_MORE: 'Show More',
  SHOW_LESS: 'Show Less',
  LICENSE_TYPE: 'License type',
  LICENSE_SUMMARY: 'License Summary',
  BACK: 'Back',
  LANG_VECTOR: 'Vector',
  PREMIUM_VECTOR: 'PREMIUM Stock Vector',
  SCALE_ANY_SIZE: 'Scale to any size',
  LANDINGPAGE_VECTOR_HEADLINE: 'Stock Vectors - Royalty Free Illustrations, Cliparts and Graphics',
  LANDINGPAGE_PHOTO_HEADLINE: 'Stock Photography - Royalty Free Photos, Images and Pictures',
  CATEGORIES: 'Categories',
  LANDINGPAGE_PRICING_1: 'On Demand',
  LANDINGPAGE_PRICING_2: 'Buy Subscription',
  LANDINGPAGE_AS_LOW_AS: 'From as low as',
  EMPTY_SEARCH_TITLE: 'Oops, your search did not return any results.',
  LANG_SMALL: 'Small',
  LANG_MEDIUM: 'Medium',
  LANG_LARGE: 'Large',
  LANG_EXTRA_LARGE: 'Extra Large',
  LANG_LOW_CREDITS: "It looks like you're running low on download credits!",
  LANG_TOPUP: 'Top up',
  LANG_TOPUP_NOW: 'Top up now',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} Stock Photo, Picture and Royalty Free Image. Image {mediaId}.',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} Royalty Free SVG, Cliparts, Vectors, and Stock Illustration. Image {mediaId}.',
  SEO_DETAILS_METADESC_STOCKPHOTO: 'Picture of {description} stock photo, images and stock photography. Image {mediaId}.',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Illustration of {description} vector art, clipart and stock vectors. Image {mediaId}.',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Free Image and Photograph {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Free picture of {description}. Free Image {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Free Vector and graphic {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'Free vector of {description}. Free graphic {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF - Millions of Creative Stock Photos, Vectors, Videos and Music Files For Your Inspiration and Projects.',
  LIVECHAT_LOOKINGHELP: 'Would you like some help?',
  LIVECHAT_CHATWITHUS: 'CHAT WITH US!',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: 'Related Searches',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'Related Stock Photo Searches',
  LANG_PROPERATTRIBUTE: "Here's our attribution:",
  LANG_ATTRIBUTEDESC: 'Please attribute this image to its photographer by placing this credit line below in any area within your product, website or collateral.',
  LANG_ATTRIBUTE_TITLE: 'Attribution is optional.',
  LANG_ATTRIBUTE_CREDIT: 'If you wish to attribute the creator, please use the credit line below:',
  LANG_ATTRIBUTE_COPYLINK: 'Copy link',
  LANG_ATTRIBUTE_COPYTEXT: 'Copy text',
  LANG_LICENSE_AGREEMENT: "<a href={url}><b>Here's</b></a> the license agreement for this download",
  LANG_NEXTPAGE: 'Next page',
  LANG_IMAGE_NO_AVAILABLE: 'The image #{mediaId} is no longer available.',
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Search and download from millions of HD stock photos, royalty free images and pictures',
  LANG_VECTOR_METAIMG_DESC: 'Search and download from millions of HD stock photos, royalty free images and pictures',
  LANG_ANOTHERMETAKWDEFAULT: 'royalty free, stock photo, stock photography, stock images, pictures, photographs',
  LANG_ANOTHERMETADESCDEFAULT: 'Search and download from millions of HD stock photos, royalty free images, cliparts, vectors and illustrations',
  EMPTY_FILTERED_RESULT_TITLE: 'Oops, your search with those filters did not return with any results.',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Try remove some filters or try a different keyword.',
  CLEAR_ALL_FILTER: 'Clear all filters',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Massive Markdowns on all Subscriptions.',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'View Plans',
  LANG_MODEL_RELEASED: 'Model Released',
  LANG_PROPERTY_RELEASED: 'Property Released',
  LANG_MODEL_RELEASE: 'with Model Release',
  LANG_PROPERTY_RELEASE: 'with Property Release',
  LANG_YES: 'Yes',
  LANG_NO: 'No',
  LANG_MREXPLAIN: 'A model release, is a legal document signed by the model(s) of a photograph granting permission to publish / distribute / use his or her likeness in the photograph in one form or another.',
  LANG_PREXPLAIN: 'A property release, is a legal document signed by the owner of the object, subject or premise at which a photograph was taken, granting permission to publish / distribute / use the photograph in one form or another.',
  LANG_DOWNLOADS: 'Downloads',
  LANG_OLDFILENAME: 'Old filename',
  LANG_DOWNLOAD_PREVIEW: 'Download Preview',
  LANG_SHARE: 'Share',
  LANG_RESTRICTIONS: 'Restrictions',
  LANG_EDITORIAL_USE: 'For Editorial Use Only',
  LANG_EDITORIALDESCP: 'Editorial Use Only: This image can only be used for editorial purpose. Use of this image in advertising, commercial or for promotional purposes is prohibited unless additional clearances are secured by the licensee. 123RF.com does not provide any clearance services.',
  LANG_LOGO_OWNERSHIP: 'Logo Ownership Rights',
  LANG_PHOTO_SHOT_SMARTPHONE: 'This photo was shot with a smartphone.',
  LANG_VECTORNOLOGO: 'If you wish to use this as your company logo, we may arrange for a <b>rights buy out</b> on your behalf. Please contact us at <b>{phonenum}</b> to find out more!',
  LANG_DOWNLOAD_QUOTA: '<b>{quota} Download Quota</b> from Subscription Plan',
  LANG_DOWNLOAD_SLOT: '<b>{quota} Download Slots</b> from Download Packs',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: 'Are you using an older browser?',
  UPGRADE_NEWER_BROWSER: 'Upgrade to a newer browser version to enjoy a smoother experience!',
  CLICK_TO_DOWNLOAD: 'Click to download any of these:',
  LANG_VECTOR_EDITABLE: 'Editable files in SVG and EPS',
  PROMOBAR_GOOD15_TEXT1: 'Pay less, download even more. ',
  PROMOBAR_GOOD15_TEXT2: '15% off subscription plans with <b>promo code</b>:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Pay less, download even more. ',
  PROMOBAR_SUMMER25_TEXT2: '25% off ALL plans with <b>promo code</b>:',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '30% off ALL plans with <b>promo code</b>:',
  NO_EDIT_COMPARE_MSG: 'No edits were made to compare.',
  SWITCH_TO_VECTOR_MSG: 'Image editor is not supported for Vector size images. All edits will be lost if you switch to Vector format.',
  SWITCH_TO_XL_MSG: 'Image editor is not supported for XL size images. All edits will be lost if you switch to XL format.',
  UNAVAILABLE_FOR_THIS_SIZE: 'Unavailable for {size} size.',
  SWITCH: 'SWITCH',
  SWITCH_LICENSE_EXT_MSG: 'Would you like to download this image first as a {type}? You will lose all edits if you switch to Extended License.',
  LANG_SESSION_EXPIRED: 'Your session has expired. Please <a href={url}>login here</a>',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_COLOR: 'Text color',
  LANG_TEXT_HIGHLIGHT: 'Highlight',
  LANG_TEXT_ALIGNMENT: 'Alignment',
  LANG_TEXT_BOLD: 'Bold',
  LANG_TEXT_ITALIC: 'Italic',
  LANG_TEXT_UNDERLINE: 'Underline',
  LANG_TEXT_BULLET: 'Bullet',
  LANG_TEXT_LINE_SPACING: 'Line Spacing',
  LANG_TEXT_LETTER_SPACING: 'Letter Spacing',
  LANG_BRING_FORWARD: 'Bring to front',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_BACKWARD: 'Bring to back',
  LANG_DUPLICATE: 'Duplicate',
  LANG_OPACITY: 'Opacity',
  LANG_DELETE: 'Delete',
  LANG_TEXT_ADD: 'Add Text',
  LANG_TEXT_EDIT: 'Edit Text',
  LANG_TEXT_FONT_TYPE: 'Font Type',
  LANG_TEXT_FONT_SIZE: 'Font Size',
  LANG_TEXT_ALL_FONTS: 'All Fonts',
  LANG_TEXT_UNDO: 'Undo',
  LANG_TEXT_REDO: 'Redo',
  LANG_READ_MORE: 'Read more',
  LANG_TEXT_FONT: 'Font',
  LANG_TEXT_OPACITY: 'Opacity',
  LANG_SEARCH_FONT_TYPE: 'Search Font Type',
  LANG_TELL_ME_MORE: 'Tell me more',
  LANG_SEARCH_SHAPE_TYPE: 'Search Elements',
  LANG_DONE: 'Done',
  ADD_HEADER: 'Add a header',
  ADD_TITLE: 'Add a title',
  ADD_BODY: 'Add a body text',
  ADD_ASSISTIVE: 'Add an assistive text',
  STOCK_PHOTO_BANNER_TITLE: 'Millions of royalty free stock images to create everything!',
  STOCK_PHOTO_BANNER_DESC: 'Find all the stunning imagery you need to create scroll-stopping visuals to illustrate your big ideas to life now.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Visualize your stories with the right imagery',
  STOCK_PHOTO_SUBSCRIBE_DESC: "Get instant access to hundreds of millions of images to empower your storytelling now. Our visuals and your story, it's the perfect combination to maximize your creative potential!",
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Download Pack',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'For as low as',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Subscription Plan',
  STOCK_PHOTO_CARD_BUY_NOW: 'Buy now',
  STOCK_PHOTO_CARD_IMAGE: 'image',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Subscribe now',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Popular royalty free image categories',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Discover affordable plans and pricing for photos',
  STOCK_PHOTO_DISCOVER_NOW: 'Check it out',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Landscape',
  STOCK_PHOTO_BABIES: 'Babies',
  STOCK_PHOTO_BUSINESS: 'Business',
  STOCK_PHOTO_FOOD: 'Food',
  STOCK_PHOTO_ANIMALS: 'Animals',
  STOCK_PHOTO_TECHNOLOGY: 'Technology',
  STOCK_PHOTO_HEALTHCARE: 'Healthcare',
  STOCK_PHOTO_NATURE: 'Nature',
  STOCK_PHOTO_ROMANCE: 'Romance',
  FAQ_RELATED: 'Related: ',
  FAQ_PHOTO_TITLE: 'Create the best artwork with 123RF images',
  FAQ_PHOTO_DESCRIPTION: 'Discover our diverse collections of high-quality and royalty-free stock photos, editorial images, and photographs - all in high resolution! Get the best images you need in one place.',
  FAQ_VECTOR_TITLE: 'Create something better with 123RF vectors',
  FAQ_VECTOR_DESCRIPTION: 'Explore our diverse collections of Royalty-free vector graphics, vector designs, vector images, vector art, clipart and illustrations. All vectors you need in one place!',
  FAQ_FOOTER: 'Want to learn more about 123RF stock vectors?',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Create with millions of royalty free vectors at your fingertips.',
  CLIPART_VECTOR_NEED_AN_EYE: 'Find cool vector illustrations, infographics, clipart, icons and so much more that help transform your amazing ideas into reality.',
  CLIPART_VECTOR_FIND_VECTOR: 'Find the right vectors to spice up your projects.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: "Whether it's cool infographics, illustrations, cartoons, clipart, backgrounds, icons or whatever you need to create stunning visuals; we’ve got them right here. Vectorize your designs to life now!",
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Popular royalty free vector categories',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Discover affordable plans and pricing for vectors',
  CLIPART_VECTOR_DISCOVER_NOW: 'Discover Now',
  SPEND_AND_WIN_SUBTITLE: 'Every {currency} spent = 1 Entry',
  SPEND_AND_WIN_IMGALT: 'Spend & Win an iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: 'Graphic',
  CLIPART_VECTOR_ADVERTISING: 'Advertising',
  CLIPART_VECTOR_FLOWER: 'Flower',
  CLIPART_VECTOR_ABSTRACT: 'Abstract',
  CLIPART_VECTOR_POP: 'Pop',
  CLIPART_VECTOR_BIRD: 'Bird',
  CLIPART_VECTOR_ILLUSTRATION: 'Illustration',
  CLIPART_VECTOR_PIXEL: 'Pixel',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'Vector, SVG, and EPS',
  FREE_UPGRADE: 'Free Upgrade',
  FREE_UPGRADE_MESSAGE: 'FREE XL upgrade for the entire subscription or download pack. Purchase now.',
  LANG_FREE_IMAGE: '123RF Free',
  LANG_IMAGE_FREE: 'This stock photo is free for commercial and personal use. Attribution is required.',
  LANG_HOW_TO_ATTRIBUTE: 'How do I attribute?',
  LANG_FREE_DOWNLOAD: 'Free Download',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Download',
  LANG_GO_PLUS_BUTTON: 'Go PLUS',
  LANG_ATTRIBUTION_IS_KEY: 'Attribution is an important element to the content creators who make their work publically accessible. Our content creators appreciate the exposure gained from their creations being used, shared and credited by the millions of users like you.',
  LANG_GET_ATTRIBUTION_LINK: 'Copy this link and place it in a location close to where content is used.',
  LANG_CREDIT_OWNER: 'Please credit the Creator:',
  LANG_USE_ATTRIBUTION: 'Please use the attribution link below.',
  LANG_ATTRIBUTION_LOCATION: "If this isn't doable, other good locations are at the footer of the website, blog, or newsletter or even a specific credits section of the site will be sufficient to credit the owner of the {media_type}.",
  LANG_GOT_A_QUESTION: "Have a question? <a href={url} target='_blank'><u>Contact us</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'You would need to credit the owner for this free image.',
  LANG_ATTR_FREE_DOWNLOADING: 'Hang in there! It won’t take long.',
  LANG_PLUS_CONTENT_1: 'You’ll get unlimited downloads',
  LANG_PLUS_CONTENT_2: 'Access to 100m+ photos and vectors',
  LANG_PLUS_CONTENT_3: 'Exclusive 3D Illustrations only at 123RF!',
  LANG_PLUS_CONTENT_4: 'Unlimited Downloads',
  LANG_PLUS_CONTENT_5: 'Unlimited Creative Freedom',
  LANG_ASK_DOWNLOAD_PLUS: 'Want to download this PLUS content?',
  LANG_BENEFIT_PLUS: 'Benefit from our PLUS plan from only ',
  LANG_SIGN_UP_PLUS: 'Sign up for our 123RF PLUS plan from as low as',
  LANG_FOR_ONLY: "<span class='text'>for only</span> <span class='price'>{price}</span>/month",
  LANG_FOR_ONLY_2: "<span class='text'>for only</span> <span class='price'>{price}</span>",
  FREE_VECTOR: 'FREE Stock Vector',
  FREE_STOCKPHOTO: 'FREE Stock Photo',
  FREE_PUBLIC_DOMAIN_CC0: 'Public Domain (CC0)',
  BUY_5_DOWNLOAD_PACK: "<b>Limited time offer</b><br/>3 + <b><span style='color:red'>2 FREE</span></b> = 5 photos or vectors",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'Download any time (up to a year)',
  BUY_5_GET_2_FREE: 'Get 2 FREE!',
  LANG_RELEASES: 'Releases',
  LANG_UNAVAILABLE: 'Unavailable',
  LANG_ATTR_FREE_IMG_TITLE2: 'We are happy you found what you needed. 🎉',
  LANG_PLEASE_NOTE: 'Please note that this content piece does not come with any releases. Permission from the brand owners, copyright holders and recognizable individuals must be secured for commercial use.',
  LANG_PLEASE_ADVISED: 'Please be advised that images containing recognizable individuals do not come with any releases. To use these images commercially, permission must be obtained from the relevant parties. If the image does not contain recognizable individuals, there should be no copyright issues when using it commercially.',
  LANG_REACHED_LIMIT: 'You’ve reached your daily download limit!',
  LANG_COME_TOMORROW: 'We’re thrilled that you love our free images! Do come back tomorrow for a refresh on your download limit.',
  LANG_REACHED_LIMIT_PLUS: 'Daily Download limit reached',
  LANG_COME_TOMORROW_PLUS: 'Wow! You have reached your download limit for today. Please come back tomorrow to download more. Thank you for the support!',
  SEE_YOU_TOMORROW: 'See You Tomorrow!',
  LANG_DOWNLOAD_ERROR: 'Ops! Your image could not be downloaded due to an error.',
  LANG_REFRESH_AND_TRY_AGAIN: "Please refresh your browser and try again. <a href={url} target='_blank'>Let us know</a> if this issue persists.",
  ORIGINAL: 'Original',
  'AUTO_B&W': 'Auto B/W',
  AUTO_ADJUST: 'Auto Adjust',
  AUTO_POP: 'Auto Pop',
  NONE: 'None',
  LANG_CROP: 'Crop',
  LANG_ENABLE_EDITOR: 'Edit Image',
  LANG_DISABLE_EDITOR: 'Close',
  LANG_IMAGE_FREE_CC0: "This is a CC0 image. Attribution is not required. You may use it according to the <a class='linkCC0' href={url} target='_blank'>CC0 License Terms and Conditions</a>.",
  LANG_PREVIEW: 'Preview',
  LANG_ATTRIBUTION_CC0_TITLE: 'CC0 Image: Attribution Not Required',
  LANG_ATTRIBUTION_TITLE: 'Attribution Required',
  LANG_ATTRIBUTION_CONTENT: 'Attribution link will be provided after you downloaded the file.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'Attribution is not required as this is a CC0 image.',
  LANG_SUBCRIBE_PREMIUM: 'You’ve reached your daily download limit! To download more, subscribe to 123RF PREMIUM.',
  LANG_REMOVE_ATTRIBUTION: 'Remove Attribution?',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Subscribe to <img src={image} alt='123RF Plus Logo'> for unlimited access to 100m stock photos and illustrations with no attributions required.",
  LANG_CREATOR_ATTRIBUTION: 'Please credit the Creator with the attribution link below.',
  LANG_GET_ATTRIBUTION_LINK_2: 'Copy this link and place it in a location close to where the content is used, the footer of the website or a specific credits section of the site.',
  LANG_APPLY_BG_REMOVAL: 'Apply Background Removal',
  LANG_APPLYING_EDIT: 'Applying edit...',
  LANG_APPLYING_EDIT_ERROR: 'Something went wrong.',
  LANG_TRY_AGAIN: 'Try again?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Full Collection',
  LANG_SEARCHCOLLECTION_PLUS: 'Essential Collection',
  LANG_SEARCHCOLLECTION_FREE: 'Basic Collection',
  LANG_SIMILARIMAGESPAGE_TITLE: 'Stock Photos, Vectors and Royalty Free Images from 123RF',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Similar Stock Photos',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Oops! Nothing to see here.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Image Not Available',
  LANG_PER_IMAGE: '/image',
  LANG_PLANS_AND_PRICING: 'Plans & Pricing',
  LANG_CHOOSE_BEST_PLAN: 'Choose the best plan that fits your needs.',
  LANG_WANT_MORE_SAVING_1: 'Want more savings?',
  LANG_WANT_MORE_SAVING_2: 'See more plans from {price}/image',
  LANG_BEST_VALUE: 'Best Value, Save {percentage}%',
  LANG_YEARLY_PLAN: 'Yearly Plan',
  LANG_WITH_MONTHLY_PAYMENTS: 'With Monthly Payments',
  LANG_GRAB_YEARLY_PLAN: 'Grab a yearly plan and save {percentage}%.',
  LANG_PRICE_PER_MONTH: '{price} per month',
  LANG_PRICE_PER_YEAR: '{price} per year',
  LANG_GET_X_EVERY_MONTH: 'Get {quota} photos or vectors every month',
  LANG_X_EVERY_MONTH: '{quota} photos or vectors<br>every month',
  LANG_SINGLE_IMAGE_PURCHASE: 'Single Image Purchase',
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Get only what you need<br>for the moment.',
  LANG_TOTAL_PRICE: 'Total {price}',
  LANG_DOWNLOAD_IMMEDIATELY: 'Download the image immediately after checkout.',
  LANG_NEXT: 'Next',
  LANG_DOWNLOAD_PACK: 'Download Pack',
  LANG_GET_X_ANY_TIME: '{quota} photos or vectors any time',
  LANG_X_CREDITS: '{total} Credits',
  LANG_PER_CREDIT: '/Credit',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount} photos{videoCount, plural, =0 {} =1 { or # video} other { or # videos}}<br>any time',
  INCLUDES_STANDARD_LICENSE: 'Includes Standard License',
  LANG_MEDIUM_SIZE: 'Medium size',
  LANG_LARGE_SIZE: 'Large size',
  LANG_EXTRA_LARGE_SIZE: 'Extra Large size',
  LANG_STANDARD_SIZES: 'STANDARD SIZES',
  LANG_ROYALTY_FREE: 'px: A pixel is the basic unit of a digital image. Image size, measured in pixels, determines the detail level and download size.dpi: Dots per inch measures print resolution. Higher dpi means more detail. For printing, the recommended resolution for all images is 300 dpi',
  LANG_OPTIONS: 'Options',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Benefit from our PLUS plan',
  LANG_RELATED_FONT_SEARCH: 'Related Searches',
  AI_GENERATED_PHOTO: 'AI-generated image',
  FONT_PRICING_CREDIT: 'Credits',
  '3_FONTS_3_IMGS': '3 fonts <b>or</b> 3 images <b>or</b> 1 video',
  '9_FONTS_9_IMG_3_VIDEOS': '9 fonts <b>or</b> 9 images  <b>or</b> 3 videos',
  PURCHASE_AND_DOWNLOAD: 'Purchase & download',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Font (otf, ttf)',
  FONT_PACKS_LICENSE: '123RF Packs License',
  FONT_META_DESC: 'Download {product_title} font, typeface or typography to create stunning designs for print and web.',
  FONT_META_TITLE: 'Font - {product_title} | 123RF',
  FONT_SIMILAR: 'Similar fonts',
  LANG_PLANS: 'PLANS',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_LIMIT: 'Oh no! You have reached the limit to generate AI images for today.',
  LANG_GENERATE_COMEBACK: 'Come back tomorrow for more generations!',
  LANG_CONFIRM_OK: 'Okay',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Try creating variations',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: 'Generate new images based on the original image contributed. Plus, it’s a new way to reward our 123RF contributors every time an AI generated image is licensed!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'Our community thanks you. Our 123RF Contributor will be compensated when you download the image.',
  LANG_LICENSE_AND_DOWNLOAD: 'License & download',
  LANG_CONTRIBUTORS_AI_VARIATION: '123RF contributors of the original image are compensated for every AI variation licensed.',
  LANG_AI_SWITCH_CONTENT: 'Image editor is not supported for AI variations. All edits will be lost if you switch to AI variations.',
  LANG_GENERATE_VARIATIONS: 'Generate variations',
  LANG_GENERATE_WITH_AI: 'Generate images with AI',
  LANG_NEW: 'NEW',
  LANG_AI_GENERATOR_LICENSE: '123RF AI Generator Terms of Service (“Terms”)',
  LANG_I_UNDERSTAND: 'I understand',
  LANG_WHAT_IS_THIS: 'What is this?',
  LANG_AUTHENTIC: 'Authentic',
  LANG_ABSTRACT: 'Abstract',
  LANG_MACRO_CLOSEUP: 'Macro/Close up',
  LANG_OBJECT: 'Object',
  LANG_WATERCOLOR: 'Watercolor',
  LANG_FLAT: 'Flat',
  LANG_CARTOON: 'Cartoon',
  LANG_GEOMETRIC: 'Geometric',
  LANG_GRADIENT: 'Gradient',
  LANG_ISOMETRIC: 'Isometric',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: 'Hand-drawn',
  LANG_NATURAL: 'Natural',
  LANG_WARM: 'Warm',
  LANG_DRAMATIC: 'Dramatic',
  LANG_VIVID_BOLD: 'Vivid and bold',
  LANG_BLACK_WHITE: 'Black and white',
  LANG_ASIAN: 'Asian',
  LANG_AFRICAN_AMERICAN: 'African American',
  LANG_STANDARD: 'Standard',
  LANG_EXTENDED: 'Extended',
  LANG_STANDARD_CONTENT_1: 'Unlimited views for social, web, email, and mobile.',
  LANG_STANDARD_CONTENT_2: 'Up to 500,000 print-runs.',
  LANG_STANDARD_CONTENT_3: 'Not to be used on merchandise for sale.',
  LANG_EXTENDED_CONTENT_1: 'Unlimited views for social, web, email, and mobile.',
  LANG_EXTENDED_CONTENT_2: 'Unlimited print-runs.',
  LANG_EXTENDED_CONTENT_3: 'Can be used on merchandise for sale.',
  LANG_ORIGINAL_IMAGE: 'Original Image',
  LANG_IMPORTANT_NOTE: 'Important Note',
  LANG_AI_ORIGINAL_ALTERED: 'AI variations are variants of the original image that have been altered using AI technology. It is similar to having an AI editor touch up your chosen image.',
  LANG_GENERATE_VARIATIONS_NOTE: 'As we are currently in the Beta stage of AI-powered Image Variations, the generated images(s) may produce unexpected results. We are working hard to ensure that such results and variations conform to ethical and responsible AI guidelines.',
  LANG_LICENSE_AI_EDITED: 'When you download the generated variation of the image, the 123RF contributor will be compensated.',
  LANG_MOOD: 'Mood',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Eg. Green colour, straight hair',
  LANG_SIMILAR_STOCK: 'Similar stock images',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'with Model Release',
  WITH_PROPERTY_RELEASE: 'with Property Release',
  MODEL_RELEASE_CONTENT: 'A model release, is a legal document signed by the model(s) of a photograph granting permission to publish / distribute / use his or her likeness in the photograph in one form or another.',
  PROPERTY_RELEASE_CONTENT: 'A property release, is a legal document signed by the owner of the object, subject or premise at which a photograph was taken, granting permission to publish / distribute / use the photograph in one form or another.',

  variousStockGAI: 'A legally compliant AI model trained with millions of stock photos and optimized for enterprise needs giving peace of mind with liability coverage.',
  sizes: 'Sizes',
};

export default en;
